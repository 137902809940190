import FilterIcon from "./linear";

export default FilterIcon;
export { default as FilterIcon, iconInfo as FilterIconInfo } from "./linear";

export {
    default as FilterBoldIcon,
    iconInfo as FilterBoldIconInfo,
} from "./bold";
export {
    default as FilterBrokenIcon,
    iconInfo as FilterBrokenIconInfo,
} from "./broken";
export {
    default as FilterBulkIcon,
    iconInfo as FilterBulkIconInfo,
} from "./bulk";
export {
    default as FilterLinearIcon,
    iconInfo as FilterLinearIconInfo,
} from "./linear";
export {
    default as FilterOutlineIcon,
    iconInfo as FilterOutlineIconInfo,
} from "./outline";
export {
    default as FilterTwotoneIcon,
    iconInfo as FilterTwotoneIconInfo,
} from "./twotone";
