import type { ReactNode } from "react";

export type ToastPositions =
    | `top${"-left" | "-right" | ""}`
    | `bottom${"-left" | "-right" | ""}`;
export type ToastStatus = "success" | "error" | "warning" | "info";

export interface ToastData {
    className?: string;
    description?: string;
    duration?: number;
    id: string;
    isClosable?: boolean;
    position?: ToastPositions;
    render?: ReactNode | ((onClose: () => void) => ReactNode);
    status?: ToastStatus;
    title?: string;
}

export const toastVariants: Record<ToastStatus, string> = {
    error: "bg-red-400/80 hover:bg-red-400 text-lightGrey-50",
    info: "bg-doladoBlue/80 hover:bg-doladoBlue text-lightGrey-50",
    success: "bg-olive/80 hover:bg-olive text-lightGrey-50",
    warning: "bg-orange/80 hover:bg-orange text-lightGrey-50",
};

export const toastPositions: Record<ToastPositions, string> = {
    bottom: "flex-col-reverse items-center bottom-16 left-1/2 -translate-x-1/2",
    "bottom-left": "flex-col-reverse items-start bottom-16 left-16",
    "bottom-right": "flex-col-reverse items-end bottom-16 right-16",
    top: "flex-col items-center top-16 left-1/2 -translate-x-1/2",
    "top-left": "flex-col items-start top-16 left-16",
    "top-right": "flex-col items-end top-16 right-16",
};

export const toastIconVariants: Record<ToastStatus, string> = {
    error: "bg-lightGrey-50 text-red-400",
    info: "bg-lightGrey-50 text-doladoBlue",
    success: "bg-lightGrey-50 p-4 text-olive",
    warning: "bg-lightGrey-50 text-orange",
};

export const toastCommonStyle =
    "relative cursor-pointer flex gap-16 max-w-max min-w-280 rounded-4 shadow-md p-16 text-sm";
