import type { TailwindPattern } from "../interface";

const transformPattern: TailwindPattern = {
    base: /scale|rotate|translate|skew|origin/,
    variants: [
        { base: /scale-x/, arbitrary: "size" },
        { base: /scale-y/, arbitrary: "size" },
        { base: /scale/, arbitrary: "size" },
        { base: /translate-x/, arbitrary: "size" },
        { base: /translate-y/, arbitrary: "size" },
        { base: /skew-x/, arbitrary: "angle" },
        { base: /skew-y/, arbitrary: "angle" },
        { base: /rotate/, arbitrary: "angle" },
        { base: /origin/, arbitrary: "position" },
    ],
    arbitrary: "any",
};

export default transformPattern;

export const transformSample = [
    ["scale-x-10", "-scale-x-20", "scale-x-[1.7]", "scale-x-[-50%]"],
    ["scale-y-10", "-scale-y-20", "scale-y-[1.7]", "scale-y-[-50%]"],
    ["scale-10", "-scale-20", "scale-[1.7]", "scale-[-50%]"],
    [
        "translate-x-px",
        "translate-x-full",
        "translate-x-4",
        "translate-x-1.5",
        "translate-x-1/2",
        "-translate-x-6",
        "translate-x-[17rem]",
        "translate-x-[-5px]",
    ],
    [
        "translate-y-px",
        "translate-y-full",
        "translate-y-4",
        "translate-y-1.5",
        "translate-y-1/2",
        "-translate-y-6",
        "translate-y-[17rem]",
        "translate-y-[-5px]",
    ],
    ["skew-x-2", "-skew-x-4", "skew-x-[15deg]", "skew-x-[-5deg]"],
    ["skew-y-2", "-skew-y-4", "skew-y-[15deg]", "skew-y-[-5deg]"],
    ["rotate-2", "-rotate-4", "rotate-[15deg]", "rotate-[-5deg]"],
    [
        "origin-center",
        "origin-top",
        "origin-top-right",
        "origin-top-left",
        "origin-bottom",
        "origin-bottom-right",
        "origin-bottom-left",
        "origin-right",
        "origin-left",
        "origin-[33%_75%]",
    ],
];
