import { DEFAULT_STATUS } from "@redux/utils/helpers";
import type { PersistentState } from "@redux/utils/interface";

import type { SkuCatalogData } from "@services/skus/catalog/interface";
import type { GetSkusCatalogParams } from "@services/skus/catalog/get";

export interface CategoryInfo {
    AccountName: string;
    IsActive: 0 | 1;
    Name: string;
    label: string;
    TotalSkus: number;
    value: number;
}

export type State = PersistentState<{
    companyId: string | null;
    companyName: string | null;
    categories: {
        data: CategoryInfo[];
        status: DEFAULT_STATUS;
    };
    products: {
        data: (SkuCatalogData | null)[];
        pageInfo: {
            limit: number;
            page: number;
            total: number;
        };
        status: DEFAULT_STATUS;
        query: Pick<
            GetSkusCatalogParams,
            "categoryId" | "search" | "hasKitsRelated" | "type"
        >;
    };
}>;

export const moduleId = "skusCatalogGridLayout" as const;

export const initialState: State = {
    hydrated: false,
    companyId: null,
    companyName: null,
    categories: {
        data: [],
        status: DEFAULT_STATUS.PENDING,
    },
    products: {
        data: [],
        pageInfo: {
            limit: 0,
            page: 0,
            total: 0,
        },
        status: DEFAULT_STATUS.PENDING,
        query: {},
    },
};
