"use client";

import type { FC, ReactElement, SVGProps } from "react";

import { lazy, Suspense, useMemo, useRef } from "react";

import type { IconNames, IconVariants } from "./interface";

import { classes } from "@utils/styles/tailwind/v4";

async function loadMock(icon: IconNames, variant: IconVariants) {
    return import(
        /* webpackChunkName: "mocks-[request]" */
        /* webpackExports: ["ReactComponent"] */
        `./mocks/${icon}/${variant}.svg`
    )
        .then((md) => ({
            default: md.ReactComponent as FC<SVGProps<SVGElement>>,
        }))
        .catch(() => ({ default: null }));
}

async function manageLoading(
    icon: IconNames,
    variant: IconVariants,
    iconFallback?: IconNames,
    variantFallback?: IconVariants,
) {
    let md = await loadMock(icon, variant);
    if (!md.default) {
        md = await loadMock(icon, variantFallback ?? "linear");
    }
    if (!md.default && iconFallback) {
        md = await loadMock(iconFallback, variantFallback ?? "linear");
    }
    return md;
}

export interface IconProps extends Omit<SVGProps<SVGElement>, "ref"> {
    icon: IconNames;
    iconFallback?: IconNames;
    variant?: IconVariants;
    variantFallback?: IconVariants;
}

const Icon: FC<IconProps> = ({
    className,
    icon,
    iconFallback,
    variant = "linear",
    variantFallback = "linear",
    ...rest
}) => {
    const fallback = useRef<ReactElement | null>(
        <div className={`w-24 h-24 ${className}`} />,
    );

    const SVG = useMemo(
        () =>
            lazy(async () => {
                const md = await manageLoading(
                    icon,
                    variant,
                    iconFallback,
                    variantFallback,
                );
                fallback.current = md.default && (
                    <md.default
                        className={classes`w-24 h-24 ${className}`}
                        {...rest}
                    />
                );
                return {
                    default: (props: SVGProps<SVGElement>) =>
                        md.default && <md.default {...props} />,
                };
            }),
        [className, icon, iconFallback, rest, variant, variantFallback],
    );

    return (
        <Suspense fallback={fallback.current}>
            <SVG
                aria-hidden="true"
                focusable="false"
                role="img"
                className={classes`w-24 h-24 ${className}`}
                {...rest}
            />
        </Suspense>
    );
};

export default Icon;

export type MockedIcon = FC<
    Omit<IconProps, "icon" | "iconFallback" | "variant" | "variantFallback">
>;

export function MountMockedIcon(
    info: Pick<
        IconProps,
        "icon" | "iconFallback" | "variant" | "variantFallback"
    >,
): MockedIcon {
    const MockedIcon: MockedIcon = (props) => <Icon {...info} {...props} />;
    MockedIcon.displayName = `MockedIcon(${info.icon
        .split("-")
        .map((el) => el[0].toUpperCase() + el.slice(1))
        .join("")})`;
    return MockedIcon;
}
