import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronLeftOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 11.9993 20.67 C 11.8093 20.67 11.6193 20.6 11.4693 20.45 L 4.9493 13.93 C 3.8893 12.87 3.8893 11.13 4.9493 10.07 L 11.4693 3.55 C 11.7593 3.26 12.2393 3.26 12.5293 3.55 C 12.8193 3.84 12.8193 4.32 12.5293 4.61 L 6.0093 11.13 C 5.5293 11.61 5.5293 12.39 6.0093 12.87 L 12.5293 19.39 C 12.8193 19.68 12.8193 20.16 12.5293 20.45 C 12.3793 20.59 12.1893 20.67 11.9993 20.67 Z"
                fill="currentColor"
            />
            <path
                d="M 17.9993 20.67 C 17.8093 20.67 17.6193 20.6 17.4693 20.45 L 10.9493 13.93 C 9.8893 12.87 9.8893 11.13 10.9493 10.07 L 17.4693 3.55 C 17.7593 3.26 18.2393 3.26 18.5293 3.55 C 18.8193 3.84 18.8193 4.32 18.5293 4.61 L 12.0093 11.13 C 11.5293 11.61 11.5293 12.39 12.0093 12.87 L 18.5293 19.39 C 18.8193 19.68 18.8193 20.16 18.5293 20.45 C 18.3793 20.59 18.1893 20.67 17.9993 20.67 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
