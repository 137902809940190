import type { PartsInfo, PatternChecker, PatternMatches } from "./interface";

export function parsePartsInfo(cls: string): PartsInfo {
    const temp = cls.split(":");
    return [
        cls,
        [temp.slice(0, -1).join(":"), temp[temp.length - 1]],
        temp[temp.length - 1].split("-"),
    ];
}

export function filterPartInfo(
    classes: PartsInfo[],
    parts: PartsInfo,
): PartsInfo[] {
    return classes.filter(
        (parts2) =>
            parts2[0] !== parts[0] &&
            parts2[1][0] === parts[1][0] &&
            (parts2[2][0] || parts2[2][1]) === (parts[2][0] || parts[2][1]),
    );
}

function applyRegExp(
    regex: RegExp,
    classes: (string | PartsInfo)[],
): PartsInfo[] {
    return classes
        .map((cls) => (typeof cls === "string" ? parsePartsInfo(cls) : cls))
        .filter((parts) => parts[1][1].match(regex));
}

// TODO Adicionar seleção de classes por modificador para melhorar performance

export function applyPatternChecker(
    pattern: PatternChecker,
    classes: (string | PartsInfo)[],
): PatternMatches {
    const matches: PatternMatches = { base: [], variants: [] };
    matches.base = applyRegExp(pattern.base, classes);
    if (matches.base.length > 1) {
        for (const vrt of pattern.variants) {
            if (vrt instanceof RegExp) {
                matches.variants.push(applyRegExp(vrt, matches.base));
            } else {
                matches.variants.push(applyPatternChecker(vrt, matches.base));
            }
        }
    }
    return matches;
}

export function filterPatternMatches(
    matches: PatternMatches,
    checked: string[] = [],
    deleted: string[] = [],
): [string[], string[]] {
    if (matches.variants.length) {
        for (const vrt of matches.variants) {
            if (Array.isArray(vrt)) {
                for (const parts of vrt) {
                    if (
                        checked.includes(parts[0]) ||
                        deleted.includes(parts[0])
                    )
                        continue;
                    checked.push(parts[0]);
                    const filtered = filterPartInfo(vrt, parts).map(
                        ([cls]) => cls,
                    );
                    deleted.push(...filtered);
                }
            } else {
                filterPatternMatches(vrt, checked, deleted);
            }
        }
    } else {
        for (const parts of matches.base) {
            if (checked.includes(parts[0]) || deleted.includes(parts[0]))
                continue;
            checked.push(parts[0]);
            const filtered = matches.base
                .filter(
                    (parts2) =>
                        parts2[0] !== parts[0] && parts2[1][0] === parts[1][0],
                )
                .map(([cls]) => cls);
            deleted.push(...filtered);
        }
    }

    return [checked, deleted];
}
