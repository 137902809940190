import BoxAddIcon from "./linear";

export default BoxAddIcon;
export { default as BoxAddIcon, iconInfo as BoxAddIconInfo } from "./linear";

export {
    default as BoxAddBoldIcon,
    iconInfo as BoxAddBoldIconInfo,
} from "./bold";
export {
    default as BoxAddBrokenIcon,
    iconInfo as BoxAddBrokenIconInfo,
} from "./broken";
export {
    default as BoxAddBulkIcon,
    iconInfo as BoxAddBulkIconInfo,
} from "./bulk";
export {
    default as BoxAddLinearIcon,
    iconInfo as BoxAddLinearIconInfo,
} from "./linear";
export {
    default as BoxAddOutlineIcon,
    iconInfo as BoxAddOutlineIconInfo,
} from "./outline";
export {
    default as BoxAddTwotoneIcon,
    iconInfo as BoxAddTwotoneIconInfo,
} from "./twotone";
