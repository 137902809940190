import type { Dispatch, MutableRefObject, SetStateAction } from "react";

import { useCallback, useRef, useState } from "react";

export default function useStateWithRef<T>(
    initState: T,
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
    const [state, _setState] = useState(initState);
    const ref = useRef(state);
    const setState = useCallback((newState: SetStateAction<T>) => {
        ref.current =
            typeof newState === "function"
                ? (newState as (prev: T) => T)(ref.current)
                : newState;
        _setState(ref.current);
    }, []);
    return [state, setState, ref];
}
