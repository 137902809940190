import { lazy } from "react";

import { staticContent } from "./relations";
import type { ModuleSpecs, ModuleItem } from "./interface";

import { applyParams, extractParams } from "@router/helpers";
import { routeNames } from "@router";

import createUUID from "@utils/cryptography/createUUID";

export function loadComponent({ path, index }: ModuleSpecs) {
    const loader = index
        ? () => import("./" + path)
        : () => import("../../pages/" + path);

    return lazy(loader);
}

export async function FrontJson(page: string) {
    const content = staticContent[page];
    switch (content?.index) {
        case 0:
            return await import(`../../controllers/front/${content.path}`).then(
                (md) => md.default,
            );
        default:
            return [];
    }
}

export function parseModuleSpecs(
    { fixed, route, tag, title }: ModuleSpecs,
    overwrite?: Partial<ModuleItem>,
): ModuleItem {
    const paramsKeys = route ? extractParams(route) : [];
    const params: Record<string, any> = {};
    for (const key of paramsKeys) {
        params[key] = null;
    }
    if (overwrite?.params) {
        for (const key in overwrite.params) {
            if (key === "*") continue;
            params[key] = overwrite.params[key];
        }
    }
    return {
        key: overwrite?.key || createUUID(),
        tag: overwrite?.tag || tag,
        title: overwrite?.title || title,
        params,
        ...((overwrite?.fixed || fixed) && {
            fixed: overwrite?.fixed || fixed,
        }),
        ...((overwrite?.route || route) && {
            route: overwrite?.route || route,
        }),
        ...(overwrite?.oldKey && { oldKey: overwrite.oldKey }),
        ...(overwrite?.search && { search: overwrite.search }),
    };
}

export function mapSearchToModule(
    md: ModuleItem,
    search: URLSearchParams,
): void {
    const paramsKeys = md.route ? extractParams(md.route) : [];
    const newParams: Record<string, any> = {};
    const newSearch = new URLSearchParams();
    for (const key of paramsKeys) {
        newParams[key] = md.params[key] || null;
        newSearch.set(key, md.params[key] || "");
    }
    for (const key of search.keys()) {
        if (key === "component") continue;
        if (key === "title") {
            md.title = search.get(key) || "";
            continue;
        }
        if (search.get(key)) {
            newParams[key] = search.get(key) || "";
            newSearch.set(key, search.get(key) || "");
        }
    }
    md.params = newParams;
    md.search = newSearch.size ? `?${newSearch.toString()}` : "";
}

export function formatURL({ route, params }: ModuleItem): string {
    const paramsKeys = route ? extractParams(route) : [];
    const temp = new URLSearchParams();
    for (const key in params) {
        if (paramsKeys.includes(key) || key === "*") continue;
        temp.append(key, params[key]);
    }
    const url =
        applyParams(route || routeNames.APP_HOME, params) +
        (temp.size ? `?${temp.toString()}` : "");
    return url;
}

export function compareModules(
    md1: Partial<ModuleItem>,
    md2: Partial<ModuleItem>,
): boolean {
    if (md1.key && md2.key && md1.key === md2.key) return true;
    if (md1.oldKey && md2.oldKey && md1.oldKey === md2.oldKey) return true;
    if (md1.tag && md2.tag && md1.tag === md2.tag) {
        const routeParams1 = md1.route ? extractParams(md1.route) : [];
        const routeParams2 = md2.route ? extractParams(md2.route) : [];
        const params1 = md1.params || {};
        const params2 = md2.params || {};
        if (!routeParams1.length && !routeParams2.length) return true;
        if (
            routeParams1.length &&
            routeParams2.length &&
            routeParams1.length === routeParams2.length &&
            routeParams1.every(
                (param) =>
                    routeParams2.includes(param) &&
                    params1[param] === params2[param],
            )
        )
            return true;
    }
    return false;
}
