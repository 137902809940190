import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ShoppingCartBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M4.75064 13.9699C4.61064 15.5999 5.90064 16.9999 7.54064 16.9999H18.1906C19.6306 16.9999 20.8906 15.8199 21.0006 14.3899L21.5406 6.88989C21.6606 5.22989 20.4006 3.87988 18.7306 3.87988H5.82065"
                stroke="currentColor"
            />
            <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L5.08 10.05"
                stroke="currentColor"
            />
            <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
            />
            <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
            />
            <path d="M9 8H21" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
