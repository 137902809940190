import Subdomain from "./Subdomain.js";
import Domain from "./Domain.js";

import { LOCAL_STORAGE_NAMES } from "@constants/localStorage";

const domain = Domain();
const subdomain = Subdomain();
const api = process.env.REACT_APP_API_DOMAIN || "";

var config = {
    bcrypt: {
        saltRounds: 10,
    },
    Api: api + "/1.0",
    ApiUrl: "https://" + subdomain + "." + api + "/1.0",
    PhpUrl: "https://worker." + api,
    ApiUrlAdmin: "https://admin." + api + "/1.0",
    Domain: `http://${domain}`,
    UrlFront:
        domain === "localhost"
            ? `http://${domain}`
            : `https://${subdomain}.${domain}`,
    // Domain: domain,
    // UrlFront:
    //     domain.substring(0, 16) === "http://localhost"
    //         ? domain
    //         : "https://" + subdomain + "." + domain,
    Subdomain: subdomain,
    ApiToken: localStorage.getItem(LOCAL_STORAGE_NAMES.ACCESS_TOKEN),
    ApiContentType: "application/json",
    port: process.env.NODE_ENV === "production" ? "" : "8080",
    hostUI: process.env.NODE_ENV === "production" ? "" : "http://localhost",
    portUI: process.env.NODE_ENV === "production" ? "" : "3000",
};

config.host =
    process.env.NODE_ENV === "production" ? config.remote : "http://localhost";
config.apiUrl = `${config.host}${config.port ? `:${config.port}` : ``}`;

localStorage.setItem(
    "ConfigApp",
    JSON.stringify({ Domain: config.Domain, Subdomain: config.Subdomain }),
);
export default config;
