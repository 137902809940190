type Relation = Record<
    string,
    ((...args: any[]) => any) | Record<string, (...args: any[]) => any>
>;

export function mapSelectorRelations(
    target: Relation,
    ...relations: Relation[]
) {
    for (const relation of relations) {
        const keys = Object.getOwnPropertyNames(relation);
        for (const key of keys) {
            if (typeof relation[key] === "function") {
                target[key] = relation[key];
            } else {
                target[key] = Object.assign({}, target[key], relation[key]);
            }
        }
    }
}
