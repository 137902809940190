import type { ComponentType } from "react";

import { createContext } from "react";

export type StackItem = {
    key: string;
    tag: string;
    props?: Record<string, any>;
};
export type StackEntry<Tags extends string = string> = {
    tag: Tags;
    props?: Record<string, any>;
};

export interface SetModal<Tags extends string = string> {
    (entries: StackEntry<Tags>[]): void;
}
export interface PushModal<Tags extends string = string> {
    (tag: Tags, props?: Record<string, any>): [string, () => void];
}

export interface ModalManagerInjectedProps<Tags extends string = string> {
    _modalManager: Pick<ModalManagerContext, "clear" | "pop"> & {
        close: () => void;
        push: PushModal<Tags>;
        set: SetModal<Tags>;
        itemKey: string;
        zIndex: number;
    };
}

export type ModalManagerComponent<
    Props extends Record<string, any> = NonNullable<unknown>,
    Tags extends string = string,
> = Props extends Record<string, never>
    ? ComponentType<ModalManagerInjectedProps<Tags>>
    : ComponentType<Props & ModalManagerInjectedProps<Tags>>;

export interface ModalManagerContext {
    appendPool: (tag: string, Component: ModalManagerComponent) => void;
    clear: (keys?: string[]) => void;
    current: string | null;
    pop: () => void;
    push: PushModal;
    removePool: (tag: string) => void;
    set: SetModal;
    stack: StackItem[];
}

export const ModalManagerContext = createContext<ModalManagerContext>({
    appendPool: () => "",
    clear: () => null,
    current: null,
    pop: () => null,
    push: () => ["", () => null],
    removePool: () => null,
    set: () => null,
    stack: [],
});
