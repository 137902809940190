import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DangerBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M12 9V14" stroke="currentColor" />
            <path
                d="M20.2394 14L21.2994 15.9C22.9794 18.93 21.5194 21.41 18.0594 21.41H11.9994H5.93944C2.46944 21.41 1.01944 18.93 2.69944 15.9L5.81944 10.28L8.75944 5.00003C10.5394 1.79003 13.4594 1.79003 15.2394 5.00003L18.1794 10.29"
                stroke="currentColor"
            />
            <path
                d="M11.9941 17H12.0031"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
