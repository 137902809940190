import type { FormEvent } from "react";

import { coupled, length, numeric, required, validate } from "../tests";
import type { ErrorTestFunction } from "../tests/interface";
import { parseLengthParams } from "../tests/length/helpers";
import type { LengthParams } from "../tests/length";
import { parseNumericParams } from "../tests/numeric/helpers";
import type { NumericParams } from "../tests/numeric";
import type { ValidatorFunction } from "../tests/validate";

interface ValidationOptions {
    validators?: Record<string, ValidatorFunction>;
}

export type ErrorRelation<Data extends Record<string, any>> = Partial<
    Record<keyof Data, string[]>
>;

type TestsTypes = "compare" | "length" | "numeric" | "required" | "validate";

export const testsMap: Record<
    TestsTypes,
    (
        form: HTMLFormElement,
        current: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
        options: ValidationOptions,
    ) => ReturnType<ErrorTestFunction>
> = {
    compare: (form, { name, value, dataset }) => {
        const compare = dataset.compare as string;
        const el = form.elements.namedItem(compare) as
            | HTMLInputElement
            | HTMLTextAreaElement;
        return coupled(
            dataset.label || name,
            { value },
            {
                name: el.dataset.label ?? el.name,
                value: el.value,
            },
        );
    },
    length: (_, field) => {
        const { name, value, dataset } = field;
        let params: LengthParams = {};
        if (
            (field instanceof HTMLInputElement ||
                field instanceof HTMLTextAreaElement) &&
            field.maxLength !== -1 &&
            field.minLength !== -1 &&
            (field.maxLength < 524288 || field.minLength > 0)
        ) {
            params = { lte: field.maxLength, gte: field.minLength };
        } else {
            params = parseLengthParams(dataset.length);
        }
        return length(params)(dataset.label || name, { value });
    },
    numeric: (_, field) => {
        const { name, value, dataset } = field;
        let params: NumericParams = {};
        if (
            field instanceof HTMLInputElement &&
            (field.max || field.min || field.step)
        ) {
            params = {
                ...(!!field.min && {
                    gte: parseFloat(field.min),
                }),
                ...(!!field.max && {
                    lte: parseFloat(field.max),
                }),
                ...(!!field.step && {
                    step: parseFloat(field.step),
                }),
            };
        } else {
            params = parseNumericParams(dataset.numeric);
        }
        return numeric(params)(dataset.label || name, { value });
    },
    required: (_, field) => {
        const { name, value, dataset } = field;
        return required(dataset.label || name, {
            value,
            ...(field instanceof HTMLInputElement &&
                ["checkbox", "radio"].includes(field.type) && {
                    checked: field.checked,
                }),
        });
    },
    validate: async (_, { name, value, dataset }, { validators }) => {
        const type = dataset.validate as string;
        return await validate(type, validators)(dataset.label || name, {
            value,
        });
    },
};

export async function validateField(
    form: HTMLFormElement,
    current: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement,
    options: ValidationOptions,
): Promise<string[] | undefined> {
    let temp;
    const errors: string[] = [];

    const { dataset, name } = current;

    if (current.required) {
        temp = await testsMap.required(form, current, options);
        if (temp) errors.push(temp);
    }

    if (
        (current instanceof HTMLInputElement ||
            current instanceof HTMLTextAreaElement) &&
        current.maxLength !== -1 &&
        current.minLength !== -1 &&
        (current.maxLength < 524288 || current.minLength > 0)
    ) {
        temp = await testsMap.length(form, current, options);
        if (temp) errors.push(temp);
    }

    if (
        current instanceof HTMLInputElement &&
        current.type === "number" &&
        (current.max || current.min || current.step)
    ) {
        temp = await testsMap.numeric(form, current, options);
        if (temp) errors.push(temp);
    }

    for (const test in testsMap) {
        if (current.dataset[test]) {
            temp = await testsMap[test as TestsTypes](form, current, options);
            if (temp) errors.push(temp);
        }
    }

    if (!current.checkValidity() || errors.length) {
        errors.push(`${dataset.label || name} inválido`);
    }

    if (errors.length) return errors;
}

export async function validateForm<Data extends Record<string, any>>(
    event: FormEvent<HTMLFormElement>,
    options: ValidationOptions = {},
): Promise<ErrorRelation<Data> | undefined> {
    const form = event.target as HTMLFormElement;

    const errors: ErrorRelation<Data> = {};
    for (const el of form.elements) {
        if (
            (!(el instanceof HTMLInputElement) &&
                !(el instanceof HTMLSelectElement) &&
                !(el instanceof HTMLTextAreaElement)) ||
            el.disabled
        )
            continue;

        const temp = await validateField(form, el, options);
        if (temp) {
            const name = el.name as Extract<keyof Data, string>;
            if (errors[name]) {
                errors[name]?.push(...temp);
            } else {
                errors[name] = temp;
            }
        }
    }

    if (Object.keys(errors).length) return errors;
}
