import type { FC, ReactNode } from "react";

import { ConfigProvider } from "antd";

import { useSession } from "./session";

import CircularProgress from "@old/components/CircularProgress";

import AppLocale from "@old/lngProvider";

const ThemeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
    const { loading } = useSession();

    if (loading) return <CircularProgress className="" />;
    return (
        <ConfigProvider locale={AppLocale.pt.antd}>{children}</ConfigProvider>
    );
};

export default ThemeProvider;
