import SearchNormalIcon from "./linear";

export default SearchNormalIcon;
export {
    default as SearchNormalIcon,
    iconInfo as SearchNormalIconInfo,
} from "./linear";

export {
    default as SearchNormalBoldIcon,
    iconInfo as SearchNormalBoldIconInfo,
} from "./bold";
export {
    default as SearchNormalBrokenIcon,
    iconInfo as SearchNormalBrokenIconInfo,
} from "./broken";
export {
    default as SearchNormalBulkIcon,
    iconInfo as SearchNormalBulkIconInfo,
} from "./bulk";
export {
    default as SearchNormalLinearIcon,
    iconInfo as SearchNormalLinearIconInfo,
} from "./linear";
export {
    default as SearchNormalOutlineIcon,
    iconInfo as SearchNormalOutlineIconInfo,
} from "./outline";
export {
    default as SearchNormalTwotoneIcon,
    iconInfo as SearchNormalTwotoneIconInfo,
} from "./twotone";
