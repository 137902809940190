import NotificationIcon from "./linear";

export default NotificationIcon;
export {
    default as NotificationIcon,
    iconInfo as NotificationIconInfo,
} from "./linear";

export {
    default as NotificationBoldIcon,
    iconInfo as NotificationBoldIconInfo,
} from "./bold";
export {
    default as NotificationBrokenIcon,
    iconInfo as NotificationBrokenIconInfo,
} from "./broken";
export {
    default as NotificationBulkIcon,
    iconInfo as NotificationBulkIconInfo,
} from "./bulk";
export {
    default as NotificationLinearIcon,
    iconInfo as NotificationLinearIconInfo,
} from "./linear";
export {
    default as NotificationOutlineIcon,
    iconInfo as NotificationOutlineIconInfo,
} from "./outline";
export {
    default as NotificationTwotoneIcon,
    iconInfo as NotificationTwotoneIconInfo,
} from "./twotone";
