import type { CompanyIntegration } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "@old/configs/rest/lambdas";

interface GetCompanyIntegrationsParams {
    IDTypeCompanyIntegrationCategory?: string | number;
    Status?: 0 | 1;
}

export default async function getCompanyIntegrations(
    params: GetCompanyIntegrationsParams = {},
    { signal }: RequestOptions = {},
): Promise<CompanyIntegration[]> {
    try {
        const { data } = await lambdas.get<CompanyIntegration[]>(
            "/company/integration",
            { signal, params },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
