import type { TailwindPattern } from "../interface";

const overflowPattern: TailwindPattern = {
    base: /overflow/,
    variants: [/overflow-x/, /overflow-y/, /overflow/],
};

export default overflowPattern;

export const overflowSample = [
    [
        "overflow-x-auto",
        "overflow-x-hidden",
        "overflow-x-clip",
        "overflow-x-visible",
        "overflow-x-scroll",
    ],
    [
        "overflow-y-auto",
        "overflow-y-hidden",
        "overflow-y-clip",
        "overflow-y-visible",
        "overflow-y-scroll",
    ],
    [
        "overflow-auto",
        "overflow-hidden",
        "overflow-clip",
        "overflow-visible",
        "overflow-scroll",
    ],
];
