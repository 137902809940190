import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CalendarBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M8 2V5" stroke="currentColor" />
            <path d="M16 2V5" stroke="currentColor" />
            <path d="M3.5 9.08984H20.5" stroke="currentColor" />
            <path
                d="M3 13.01V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17"
                stroke="currentColor"
            />
            <path
                d="M15.6937 13.7H15.7027"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M15.6937 16.7H15.7027"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.9945 13.7H12.0035"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.9945 16.7H12.0035"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M8.29529 13.7H8.30427"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M8.29529 16.7H8.30427"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
