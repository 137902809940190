import TickCircleIcon from "./linear";

export default TickCircleIcon;
export {
    default as TickCircleIcon,
    iconInfo as TickCircleIconInfo,
} from "./linear";

export {
    default as TickCircleBoldIcon,
    iconInfo as TickCircleBoldIconInfo,
} from "./bold";
export {
    default as TickCircleBrokenIcon,
    iconInfo as TickCircleBrokenIconInfo,
} from "./broken";
export {
    default as TickCircleBulkIcon,
    iconInfo as TickCircleBulkIconInfo,
} from "./bulk";
export {
    default as TickCircleLinearIcon,
    iconInfo as TickCircleLinearIconInfo,
} from "./linear";
export {
    default as TickCircleOutlineIcon,
    iconInfo as TickCircleOutlineIconInfo,
} from "./outline";
export {
    default as TickCircleTwotoneIcon,
    iconInfo as TickCircleTwotoneIconInfo,
} from "./twotone";
