import type { Draft } from "immer";

import type { PayloadAction } from "@reduxjs/toolkit";

import type { AsyncThunkSliceReducerConfig } from "@reduxjs/toolkit/dist/createSlice";

import { DEFAULT_STATUS } from "../helpers";
import type { StatusState } from "../helpers";

export function processActionStatus<
    State,
    Payload = void,
    Params = never,
>(configs: {
    mapState: (
        state: Draft<State>,
        params?: Params,
    ) =>
        | (Pick<StatusState, "status"> &
              Partial<Pick<StatusState, "message" | "requestId">>)
        | undefined;
    fulfilled?: {
        message?: string;
        callback?: (
            state: Draft<State>,
            action: PayloadAction<
                Payload,
                string,
                {
                    arg: Params;
                    requestId: string;
                    requestStatus: "fulfilled";
                }
            >,
        ) => void;
    };
    rejected?: {
        message?: string;
        callback?: (
            state: Draft<State>,
            action: PayloadAction<
                Payload,
                string,
                {
                    arg: Params;
                    requestId: string;
                    requestStatus: "rejected";
                    aborted: boolean;
                    condition: boolean;
                }
            >,
        ) => void;
    };
    parseMessage?: boolean;
    trackId?: boolean;
}): Required<
    Pick<
        AsyncThunkSliceReducerConfig<State, unknown>,
        "fulfilled" | "pending" | "rejected"
    >
> {
    return {
        pending: (state, action) => {
            const statusState = configs.mapState(
                state,
                action.meta.arg as Params,
            );
            if (!statusState) return;
            statusState.status = DEFAULT_STATUS.PROCESSING;
            if (configs.parseMessage) statusState.message = null;
            if (configs.trackId) statusState.requestId = action.meta.requestId;
        },
        rejected: (state, action) => {
            const statusState = configs.mapState(
                state,
                action.meta.arg as Params,
            );
            if (!statusState) return;
            if (
                !configs.trackId ||
                statusState.requestId === action.meta.requestId
            ) {
                if (action.meta.aborted || action.payload === "canceled") {
                    statusState.status = DEFAULT_STATUS.IDLE;
                } else {
                    statusState.status = DEFAULT_STATUS.FAILURE;
                    if (configs.parseMessage) {
                        statusState.message =
                            typeof action.payload === "string"
                                ? action.payload
                                : action.error.message ||
                                  configs.rejected?.message ||
                                  "Erro ao realizar ação";
                    }
                }
            }
            configs.rejected?.callback?.(
                state,
                action as PayloadAction<
                    Payload,
                    string,
                    {
                        arg: Params;
                        requestId: string;
                        requestStatus: "rejected";
                        aborted: boolean;
                        condition: boolean;
                    }
                >,
            );
        },
        fulfilled: (state, action) => {
            const statusState = configs.mapState(
                state,
                action.meta.arg as Params,
            );
            if (!statusState) return;
            if (
                !configs.trackId ||
                statusState.requestId === action.meta.requestId
            ) {
                statusState.status = DEFAULT_STATUS.SUCCESS;
                if (configs.parseMessage) {
                    statusState.message =
                        configs.fulfilled?.message ||
                        "Ação realizada com sucesso";
                }
            }
            configs.fulfilled?.callback?.(
                state,
                action as PayloadAction<
                    Payload,
                    string,
                    {
                        arg: Params;
                        requestId: string;
                        requestStatus: "fulfilled";
                    }
                >,
            );
        },
    };
}

export function processLoadingStatus<S>(configs: {
    mapState: (
        state: Draft<S>,
    ) => Pick<StatusState, "message" | "status"> &
        Partial<Pick<StatusState, "requestId">>;
    trackId?: boolean;
}): Required<
    Pick<
        AsyncThunkSliceReducerConfig<S, unknown>,
        "fulfilled" | "pending" | "rejected"
    >
> {
    return {
        pending: (state, action) => {
            const statusState = configs.mapState(state);
            statusState.status = DEFAULT_STATUS.LOADING;
            statusState.message = null;
            if (configs.trackId) statusState.requestId = action.meta.requestId;
        },
        rejected: (state, action) => {
            const statusState = configs.mapState(state);
            if (
                !configs.trackId ||
                statusState.requestId === action.meta.requestId
            ) {
                if (action.meta.aborted || action.payload === "canceled") {
                    statusState.status = DEFAULT_STATUS.IDLE;
                } else {
                    statusState.status = DEFAULT_STATUS.FAILURE;
                    statusState.message =
                        typeof action.payload === "string"
                            ? action.payload
                            : action.error.message || "Internal Error";
                }
            }
        },
        fulfilled: (state, action) => {
            const statusState = configs.mapState(state);
            if (
                !configs.trackId ||
                statusState.requestId === action.meta.requestId
            ) {
                statusState.status = DEFAULT_STATUS.IDLE;
            }
        },
    };
}
