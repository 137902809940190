import { createContext } from "react";

import type {
    IconNames,
    IconVariants,
} from "@components/@basic/Icons/interface";

export interface MenuRelationItem {
    key: string | number;
    label: string;
    icon: [IconNames, IconVariants];
    link: string;
    index: number;
    isAdminOnly?: boolean;
    isClientOnly?: boolean;
    action: "switch" | "append";
}

export interface MenuRelationGroup {
    key: string | number;
    label: string;
    icon: string;
    children: MenuRelationItem[];
}

export interface MenuContext {
    groups: MenuRelationGroup[];
    hydrated: boolean;
    loading: boolean;
}

export const MenuContext = createContext<MenuContext>({
    groups: [],
    hydrated: false,
    loading: false,
});
