import ShopAddIcon from "./linear";

export default ShopAddIcon;
export { default as ShopAddIcon, iconInfo as ShopAddIconInfo } from "./linear";

export {
    default as ShopAddBoldIcon,
    iconInfo as ShopAddBoldIconInfo,
} from "./bold";
export {
    default as ShopAddBrokenIcon,
    iconInfo as ShopAddBrokenIconInfo,
} from "./broken";
export {
    default as ShopAddBulkIcon,
    iconInfo as ShopAddBulkIconInfo,
} from "./bulk";
export {
    default as ShopAddLinearIcon,
    iconInfo as ShopAddLinearIconInfo,
} from "./linear";
export {
    default as ShopAddOutlineIcon,
    iconInfo as ShopAddOutlineIconInfo,
} from "./outline";
export {
    default as ShopAddTwotoneIcon,
    iconInfo as ShopAddTwotoneIconInfo,
} from "./twotone";
