import WalletSideBarIcon from "./linear";

export default WalletSideBarIcon;
export {
    default as WalletSideBarIcon,
    iconInfo as WalletSideBarInfo,
} from "./linear";
export {
    default as WalletSideBarBoldIcon,
    iconInfo as WalletSideBarBoldIconInfo,
} from "./bold";
export {
    default as WalletSideBarBulkIcon,
    iconInfo as WalletSideBarBulkIconInfo,
} from "./bulk";
export {
    default as WalletSideBarDuotoneIcon,
    iconInfo as WalletSideBarDuotoneIconInfo,
} from "./twotone";
export {
    default as WalletSideBarLightIcon,
    iconInfo as WalletSideBarLightIconInfo,
} from "./broken";
export {
    default as WalletSideBarRegularIcon,
    iconInfo as WalletSideBarRegularIconInfo,
} from "./outline";
