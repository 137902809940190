import type { FC, FormEventHandler } from "react";

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { PasswordForgotFormNames } from "./interface";
import type { PasswordForgotFormData } from "./interface";

import Button from "@components/@basic/Button";
import FormControl, {
    FormErrorMessage,
} from "@components/@basic/Forms/FormControl";
import Input from "@components/@basic/Forms/Input";
import { useToast } from "@components/@basic/Toast";

import DoladoLoginLayout from "@layouts/DoladoLogin";

import { routeNames } from "@router";

import { parseFormData } from "@utils/forms/parsers";
import { validateForm } from "@utils/forms/validation";
import type { ErrorRelation } from "@utils/forms/validation";

import ControllerApi from "@old/controllers/ControllerApi";

const PasswordForgotPage: FC = () => {
    const [processing, setProcessing] = useState(false);
    const [errors, setErrors] = useState<ErrorRelation<PasswordForgotFormData>>(
        {},
    );

    const navigate = useNavigate();
    const toast = useToast();

    const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        setErrors({});

        const errorRelation = await validateForm<PasswordForgotFormData>(event);
        if (errorRelation) {
            setErrors(errorRelation);
            return;
        }

        const data = parseFormData<PasswordForgotFormData>(event);

        setProcessing(true);

        const response = await ControllerApi(
            "GET_URL",
            `/user/password-reset?user=${data.email}`,
        );

        if (response?.status === "success") {
            toast.dispatch({
                title: "Email de recuperação enviado.",
                status: "success",
                duration: 2000,
                position: "top",
            });
            setTimeout(navigate, 2000, routeNames.LOGIN);
        } else {
            toast.dispatch({
                title: "Usuário não encontrado",
                status: "error",
                duration: 5000,
                position: "top",
            });
        }

        setProcessing(false);
    };

    return (
        <DoladoLoginLayout message="Por favor, preencha o seu e-mail">
            <form
                noValidate
                className="flex flex-col justify-between w-full h-full"
                onSubmit={handleSubmit}>
                <div>
                    <FormControl
                        isDisabled={processing}
                        isInvalid={!!errors.email}>
                        <Input
                            className="bg-lightGrey-500 min-h-36"
                            required
                            type="email"
                            name={PasswordForgotFormNames.EMAIL}
                            data-label="E-mail"
                            placeholder="E-mail"
                        />
                        <FormErrorMessage>{errors.email?.[0]}</FormErrorMessage>
                    </FormControl>
                </div>

                <div className="mt-64 md:mt-40">
                    <Button
                        size="md"
                        type="submit"
                        className="md:inline-block max-md:w-full md:min-w-136 md:mr-12 md:align-middle"
                        isDisabled={processing}
                        isLoading={processing}>
                        Redefinir Senha
                    </Button>
                    <p className="md:inline-block max-md:mt-12 mb-0 max-md:leading-8 max-md:text-center md:align-middle">
                        <span className="md:mr-12">ou</span>
                        <Link
                            to={routeNames.LOGIN}
                            className="max-md:block hover:underline hover:underline-offset-2">
                            Logar
                        </Link>
                    </p>
                </div>
            </form>
        </DoladoLoginLayout>
    );
};

export default PasswordForgotPage;
