import { forwardRef, useState } from "react";

import ButtonIcon from "../../ButtonIcon";
import Input from "../Input";
import InputGroup, { InputElement } from "../Input/Group";
import type { InputProps } from "../Input";

import { EyeIconInfo, EyeSlashIconInfo } from "../../Icons/Mocks";

interface ToggleableInputProps extends Omit<InputProps, "type"> {
    groupStyle?: string;
}

const ToggleableInput = forwardRef<HTMLInputElement, ToggleableInputProps>(
    function ToggleableInput({ groupStyle, ...props }, ref) {
        const [showContent, setShowContent] = useState(false);
        const toggleContent = () => setShowContent((prev) => !prev);
        return (
            <InputGroup className={groupStyle}>
                <Input
                    ref={ref as any}
                    {...props}
                    type={showContent ? "text" : "password"}
                />
                <InputElement position="right" clickable>
                    <ButtonIcon
                        label="toggle-content"
                        icon={showContent ? EyeSlashIconInfo : EyeIconInfo}
                        variant="unstyled"
                        size="sm"
                        type="button"
                        onClick={toggleContent}
                    />
                </InputElement>
            </InputGroup>
        );
    },
);

export default ToggleableInput;
