import antdBR from "antd/lib/locale-provider/pt_BR";
import brMessages from "../locales/pt_BR.json";

const ptLang = {
    messages: {
        ...brMessages,
    },
    antd: antdBR,
    locale: "pt-BR",
};
export default ptLang;
