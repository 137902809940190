import { useCallback } from "react";
import type { MutableRefObject } from "react";
import { useStore } from "react-redux";
import type { Action } from "redux";
import type { ThunkDispatch } from "redux-thunk";
import type { AsyncThunkAction } from "@reduxjs/toolkit";
import type { AsyncThunkConfig } from "@reduxjs/toolkit/dist/createAsyncThunk";

import type { DynamicStore } from "../store/interface";

export default function useAsyncUpdate(
    abort?: MutableRefObject<(() => void) | null>,
) {
    const store = useStore() as DynamicStore<
        Record<string, any>,
        Action,
        { dispatch: ThunkDispatch<Record<string, any>, any, Action> }
    >;

    const asyncUpdate = useCallback(
        async <R, P>(
            action: AsyncThunkAction<P, NonNullable<unknown>, AsyncThunkConfig>,
            selector: (root: Record<string, any>) => R,
        ): Promise<
            [
                R,
                Awaited<
                    ReturnType<
                        AsyncThunkAction<
                            P,
                            NonNullable<unknown>,
                            AsyncThunkConfig
                        >
                    >
                >,
            ]
        > => {
            if (abort?.current) abort.current();
            const promise = store.dispatch(action);
            if (abort) abort.current = promise.abort;
            const actionResponse = await promise;
            const updatedData = selector(store.getState());
            return [updatedData, actionResponse];
        },
        [abort, store],
    );

    return asyncUpdate;
}
