import type { ComponentType, FC, ReactNode } from "react";
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useStore } from "./hooks";

import type {
    ModuleBuilderFunction,
    ModuleConfigs,
    ModuleData,
} from "./utils/modules/interface";

export type ReduxDynamicModuleContext<
    Modules extends ModuleData[] = ModuleData[],
> = {
    actions: {
        [I in Extract<keyof Modules, `${number}`> as `${Extract<
            Modules[I]["id"],
            string
        >}`]: Modules[I]["actions"];
    };
    propagate: (mds: ModuleData[], remove?: boolean) => void;
    selectors: {
        [I in Extract<keyof Modules, `${number}`> as `${Extract<
            Modules[I]["id"],
            string
        >}`]: Modules[I]["selectors"];
    };
};

const ReduxDynamicModuleContext = createContext<ReduxDynamicModuleContext>({
    actions: {},
    propagate: () => null,
    selectors: {},
});

interface ReduxDynamicModuleProps {
    builder?: ModuleBuilderFunction;
    builders?: ModuleBuilderFunction[];
    children?: ReactNode;
    hash?: ModuleConfigs["hash"];
    module?: ModuleData;
    modules?: ModuleData[];
}

export const ReduxDynamicModule: FC<ReduxDynamicModuleProps> = ({
    builder,
    builders,
    module,
    modules: _modules,
    hash,
    children,
}) => {
    const superContext = useContext(ReduxDynamicModuleContext);
    const superPropagate = useRef(superContext.propagate).current;

    if (!module && !_modules && !builder && !builders) {
        throw new Error("Module configuration not provided");
    }

    const [propagated, setPropagated] = useState<ModuleData[]>([]);
    const modules = useRef<ModuleData[]>(
        [
            module,
            _modules,
            builder?.({ hash }),
            builders?.map((builder) => builder({ hash })),
        ]
            .flat()
            .filter((el): el is NonNullable<typeof el> => !!el),
    ).current;
    const mounted = useRef(false);

    const store = useStore();

    useEffect(() => {
        superPropagate(modules);
        store.addModules(modules);
        mounted.current = true;
        return () => {
            superPropagate(modules, true);
            store.removeModules(modules);
            mounted.current = false;
        };
    }, [modules, store, superPropagate]);

    useEffect(() => {
        if (!mounted.current) return;
        let missing = false;
        for (const md of modules) {
            const curr = store.modules.find((md2) => md2.name === md.name);
            if (!curr) missing = true;
        }
        if (missing) store.addModules(modules);
    });

    const propagate = useCallback<ReduxDynamicModuleContext["propagate"]>(
        (mds, remove = false) => {
            superPropagate(mds, remove);
            setPropagated((prev) => {
                const temp = [...prev];
                for (const md of mds) {
                    const idx = temp.findIndex(({ id }) => id == md.id);
                    if (remove && idx !== -1) temp.splice(idx, 1);
                    else if (!remove && idx === -1) temp.push(md);
                }
                return temp;
            });
        },
        [superPropagate],
    );

    const state = useMemo(() => {
        const temp: ReduxDynamicModuleContext = {
            actions: { ...superContext.actions },
            propagate,
            selectors: { ...superContext.selectors },
        };
        for (const md of modules) {
            temp.actions[md.id] = md.actions;
            temp.selectors[md.id] = md.selectors;
        }
        for (const md of propagated) {
            temp.actions[md.id] = md.actions;
            temp.selectors[md.id] = md.selectors;
        }
        return temp;
    }, [
        modules,
        propagated,
        propagate,
        superContext.actions,
        superContext.selectors,
    ]);

    return (
        <ReduxDynamicModuleContext.Provider value={state}>
            {children}
        </ReduxDynamicModuleContext.Provider>
    );
};

export function withModule(
    configs: Pick<
        ReduxDynamicModuleProps,
        "builder" | "builders" | "module" | "modules"
    >,
): <Props extends Record<string, any>>(
    Component: ComponentType<Props>,
) => FC<Props & Pick<ModuleConfigs, "hash">> {
    return <Props extends Record<string, any>>(
        Component: ComponentType<Props>,
    ) => {
        const Wrapped: FC<Props & Pick<ModuleConfigs, "hash">> = ({
            hash,
            ...props
        }) => (
            <ReduxDynamicModule {...configs} hash={hash}>
                <Component {...(props as Props)} />
            </ReduxDynamicModule>
        );
        Wrapped.displayName = `withModule(${Component.displayName})`;
        return Wrapped;
    };
}

export type ParseModuleData<
    Modules extends (ModuleData | ModuleBuilderFunction)[],
> = {
    [K in keyof Modules]: K extends `${number}`
        ? Modules[K] extends ModuleBuilderFunction
            ? ReturnType<Modules[K]>
            : Modules[K] extends ModuleData
            ? Modules[K]
            : never
        : Modules[K];
} extends infer R
    ? R extends ModuleData[]
        ? R
        : never
    : never;

export function useModule<
    Modules extends (ModuleData | ModuleBuilderFunction)[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(...mds: [...Modules]): ReduxDynamicModuleContext<ParseModuleData<Modules>> {
    return useContext(ReduxDynamicModuleContext) as ReduxDynamicModuleContext<
        ParseModuleData<Modules>
    >;
}
