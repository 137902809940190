import CaretLeftIcon from "./linear";

export default CaretLeftIcon;
export {
    default as CaretLeftIcon,
    iconInfo as CaretLeftIconInfo,
} from "./linear";

export {
    default as CaretLeftLinearIcon,
    iconInfo as CaretLeftLinearIconInfo,
} from "./linear";
