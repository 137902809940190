import ChevronRightIcon from "./linear";

export default ChevronRightIcon;
export {
    default as ChevronRightIcon,
    iconInfo as ChevronRightIconInfo,
} from "./linear";

export {
    default as ChevronRightBoldIcon,
    iconInfo as ChevronRightBoldIconInfo,
} from "./bold";
export {
    default as ChevronRightBrokenIcon,
    iconInfo as ChevronRightBrokenIconInfo,
} from "./broken";
export {
    default as ChevronRightBulkIcon,
    iconInfo as ChevronRightBulkIconInfo,
} from "./bulk";
export {
    default as ChevronRightLinearIcon,
    iconInfo as ChevronRightLinearIconInfo,
} from "./linear";
export {
    default as ChevronRightOutlineIcon,
    iconInfo as ChevronRightOutlineIconInfo,
} from "./outline";
export {
    default as ChevronRightTwotoneIcon,
    iconInfo as ChevronRightTwotoneIconInfo,
} from "./twotone";
