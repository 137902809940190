import type { ReactNode } from "react";

import { forwardRef } from "react";

import { useModalContext } from "./helpers";

import ButtonIcon from "../ButtonIcon";
import { CloseIconInfo } from "../Icons/Mocks/close";

import { classes } from "@utils/styles/tailwind/v4";

interface ModalHeaderProps {
    autoFocus?: boolean;
    buttonStyle?: string;
    children?: ReactNode;
    className?: string;
    hideButton?: boolean;
    iconStyle?: string;
}

const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(
    function ModalHeader(
        {
            autoFocus,
            hideButton,
            className,
            buttonStyle = "",
            iconStyle = "",
            children,
        },
        forwarded,
    ) {
        const { aria, onClose } = useModalContext();
        return (
            <div
                ref={forwarded}
                id={aria.label}
                className={classes`relative p-16 ${className}`}>
                {!hideButton && (
                    <ButtonIcon
                        autoFocus={autoFocus}
                        variant="ghost"
                        size="md"
                        icon={CloseIconInfo}
                        label="close-drawer"
                        title="Fechar"
                        iconStyle={`text-inherit ${iconStyle}`}
                        className={`absolute top-16 right-16 hover:bg-transparent text-inherit ${buttonStyle}`}
                        onClick={onClose}
                    />
                )}
                {children}
            </div>
        );
    },
);

export default ModalHeader;
