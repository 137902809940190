import {
    lowercaseSimpleWordsPattern,
    numbersPattern,
    specialCharacters,
    uppercaseSimpleWordsPattern,
} from "@utils/inputs/patterns/intervals";

export interface IndicatorInfo {
    pattern: RegExp;
    content: string;
}

export const indicators: IndicatorInfo[] = [
    { pattern: /.{8,}/g, content: "Conter 8 ou mais caracteres" },
    {
        pattern: new RegExp(lowercaseSimpleWordsPattern, "g"),
        content: "Conter letras minúsculas",
    },
    {
        pattern: new RegExp(uppercaseSimpleWordsPattern, "g"),
        content: "Conter letras maiúsculas",
    },
    {
        pattern: new RegExp(numbersPattern, "g"),
        content: "Conter caracteres numéricos",
    },
    {
        pattern: new RegExp(specialCharacters, "g"),
        content: "Conter caracteres especiais",
    },
];
