import backgroundPattern from "./background";
import borderPattern from "./border";
import boxPattern from "./box";
import containerPattern from "./container";
import displacementPattern from "./displacement";
import displayPattern from "./display";
import effectsPattern from "./effects";
import filtersPattern from "./filters";
import flexPattern from "./flex";
import floatsPattern from "./floats";
import fontPattern from "./font";
import forcedColorAdjustPattern from "./forcedColorAdjust";
import gridPattern from "./grid";
import interactivityPattern from "./interactivity";
import layoutPattern from "./layout";
import listPattern from "./list";
import objectPattern from "./object";
import outlinePattern from "./outline";
import overflowPattern from "./overflow";
import overscrollPattern from "./overscroll";
import positionPattern from "./position";
import ringPattern from "./ring";
import roundedPattern from "./rounded";
import screenReadersPattern from "./screenReaders";
import scrollPattern from "./scroll";
import sizingPattern from "./sizing";
import snapPattern from "./snap";
import spacingPattern from "./spacing";
import svgPattern from "./svg";
import tablePattern from "./table";
import textPattern from "./text";
import textDecorationPattern from "./textDecoration";
import transformPattern from "./transform";
import transitionsPattern from "./transitions";
import visibilityPattern from "./visibility";

import animationsPattern from "./custom/animations";
import backgroundDisplacementPattern from "./custom/backgroundDisplacement";
import filterColorsPattern from "./custom/filterColors";
import gridDynamicPattern from "./custom/gridDynamic";

export const defaultPatterns = [
    backgroundPattern,
    borderPattern,
    boxPattern,
    containerPattern,
    displacementPattern,
    displayPattern,
    effectsPattern,
    filtersPattern,
    flexPattern,
    floatsPattern,
    fontPattern,
    forcedColorAdjustPattern,
    gridPattern,
    interactivityPattern,
    layoutPattern,
    listPattern,
    objectPattern,
    outlinePattern,
    overflowPattern,
    overscrollPattern,
    positionPattern,
    ringPattern,
    roundedPattern,
    screenReadersPattern,
    scrollPattern,
    sizingPattern,
    snapPattern,
    spacingPattern,
    svgPattern,
    tablePattern,
    textPattern,
    textDecorationPattern,
    transformPattern,
    transitionsPattern,
    visibilityPattern,
];

export const customPatterns = [
    animationsPattern,
    backgroundDisplacementPattern,
    filterColorsPattern,
    gridDynamicPattern,
];
