import type { TailwindPattern } from "../interface";

const listPattern: TailwindPattern = {
    base: /list(?!-item)/,
    variants: [
        { base: /list-image/, arbitrary: "any" },
        /list-(inside|outside)/,
        { base: /list/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default listPattern;

export const listSample = [
    ["list-image-none", "list-image-[url(checkmark.png)]"],
    ["list-inside", "list-outside"],
    ["list-none", "list-disc", "list-decimal", "list-[upper-roman]"],
];
