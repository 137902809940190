import type { ComponentType, FC, ReactNode } from "react";

import { Navigate } from "react-router-dom";

import { useSession } from "@contexts/session";

import { routeNames } from "@router/interface";

interface PrivateRouteProps {
    children?: ReactNode;
    fallback?: string;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children, fallback }) => {
    const { logged, loading } = useSession();
    if (loading) return null;
    if (!logged) return <Navigate to={fallback ?? routeNames.LOGIN} />;
    return <>{children}</>;
};

export default PrivateRoute;

export function withPrivateRoute<Props extends JSX.IntrinsicAttributes>(
    Component: ComponentType<Props>,
): FC<Props> {
    const fc: FC<Props> = (props) => (
        <PrivateRoute>
            <Component {...props} />
        </PrivateRoute>
    );
    fc.displayName = `PrivateRoute(${Component.displayName})`;
    return fc;
}
