export default async function getHubShippingFree() {
    return [
        {
            value: "1",
            label: "Com frete grátis",
            comments:
                "Todos os seus compradores terão: Frete grátis no serviço normal. Descontos significativos no serviço expresso. Em algunos casos, no lugar do frete grátis, eles terão descontos nos dois serviços. Isso dependerá do peso, do preço e da distância do envio.",
        },
        {
            value: "0",
            label: "Não oferecer frete grátis",
            comments: "",
        },
    ];
}
