import DocumentForwardIcon from "./linear";

export default DocumentForwardIcon;
export {
    default as DocumentForwardIcon,
    iconInfo as DocumentForwardIconInfo,
} from "./linear";

export {
    default as DocumentForwardBoldIcon,
    iconInfo as DocumentForwardBoldIconInfo,
} from "./bold";
export {
    default as DocumentForwardBrokenIcon,
    iconInfo as DocumentForwardBrokenIconInfo,
} from "./broken";
export {
    default as DocumentForwardBulkIcon,
    iconInfo as DocumentForwardBulkIconInfo,
} from "./bulk";
export {
    default as DocumentForwardLinearIcon,
    iconInfo as DocumentForwardLinearIconInfo,
} from "./linear";
export {
    default as DocumentForwardOutlineIcon,
    iconInfo as DocumentForwardOutlineIconInfo,
} from "./outline";
export {
    default as DocumentForwardTwotoneIcon,
    iconInfo as DocumentForwardTwotoneIconInfo,
} from "./twotone";
