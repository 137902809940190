import { thunk } from "redux-thunk";
import { enableMapSet } from "immer";

import { moduleRelation } from "./modules";
// import { moduleRelation } from "./examples/modules";
import type { RootState } from "./modules";

import garbageCollectorMiddleware from "./middlewares/garbage-collector";
import moduleHotLauncherMiddleware from "./middlewares/module-hot-launcher";

import {
    applyDynamicMiddleware,
    composeEnhancers,
    createDynamicStore,
    devToolsMiddleware,
    moduleHydrationMiddleware,
} from "./utils";
import type { ModuleFetcher } from "./utils/interface";
import type { ModuleData } from "./utils/modules/interface";

const initModules: ModuleData[] = [];

const moduleFetcher = ((id) =>
    import(
        `./modules${moduleRelation[id as keyof typeof moduleRelation]}/index.ts`
    )) satisfies ModuleFetcher;

// const moduleFetcher = ((id) =>
//     import(
//         `./examples/modules${
//             moduleRelation[id as keyof typeof moduleRelation]
//         }/index.ts`
//     )) satisfies ModuleFetcher;

export function configureStore() {
    enableMapSet();
    const store = createDynamicStore<RootState>(
        initModules,
        composeEnhancers(
            applyDynamicMiddleware(
                thunk,
                moduleHydrationMiddleware(moduleFetcher),
                garbageCollectorMiddleware,
                moduleHotLauncherMiddleware(moduleFetcher),
            ),
            devToolsMiddleware(),
        ),
    );
    store.initPersistor();
    return store;
}

export * from "./components";
export * from "./hooks";
