import type { NumericParams } from ".";

export function parseNumericParams(str?: string): NumericParams {
    if (!str) return {};
    return Object.fromEntries(
        str
            .split(",")
            .filter((vl) => vl.match(/^(eq|lt|lte|gt|gte|step):-?\d+\.?\d*$/))
            .map((vl) => {
                const temp = vl.split(":");
                return [temp[0], parseFloat(temp[1])];
            }),
    );
}

export function calcRoundDistance(value: number, step: number) {
    const rest = Math.abs(value % step);
    let dist = rest;
    if (rest > step - rest) dist = rest - step;
    return Math.sign(value) < 0 ? dist : -dist;
}
