import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";
import type { PaginationState } from "@redux/plugins/pagination-manager/interface";

export enum FulfillmentStatus {
    OPEN = "open",
    ANALYSIS = "analysis",
    APPROVED = "approved",
    PROCESSING = "processing",
    COMPLETED = "completed",
    CANCELLED = "cancelled",
}

export enum SalesChannels {
    MERCADO_LIVRE = "Mercado Livre",
    SHOPEE = "Shopee",
}

export interface Fulfillment {
    account_name?: string;
    collecction_date?: string;
    created_at: string;
    entry_authorization_url?: string;
    id: number;
    label: string;
    label_volume?: string;
    label_product?: string;
    label_product_fragile?: string;
    label_volume_url?: string;
    label_product_url?: string;
    label_product_fragile_url?: string;
    nfe_xml_url?: string;
    observations?: string;
    order_origin?: string;
    payment_receipt_url?: string;
    products?: FulfillmentProduct[];
    sales_channel: SalesChannels;
    status: FulfillmentStatus | "";
    updated_at?: string;
}

export interface FulfillmentProduct {
    created_at: string;
    fulfillment_id: number;
    name: string;
    quantity: number;
    ref_code: string;
    sku_id: number;
    unit_price: number;
    url_thumb: string;
}

export type UpdateFulfillmentPayload = Pick<
    Fulfillment,
    | "collecction_date"
    | "label_volume"
    | "label_product"
    | "label_product_fragile"
    | "observations"
    | "order_origin"
>;

export interface FulfillmentsParams {
    account_name?: string;
    created_at?: string;
    createdAtMax?: string;
    createdAtMin?: string;
    label?: string;
    limit?: number;
    page?: number;
    sales_channel?: SalesChannels;
    status?: FulfillmentStatus | undefined;
}

export type PaginationParams = Omit<FulfillmentsParams, "limit" | "page">;

export interface State {
    payloads: PaginationState<Fulfillment>;
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
}

export const moduleId = "fulfillments" as const;
