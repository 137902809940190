import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronRightBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 13.0217 18.6812 L 16.2317 15.4712 L 18.2017 13.5112 C 19.0317 12.6812 19.0317 11.3312 18.2017 10.5012 L 13.0217 5.3212 C 12.3417 4.6412 11.1817 5.1312 11.1817 6.0812 V 8.7212 L 12.2017 9.7412 C 13.7017 11.2412 13.7017 12.7712 12.2017 14.2712 L 11.1817 15.2912 V 17.9212 C 11.1817 18.8812 12.3417 19.3612 13.0217 18.6812 Z"
                fill="currentColor"
            />
            <path
                d="M 7.0217 18.6812 L 10.2317 15.4712 L 12.2017 13.5112 C 13.0317 12.6812 13.0317 11.3312 12.2017 10.5012 L 7.0217 5.3212 C 6.3417 4.6412 5.1817 5.1312 5.1817 6.0812 V 11.6912 V 17.9212 C 5.1817 18.8812 6.3417 19.3612 7.0217 18.6812 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
