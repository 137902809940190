import type { FC, ReactNode } from "react";

import { Children, forwardRef, isValidElement, useMemo } from "react";

import { inputGroupPaddings } from "./interface";

import { InputOverwriteContextProvider } from "../utils";
import type { InputSizes, InputVariants } from "../utils";

import { classes } from "@utils/styles/tailwind/v3";

interface InputAddonProps {
    className?: string;
    children?: ReactNode;
}

export const InputAddon: FC<InputAddonProps> = ({
    className = "",
    children,
}) => (
    <div
        className={classes`grid place-content-center z-10 bg-slate-200 border border-gray-300 first:rounded-s-4 last:rounded-e-4 px-8 ${className}`}>
        {children}
    </div>
);

interface InputElementProps {
    position?: "left" | "right";
    clickable?: boolean;
    children?: ReactNode;
}

export const InputElement: FC<InputElementProps> = ({
    position = "left",
    clickable,
    children,
}) => (
    <div
        className={`absolute inset-y-0 z-10 ${
            position === "left" ? "left-0" : "right-0"
        } grid place-content-center px-8 ${
            clickable ? "cursor-pointer" : "pointer-events-none touch-none"
        }`}>
        {children}
    </div>
);

interface InputGroupProps {
    variant?: InputVariants;
    size?: InputSizes;
    className?: string;
    children?: ReactNode;
}

export const InputGroup = forwardRef<HTMLFieldSetElement, InputGroupProps>(
    function InputGroup(
        { variant, size = "md", className = "", children },
        ref,
    ) {
        const elementsStyle = useMemo(() => {
            const temp: Set<string> = new Set();

            let inputIdx = 0;
            const addonIdxs: number[] = [];
            Children.forEach(children, (child, idx) => {
                if (!isValidElement(child)) return;

                switch (child.type) {
                    case InputElement: {
                        const position =
                            (child.props as InputElementProps).position ??
                            "left";
                        temp.add(inputGroupPaddings[size][position]);
                        break;
                    }
                    case InputAddon:
                        addonIdxs.push(idx);
                        break;
                    default:
                        inputIdx = idx;
                        break;
                }
            });

            temp.add("rounded-none");
            if (addonIdxs.every((idx) => idx > inputIdx)) {
                temp.add("rounded-s-4");
            }
            if (addonIdxs.every((idx) => idx < inputIdx)) {
                temp.add("rounded-e-4");
            }

            return [...temp.values()].join(" ");
        }, [children, size]);

        return (
            <InputOverwriteContextProvider
                value={{
                    variant,
                    size,
                    className: `z-0 ${elementsStyle}`,
                }}>
                <fieldset
                    ref={ref}
                    className={classes`relative flex items-stretch h-max ${className}`}>
                    {children}
                </fieldset>
            </InputOverwriteContextProvider>
        );
    },
);

export default InputGroup;
