import MenuIcon from "./linear";

export default MenuIcon;
export { default as MenuIcon, iconInfo as MenuIconInfo } from "./linear";

export { default as MenuBoldIcon, iconInfo as MenuBoldIconInfo } from "./bold";
export {
    default as MenuBrokenIcon,
    iconInfo as MenuBrokenIconInfo,
} from "./broken";
export { default as MenuBulkIcon, iconInfo as MenuBulkIconInfo } from "./bulk";
export {
    default as MenuLinearIcon,
    iconInfo as MenuLinearIconInfo,
} from "./linear";
export {
    default as MenuOutlineIcon,
    iconInfo as MenuOutlineIconInfo,
} from "./outline";
export {
    default as MenuTwotoneIcon,
    iconInfo as MenuTwotoneIconInfo,
} from "./twotone";
