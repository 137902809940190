import { useContext } from "react";

import { SessionContext } from "./interface";

export function useSession(): SessionContext {
    return useContext(SessionContext);
}

export function hasPrivileges(
    privileges: Record<string, boolean> | undefined | null,
    tags: string[],
    isAny = false,
): boolean {
    if (!privileges) return false;
    return (
        (isAny && tags.some((tag) => !!privileges[tag])) ||
        (!isAny && tags.every((tag) => !!privileges[tag]))
    );
}
