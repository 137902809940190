import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ProfileOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 12.1606 11.62 C 12.1305 11.62 12.1105 11.62 12.0806 11.62 C 12.0305 11.61 11.9605 11.61 11.9006 11.62 C 9.0006 11.53 6.8106 9.25 6.8106 6.44 C 6.8106 3.58 9.1406 1.25 12.0006 1.25 C 14.8605 1.25 17.1905 3.58 17.1905 6.44 C 17.1805 9.25 14.9805 11.53 12.1905 11.62 C 12.1806 11.62 12.1706 11.62 12.1606 11.62 Z M 12.0006 2.75 C 9.9705 2.75 8.3105 4.41 8.3105 6.44 C 8.3105 8.44 9.8705 10.05 11.8605 10.12 C 11.9206 10.11 12.0505 10.11 12.1806 10.12 C 14.1405 10.03 15.6805 8.42 15.6905 6.44 C 15.6905 4.41 14.0305 2.75 12.0006 2.75 Z"
                fill="currentColor"
            />
            <path
                d="M 12.1696 22.55 C 10.2096 22.55 8.2396 22.05 6.7496 21.05 C 5.3596 20.13 4.5996 18.87 4.5996 17.5 C 4.5996 16.13 5.3596 14.86 6.7496 13.93 C 9.7496 11.94 14.6096 11.94 17.5896 13.93 C 18.9696 14.85 19.7396 16.11 19.7396 17.48 C 19.7396 18.85 18.9796 20.12 17.5896 21.05 C 16.0896 22.05 14.1296 22.55 12.1696 22.55 Z M 7.5796 15.19 C 6.6196 15.83 6.0996 16.65 6.0996 17.51 C 6.0996 18.36 6.6296 19.18 7.5796 19.81 C 10.0696 21.48 14.2696 21.48 16.7596 19.81 C 17.7196 19.17 18.2396 18.35 18.2396 17.49 C 18.2396 16.64 17.7096 15.82 16.7596 15.19 C 14.2696 13.53 10.0696 13.53 7.5796 15.19 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
