import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowLeftRightBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M21.75 6.72922C21.75 6.52922 21.67 6.33922 21.53 6.19922L17.81 2.47922C17.52 2.18922 17.04 2.18922 16.75 2.47922C16.46 2.76922 16.46 3.24922 16.75 3.53922L19.2 5.98922H3C2.59 5.98922 2.25 6.32922 2.25 6.73922C2.25 7.14922 2.59 7.47922 3 7.47922H19.19L16.75 9.91922C16.46 10.2092 16.46 10.6892 16.75 10.9792C16.9 11.1292 17.09 11.1992 17.28 11.1992C17.47 11.1992 17.66 11.1292 17.81 10.9792L21.52 7.26922C21.59 7.19922 21.65 7.10922 21.69 7.00922C21.69 6.99922 21.69 6.98922 21.7 6.97922C21.73 6.89922 21.75 6.81922 21.75 6.72922Z"
                fill="currentColor"
            />
            <path
                d="M21 16.5183H4.81L7.25 14.0783C7.54 13.7883 7.54 13.3083 7.25 13.0183C6.96 12.7283 6.48 12.7283 6.19 13.0183L2.48 16.7283C2.41 16.7983 2.35 16.8883 2.31 16.9883C2.31 16.9983 2.31 17.0083 2.3 17.0183C2.27 17.0983 2.25 17.1883 2.25 17.2783C2.25 17.4783 2.33 17.6683 2.47 17.8083L6.19 21.5283C6.34 21.6783 6.53 21.7483 6.72 21.7483C6.91 21.7483 7.1 21.6783 7.25 21.5283C7.54 21.2383 7.54 20.7583 7.25 20.4683L4.8 18.0183H21C21.41 18.0183 21.75 17.6783 21.75 17.2683C21.75 16.8583 21.41 16.5183 21 16.5183Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
