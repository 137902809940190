import type { FC } from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { ToastPortal } from "./components/@basic/Toast";

import SessionContextProvider from "./contexts/session";
import ThemeProvider from "./contexts/theme";

import AppRouter from "./router";

import { configureStore } from "./redux";

const store = configureStore();

const App: FC = () => (
    <Provider store={store}>
        <PersistGate persistor={store.persistor} loading={null}>
            <SessionContextProvider>
                <ThemeProvider>
                    <ToastPortal>
                        <AppRouter />
                    </ToastPortal>
                </ThemeProvider>
            </SessionContextProvider>
        </PersistGate>
    </Provider>
);

export default App;
