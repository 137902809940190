import { mapErrorData } from "../../helpers";
import type { ErrorData, RequestOptions } from "../../interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

import { MountException, MountResponse } from "@services/helpers";
import type { RequestResponse } from "@services/interface";

interface SyncOfferParams {
    IDHubProduct: string | number;
    IDTypeSalesChannelResource: string | number;
}

export default async function syncOffer(
    typeCompanyIntegrationId: string | number,
    params: SyncOfferParams,
    { accountName, signal }: RequestOptions = {},
): Promise<RequestResponse> {
    try {
        const { data } = await lambdas.post(
            `/hub/resource/${typeCompanyIntegrationId}`,
            undefined,
            {
                baseURL: mountBaseUrl(accountName),
                signal,
                params,
            },
        );
        return MountResponse(null);
    } catch (error) {
        console.log(error);
        return MountException<ErrorData>(error, mapErrorData);
    }
}
