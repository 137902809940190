import type { TailwindPattern } from "../../interface";

const backgroundDisplacementPattern: TailwindPattern = {
    base: /bg-(pos|size)/,
    variants: [
        { base: /bg-pos-x/, arbitrary: "position" },
        { base: /bg-pos-y/, arbitrary: "position" },
        { base: /bg-size-h/, arbitrary: "size" },
        { base: /bg-size-w/, arbitrary: "size" },
        /bg-pos/,
        /bg-size/,
    ],
    arbitrary: "any",
};

export default backgroundDisplacementPattern;

export const backgroundDisplacementSample = [
    [
        "bg-pos-x-16",
        "bg-pos-x-left",
        "bg-pos-x-right",
        "bg-pos-x-[10px]",
        "bg-pos-x-[right_18px]",
    ],
    [
        "bg-pos-y-16",
        "bg-pos-y-bottom",
        "bg-pos-y-top",
        "bg-pos-y-[10px]",
        "bg-pos-y-[top_18px]",
    ],
    [
        "bg-size-h-auto",
        "bg-size-h-full",
        "bg-size-h-fit",
        "bg-size-h-max",
        "bg-size-h-min",
        "bg-size-h-px",
        "bg-size-h-64",
        "bg-size-h-1/2",
        "bg-size-h-[50%]",
        "bg-size-h-[calc(100%-32px)]",
    ],
    [
        "bg-size-w-auto",
        "bg-size-w-full",
        "bg-size-w-fit",
        "bg-size-w-max",
        "bg-size-w-min",
        "bg-size-w-px",
        "bg-size-w-64",
        "bg-size-w-1/2",
        "bg-size-w-[50%]",
        "bg-size-w-[calc(100%-32px)]",
    ],
    ["bg-pos"],
    ["bg-size"],
];
