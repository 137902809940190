import type { FC, ReactNode } from "react";

import { Suspense, useCallback, useMemo } from "react";

import { commonModals } from "./relations";
import { ModalManagerContext } from "./interface";
import type {
    ModalManagerComponent,
    ModalManagerInjectedProps,
    StackItem,
} from "./interface";

import useStateWithRef from "@hooks/state-management/useStateWithRef";

import createUUID from "@utils/cryptography/createUUID";

interface ModalManagerProviderProps {
    children?: ReactNode;
}

const ModalManagerProvider: FC<ModalManagerProviderProps> = ({ children }) => {
    const [pool, setPool, poolRef] = useStateWithRef<
        Map<string, ModalManagerComponent<any>>
    >(new Map(Object.entries(commonModals)));
    const [stack, setStack, stackRef] = useStateWithRef<StackItem[]>([]);

    const appendPool = useCallback<ModalManagerContext["appendPool"]>(
        (tag, Component) => {
            if (poolRef.current.has(tag)) return;
            poolRef.current = new Map(poolRef.current);
            poolRef.current.set(tag, Component);
            setPool(poolRef.current);
        },
        [poolRef, setPool],
    );

    const removePool = useCallback<ModalManagerContext["removePool"]>(
        (tag) => {
            if (!poolRef.current.has(tag)) return;

            poolRef.current = new Map(poolRef.current);
            poolRef.current.delete(tag);
            setPool(poolRef.current);

            setStack((prev) => prev.filter((item) => item.tag !== tag));
        },
        [poolRef, setPool, setStack],
    );

    const clear = useCallback<ModalManagerContext["clear"]>(
        (keys) => {
            const keysToRemove =
                typeof keys === "function" ? keys(stackRef.current) : keys;

            if (!keys) {
                setStack([]);
            } else {
                setStack((prev) =>
                    prev.filter(({ key }) => !keysToRemove.includes(key)),
                );
            }
        },
        [setStack, stackRef],
    );

    const push = useCallback<ModalManagerContext["push"]>(
        (tag, props) => {
            if (!poolRef.current.has(tag)) return ["", () => null];

            const key = createUUID();
            setStack((prev) => [...prev, { key, tag, props }]);

            const clearItem = () => {
                setStack((prev) => prev.filter((item) => item.key !== key));
            };

            return [key, clearItem];
        },
        [poolRef, setStack],
    );

    const pop = useCallback<ModalManagerContext["pop"]>(() => {
        setStack((prev) => prev.slice(0, -1));
    }, [setStack]);

    const set = useCallback<ModalManagerContext["set"]>(
        (entries) => {
            setStack(
                entries
                    .filter(({ tag }) => poolRef.current.has(tag))
                    .map(({ tag, props }) => ({
                        key: createUUID(),
                        tag,
                        props,
                    })),
            );
        },
        [poolRef, setStack],
    );

    const state = useMemo<ModalManagerContext>(
        () => ({
            appendPool,
            clear,
            pop,
            push,
            removePool,
            set,
        }),
        [appendPool, clear, removePool, pop, push, set],
    );

    return (
        <ModalManagerContext.Provider value={state}>
            {children}
            {stack.map(({ key, tag, props }, idx) => {
                const Component = pool.get(tag);
                if (!Component) return null;

                const injected: ModalManagerInjectedProps["_modalManager"] = {
                    clear,
                    pop,
                    push,
                    set,
                    close: () => clear([key]),
                    itemKey: key,
                    zIndex: idx * 10,
                };
                return (
                    <Suspense key={key} fallback={null}>
                        <Component _modalManager={injected} {...props} />
                    </Suspense>
                );
            })}
        </ModalManagerContext.Provider>
    );
};

export default ModalManagerProvider;

export * from "./utils";
