import { legacy_createStore } from "redux";
import type {
    Action,
    Dispatch,
    PreloadedStateShapeFromReducersMapObject,
} from "redux";
import { persistStore, REHYDRATE } from "redux-persist";

import { fetchModules, modules2Reducer } from "./helpers";
import { internalId } from "./interface";
import type { DynamicStore, ModuleFetcher } from "./interface";

import type { DynamicStoreEnhancer } from "../middlewares/interface";
import { formatModuleName } from "../modules/helpers";
import type { ModuleData } from "../modules/interface";
import { purgePersistency } from "../persistency";

export function createDynamicStore<
    S,
    A extends Action<any> = Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    moduleFetcher: ModuleFetcher,
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext>;
export function createDynamicStore<
    S,
    A extends Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    moduleFetcher: ModuleFetcher,
    preloadedState?: PreloadedStateShapeFromReducersMapObject<S>,
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext>;
export function createDynamicStore<
    S,
    A extends Action<any>,
    Ext extends Record<string, any> = NonNullable<unknown>,
    StateExt extends Record<string, any> = NonNullable<unknown>,
>(
    initModules: ModuleData[],
    moduleFetcher: ModuleFetcher,
    preloadedState?:
        | PreloadedStateShapeFromReducersMapObject<S>
        | DynamicStoreEnhancer<Ext, StateExt>,
    enhancer?: DynamicStoreEnhancer<Ext, StateExt>,
): DynamicStore<S & StateExt, A, Ext> {
    if (
        typeof preloadedState === "function" &&
        typeof enhancer === "function"
    ) {
        throw new Error("Multiple enhancers not supported");
    }

    if (
        typeof preloadedState === "function" &&
        typeof enhancer === "undefined"
    ) {
        enhancer = preloadedState as DynamicStoreEnhancer<Ext, StateExt>;
        preloadedState = undefined;
    }

    if (typeof enhancer !== "undefined") {
        if (typeof enhancer !== "function") {
            throw new Error("Expected the enhancer to be a function.");
        }

        return enhancer(createDynamicStore)(
            initModules,
            moduleFetcher,
            preloadedState as
                | PreloadedStateShapeFromReducersMapObject<S>
                | undefined,
        ) as unknown as DynamicStore<S & StateExt, A, Ext>;
    }

    const modules: ModuleData[] = initModules;

    const store = legacy_createStore(
        modules2Reducer(modules),
        preloadedState,
    ) satisfies DynamicStore<S & StateExt, A, Ext>;

    store.modules = modules;

    const nativeDispatch = store.dispatch;
    const newDispatch: Dispatch<A> = (action) => {
        nativeDispatch(action);
        if (
            action.type === REHYDRATE &&
            "key" in action &&
            action.key === "root" &&
            "payload" in action &&
            action.payload &&
            typeof action.payload === "object" &&
            internalId in action.payload
        ) {
            const names = action.payload[internalId] as string[];
            (async () => {
                store.addModules(await fetchModules(moduleFetcher, names));
            })();
        }
        return action;
    };
    store.dispatch = newDispatch;

    const temp = { enhancer: undefined, manualPersist: true };
    store.persistor = persistStore(store, temp);
    store.initPersistor = () => {
        store.persistor.persist();
    };

    const updateModules = () => {
        const newReducer = modules2Reducer(modules);
        store.replaceReducer(newReducer);
        store.persistor = persistStore(store);
    };

    store.addModules = (mds: ModuleData[]) => {
        let changed = false;
        mds.forEach((md) => {
            const name = formatModuleName(md);
            const idx = modules.findIndex(
                (module) => formatModuleName(module) === name,
            );
            if (idx === -1) {
                changed = true;
                modules.push(md);
            }
        });
        if (changed) updateModules();
    };

    store.removeModules = (mds: Pick<ModuleData, "hash" | "id">[]) => {
        let changed = false;
        mds.forEach((md) => {
            const name = formatModuleName(md);
            const idx = modules.findIndex(
                (module) => formatModuleName(module) === name && !module.fixed,
            );
            if (idx !== -1) {
                changed = true;
                purgePersistency(modules[idx]);
                modules.splice(idx, 1);
            }
        });
        if (changed) updateModules();
    };

    store.toggleModule = (md: ModuleData) => {
        const name = formatModuleName(md);
        const idx = modules.findIndex(
            (module) => formatModuleName(module) === name,
        );
        if (idx === -1) {
            modules.push(md);
            updateModules();
        } else if (!md.fixed) {
            purgePersistency(md);
            modules.splice(idx, 1);
            updateModules();
        }
    };

    return store;
}
