import UserIcon from "./linear";

export default UserIcon;
export { default as UserIcon, iconInfo as UserIconInfo } from "./linear";

export { default as UserBoldIcon, iconInfo as UserBoldIconInfo } from "./bold";
export {
    default as UserBrokenIcon,
    iconInfo as UserBrokenIconInfo,
} from "./broken";
export { default as UserBulkIcon, iconInfo as UserBulkIconInfo } from "./bulk";
export {
    default as UserLinearIcon,
    iconInfo as UserLinearIconInfo,
} from "./linear";
export {
    default as UserOutlineIcon,
    iconInfo as UserOutlineIconInfo,
} from "./outline";
export {
    default as UserTwotoneIcon,
    iconInfo as UserTwotoneIconInfo,
} from "./twotone";
