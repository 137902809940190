import type { ComponentProps, ReactNode } from "react";

import { forwardRef, useMemo } from "react";

import {
    buttonCommonStyle,
    buttonIconsSizes,
    buttonSizes,
    buttonVariants,
} from "./interface";
import type { ButtonSizes, ButtonVariants } from "./interface";

import Icon from "../Icons";
import type { IconNames, IconVariants } from "../Icons/interface";
import { ReactComponent as Spinner } from "../Icons/mocks/spinner/linear.svg";

import { classes } from "@utils/styles/tailwind/v4";

export interface ButtonProps extends ComponentProps<"button"> {
    variant?: ButtonVariants;
    size?: ButtonSizes;
    isFullWidth?: boolean;
    isLoading?: boolean;
    isDisabled?: boolean;
    leftIcon?: IconNames | Exclude<ReactNode, string>;
    leftIconVariant?: IconVariants;
    rightIcon?: IconNames | Exclude<ReactNode, string>;
    rightIconVariant?: IconVariants;
    iconStyle?: string;
    textStyle?: string;
    children?: ReactNode;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
    {
        variant = "solid",
        size = "lg",
        isFullWidth,
        isLoading,
        isDisabled,
        className,
        leftIcon,
        leftIconVariant = "linear",
        rightIcon,
        rightIconVariant = "linear",
        iconStyle = "",
        textStyle,
        children,
        ["aria-label"]: ariaLabel,
        title,
        ...rest
    },
    ref,
) {
    const renderIcon = (
        icon: IconNames | Exclude<ReactNode, string> | undefined,
        vrt: IconVariants | undefined,
        side: "left" | "right",
    ) => {
        if (!icon) return <div />;
        if (typeof icon === "string") {
            return (
                <Icon
                    icon={icon as IconNames}
                    variant={vrt}
                    className={`${side === "left" ? "mr-8" : "ml-8"} ${
                        buttonIconsSizes[size]
                    } ${iconStyle}`}
                />
            );
        }
        return icon;
    };

    const currentStyle = useMemo(
        () =>
            classes`${variant !== "unstyled" && buttonCommonStyle} ${
                buttonSizes[size]
            } ${buttonVariants[variant]} ${
                isFullWidth && "w-full"
            } ${className}`,
        [variant, size, isFullWidth, className],
    );

    return (
        <button
            ref={ref}
            aria-label={
                ariaLabel || title || (isLoading ? "loading" : undefined)
            }
            className={currentStyle}
            disabled={isDisabled}
            title={title}
            {...rest}>
            {isLoading ? (
                <Spinner
                    aria-hidden="true"
                    aria-busy="true"
                    focusable="false"
                    role="img"
                    className={`${buttonIconsSizes[size]} mx-auto aspect-square animate-spin`}
                />
            ) : (
                <>
                    {renderIcon(leftIcon, leftIconVariant, "left")}
                    <div className={classes`flex-1 h-max ${textStyle}`}>
                        {children}
                    </div>
                    {renderIcon(rightIcon, rightIconVariant, "right")}
                </>
            )}
        </button>
    );
});

export default Button;
