import type { ModuleConfigs, ModuleName } from "./interface";

import type { ReducerPlainRelation } from "../interface";

export function formatModuleName<ID extends string>({
    id,
    hash,
}: Pick<ModuleConfigs<any, ID>, "hash" | "id">): ModuleName<ID> {
    return `${id}${hash ? `[${hash}]` : ""}` as ModuleName<ID>;
}

export function splitModuleName(name: string): ModuleConfigs {
    const [id, hash] = name.replace(/\[(.+)\]/g, ":$1").split(":");
    return { id, hash };
}

export function flatReducers(ref: ReducerPlainRelation, prefix = "") {
    let relation: ReducerPlainRelation = {};
    for (const key in ref) {
        const reducer = ref[key];
        if (
            typeof reducer === "function" ||
            "prepare" in reducer ||
            "payloadCreator" in reducer
        ) {
            relation[prefix ? `${prefix}.${key}` : key] = reducer;
        } else {
            relation = { ...relation, ...flatReducers(reducer, key) };
        }
    }
    return relation;
}

export function remapActions(actions: Record<string, any>) {
    const parsed: Record<string, any> = {};
    for (const map in actions) {
        const keys = map.split(".");
        let current: any = parsed;
        keys.forEach((key, idx, arr) => {
            if (idx >= arr.length - 1) {
                current[key] = actions[map];
            } else {
                if (!current[key]) current[key] = {};
                current = current[key];
            }
        });
    }
    return parsed;
}
