import { useContext } from "react";

import { SessionContext } from "./interface";

export function useSession(): SessionContext {
    return useContext(SessionContext);
}

export function hasPrivileges(
    privileges: Record<string, boolean> | undefined | null,
    tags: string[],
    isAny = false,
): boolean {
    if (!privileges) return false;
    return (
        (isAny && tags.some((tag) => !!privileges[tag])) ||
        (!isAny && tags.every((tag) => !!privileges[tag]))
    );
}

export type PrivilegeRelation = Record<string, string | string[]>;

export function parsePrivileges<R extends PrivilegeRelation>(
    privileges: Record<string, boolean> | undefined | null,
    relation: R,
): Record<keyof R, boolean> {
    const parsed: Record<string, boolean> = {};
    const keys = Object.getOwnPropertyNames(relation);
    for (const key of keys) {
        const tags = relation[key];
        const temp = typeof tags === "string" ? [tags] : tags;
        parsed[key] = hasPrivileges(
            privileges,
            temp,
            typeof relation[key] !== "string",
        );
    }
    return parsed as Record<keyof R, boolean>;
}
