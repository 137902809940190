import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronRightBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 15.8103 16.0601 L 18.4303 13.4401 C 19.2003 12.6701 19.2003 11.4101 18.4303 10.6401 L 11.9103 4.1201"
                stroke="currentColor"
            />
            <path d="M 11.9092 19.96 L 12.9492 18.92" stroke="currentColor" />

            <path
                d="M 9.8103 16.0601 L 12.4303 13.4401 C 13.2003 12.6701 13.2003 11.4101 12.4303 10.6401 L 5.9103 4.1201"
                stroke="currentColor"
            />
            <path d="M 5.9092 19.96 L 6.9492 18.92" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
