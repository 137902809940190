import BellIcon from "./linear";

export default BellIcon;
export { default as BellIcon, iconInfo as BellIconInfo } from "./linear";

export { default as BellBoldIcon, iconInfo as BellBoldIconInfo } from "./bold";
export {
    default as BellBrokenIcon,
    iconInfo as BellBrokenIconInfo,
} from "./broken";
export { default as BellBulkIcon, iconInfo as BellBulkIconInfo } from "./bulk";
export {
    default as BellLinearIcon,
    iconInfo as BellLinearIconInfo,
} from "./linear";
export {
    default as BellOutlineIcon,
    iconInfo as BellOutlineIconInfo,
} from "./outline";
export {
    default as BellTwotoneIcon,
    iconInfo as BellTwotoneIconInfo,
} from "./twotone";
