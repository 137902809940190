import type { TailwindPattern } from "../../interface";

const animationsPattern: TailwindPattern = {
    base: /animation-/,
    variants: [
        /animation-count/,
        {
            base: /animation-\d+-timing/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
        },
        {
            base: /animation-\d+-fill/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
        },
        {
            base: /animation-\d+-dir/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
        },
        {
            base: /animation-\d+-play/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
        },
        {
            base: /animation-\d+-duration/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
            arbitrary: "number",
        },
        {
            base: /animation-\d+-delay/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
            arbitrary: "number",
        },
        {
            base: /animation-\d+-iter/,
            variants: [
                /animation-1/,
                /animation-2/,
                /animation-3/,
                /animation-4/,
                /animation-5/,
            ],
            arbitrary: "number",
        },
    ],
    arbitrary: "any",
};

export default animationsPattern;

export const animationsSample = [
    ["animation-count-1", "animation-count-2"],
    [
        "animation-1-timing-ease",
        "animation-1-timing-ease-in",
        "animation-1-timing-ease-out",
        "animation-1-timing-ease-in-out",
        "animation-1-timing-linear",
    ],
    [
        "animation-2-timing-ease",
        "animation-2-timing-ease-in",
        "animation-2-timing-ease-out",
        "animation-2-timing-ease-in-out",
        "animation-2-timing-linear",
    ],
    [
        "animation-3-timing-ease",
        "animation-3-timing-ease-in",
        "animation-3-timing-ease-out",
        "animation-3-timing-ease-in-out",
        "animation-3-timing-linear",
    ],
    [
        "animation-4-timing-ease",
        "animation-4-timing-ease-in",
        "animation-4-timing-ease-out",
        "animation-4-timing-ease-in-out",
        "animation-4-timing-linear",
    ],
    [
        "animation-5-timing-ease",
        "animation-5-timing-ease-in",
        "animation-5-timing-ease-out",
        "animation-5-timing-ease-in-out",
        "animation-5-timing-linear",
    ],
    [
        "animation-1-fill-none",
        "animation-1-fill-fwd",
        "animation-1-fill-bwd",
        "animation-1-fill-both",
    ],
    [
        "animation-2-fill-none",
        "animation-2-fill-fwd",
        "animation-2-fill-bwd",
        "animation-2-fill-both",
    ],
    [
        "animation-3-fill-none",
        "animation-3-fill-fwd",
        "animation-3-fill-bwd",
        "animation-3-fill-both",
    ],
    [
        "animation-4-fill-none",
        "animation-4-fill-fwd",
        "animation-4-fill-bwd",
        "animation-4-fill-both",
    ],
    [
        "animation-5-fill-none",
        "animation-5-fill-fwd",
        "animation-5-fill-bwd",
        "animation-5-fill-both",
    ],
    [
        "animation-1-dir-normal",
        "animation-1-dir-reverse",
        "animation-1-dir-alt",
        "animation-1-dir-alt-reverse",
    ],
    [
        "animation-2-dir-normal",
        "animation-2-dir-reverse",
        "animation-2-dir-alt",
        "animation-2-dir-alt-reverse",
    ],
    [
        "animation-3-dir-normal",
        "animation-3-dir-reverse",
        "animation-3-dir-alt",
        "animation-3-dir-alt-reverse",
    ],
    [
        "animation-4-dir-normal",
        "animation-4-dir-reverse",
        "animation-4-dir-alt",
        "animation-4-dir-alt-reverse",
    ],
    [
        "animation-5-dir-normal",
        "animation-5-dir-reverse",
        "animation-5-dir-alt",
        "animation-5-dir-alt-reverse",
    ],
    ["animation-1-play-running", "animation-1-play-play"],
    ["animation-2-play-running", "animation-2-play-play"],
    ["animation-3-play-running", "animation-3-play-play"],
    ["animation-4-play-running", "animation-4-play-play"],
    ["animation-5-play-running", "animation-5-play-play"],
    [
        "animation-1-duration-100",
        "animation-1-duration-300",
        "animation-1-duration-[430]",
    ],
    [
        "animation-2-duration-100",
        "animation-2-duration-300",
        "animation-2-duration-[430]",
    ],
    [
        "animation-3-duration-100",
        "animation-3-duration-300",
        "animation-3-duration-[430]",
    ],
    [
        "animation-4-duration-100",
        "animation-4-duration-300",
        "animation-4-duration-[430]",
    ],
    [
        "animation-5-duration-100",
        "animation-5-duration-300",
        "animation-5-duration-[430]",
    ],
    [
        "animation-1-delay-100",
        "animation-1-delay-300",
        "animation-1-delay-[430]",
    ],
    [
        "animation-2-delay-100",
        "animation-2-delay-300",
        "animation-2-delay-[430]",
    ],
    [
        "animation-3-delay-100",
        "animation-3-delay-300",
        "animation-3-delay-[430]",
    ],
    [
        "animation-4-delay-100",
        "animation-4-delay-300",
        "animation-4-delay-[430]",
    ],
    [
        "animation-5-delay-100",
        "animation-5-delay-300",
        "animation-5-delay-[430]",
    ],
    [
        "animation-1-iter-infinite",
        "animation-1-iter-1",
        "animation-1-iter-2",
        "animation-1-iter-3",
        "animation-1-iter-4",
        "animation-1-iter-5",
        "animation-1-iter-[5]",
    ],
    [
        "animation-2-iter-infinite",
        "animation-2-iter-1",
        "animation-2-iter-2",
        "animation-2-iter-3",
        "animation-2-iter-4",
        "animation-2-iter-5",
        "animation-2-iter-[5]",
    ],
    [
        "animation-3-iter-infinite",
        "animation-3-iter-1",
        "animation-3-iter-2",
        "animation-3-iter-3",
        "animation-3-iter-4",
        "animation-3-iter-5",
        "animation-3-iter-[5]",
    ],
    [
        "animation-4-iter-infinite",
        "animation-4-iter-1",
        "animation-4-iter-2",
        "animation-4-iter-3",
        "animation-4-iter-4",
        "animation-4-iter-5",
        "animation-4-iter-[5]",
    ],
    [
        "animation-5-iter-infinite",
        "animation-5-iter-1",
        "animation-5-iter-2",
        "animation-5-iter-3",
        "animation-5-iter-4",
        "animation-5-iter-5",
        "animation-5-iter-[5]",
    ],
];
