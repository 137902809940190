import { defaultPatterns, customPatterns } from "./patterns";
import { applyPattern, parseTemplateVariable } from "./helpers";
import type { TailwindPattern } from "./interface";

export function purgeClasses(
    classes: string,
    patterns: TailwindPattern[],
): string {
    const modifiers = new Set(classes.match(/(?<= |^)[^\s]+:/g) || []);
    modifiers.add("");

    const parsed: string[] = [];
    let remain = classes.split(" ").filter((el) => !!el);

    for (const modifier of modifiers) {
        for (const pattern of patterns) {
            if (!remain.length) break;
            const matches = applyPattern(classes, pattern, modifier);
            for (const match of matches) {
                parsed.push(modifier + match[match.length - 1]);
                match.forEach((mt) => {
                    remain = remain.filter((el) => el !== modifier + mt);
                });
            }
        }
    }

    return `${remain.length ? `${remain.join(" ")} ` : ""}${parsed.join(" ")}`;
}

export function classes(
    fixed: TemplateStringsArray,
    ...variable: any[]
): string {
    const parsed = variable.map(parseTemplateVariable);
    return purgeClasses(
        fixed
            .flatMap((str, idx) => (idx !== 0 ? [parsed[idx - 1], str] : [str]))
            .map((el) => el.trim())
            .filter((el) => !!el)
            .join(" "),
        defaultPatterns.concat(...customPatterns),
    );
}
