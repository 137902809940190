import { mapStepSpecs } from "./helpers";
import type { FlowSteps, State } from "./interface";

import { createSelectorsCreator } from "@redux/utils";
import { DEFAULT_STATUS } from "@redux/utils/helpers";

const selectorsSetup = createSelectorsCreator({} as State | undefined)(
    (creator) => ({
        getStatus: creator(
            (state) => [state?.status, state?.message || null],
            (status, message) => ({
                failure: status === DEFAULT_STATUS.FAILURE,
                loading: status === DEFAULT_STATUS.LOADING,
                message,
                pending: !status || status === DEFAULT_STATUS.PENDING,
                processing: status === DEFAULT_STATUS.PROCESSING,
                success: status === DEFAULT_STATUS.SUCCESS,
            }),
        ),
        getData: creator(
            (state) => [state?.data || {}],
            (data) => data,
        ),
        getFlowData: creator(
            (state) => {
                const { step = null, type = null } = state?.flow || {};
                if (step === null)
                    return [type, step as FlowSteps | null, false];
                const specs = mapStepSpecs[step];
                return [type, step, specs.validation(state?.data || {})];
            },
            (type, step, valid) => ({ type, step, valid }),
        ),
        getConsultant: creator(
            (state) => [state?.consultant || null],
            (consultant) => consultant,
        ),
        getPaymentMethodsStatus: creator(
            (state) => [state?.paymentMethods.status],
            (status) => ({
                loading: status === DEFAULT_STATUS.LOADING,
                pending: !status || status === DEFAULT_STATUS.PENDING,
            }),
        ),
        getPaymentMethods: creator(
            (state) => [state?.paymentMethods.data || []],
            (paymentMethods) => paymentMethods,
        ),
        getPayloadInfo: creator(
            (state) => [state?.payloadInfo || null],
            (payloadInfo) => payloadInfo,
        ),
    }),
);

export default selectorsSetup;
