import type { MutableRefObject } from "react";
import { useEffect, useMemo, useRef } from "react";

import ObjectObserver from "@utils/objects/observer";
import type { ObjectObserverEventListeners } from "@utils/objects/observer";

export default function useScrollOnWheel<T extends HTMLElement>(
    ref: MutableRefObject<T | null>,
) {
    const isScrolling = useRef(false);
    const timeout = useRef<NodeJS.Timeout | null>(null);

    const observer = useMemo(() => new ObjectObserver(ref), [ref]);

    useEffect(() => {
        const onScroll = (event: WheelEvent) => {
            if (!observer.proxy.current) return;

            const { clientWidth, scrollLeft, scrollWidth } =
                observer.proxy.current;
            const delta = event.deltaY;

            if (
                (delta < 0 && scrollLeft > 0) ||
                (delta > 0 && scrollLeft + clientWidth < scrollWidth)
            ) {
                event.preventDefault();

                isScrolling.current = true;

                observer.proxy.current.scrollBy(event.deltaY, 0);

                if (timeout.current) clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    isScrolling.current = false;
                }, 300);

                return;
            }

            if (isScrolling.current) event.preventDefault();
        };

        const onChangeRef: ObjectObserverEventListeners<
            MutableRefObject<T | null>
        >["onset"][number] = (
            _: any,
            oldRef: HTMLElement | null,
            newRef: HTMLElement | null,
        ) => {
            if (oldRef) oldRef.removeEventListener("wheel", onScroll);
            if (newRef)
                newRef.addEventListener("wheel", onScroll, { passive: false });
        };

        onChangeRef("current", null, observer.proxy.current);

        observer.addEventListener("onset", onChangeRef);
        return () => {
            observer.removeEventListener("onset", onChangeRef);
        };
    }, [observer]);

    return observer.proxy;
}
