import MoreIcon from "./linear";

export default MoreIcon;
export { default as MoreIcon, iconInfo as MoreIconInfo } from "./linear";

export { default as MoreBoldIcon, iconInfo as MoreBoldIconInfo } from "./bold";
export {
    default as MoreBrokenIcon,
    iconInfo as MoreBrokenIconInfo,
} from "./broken";
export { default as MoreBulkIcon, iconInfo as MoreBulkIconInfo } from "./bulk";
export {
    default as MoreLinearIcon,
    iconInfo as MoreLinearIconInfo,
} from "./linear";
export {
    default as MoreOutlineIcon,
    iconInfo as MoreOutlineIconInfo,
} from "./outline";
export {
    default as MoreTwotoneIcon,
    iconInfo as MoreTwotoneIconInfo,
} from "./twotone";
