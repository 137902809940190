import { useEffect } from "react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

const environment = process.env.REACT_APP_SENTRY_ENV;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DNS;

if (environment && SENTRY_DSN) {
    Sentry.init({
        dsn: SENTRY_DSN,
        environment,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        tracesSampleRate: 0.1,
        sampleRate: 0.8,
        tracePropagationTargets: [
            "localhost",
            process.env.REACT_APP_BACKEND_ENDPOINT || "",
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}
