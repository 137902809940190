import DocumentUploadIcon from "./linear";

export default DocumentUploadIcon;
export {
    default as DocumentUploadIcon,
    iconInfo as DocumentUploadIconInfo,
} from "./linear";

export {
    default as DocumentUploadBoldIcon,
    iconInfo as DocumentUploadBoldIconInfo,
} from "./bold";
export {
    default as DocumentUploadBrokenIcon,
    iconInfo as DocumentUploadBrokenIconInfo,
} from "./broken";
export {
    default as DocumentUploadBulkIcon,
    iconInfo as DocumentUploadBulkIconInfo,
} from "./bulk";
export {
    default as DocumentUploadLinearIcon,
    iconInfo as DocumentUploadLinearIconInfo,
} from "./linear";
export {
    default as DocumentUploadOutlineIcon,
    iconInfo as DocumentUploadOutlineIconInfo,
} from "./outline";
export {
    default as DocumentUploadTwotoneIcon,
    iconInfo as DocumentUploadTwotoneIconInfo,
} from "./twotone";
