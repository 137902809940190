import {
    fetchIntegrationParams,
    fetchOfficialStore,
    fetchQuestionAnswerTemplates,
    fetchShippingFree,
    fetchShippingModes,
    fetchSkuCondition,
    fetchWarrantyTypes,
} from "../helpers";

import { moduleId } from "../interface";
import type { FetchIntegrationInstanceDataFunction, State } from "../interface";

import type { RootState } from "../../../..";

import { createReducers } from "@redux/utils";
import { DEFAULT_STATUS } from "@redux/utils/helpers";

import getSkuSpecifications from "@services/skus/sku/specifications/get";
import type { SkuPlainSpecification } from "@services/skus/sku/specifications/interface";

import getCompanyBranches from "@old/services/companies/getBranches";
import getCompanyIntegrations from "@old/services/companies/integrations/getAll";
import type { CompanyIntegration } from "@old/services/companies/integrations/interface";
import getFromToCategories from "@old/services/hub/from-to/getCategories";
import getSkuImages from "@old/services/skus/getImages";
import getHubResourcesInfo from "@old/services/hub/resources/getInfo";
import getSku from "@old/services/skus/getOne";
import getSkuVariants from "@old/services/skus/getVariants";
import type { Sku } from "@old/services/skus/interface";
import getSkuRelatedKits from "@old/services/skus/getRelatedKits";
import postSkuMatch from "@old/services/skus/postMatch";
import getSkuSpecificationsDefinitions from "@old/services/skus/specifications/definitions/getAll";
import type { SkuSpecificationDefinition } from "@old/services/skus/specifications/interface";

interface FetchSkuDataParams {
    accountName: string;
    IDSku: string | number;
}

export default createReducers({} as State)((creator) => ({
    fetchAccounts: creator.asyncThunk(
        async (_: undefined, { signal }) => {
            const response = await getCompanyBranches({ signal });
            return response?.Branch || [];
        },
        {
            options: {
                condition: (_, { getState }) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const status = state?.accounts.status;
                    return status && status === DEFAULT_STATUS.PENDING;
                },
            },
            pending: (state) => {
                state.accounts.status = DEFAULT_STATUS.LOADING;
            },
            fulfilled: (state, action) => {
                state.accounts.list = action.payload;
                state.accounts.status = DEFAULT_STATUS.IDLE;
            },
        },
    ),
    fetchSku: creator.asyncThunk(
        async ({ accountName, IDSku }: FetchSkuDataParams, { signal }) => {
            const promises = [
                getSku(IDSku, { accountName, signal }),
                getSkuRelatedKits(IDSku, { accountName, signal }),
                getSkuImages(IDSku, { signal }),
            ] as const;

            const [data, relatedKits, images] = await Promise.all(promises);

            if (data?.IDSku && !data.HasMatch) {
                postSkuMatch(IDSku, { accountName, signal });
            }

            if (data?.IDSku && data.IDTypeSku === 4) {
                const variants = await getSkuVariants(IDSku, {
                    accountName,
                    signal,
                });

                if (variants.length) {
                    data.PriceSell = variants[0].PriceSell;
                }
            }

            return { data: data?.IDSku ? data : null, relatedKits, images };
        },
        {
            options: {
                condition: ({ IDSku }, { getState }) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const id = state?.sku.data?.IDSku;
                    const lastUpdate = state?.sku.lastUpdate || 0;
                    const offerId = state?.offerInfo?.id;
                    const now = Date.now();
                    return (
                        IDSku != id || now - lastUpdate > 600_000 || !!offerId
                    );
                },
            },
            pending: (state) => {
                state.sku.status = DEFAULT_STATUS.LOADING;
                state.status = DEFAULT_STATUS.IDLE;
                state.userErrors = [];
                state.offerInfo = null;
            },
            fulfilled: (state, action) => {
                const { data, images, relatedKits } = action.payload;
                state.sku.data = data;
                state.sku.images = images;
                state.sku.relatedKits = relatedKits;
                state.sku.lastUpdate = Date.now();
                state.sku.status = DEFAULT_STATUS.IDLE;

                state.integrations.instanceData = {};
                state.integrations.typeData = {};
                state.integrations.list = [];
                state.integrations.status = DEFAULT_STATUS.PENDING;
            },
        },
    ),
    fetchIntegrations: creator.asyncThunk(
        async (_: undefined, { signal }) => {
            return await getCompanyIntegrations(
                { IDTypeCompanyIntegrationCategory: 1, Status: 1 },
                { signal },
            );
        },
        {
            options: {
                condition: (_, { getState }) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const status = state?.integrations.status;
                    return status && status === DEFAULT_STATUS.PENDING;
                },
            },
            pending: (state) => {
                state.integrations.status = DEFAULT_STATUS.LOADING;
                state.status = DEFAULT_STATUS.IDLE;
                state.userErrors = [];
            },
            fulfilled: (state, action) => {
                state.integrations.list = action.payload;
                state.integrations.status = DEFAULT_STATUS.IDLE;
            },
        },
    ),
    fetchIntegrationTypeData: creator.asyncThunk(
        async (
            typeCompanyIntegrationId: string | number,
            { getState, signal },
        ) => {
            const root = getState() as RootState;
            const state = root[moduleId] as State;

            const skuId = state.sku.data?.IDSku as number;
            const categoryId = state.sku.data?.IDCategory as number;

            const promises = [
                getFromToCategories(
                    {
                        IDCategoryTo: categoryId,
                        IDTypeCompanyIntegration: typeCompanyIntegrationId,
                    },
                    { signal },
                ),
                getHubResourcesInfo(
                    typeCompanyIntegrationId,
                    { ResourceType: "CategoryAttribute" },
                    { signal },
                ),
            ] as const;

            const [[category], [resource]] = await Promise.all(promises);

            let definitions: SkuSpecificationDefinition[] = [];
            let records: SkuPlainSpecification[] = [];
            if (
                category?.IDCategoryFrom &&
                resource?.IDTypeSalesChannelResource
            ) {
                definitions = await getSkuSpecificationsDefinitions(
                    typeCompanyIntegrationId,
                    {
                        CategoryId: category.IDCategoryFrom.trim(),
                        IDCompanyIntegration: category.IDCompanyIntegration,
                        IDTypeSalesChannelResource:
                            resource.IDTypeSalesChannelResource,
                    },
                    { signal },
                );
                if (definitions.length) {
                    records = await getSkuSpecifications(
                        skuId,
                        typeCompanyIntegrationId,
                        {
                            signal,
                        },
                    );
                }
            }

            return { category, resource, definitions, records };
        },
        {
            options: {
                condition: (typeCompanyIntegrationId, { getState }) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const skuExists =
                        state?.sku.status &&
                        state.sku.status !== DEFAULT_STATUS.PENDING;
                    const typeDataExists =
                        state?.integrations.typeData?.[
                            typeCompanyIntegrationId
                        ];
                    return !!(skuExists && !typeDataExists);
                },
            },
            pending: (state, action) => {
                const id = action.meta.arg;
                if (!state.integrations.typeData[id]) {
                    state.integrations.typeData[id] = {
                        category: null,
                        resource: null,
                        specifications: {
                            definitions: [],
                            records: [],
                        },
                        status: DEFAULT_STATUS.LOADING,
                    };
                } else {
                    state.integrations.typeData[id].status =
                        DEFAULT_STATUS.LOADING;
                }
                state.status = DEFAULT_STATUS.IDLE;
                state.userErrors = [];
            },
            fulfilled: (state, action) => {
                const id = action.meta.arg;
                const { category, definitions, records, resource } =
                    action.payload;

                const integration = state.integrations.typeData[id];

                integration.category = category || null;
                integration.resource = resource || null;
                integration.specifications.definitions = definitions;
                integration.specifications.records = records;
                integration.status = DEFAULT_STATUS.IDLE;
            },
        },
    ),
    fetchIntegrationInstanceData: creator.asyncThunk(
        async (companyIntegrationId: string | number, { signal, getState }) => {
            const root = getState() as RootState;
            const state = root[moduleId] as State;

            const sku = state.sku.data as Sku;
            const integration = state?.integrations.list.find(
                (integration) =>
                    integration.IDCompanyIntegration == companyIntegrationId,
            ) as CompanyIntegration;
            const typeData =
                state.integrations.typeData[
                    integration.IDTypeCompanyIntegration
                ];
            const instanceData =
                state.integrations.instanceData[
                    integration.IDCompanyIntegration
                ] || {};

            const funcs: FetchIntegrationInstanceDataFunction[] = [
                fetchWarrantyTypes,
            ];

            if (integration.IDTypeCompanyIntegration === 10) {
                funcs.push(
                    fetchOfficialStore,
                    fetchQuestionAnswerTemplates,
                    fetchShippingFree,
                    fetchShippingModes,
                    fetchSkuCondition,
                    fetchWarrantyTypes,
                    fetchIntegrationParams,
                );
            }

            const responses = await Promise.all(
                funcs.map((func) =>
                    func(
                        {
                            instanceData,
                            integration,
                            sku,
                            typeData,
                        },
                        { signal },
                    ),
                ),
            );

            return responses.reduce(
                (tol, el) => Object.assign({}, tol, el),
                {},
            );
        },
        {
            options: {
                condition: (companyIntegrationId, { getState }) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const skuExists =
                        state?.sku.status &&
                        state.sku.status !== DEFAULT_STATUS.PENDING;
                    const integrationExists = state?.integrations.list.find(
                        (integration) =>
                            integration.IDCompanyIntegration ==
                            companyIntegrationId,
                    );
                    const instanceDataExists =
                        state?.integrations.instanceData?.[
                            companyIntegrationId
                        ];
                    return !!(
                        skuExists &&
                        integrationExists &&
                        !instanceDataExists
                    );
                },
            },
            pending: (state, action) => {
                const id = action.meta.arg;
                if (!state.integrations.instanceData[id]) {
                    state.integrations.instanceData[id] = {
                        status: DEFAULT_STATUS.LOADING,
                    };
                } else {
                    state.integrations.instanceData[id].status =
                        DEFAULT_STATUS.LOADING;
                }
                state.status = DEFAULT_STATUS.IDLE;
                state.userErrors = [];
            },
            fulfilled: (state, action) => {
                const id = action.meta.arg;
                state.integrations.instanceData[id] = Object.assign(
                    {},
                    action.payload,
                    { status: DEFAULT_STATUS.IDLE },
                );
            },
        },
    ),
}));
