import WalletIcon from "./linear";

export default WalletIcon;
export { default as WalletIcon, iconInfo as WalletInfo } from "./linear";
export {
    default as WalletBoldIcon,
    iconInfo as WalletBoldIconInfo,
} from "./bold";
export {
    default as WalletBulkIcon,
    iconInfo as WalletBulkIconInfo,
} from "./bulk";
export {
    default as WalletDuotoneIcon,
    iconInfo as WalletDuotoneIconInfo,
} from "./twotone";
export {
    default as WalletLightIcon,
    iconInfo as WalletLightIconInfo,
} from "./broken";
export {
    default as WalletRegularIcon,
    iconInfo as WalletRegularIconInfo,
} from "./outline";
