import type { PatternChecker } from "./interface";

export const alignmentPatternChecker: PatternChecker = {
    base: /^(justify|place)-(content|items|self)/,
    variants: [
        /justify-items/,
        /justify-self/,
        /place-content/,
        /place-items/,
        /place-self/,
    ],
};

export const backgroundPatternChecker: PatternChecker = {
    base: /^bg/,
    variants: [
        /bg-clip/,
        /bg-origin/,
        /bg-(fixed|local|scroll)/,
        /bg-(bottom|center|left|right|top)/,
        /bg(-no)?-repeat/,
        /bg-(auto|cover|contain)/,
        /bg-(none|gradient)/,
        /bg-(flow)/,
        { base: /bg-size/, variants: [/bg-size$/, /bg-size-w/, /bg-size-h/] },
    ],
};

export const borderPatternChecker: PatternChecker = {
    base: /^border/,
    variants: [
        /border-(solid|dashed|dotted|double|hidden|none)/,
        {
            base: /border(-(x|y|t|b|r|l|s|e))?(-\d+|)$/,
            variants: [
                /border(-\d+|)$/,
                /border-x/,
                /border-y/,
                /border-t/,
                /border-b/,
                /border-r/,
                /border-l/,
                /border-s/,
                /border-e/,
            ],
        },
        {
            base: /border-(x|y|t|b|l|r|s|e)-[a-zA-Z]+/,
            variants: [
                /border-x-/,
                /border-y-/,
                /border-t-/,
                /border-b-/,
                /border-r-/,
                /border-l-/,
                /border-s-/,
                /border-e-/,
            ],
        },
    ],
};

export const borderRadiusPatternChecker: PatternChecker = {
    base: /^rounded-(s[se]?|e[se]?|t[rl]?|b[rl]?|l|r)(-|$)/,
    variants: [
        /rounded-s(-|$)/,
        /rounded-e(-|$)/,
        /rounded-t(-|$)/,
        /rounded-b(-|$)/,
        /rounded-l(-|$)/,
        /rounded-r(-|$)/,
        /rounded-ss(-|$)/,
        /rounded-se(-|$)/,
        /rounded-es(-|$)/,
        /rounded-ee(-|$)/,
        /rounded-tl(-|$)/,
        /rounded-tr(-|$)/,
        /rounded-bl(-|$)/,
        /rounded-br(-|$)/,
    ],
};

export const clipPathPatternChecker: PatternChecker = {
    base: /^clip/,
    variants: [/clip-path-href/],
};

export const displayPatternChecker: PatternChecker = {
    base: /^(block|inline(-(block|flex|table|grid))?|flex|table(-(caption|cell|column|footer|header|row)(-group)?)?|flow-root|grid|contents|list-item|hidden)$/,
    variants: [],
};

export const flexPatternChecker: PatternChecker = {
    base: /^flex/,
    variants: [/flex-(row|col)/, /flex-(no)?wrap/],
};

export const gapPatternChecker: PatternChecker = {
    base: /^gap-(x|y)/,
    variants: [/gap-x/, /gap-y/],
};

export const gridPatternChecker: PatternChecker = {
    base: /^(grid|col|row|auto)-/,
    variants: [
        /grid-cols-/,
        /grid-rows-/,
        /grid-flow-/,
        /col-(auto|span)/,
        /col-start-/,
        /col-end-/,
        /row-(auto|span)/,
        /row-start-/,
        /row-end-/,
        /auto-cols-/,
        /auto-rows-/,
    ],
};

export const objectPatternChecker: PatternChecker = {
    base: /^object/,
    variants: [
        /object-(contain|cover|fill|none|scale-down)/,
        /object-(top|bottom|left|right|center)/,
    ],
};

export const outlinePatternsChecker: PatternChecker = {
    base: /^outline/,
    variants: [
        /outline(-(none|dashed|dotted|double)|$)/,
        /outline-\d+/,
        /outline-offset-/,
    ],
};

export const overflowPatternChecker: PatternChecker = {
    base: /^overflow-(x|y)/,
    variants: [/overflow-x/, /overflow-y/],
};

export const placementPatternChecker: PatternChecker = {
    base: /^-?inset-(x|y)/,
    variants: [/inset-x/, /inset-y/],
};

export const positionPatternChecker: PatternChecker = {
    base: /^(static|fixed|absolute|relative|sticky)$/,
    variants: [],
};

export const ringPatternChecker: PatternChecker = {
    base: /^ring/,
    variants: [/ring(-(\d+|inset)|$)/, /ring-offset-\d+/, /ring-offset-\d+/],
};

export const scalePatternChecker: PatternChecker = {
    base: /^-?scale-(x|y)/,
    variants: [/scale-x/, /scale-y/],
};

export const spacingPatternChecker: PatternChecker = {
    base: /^-?space/,
    variants: [/space-x/, /space-y/],
};

export const sizingPatternChecker: PatternChecker = {
    base: /^(max|min)-(w|h)/,
    variants: [/max-w/, /min-w/, /max-h/, /min-h/],
};

export const textDecorationPatternChecker: PatternChecker = {
    base: /^((underline|overline|line-through|no-underline)$|decoration)/,
    variants: [
        /^(underline|overline|line-through|no-underline)$/,
        /decoration-(solid|dotted|double|dashed|wavy)/,
        /decoration-(auto|from-font|\d+)/,
    ],
};

export const textFontPatternChecker: PatternChecker = {
    base: /^((subpixel-)?antialiased|(not-)?italic|font-(sans|serif|mono))$/,
    variants: [
        /^(subpixel-)?antialiased$/,
        /^(not-)?italic$/,
        /^font-(sans|serif|mono)$/,
    ],
};

export const textOverflowPatternChecker: PatternChecker = {
    base: /^(truncate|text-(ellipsis|clip))$/,
    variants: [],
};

export const textTransformationPatternChecker: PatternChecker = {
    base: /^(uppercase|lowercase|capitalize|normal-case)$/,
    variants: [],
};

export const translationPatternChecker: PatternChecker = {
    base: /^-?translate/,
    variants: [/translate-x/, /translate-y/],
};

export const typographyPatternChecker: PatternChecker = {
    base: /^text-/,
    variants: [
        /^text-(xs|sm|base|lg|[2-9]?xl|\[.+\])(\/.+)?$/,
        /text-(left|right|center|justify|start|end)/,
    ],
};

export const visibilityPatternChecker: PatternChecker = {
    base: /^((in)?visible|collapse)$/,
    variants: [],
};

export const patternCheckers: PatternChecker[] = [
    alignmentPatternChecker,
    backgroundPatternChecker,
    borderPatternChecker,
    borderRadiusPatternChecker,
    clipPathPatternChecker,
    displayPatternChecker,
    flexPatternChecker,
    gapPatternChecker,
    gridPatternChecker,
    objectPatternChecker,
    outlinePatternsChecker,
    overflowPatternChecker,
    placementPatternChecker,
    positionPatternChecker,
    ringPatternChecker,
    scalePatternChecker,
    spacingPatternChecker,
    sizingPatternChecker,
    textDecorationPatternChecker,
    textFontPatternChecker,
    textOverflowPatternChecker,
    textTransformationPatternChecker,
    translationPatternChecker,
    typographyPatternChecker,
    visibilityPatternChecker,
];
