import FormatNumberToUSA from "./FormatNumberToUSA";

export default function FormatNumberToWeight(value) {
    if (value) {
        let weight =
            value === typeof "string" ? FormatNumberToUSA(value) : value;

        weight = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
            minimumFractionDigits: 3,
        }).format(weight);
        weight = weight.replace("R$ ", "");

        return weight;
    } else {
        return "0,000";
    }
}
