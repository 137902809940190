import DocumentIcon from "./linear";

export default DocumentIcon;
export {
    default as DocumentIcon,
    iconInfo as DocumentIconInfo,
} from "./linear";

export {
    default as DocumentBoldIcon,
    iconInfo as DocumentBoldIconInfo,
} from "./bold";
export {
    default as DocumentBrokenIcon,
    iconInfo as DocumentBrokenIconInfo,
} from "./broken";
export {
    default as DocumentBulkIcon,
    iconInfo as DocumentBulkIconInfo,
} from "./bulk";
export {
    default as DocumentLinearIcon,
    iconInfo as DocumentLinearIconInfo,
} from "./linear";
export {
    default as DocumentOutlineIcon,
    iconInfo as DocumentOutlineIconInfo,
} from "./outline";
export {
    default as DocumentTwotoneIcon,
    iconInfo as DocumentTwotoneIconInfo,
} from "./twotone";
