import type { AllowNull, ParsedRecord } from "@utils/types";

export interface ILocalStorage<
    Structure extends Record<string, any>,
    Names extends keyof Structure = keyof Structure,
> {
    getItem: <NAME extends Names>(name: NAME) => Structure[NAME] | null;
    getItems: <NAMES extends Names[]>(
        names: NAMES,
    ) => AllowNull<ParsedRecord<Structure, NAMES>>;
    removeItems: (names: Names[]) => void;
    setItem: <NAME extends Names>(name: NAME, value: Structure[NAME]) => void;
    setItems: (data: Partial<Structure>) => void;
}

export default class LocalStorage<
    Structure extends Record<string, any>,
    Names extends keyof Structure = keyof Structure,
> implements ILocalStorage<Structure, Names>
{
    private storage: Storage | undefined;

    constructor() {
        const uid = new Date().toUTCString();
        let temp;
        try {
            (temp = window.localStorage).setItem("uid", uid);
            if (temp.getItem("uid") === uid) {
                temp.removeItem(uid);
                this.storage = temp;
            }
        } catch {
            //
        }
    }

    public getItem<NAME extends Names>(name: NAME): Structure[NAME] | null {
        if (this.storage) {
            const value = this.storage.getItem(name as string);
            try {
                return value && JSON.parse(value);
            } catch {
                return value as Structure[NAME];
            }
        }
        return null;
    }

    public getItems<NAMES extends Names[]>(
        names: NAMES,
    ): AllowNull<ParsedRecord<Structure, NAMES>> {
        const data: Partial<AllowNull<Structure>> = names.reduce(
            (obj, nm) => ({ ...obj, [nm]: null }),
            {},
        );
        if (this.storage) {
            for (const name of names) {
                const temp = this.storage.getItem(name as string);
                try {
                    data[name] = temp && JSON.parse(temp);
                } catch {
                    data[name] = temp as Structure[keyof Structure];
                }
            }
        }
        return data as AllowNull<ParsedRecord<Structure, NAMES>>;
    }

    public removeItems(names: Names[]): void {
        if (this.storage) {
            for (const name of names) {
                this.storage.removeItem(name as string);
            }
        }
    }

    public setItem<NAME extends Names>(
        name: NAME,
        value: Structure[NAME],
    ): void {
        if (this.storage) {
            if (typeof value !== "undefined") {
                this.storage.setItem(name as string, JSON.stringify(value));
            } else {
                this.storage.removeItem(name as string);
            }
        }
    }

    public setItems(data: Partial<Structure>): void {
        if (this.storage) {
            for (const name in data) {
                if (typeof data[name] !== "undefined") {
                    this.storage.setItem(name, JSON.stringify(data[name]));
                } else {
                    this.storage.removeItem(name);
                }
            }
        }
    }
}
