import type { Kit } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

export default async function getSkuRelatedKits(
    skuId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<Kit[]> {
    try {
        const { data } = await lambdas.get<Kit[]>(`/sku/kit/${skuId}`, {
            baseURL: mountBaseUrl(accountName),
            signal,
            params: { Type: "KitsRelated" },
        });
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
