import type { ErrorTestFunction } from "../interface";

export interface LengthParams {
    lt?: number;
    gt?: number;
    lte?: number;
    gte?: number;
    eq?: number;
}

export function length({
    eq,
    gt,
    gte,
    lt,
    lte,
}: LengthParams = {}): ErrorTestFunction {
    return (field, { value }) => {
        const length = value?.length ?? 0;
        if (typeof eq !== "undefined" && length !== eq) {
            return `${field} deve ter exatamente ${eq} caracteres`;
        }
        if (typeof lt !== "undefined" && lt > 0 && length >= lt) {
            return `${field} deve ter menos de ${lt} caracteres`;
        }
        if (typeof lte !== "undefined" && lte > 0 && length > lte) {
            return `${field} deve ter no máximo ${lte} caracteres`;
        }
        if (typeof gt !== "undefined" && gt > 0 && length <= gt) {
            return `${field} deve ter mais de ${gt} caracteres`;
        }
        if (typeof gte !== "undefined" && gte > 0 && length < gte) {
            return `${field} deve ter no mínimo ${gte} caracteres`;
        }
        return false;
    };
}
