"use client";

import type { FC } from "react";

import { useEffect, useState } from "react";

import BaseIcon, { BaseIconProps } from "./BaseIcon";
import type { IconInfo, IconNames, IconVariants } from "./interface";

async function fetchMock(
    icon: IconNames,
    variant: IconVariants,
    variantFallback: IconVariants,
    iconFallback?: IconNames,
): Promise<IconInfo | null> {
    const main = await import(`./Mocks/${icon}/${variant}.tsx`)
        .then((md) => md.iconInfo)
        .catch(() => null);
    if (main) return main;
    const vrtMain = await import(`./Mocks/${icon}/${variantFallback}.tsx`)
        .then((md) => md.iconInfo)
        .catch(() => null);
    if (vrtMain) return vrtMain;
    if (iconFallback) {
        const fallback = await import(
            `./Mocks/${iconFallback}/${variantFallback}.tsx`
        )
            .then((md) => md.iconInfo)
            .catch(() => null);
        if (fallback) return fallback;
    }
    return null;
}

export interface IconProps extends BaseIconProps {
    icon?: IconNames;
    iconFallback?: IconNames;
    variant?: IconVariants;
    variantFallback?: IconVariants;
}

const Icon: FC<IconProps> = ({
    icon,
    info,
    variant = "linear",
    iconFallback,
    variantFallback = "linear",
    ...props
}) => {
    const [iconInfo, setIconInfo] = useState<IconInfo | undefined>();
    useEffect(() => {
        if (icon) {
            (async () => {
                const iconInfo = await fetchMock(
                    icon,
                    variant,
                    variantFallback,
                    iconFallback,
                );
                if (iconInfo) setIconInfo(iconInfo);
            })();
        }
    }, [icon, variant, iconFallback, variantFallback]);
    return <BaseIcon info={info ?? iconInfo} {...props} />;
};

export default Icon;
