import type { LengthParams } from ".";

export function parseLengthParams(str?: string): LengthParams {
    if (!str) return {};
    return Object.fromEntries(
        str
            .split(",")
            .filter((vl) => vl.match(/^(eq|lt|lte|gt|gte):\d+$/))
            .map((vl) => {
                const temp = vl.split(":");
                return [temp[0], parseFloat(temp[1])];
            }),
    );
}
