import CalculatorIcon from "./linear";

export default CalculatorIcon;
export {
    default as CalculatorIcon,
    iconInfo as CalculatorIconInfo,
} from "./linear";

export {
    default as CalculatorBoldIcon,
    iconInfo as CalculatorBoldIconInfo,
} from "./bold";
export {
    default as CalculatorBrokenIcon,
    iconInfo as CalculatorBrokenIconInfo,
} from "./broken";
export {
    default as CalculatorBulkIcon,
    iconInfo as CalculatorBulkIconInfo,
} from "./bulk";
export {
    default as CalculatorLinearIcon,
    iconInfo as CalculatorLinearIconInfo,
} from "./linear";
export {
    default as CalculatorOutlineIcon,
    iconInfo as CalculatorOutlineIconInfo,
} from "./outline";
export {
    default as CalculatorTwotoneIcon,
    iconInfo as CalculatorTwotoneIconInfo,
} from "./twotone";
