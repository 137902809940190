import GridIcon from "./linear";

export default GridIcon;
export { default as GridIcon, iconInfo as GridIconInfo } from "./linear";

export { default as GridBoldIcon, iconInfo as GridBoldIconInfo } from "./bold";
export {
    default as GridBrokenIcon,
    iconInfo as GridBrokenIconInfo,
} from "./broken";
export { default as GridBulkIcon, iconInfo as GridBulkIconInfo } from "./bulk";
export {
    default as GridLinearIcon,
    iconInfo as GridLinearIconInfo,
} from "./linear";
export {
    default as GridOutlineIcon,
    iconInfo as GridOutlineIconInfo,
} from "./outline";
export {
    default as GridTwotoneIcon,
    iconInfo as GridTwotoneIconInfo,
} from "./twotone";
