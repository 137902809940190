import CopyIcon from "./linear";

export default CopyIcon;
export { default as CopyIcon, iconInfo as CopyIconInfo } from "./linear";

export { default as CopyBoldIcon, iconInfo as CopyBoldIconInfo } from "./bold";
export {
    default as CopyBrokenIcon,
    iconInfo as CopyBrokenIconInfo,
} from "./broken";
export { default as CopyBulkIcon, iconInfo as CopyBulkIconInfo } from "./bulk";
export {
    default as CopyLinearIcon,
    iconInfo as CopyLinearIconInfo,
} from "./linear";
export {
    default as CopyOutlineIcon,
    iconInfo as CopyOutlineIconInfo,
} from "./outline";
export {
    default as CopyTwotoneIcon,
    iconInfo as CopyTwotoneIconInfo,
} from "./twotone";
