import { calcRoundDistance } from "./helpers";

import type { ErrorTestFunction } from "../interface";

export interface NumericParams {
    lt?: number;
    gt?: number;
    lte?: number;
    gte?: number;
    eq?: number;
    step?: number;
}

export function size({
    eq,
    gt,
    gte,
    lt,
    lte,
}: NumericParams = {}): ErrorTestFunction {
    return (field, { value }) => {
        const num = parseFloat(value ?? "");
        if (isNaN(num)) return `${field} deve ser um número`;
        if (typeof eq !== "undefined" && num !== eq) {
            return `${field} deve ser igual a ${eq}`;
        }
        if (typeof lt !== "undefined" && num >= lt) {
            return `${field} deve ser menor que ${lt}`;
        }
        if (typeof lte !== "undefined" && num > lte) {
            return `${field} deve ser menor ou igual a ${lte}`;
        }
        if (typeof gt !== "undefined" && num <= gt) {
            return `${field} deve maior que ${gt}`;
        }
        if (typeof gte !== "undefined" && num < gte) {
            return `${field} deve maior ou igual a ${gte}`;
        }
        return false;
    };
}

export function precision(step?: number): ErrorTestFunction {
    return (field, { value }) => {
        if (typeof step === "undefined") return false;
        const num = parseFloat(value ?? "");
        if (isNaN(num)) return `${field} deve ser um número`;
        const dist = calcRoundDistance(num, step);
        return (
            Math.abs(dist) > 1e-8 &&
            `${field} deve estar em incrementos de ${step}`
        );
    };
}

export function numeric(params: NumericParams = {}): ErrorTestFunction {
    return (field, data) => {
        const sizeResult = size(params)(field, data);
        const precisionResult = precision(params.step)(field, data);
        return sizeResult || precisionResult;
    };
}
