import CaretDownIcon from "./linear";

export default CaretDownIcon;
export {
    default as CaretDownIcon,
    iconInfo as CaretDownIconInfo,
} from "./linear";

export {
    default as CaretDownLinearIcon,
    iconInfo as CaretDownLinearIconInfo,
} from "./linear";
