import ArrowUpIcon from "./linear";

export default ArrowUpIcon;
export { default as ArrowUpIcon, iconInfo as ArrowUpIconInfo } from "./linear";

export {
    default as ArrowUpBoldIcon,
    iconInfo as ArrowUpBoldIconInfo,
} from "./bold";
export {
    default as ArrowUpBrokenIcon,
    iconInfo as ArrowUpBrokenIconInfo,
} from "./broken";
export {
    default as ArrowUpBulkIcon,
    iconInfo as ArrowUpBulkIconInfo,
} from "./bulk";
export {
    default as ArrowUpLinearIcon,
    iconInfo as ArrowUpLinearIconInfo,
} from "./linear";
export {
    default as ArrowUpOutlineIcon,
    iconInfo as ArrowUpOutlineIconInfo,
} from "./outline";
export {
    default as ArrowUpTwotoneIcon,
    iconInfo as ArrowUpTwotoneIconInfo,
} from "./twotone";
