import type { TailwindPattern } from "../interface";

const objectPattern: TailwindPattern = {
    base: /object/,
    variants: [
        /object-(contain|cover|fill|none|scale-down)/,
        { base: /object/, arbitrary: "position" },
    ],
    arbitrary: "any",
};

export default objectPattern;

export const objectSample = [
    [
        "object-contain",
        "object-cover",
        "object-fill",
        "object-none",
        "object-scale-down",
    ],
    [
        "object-bottom",
        "object-center",
        "object-left",
        "object-left-bottom",
        "object-left-top",
        "object-right",
        "object-right-bottom",
        "object-right-top",
        "object-top",
        "object-[center_bottom]",
    ],
];
