import type { TailwindPattern } from "../interface";

const flexPattern: TailwindPattern = {
    base: /flex-|basis|grow|shrink/,
    variants: [
        { base: /basis/, arbitrary: "size" },
        { base: /grow/, arbitrary: "size" },
        { base: /shrink/, arbitrary: "size" },
        /flex-(row|col)(-reverse)?/,
        /flex-(nowrap|wrap(-reverse)?)/,
        { base: /flex/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default flexPattern;

export const flexSample = [
    [
        "basis-px",
        "basis-auto",
        "basis-full",
        "basis-1",
        "basis-1/2",
        "basis-[14%]",
    ],
    ["grow", "grow-0", "grow-[2]"],
    ["shrink", "shrink-0", "shrink-[2]"],
    ["flex-row", "flex-row-reverse", "flex-col", "flex-col-reverse"],
    ["flex-wrap", "flex-wrap-reverse", "flex-nowrap"],
    ["flex-1", "flex-auto", "flex-initial", "flex-none", "flex-[1_1_0]"],
];
