import ShopIcon from "./linear";

export default ShopIcon;
export { default as ShopIcon, iconInfo as ShopIconInfo } from "./linear";

export { default as ShopBoldIcon, iconInfo as ShopBoldIconInfo } from "./bold";
export {
    default as ShopBrokenIcon,
    iconInfo as ShopBrokenIconInfo,
} from "./broken";
export { default as ShopBulkIcon, iconInfo as ShopBulkIconInfo } from "./bulk";
export {
    default as ShopLinearIcon,
    iconInfo as ShopLinearIconInfo,
} from "./linear";
export {
    default as ShopOutlineIcon,
    iconInfo as ShopOutlineIconInfo,
} from "./outline";
export {
    default as ShopTwotoneIcon,
    iconInfo as ShopTwotoneIconInfo,
} from "./twotone";
