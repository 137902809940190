import ArrowLeftRightIcon from "./linear";

export default ArrowLeftRightIcon;
export {
    default as ArrowLeftRightIcon,
    iconInfo as ArrowLeftRightIconInfo,
} from "./linear";

export {
    default as ArrowLeftRightBoldIcon,
    iconInfo as ArrowLeftRightBoldIconInfo,
} from "./bold";
export {
    default as ArrowLeftRightBrokenIcon,
    iconInfo as ArrowLeftRightBrokenIconInfo,
} from "./broken";
export {
    default as ArrowLeftRightBulkIcon,
    iconInfo as ArrowLeftRightBulkIconInfo,
} from "./bulk";
export {
    default as ArrowLeftRightLinearIcon,
    iconInfo as ArrowLeftRightLinearIconInfo,
} from "./linear";
export {
    default as ArrowLeftRightOutlineIcon,
    iconInfo as ArrowLeftRightOutlineIconInfo,
} from "./outline";
export {
    default as ArrowLeftRightTwotoneIcon,
    iconInfo as ArrowLeftRightTwotoneIconInfo,
} from "./twotone";
