export type DrawerVariants = "primary" | "secondary" | "unstyled";
export type DrawerSizes = "sm" | "md" | "lg" | "unset";
export type DrawerPositions = "left" | "right";

export const portalId = "drawer-portal";
export const ariaLabel = "drawer-header";
export const ariaDescription = "drawer-body";

export const drawerVariants: Record<DrawerVariants, string> = {
    primary: "bg-white shadow-xl",
    secondary: "bg-blue-700 shadow-xl",
    unstyled: "",
};

export const drawerSizes: Record<DrawerSizes, string> = {
    lg: "max-w-512",
    md: "max-w-448",
    sm: "max-w-360",
    unset: "",
};

export const drawerPositions: Record<DrawerPositions, string> = {
    left: "fixed inset-y-0 left-0 z-50",
    right: "fixed inset-y-0 right-0 z-50",
};

export interface AnimationRelation {
    in: string;
    out: string;
}

export const drawerAnimations: Record<DrawerPositions, AnimationRelation> = {
    left: {
        in: "animate-slide-in-lr",
        out: "animate-slide-out-lr -translate-x-full",
    },
    right: {
        in: "animate-slide-in-rl",
        out: "animate-slide-out-rl translate-x-full",
    },
};

export const drawerCommonStyle =
    "flex flex-col w-full pointer-events-auto touch-auto";

export const drawerCloseButtonIconStyle: Record<DrawerVariants, string> = {
    primary: "text-gray-700",
    secondary: "text-white",
    unstyled: "",
};
