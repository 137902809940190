import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ProfileBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 15.6805 3.96 C 16.1605 4.67 16.4405 5.52 16.4405 6.44 C 16.4305 8.84 14.5405 10.79 12.1606 10.87 C 12.0605 10.86 11.9405 10.86 11.8306 10.87 C 9.4505 10.79 7.5606 8.84 7.5606 6.44 C 7.5606 3.99 9.5405 2 12.0006 2"
                stroke="currentColor"
            />
            <path
                d="M 7.1607 14.56 C 4.7407 16.18 4.7407 18.82 7.1607 20.43 C 9.9107 22.27 14.4207 22.27 17.1707 20.43 C 19.5907 18.81 19.5907 16.17 17.1707 14.56 C 14.4307 12.73 9.9207 12.73 7.1607 14.56 Z"
                stroke="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
