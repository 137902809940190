import type { TailwindPattern } from "../interface";

const layoutPattern: TailwindPattern = {
    base: /aspect|columns|order|clear|break-(after|before|inside)|isolate|isolation-auto/,
    variants: [
        /isolate|isolation-auto/,
        /break-after/,
        /break-before/,
        /break-inside/,
        /clear/,
        { base: /aspect/, arbitrary: "number" },
        { base: /columns/, arbitrary: "size" },
        { base: /order/, arbitrary: "number" },
    ],
    arbitrary: "any",
};

export default layoutPattern;

export const layoutSample = [
    ["isolate", "isolation-auto"],
    [
        "break-after-auto",
        "break-after-avoid",
        "break-after-all",
        "break-after-avoid-page",
        "break-after-page",
        "break-after-left",
        "break-after-right",
        "break-after-column",
    ],
    [
        "break-before-auto",
        "break-before-avoid",
        "break-before-all",
        "break-before-avoid-page",
        "break-before-page",
        "break-before-left",
        "break-before-right",
        "break-before-column",
    ],
    [
        "break-inside-auto",
        "break-inside-avoid",
        "break-inside-avoid-page",
        "break-inside-avoid-column",
    ],
    [
        "clear-start",
        "clear-end",
        "clear-left",
        "clear-right",
        "clear-both",
        "clear-none",
    ],
    ["aspect-auto", "aspect-square", "aspect-video", "aspect-[4/3]"],
    ["columns-auto", "columns-sm", "columns-5", "columns-[10rem]"],
    ["order-first", "order-last", "order-none", "order-5", "order-[13]"],
];
