import type { TailwindPattern } from "../interface";

const gridPattern: TailwindPattern = {
    base: /(grid|col|row|auto)-/,
    variants: [
        /grid-flow/,
        { base: /grid-cols/, arbitrary: "size" },
        { base: /grid-rows/, arbitrary: "size" },
        { base: /col-start/, arbitrary: "number" },
        { base: /col-end/, arbitrary: "number" },
        { base: /col-(auto|span)?/, arbitrary: "any" },
        { base: /row-start/, arbitrary: "number" },
        { base: /row-end/, arbitrary: "number" },
        { base: /row-(auto|span)?/, arbitrary: "any" },
        { base: /auto-cols/, arbitrary: "any" },
        { base: /auto-rows/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default gridPattern;

export const gridSample = [
    [
        "grid-flow-row",
        "grid-flow-col",
        "grid-flow-dense",
        "grid-flow-row-dense",
        "grid-flow-col-dense",
    ],
    [
        "grid-cols-none",
        "grid-cols-subgrid",
        "grid-cols-1",
        "grid-cols-[1fr_2fr]",
    ],
    [
        "grid-rows-none",
        "grid-rows-subgrid",
        "grid-rows-1",
        "grid-rows-[1fr_2fr]",
    ],
    ["col-start-auto", "col-start-10", "col-start-[20]"],
    ["col-end-auto", "col-end-10", "col-end-[20]"],
    ["col-auto", "col-span-full", "col-span-3", "col-[2_/_span_2]"],
    ["row-start-auto", "row-start-10", "row-start-[20]"],
    ["row-end-auto", "row-end-10", "row-end-[20]"],
    ["row-auto", "row-span-full", "row-span-3", "row-[2_/_span_2]"],
    [
        "auto-cols-auto",
        "auto-cols-min",
        "auto-cols-max",
        "auto-cols-fr",
        "auto-cols-[minmax(0,_2fr)]",
    ],
    [
        "auto-rows-auto",
        "auto-rows-min",
        "auto-rows-max",
        "auto-rows-fr",
        "auto-rows-[minmax(0,_2fr)]",
    ],
];
