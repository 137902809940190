import type { TailwindPattern } from "../interface";

const forcedColorAdjustPattern: TailwindPattern = {
    base: /forced-color-adjust/,
};

export default forcedColorAdjustPattern;

export const forcedColorAdjustSample = [
    ["forced-color-adjust-auto", "forced-color-adjust-none"],
];
