import ChevronUpIcon from "./linear";

export default ChevronUpIcon;
export {
    default as ChevronUpIcon,
    iconInfo as ChevronUpIconInfo,
} from "./linear";

export {
    default as ChevronUpBoldIcon,
    iconInfo as ChevronUpBoldIconInfo,
} from "./bold";
export {
    default as ChevronUpBrokenIcon,
    iconInfo as ChevronUpBrokenIconInfo,
} from "./broken";
export {
    default as ChevronUpBulkIcon,
    iconInfo as ChevronUpBulkIconInfo,
} from "./bulk";
export {
    default as ChevronUpLinearIcon,
    iconInfo as ChevronUpLinearIconInfo,
} from "./linear";
export {
    default as ChevronUpOutlineIcon,
    iconInfo as ChevronUpOutlineIconInfo,
} from "./outline";
export {
    default as ChevronUpTwotoneIcon,
    iconInfo as ChevronUpTwotoneIconInfo,
} from "./twotone";
