import HomeIcon from "./linear";

export default HomeIcon;
export { default as HomeIcon, iconInfo as HomeIconInfo } from "./linear";

export { default as HomeBoldIcon, iconInfo as HomeBoldIconInfo } from "./bold";
export {
    default as HomeBrokenIcon,
    iconInfo as HomeBrokenIconInfo,
} from "./broken";
export { default as HomeBulkIcon, iconInfo as HomeBulkIconInfo } from "./bulk";
export {
    default as HomeLinearIcon,
    iconInfo as HomeLinearIconInfo,
} from "./linear";
export {
    default as HomeOutlineIcon,
    iconInfo as HomeOutlineIconInfo,
} from "./outline";
export {
    default as HomeTwotoneIcon,
    iconInfo as HomeTwotoneIconInfo,
} from "./twotone";
