import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CreditCardsOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M19 13.3599H2C1.59 13.3599 1.25 13.0199 1.25 12.6099C1.25 12.1999 1.59 11.8599 2 11.8599H19C19.41 11.8599 19.75 12.1999 19.75 12.6099C19.75 13.0199 19.41 13.3599 19 13.3599Z"
                fill="currentColor"
            />
            <path
                d="M15.22 21.7498H5.78003C2.35003 21.7498 1.25 20.6598 1.25 17.2698V10.2798C1.25 7.5898 1.86002 5.99981 4.96002 5.81981C5.22002 5.80981 5.49003 5.7998 5.78003 5.7998H15.22C18.65 5.7998 19.75 6.8898 19.75 10.2798V17.4298C19.71 20.6998 18.61 21.7498 15.22 21.7498ZM5.78003 7.2998C5.51003 7.2998 5.26003 7.30981 5.03003 7.31981C3.24003 7.42981 2.75 7.8098 2.75 10.2798V17.2698C2.75 19.8298 3.17003 20.2498 5.78003 20.2498H15.22C17.8 20.2498 18.22 19.8498 18.25 17.4198V10.2798C18.25 7.7198 17.83 7.2998 15.22 7.2998H5.78003Z"
                fill="currentColor"
            />
            <path
                d="M19 18.18C18.81 18.18 18.62 18.11 18.49 17.98C18.34 17.84 18.25 17.64 18.25 17.43V10.28C18.25 7.72 17.83 7.3 15.22 7.3H5.78003C5.51003 7.3 5.26003 7.31 5.03003 7.32C4.83003 7.33 4.62998 7.25 4.47998 7.11C4.32998 6.97 4.25 6.77 4.25 6.56C4.29 3.3 5.39003 2.25 8.78003 2.25H18.22C21.65 2.25 22.75 3.34 22.75 6.73V13.72C22.75 16.41 22.14 18 19.04 18.18C19.03 18.18 19.01 18.18 19 18.18ZM5.78003 5.8H15.22C18.65 5.8 19.75 6.89 19.75 10.28V16.6C20.91 16.39 21.25 15.79 21.25 13.72V6.73C21.25 4.17 20.83 3.75 18.22 3.75H8.78003C6.50003 3.75 5.91003 4.06 5.78003 5.8Z"
                fill="currentColor"
            />
            <path
                d="M6.96027 18.5601H5.24023C4.83023 18.5601 4.49023 18.2201 4.49023 17.8101C4.49023 17.4001 4.83023 17.0601 5.24023 17.0601H6.96027C7.37027 17.0601 7.71027 17.4001 7.71027 17.8101C7.71027 18.2201 7.38027 18.5601 6.96027 18.5601Z"
                fill="currentColor"
            />
            <path
                d="M12.5494 18.5601H9.10938C8.69938 18.5601 8.35938 18.2201 8.35938 17.8101C8.35938 17.4001 8.69938 17.0601 9.10938 17.0601H12.5494C12.9594 17.0601 13.2994 17.4001 13.2994 17.8101C13.2994 18.2201 12.9694 18.5601 12.5494 18.5601Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
