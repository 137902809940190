import type { ModuleSpecs } from "./interface";

import { routeNames } from "@router/interface";

export const moduleRelation = {
    accountpayable: {
        index: 0,
        path: "accounts/accountspayable/accountpayable",
        tag: "accountpayable",
        title: "",
    },
    accountreceivable: {
        index: 0,
        path: "accounts/accountsreceivable/accountreceivable",
        tag: "accountreceivable",
        title: "",
    },
    "accounts/bank-account": {
        index: 0,
        path: "accounts/bank",
        tag: "accounts/bank-account",
        title: "",
    },
    "accounts/payloads": {
        index: 1,
        path: "accounts/payloads",
        tag: "accounts/payloads",
        title: "Contas Pendentes",
        route: routeNames.APP_ACCOUNTS_PAYLOADS,
    },
    accountspayable: {
        index: 0,
        path: "accounts/accountspayable",
        tag: "accountspayable",
        title: "",
    },
    accountsreceivable: {
        index: 0,
        path: "accounts/accountsreceivable",
        tag: "accountsreceivable",
        title: "",
    },
    actionsBillsList: {
        index: 0,
        path: "subscriptions/bills/components/Actions",
        tag: "actionsBillsList",
        title: "",
        oldParams: (params) => ({
            dataAction: params?.paramsI,
            listPageSelectedRows: params?.paramsII,
            IndexContext: params?.paramsIII,
        }),
    },
    brand: { index: 0, path: "skus/brands/brand", tag: "brand", title: "" },
    brands: { index: 0, path: "skus/brands", tag: "brands", title: "" },
    budget: { index: 0, path: "budgets/order", tag: "budget", title: "" },
    budgets: { index: 0, path: "budgets", tag: "budgets", title: "" },
    buyop: { index: 0, path: "op/oplist", tag: "buyop", title: "" },
    buyorder: { index: 0, path: "skus/buyorder", tag: "buyorder", title: "" },
    buyorde: {
        index: 0,
        path: "skus/buyorder/buyorde",
        tag: "buyorde",
        title: "",
    },
    buyorderanalysis: {
        index: 0,
        path: "skus/buyorder/BuyOrderAnalysis",
        tag: "buyorderanalysis",
        title: "",
    },
    BuyOrdeItems: {
        index: 0,
        path: "skus/buyorder/buyorde/components/Items",
        tag: "BuyOrdeItems",
        title: "",
    },
    "carrier/modal": {
        index: 0,
        path: "companies/carriermodal",
        tag: "carrier/modal",
        title: "",
    },
    "carrier/quotation": {
        index: 0,
        path: "logistics/quotations",
        tag: "carrier/quotation",
        title: "",
    },
    carriers: {
        index: 0,
        path: "logistics/carriers",
        tag: "carriers",
        title: "",
    },
    categories: {
        index: 0,
        path: "skus/categories",
        tag: "categories",
        title: "",
    },
    category: {
        index: 0,
        path: "skus/categories/category",
        tag: "category",
        title: "",
    },
    collectionlist: {
        index: 0,
        path: "logistics/collectionlist",
        tag: "collectionlist",
        title: "",
    },
    comments: {
        index: 0,
        path: "logistics/packages/package/components/Comments",
        tag: "comments",
        title: "",
    },
    commission: {
        index: 0,
        path: "companies/commissions",
        tag: "commission",
        title: "",
    },
    company: {
        index: 1,
        path: "companies",
        tag: "company",
        title: "Dados de Conta",
        route: routeNames.APP_COMPANIES,
    },
    companyparameter: {
        index: 0,
        path: "companies/companyparameter",
        tag: "companyparameter",
        title: "",
    },
    companyview: {
        index: 1,
        path: "companies/company",
        tag: "companyview",
        title: ({ key }) => `${key} - Dados de Conta`,
        route: routeNames.APP_COMPANIES_DETAILS,
    },
    conference: {
        index: 0,
        path: "skus/conference",
        tag: "conference",
        title: "",
    },
    consumer: {
        index: 0,
        path: "consumers/consumer",
        tag: "consumer",
        title: "",
        route: routeNames.APP_CONSUMERS_DETAILS,
    },
    consumerbankslip: {
        index: 0,
        path: "consumers/bankslip",
        tag: "consumerbankslip",
        title: "",
    },
    consumergiftcard: {
        index: 0,
        path: "consumers/giftcard",
        tag: "consumergiftcard",
        title: "",
    },
    consumers: {
        index: 1,
        path: "consumers",
        tag: "consumers",
        title: "Clientes",
        route: routeNames.APP_CONSUMERS,
    },
    ctes: { index: 0, path: "feeds/ctes", tag: "ctes", title: "" },
    dash: {
        index: 1,
        path: "dashboard",
        tag: "dash",
        title: "Início",
        fixed: true,
        route: routeNames.APP_HOME,
    },
    dashboard: {
        index: 1,
        path: "analytics",
        tag: "dashboard",
        title: "Analytics",
        route: routeNames.APP_ANALYTICS,
    },
    feeds: { index: 0, path: "feeds/feeds", tag: "feeds", title: "" },
    fulfillments: {
        index: 1,
        path: "fulfillments",
        tag: "fulfillments",
        title: "Fulfillment",
        route: routeNames.APP_FULFILLMENT,
    },
    fulfillment: {
        index: 1,
        path: "fulfillments/fulfillment",
        tag: "fulfillment",
        title: "",
        route: routeNames.APP_FULFILLMENT_DETAILS,
    },
    gnre: { index: 0, path: "orders/gnre", tag: "gnre", title: "" },
    gnrerule: { index: 0, path: "orders/gnrerule", tag: "gnrerule", title: "" },
    "hub/messages": {
        index: 0,
        path: "hub/messages",
        tag: "hub/messages",
        title: "",
    },
    "hub/orders": {
        index: 1,
        path: "hub/orders",
        tag: "hub/orders",
        title: "Pedidos Integrados",
        route: routeNames.APP_ORDERS_HUB,
    },
    "hub/productquestionanswer": {
        index: 0,
        path: "hub/questionanswer",
        tag: "hub/productquestionanswer",
        title: "",
    },
    "hub/skuoffer": {
        index: 1,
        path: "hub/offers",
        tag: "hub/skuoffer",
        title: "Anúncios",
        route: routeNames.APP_OFFERS,
    },
    "hub/skulog": { index: 0, path: "hub/logs", tag: "hub/skulog", title: "" },
    hubbrand: { index: 0, path: "hub/brands", tag: "hubbrand", title: "" },
    hubcarrier: {
        index: 0,
        path: "hub/carriers",
        tag: "hubcarrier",
        title: "",
    },
    hubcategory: {
        index: 0,
        path: "hub/categories",
        tag: "hubcategory",
        title: "",
    },
    hubOrderPayments: {
        index: 0,
        path: "hub/orders/components/HubOrderPayments",
        tag: "hubOrderPayments",
        title: "",
        oldParams: (params) => ({ data: params?.paramsII }),
    },
    hubpayment: {
        index: 0,
        path: "hub/payments",
        tag: "hubpayment",
        title: "",
    },
    hubsalespolicy: {
        index: 0,
        path: "hub/companysales",
        tag: "hubsalespolicy",
        title: "",
    },
    hubwarehouse: {
        index: 0,
        path: "hub/warehouse",
        tag: "hubwarehouse",
        title: "",
    },
    inventory: {
        index: 0,
        path: "skus/inventory",
        tag: "inventory",
        title: "",
    },
    inventoryItem: {
        index: 0,
        path: "inventory/components/inventoryItem",
        tag: "inventoryItem",
        title: "",
    },
    invoice: { index: 0, path: "orders/invoice", tag: "invoice", title: "" },
    lineop: { index: 0, path: "op/line", tag: "lineop", title: "" },
    myskus: { index: 0, path: "myskus", tag: "myskus", title: "" },
    newBrand: {
        index: 0,
        path: "skus/brands/components/NewBrand",
        tag: "newBrand",
        title: "",
        oldParams: (params) => ({ ListPage: params?.paramsI }),
    },
    newOrder: {
        index: 0,
        path: "orders/components/NewOrder",
        tag: "newOrder",
        title: "",
        oldParams: (params) => ({
            dataContext: params?.paramsI,
            Budget: params?.paramsII,
            Invoice: params?.paramsIII,
        }),
    },
    NewSku: {
        index: 0,
        path: "skus/components/NewSkus",
        tag: "NewSku",
        title: "",
        oldParams: (params) => ({
            OnlyView: params?.paramsI,
            DataContext: params?.paramsII,
            ButtonConfig: params?.paramsIII,
            IndexContext: params?.paramsIV,
            OwnProduction: params?.paramsV,
        }),
    },
    offer: {
        index: 0,
        path: "hub/offers/offer",
        tag: "offer",
        title: "",
        route: routeNames.APP_OFFERS_DETAILS,
    },
    oplogistic: {
        index: 0,
        path: "logistics/oplogistics/oplogistic",
        tag: "oplogistic",
        title: "",
    },
    oplogistics: {
        index: 0,
        path: "logistics/oplogistics",
        tag: "oplogistics",
        title: "",
    },
    opview: { index: 0, path: "op/oplist/opview", tag: "opview", title: "" },
    order: {
        index: 0,
        path: "orders/order",
        tag: "order",
        title: "",
        route: routeNames.APP_ORDERS_DETAILS,
    },
    OrderEvents: {
        index: 0,
        path: "orders/order/components/Events",
        tag: "OrderEvents",
        title: "",
    },
    OrderFiles: {
        index: 0,
        path: "orders/order/components/Files",
        tag: "OrderFiles",
        title: "",
    },
    OrderItems: {
        index: 0,
        path: "orders/order/components/Items",
        tag: "OrderItems",
        title: "",
    },
    OrderPackages: {
        index: 0,
        path: "orders/order/components/Packages",
        tag: "OrderPackages",
        title: "",
    },
    OrderPayments: {
        index: 0,
        path: "orders/order/components/Payments",
        tag: "OrderPayments",
        title: "",
    },
    "orders/rules": {
        index: 1,
        path: "orders/rules",
        tag: "orders/rules",
        title: "DE-PARA Tipo Pedido",
        route: routeNames.APP_ORDERS_RULES,
    },
    orders: {
        index: 1,
        path: "orders",
        tag: "orders",
        title: "Pedidos",
        route: routeNames.APP_ORDERS,
    },
    ordersActionList: {
        index: 0,
        path: "orders/components/Actions",
        tag: "ordersActionList",
        title: "",
        oldParams: (params) => ({
            dataAction: params?.paramsI,
            listPageSelectedRows: params?.paramsII,
            IndexContext: params?.paramsIII,
        }),
    },
    packages: {
        index: 0,
        path: "logistics/packages",
        tag: "packages",
        title: "",
    },
    packageview: {
        index: 0,
        path: "logistics/packages/package",
        tag: "packageview",
        title: "",
    },
    packing: { index: 0, path: "logistics/packing", tag: "packing", title: "" },
    parameter: {
        index: 0,
        path: "companies/companyparameter/parameter",
        tag: "parameter",
        title: "",
    },
    pickinglist: {
        index: 0,
        path: "logistics/outbound",
        tag: "pickinglist",
        title: "",
    },
    pickinglistprint: {
        index: 0,
        path: "logistics/pickinglistprint",
        tag: "pickinglistprint",
        title: "",
    },
    "policies/terms-of-use": {
        index: 1,
        path: "policies/terms-of-use",
        tag: "policies/terms-of-use",
        title: "Termos de uso",
        route: routeNames.APP_POLICIES_TERMS_OF_USE,
    },
    pricer: { index: 0, path: "pricer", tag: "pricer", title: "" },
    privilege: {
        index: 0,
        path: "companies/privileges/privilege",
        tag: "privilege",
        title: "",
    },
    privileges: {
        index: 0,
        path: "companies/privileges",
        tag: "privileges",
        title: "",
    },
    purchase: {
        index: 0,
        path: "skus/purchases/purchase",
        tag: "purchase",
        title: "",
        route: routeNames.APP_SKUS_PURCHASE_DETAILS,
    },
    PurchaseItems: {
        index: 0,
        path: "skus/purchases/purchase/components/Items",
        tag: "PurchaseItems",
        title: "",
    },
    purchaseOrder: {
        index: 0,
        path: "skus/purchases/components/PurchaseOrder",
        tag: "purchaseOrder",
        title: "",
        oldParams: (params) => ({ dataContext: params?.paramsIII }),
    },
    PurchasePayments: {
        index: 0,
        path: "skus/purchases/purchase/components/Payments",
        tag: "PurchasePayments",
        title: "",
    },
    purchases: {
        index: 0,
        path: "skus/purchases",
        tag: "purchases",
        title: "",
    },
    quotation: {
        index: 0,
        path: "logistics/quotations/quotation",
        tag: "quotation",
        title: "",
    },
    reports: { index: 0, path: "reports", tag: "reports", title: "" },
    salespolicy: {
        index: 0,
        path: "companies/companysalespolicy",
        tag: "salespolicy",
        title: "",
    },
    seller: { index: 0, path: "sellers/seller", tag: "seller", title: "" },
    sellers: { index: 0, path: "sellers", tag: "sellers", title: "" },
    sku: {
        index: 0,
        path: "skus/sku",
        tag: "sku",
        title: "",
        route: routeNames.APP_PRODUCT_DETAILS,
    },
    "sku/inventory": {
        index: 0,
        path: "inventory",
        tag: "sku/inventory",
        title: "",
    },
    skukardex: {
        index: 0,
        path: "skus/skumovement/kardex",
        tag: "skukardex",
        title: "",
    },
    skulocation: {
        index: 0,
        path: "skus/skulocation",
        tag: "skulocation",
        title: "",
    },
    skumovement: {
        index: 0,
        path: "skus/skumovement",
        tag: "skumovement",
        title: "",
    },
    skupricing: {
        index: 0,
        path: "skus/skupricing",
        tag: "skupricing",
        title: "",
    },
    skus: {
        index: 1,
        path: "skus",
        tag: "skus",
        title: "Catálogo",
        route: routeNames.APP_SKUS,
    },
    skusbatch: {
        index: 0,
        path: "skus/skusbatch",
        tag: "skusbatch",
        title: "",
    },
    skusellinganalysis: {
        index: 0,
        path: "skus/skusellinganalysis",
        tag: "skusellinganalysis",
        title: "",
    },
    skusop: { index: 0, path: "op/skus", tag: "skusop", title: "" },
    skussuppliers: {
        index: 0,
        path: "skus/skussuppliers",
        tag: "skussuppliers",
        title: "",
    },
    skustock: { index: 0, path: "skus/skustock", tag: "skustock", title: "" },
    skuvariation: {
        index: 0,
        path: "skus/skuvariation",
        tag: "skuvariation",
        title: "",
    },
    subscription: {
        index: 0,
        path: "subscriptions",
        tag: "subscription",
        title: "",
    },
    subscriptionbill: {
        index: 0,
        path: "subscriptions/bills",
        tag: "subscriptionbill",
        title: "",
    },
    subscriptionbillsheet: {
        index: 0,
        path: "subscriptions/bills/bill",
        tag: "subscriptionbillsheet",
        title: "",
    },
    subscriptioncharge: {
        index: 0,
        path: "subscriptions/changes",
        tag: "subscriptioncharge",
        title: "",
    },
    subscriptionplan: {
        index: 0,
        path: "subscriptions/plans",
        tag: "subscriptionplan",
        title: "",
    },
    subscriptionplansheet: {
        index: 0,
        path: "subscriptions/plans/plan",
        tag: "subscriptionplansheet",
        title: "",
    },
    subscriptionpromotional: {
        index: 0,
        path: "subscriptions/promotions",
        tag: "subscriptionpromotional",
        title: "",
    },
    subscriptionsheet: {
        index: 0,
        path: "subscriptions/subscription",
        tag: "subscriptionsheet",
        title: "",
    },
    supplier: {
        index: 0,
        path: "accounts/suppliers",
        tag: "supplier",
        title: "",
    },
    suppliercateogry: {
        index: 0,
        path: "accounts/suppliercategories",
        tag: "suppliercateogry",
        title: "",
    },
    supplierview: {
        index: 0,
        path: "accounts/suppliers/supplier",
        tag: "supplierview",
        title: "",
    },
    tax: { index: 0, path: "skus/taxes/tax", tag: "tax", title: "" },
    taxes: { index: 0, path: "skus/taxes", tag: "taxes", title: "" },
    "taxes/model": {
        index: 1,
        path: "taxes/models/model",
        tag: "taxes/model",
        title: ({ key }) => `${key} - Modelos Fiscais`,
        route: routeNames.APP_TAXES_MODELS_DETAILS,
    },
    "taxes/models": {
        index: 1,
        path: "taxes/models",
        tag: "taxes/models",
        title: "Modelos Fiscais",
        route: routeNames.APP_TAXES_MODELS,
    },
    trackingevents: {
        index: 0,
        path: "logistics/packages/package/components/TrackingEvents",
        tag: "trackingevents",
        title: "",
    },
    trackpi: { index: 0, path: "logistics/trackpi", tag: "trackpi", title: "" },
    trackreverse: {
        index: 0,
        path: "logistics/trackreverse",
        tag: "trackreverse",
        title: "",
    },
    typeaccount: {
        index: 0,
        path: "accounts/typeaccounts",
        tag: "typeaccount",
        title: "",
    },
    typeaccountcategory: {
        index: 0,
        path: "accounts/typeaccountcategory",
        tag: "typeaccountcategory",
        title: "",
    },
    typedocument: {
        index: 0,
        path: "accounts/typedocument",
        tag: "typedocument",
        title: "",
    },
    typeorder: {
        index: 0,
        path: "accounts/typeorder",
        tag: "typeorder",
        title: "",
    },
    typepayment: {
        index: 0,
        path: "accounts/typepayment",
        tag: "typepayment",
        title: "",
    },
    typeskuvariation: {
        index: 0,
        path: "skus/typeskuvariation",
        tag: "typeskuvariation",
        title: "",
    },
    typestatusorder: {
        index: 0,
        path: "companies/typestatusorder",
        tag: "typestatusorder",
        title: "",
    },
    typestatussefaz: {
        index: 0,
        path: "companies/typestatussefaz",
        tag: "typestatussefaz",
        title: "",
    },
    users: { index: 0, path: "companies/users", tag: "users", title: "" },
    wallets: {
        index: 1,
        path: "wallets",
        tag: "wallets",
        title: "",
        route: routeNames.APP_WALLET,
    },
    warehouse: {
        index: 0,
        path: "skus/warehouse",
        tag: "warehouse",
        title: "",
    },
    webhook: { index: 0, path: "companies/webhook", tag: "webhook", title: "" },
} satisfies Record<string, ModuleSpecs>;

export type ModuleNames = keyof typeof moduleRelation;

export const staticContent: Record<string, { index: 0 | 1; path: string }> = {
    PageListExample: { index: 0, path: "aaExample/list.json" },
    // feed
    PageListCtes: { index: 0, path: "feed/ctes/list.json" },
    PageListFeed: { index: 0, path: "feed/list.json" },

    //company
    PageCompany: { index: 0, path: "company/company/page.json" },
    PageListCompanies: { index: 0, path: "company/list.json" },
    PageCompanyStateInscription: {
        index: 0,
        path: "company/company/components/StateInscription.json",
    },
    PageCompanySimplesCred: {
        index: 0,
        path: "company/company/components/CompanySimplesCred.json",
    },
    PageCompanyParameter: {
        index: 0,
        path: "company/companyparameter/page.json",
    },
    PageListCompanyTypeStatusOrder: {
        index: 0,
        path: "company/typestatusorder/list.json",
    },
    PageListCompanyTypeStatusSefaz: {
        index: 0,
        path: "company/typestatussefaz/list.json",
    },
    PageListCompanyPrivileges: {
        index: 0,
        path: "company/privileges/list.json",
    },
    PageListCompanyUsers: { index: 0, path: "company/users/list.json" },
    PageListCompanySalesPolicy: {
        index: 0,
        path: "company/companysalespolicy/list.json",
    },
    PageListCompanyCommissions: {
        index: 0,
        path: "company/commissions/list.json",
    },
    PageListCarrierModal: { index: 0, path: "company/carriermodal/list.json" },
    PageListWebhookLog: { index: 0, path: "company/webhook/list.json" },

    //consumers
    PageListConsumers: { index: 0, path: "consumers/list.json" },
    PageConsumer: { index: 0, path: "consumers/consumer/page.json" },
    PageNewConsumer: { index: 0, path: "consumers/NewConsumer/page.json" },
    PageListGiftCard: { index: 0, path: "consumers/giftcard/list.json" },
    PageListBankslip: { index: 0, path: "consumers/bankslip/list.json" },

    //accounts
    PageListAccountsPayable: {
        index: 0,
        path: "accounts/accountspayable/list.json",
    },
    PageAccountsPayable: {
        index: 0,
        path: "accounts/accountspayable/accountpayable/page.json",
    },
    PageAccountsPayablePayments: {
        index: 0,
        path: "accounts/accountspayable/accountpayable/component/Payment.json",
    },
    PageAccountsPayableFile: {
        index: 0,
        path: "accounts/accountspayable/accountpayable/component/File.json",
    },
    PageListAccountsReceivable: {
        index: 0,
        path: "accounts/accountsreceivable/list.json",
    },
    PageAccountsReceivable: {
        index: 0,
        path: "accounts/accountsreceivable/accountreceivable/page.json",
    },
    PageAccountsReceivablePayments: {
        index: 0,
        path: "accounts/accountsreceivable/accountreceivable/component/Payment.json",
    },
    PageListSuppliers: { index: 0, path: "accounts/suppliers/list.json" },
    PageSupplier: { index: 0, path: "accounts/suppliers/supplier/page.json" },
    PageSupplierContacts: {
        index: 0,
        path: "accounts/suppliers/supplier/components/contacts.json",
    },
    PageSupplierPickinglistSchedule: {
        index: 0,
        path: "accounts/suppliers/supplier/components/pickinglistschedule.json",
    },
    PageSupplierLabelHistory: {
        index: 0,
        path: "accounts/suppliers/supplier/components/labelhistory.json",
    },
    PageListAccountsSupplierCategory: {
        index: 0,
        path: "accounts/suppliercategories/list.json",
    },
    PageListAccountsTypeCategory: {
        index: 0,
        path: "accounts/typecategories/list.json",
    },
    PageListAccountsType: { index: 0, path: "accounts/typeaccounts/list.json" },
    PageListAccountsTypeOrder: {
        index: 0,
        path: "accounts/typeorder/list.json",
    },
    PageListAccountsTypeDocument: {
        index: 0,
        path: "accounts/typedocument/list.json",
    },
    PageListAccountsTypePayment: {
        index: 0,
        path: "accounts/typepayment/list.json",
    },
    PageListBank: { index: 0, path: "accounts/bank/list.json" },
    PageSupplierCarrierTableUpload: {
        index: 0,
        path: "accounts/suppliers/supplier/components/carriertableupload.json",
    },

    //order
    PageListOrders: { index: 0, path: "orders/list.json" },
    PageOrder: { index: 0, path: "orders/order/page.json" },
    PageOrderEvents: { index: 0, path: "orders/order/components/events.json" },
    PageOrderItems: { index: 0, path: "orders/order/components/items.json" },
    PageOrderPayments: {
        index: 0,
        path: "orders/order/components/payments.json",
    },
    PageOrderFiles: { index: 0, path: "orders/order/components/files.json" },
    PageOrderPackages: {
        index: 0,
        path: "orders/order/components/packages.json",
    },
    PageListOrdersNewOrder: { index: 0, path: "orders/newOrder/page.json" },
    PageListGnre: { index: 0, path: "orders/gnre/list.json" },
    PageListGnreRule: { index: 0, path: "orders/gnrerule/list.json" },
    PageListInvoice: { index: 0, path: "orders/invoice/list.json" },
    PageOrderSimulation: {
        index: 0,
        path: "orders/order/components/simulation.json",
    },
    PageOrderImportXML: {
        index: 0,
        path: "orders/components/importorderxml.json",
    },
    PageOrderTracking: {
        index: 0,
        path: "orders/order/components/tracking.json",
    },

    //brands
    PageListBrands: { index: 0, path: "brands/list.json" },
    PageListBrandsNewBrand: { index: 0, path: "brands/newBrand/page.json" },
    PageBrand: { index: 0, path: "brands/brand/page.json" },

    //Hub
    PageListHubOffers: { index: 0, path: "hub/offers/list.json" },
    PageOffer: { index: 0, path: "hub/offers/offer/page.json" },
    PageListHubCategory: { index: 0, path: "hub/category/list.json" },
    PageListHubOrders: { index: 0, path: "hub/orders/list.json" },
    PageListHubOrdersPayments: {
        index: 0,
        path: "hub/orders/components/payments.json",
    },
    PageListHubBrands: { index: 0, path: "hub/brands/list.json" },
    PageListHubPayments: { index: 0, path: "hub/payments/list.json" },
    PageListHubWarehouse: { index: 0, path: "hub/warehouse/list.json" },
    PageListHubCarriers: { index: 0, path: "hub/carriers/list.json" },
    PageListHubSkuLog: { index: 0, path: "hub/skulog/list.json" },
    PageListHubProductQuestionAnswer: {
        index: 0,
        path: "hub/questionanswer/list.json",
    },
    PageListHubMessages: { index: 0, path: "hub/messages/list.json" },
    PageListHubCompanySales: { index: 0, path: "hub/companysales/list.json" },

    //skus
    PageSku: { index: 0, path: "skus/sku/page.json" },
    PageSkuStock: { index: 0, path: "skus/skustock/list.json" },
    PageListSkus: { index: 0, path: "skus/list.json" },
    PageListMySkus: { index: 0, path: "myskus/list.json" },
    PageListSkuVariation: {
        index: 0,
        path: "skus/sku/component/variation/list.json",
    },
    PageListSkuInventory: { index: 0, path: "skus/inventory/list.json" },
    PageListSkuInventoryV2: { index: 0, path: "skus/inventory/listv2.json" },
    PageListSkuNewInventory: {
        index: 0,
        path: "skus/inventory/newinventory/list.json",
    },
    PageListSkuBatchV2: { index: 0, path: "skus/batch/listv2.json" },
    PageBatchByAddress: { index: 0, path: "skus/batch/BatchByAddress.json" },
    PageListSkuKit: { index: 0, path: "skus/sku/component/kit/list.json" },
    PageListSkuSimilarCategory: {
        index: 0,
        path: "skus/sku/component/similarCategory/list.json",
    },
    PageListSkuSupplier: {
        index: 0,
        path: "skus/sku/component/supplier/list.json",
    },
    PageListBarCodeList: {
        index: 0,
        path: "skus/sku/component/barcode/barcodelist.json",
    },
    PageListBarCodePackageList: {
        index: 0,
        path: "skus/sku/component/barcode/barcodepackagelist.json",
    },
    PageListSkuKitResume: {
        index: 0,
        path: "skus/sku/component/kit/resume/list.json",
    },
    PageListPricing: { index: 0, path: "skus/skupricing/list.json" },
    PageListSkuLocation: { index: 0, path: "skus/skulocation/list.json" },
    PageListSkuWarehouse: { index: 0, path: "skus/warehouse/list.json" },
    PageListSkuPurchases: { index: 0, path: "skus/purchases/list.json" },
    PageListSkuPurchasesOrder: {
        index: 0,
        path: "skus/purchases/purchaseOrder/page.json",
    },
    PageListTaxes: { index: 0, path: "skus/taxes/list.json" },
    PageListSkuMoviment: { index: 0, path: "skus/skumovement/list.json" },
    PageListMetricsBuySku: {
        index: 0,
        path: "skus/skumovement/components/MetricsBuySku.json",
    },
    PageListSkuKardex: { index: 0, path: "skus/skumovement/kardex.json" },
    PageListTypeSkuVariation: {
        index: 0,
        path: "skus/typeskuvariation/list.json",
    },
    PageListTaxCFOPs: { index: 0, path: "skus/taxes/tax/cfops.json" },
    PageListTaxICMS: { index: 0, path: "skus/taxes/tax/icms.json" },
    PagePurchasesOrderSku: {
        index: 0,
        path: "skus/purchases/purchaseOrder/components/sku.json",
    },
    PagePurchase: { index: 0, path: "skus/purchases/purchase/page.json" },
    PagePurchaseItems: {
        index: 0,
        path: "skus/purchases/purchase/components/items.json",
    },
    PagePurchasePayments: {
        index: 0,
        path: "skus/purchases/purchase/components/payments.json",
    },
    PageListCategory: { index: 0, path: "skus/categories/list.json" },
    PageCategory: { index: 0, path: "skus/categories/category/page.json" },
    PageListBuyOrders: { index: 0, path: "skus/buyorders/list.json" },
    PageBuyOrder: { index: 0, path: "skus/buyorders/buyorder/page.json" },
    PageBuyOrderAnalysis: {
        index: 0,
        path: "skus/buyorders/buyorderanalysis/list.json",
    },
    PageBuyOrderItem: {
        index: 0,
        path: "skus/buyorders/buyorder/components/item.json",
    },
    PageSkusSuppliers: { index: 0, path: "skus/skussuppliers/list.json" },
    PageSkuSerialNumber: { index: 0, path: "skus/skuserialnumber/list.json" },
    StatusRuleBuyOrder: {
        index: 0,
        path: "skus/buyorders/buyorder/components/StatusRuleBuyOrder.json",
    },
    PageListCreateOrderMovement: {
        index: 0,
        path: "skus/skumovement/components/CreateOrderMovement.json",
    },

    // reports
    PageReports: { index: 0, path: "reports/page.json" },

    // logistics
    PageListOpLogistic: {
        index: 0,
        path: "logistic/oplogistics/oplogistic/page.json",
    },
    PageListOpLogistics: { index: 0, path: "logistic/oplogistics/list.json" },
    PageListOutbound: { index: 0, path: "logistic/outbound/list.json" },
    PageListPackage: { index: 0, path: "logistic/packages/list.json" },
    PagePackage: { index: 0, path: "logistic/packages/package/page.json" },
    PagePackageComments: {
        index: 0,
        path: "logistic/packages/package/components/comments.json",
    },
    PagePackageEvents: {
        index: 0,
        path: "logistic/packages/package/components/events.json",
    },
    PageListCarriers: { index: 0, path: "logistic/carriers/list.json" },
    PageListCollectionList: {
        index: 0,
        path: "packages/collectionlist/list.json",
    },
    PageListTrackPI: { index: 0, path: "logistic/trackpi/list.json" },
    PageListTrackReverse: { index: 0, path: "logistic/trackreverse/list.json" },
    PageQuotationsProducts: {
        index: 0,
        path: "logistic/quotations/components/ProductsTable.json",
    },
    PageQuotationsVolumes: {
        index: 0,
        path: "logistic/quotations/components/VolumesTable.json",
    },
    PageListQuotations: { index: 0, path: "logistic/quotations/list.json" },
    PageQuotations: {
        index: 0,
        path: "logistic/quotations/quotation/page.json",
    },
    CarrierQuotation: {
        index: 0,
        path: "logistic/quotations/quotation/components/CarrierQuotation.json",
    },

    // sellers
    PageListSellers: { index: 0, path: "sellers/list.json" },

    // subscriptions
    PageListPlans: { index: 0, path: "subscriptions/plans/list.json" },
    PagePlan: { index: 0, path: "subscriptions/plans/plan/page.json" },
    PagePlanItems: {
        index: 0,
        path: "subscriptions/plans/plan/components/items.json",
    },
    PageListSubscriptions: { index: 0, path: "subscriptions/list.json" },
    PageSubscription: {
        index: 0,
        path: "subscriptions/subscription/page.json",
    },
    PageSubscriptionBills: {
        index: 0,
        path: "subscriptions/subscription/components/bills.json",
    },
    PageSubscriptionItems: {
        index: 0,
        path: "subscriptions/subscription/components/items.json",
    },
    PageSubscriptionPromotional: {
        index: 0,
        path: "subscriptions/subscription/components/promotional.json",
    },
    PageListCharges: { index: 0, path: "subscriptions/charges/list.json" },
    PageListBills: { index: 0, path: "subscriptions/bills/list.json" },
    PageBill: { index: 0, path: "subscriptions/bills/bill/page.json" },
    PageBillItems: {
        index: 0,
        path: "subscriptions/bills/bill/components/items.json",
    },
    PageBillCharges: {
        index: 0,
        path: "subscriptions/bills/bill/components/charges.json",
    },
    PageListPromotional: {
        index: 0,
        path: "subscriptions/promotional/list.json",
    },

    //op
    PageListLineOP: { index: 0, path: "op/line/list.json" },
    PageListOP: { index: 0, path: "op/listop/list.json" },
    PageListOPSkus: { index: 0, path: "op/list.json" },
    PageOPView: { index: 0, path: "op/listop/opview/page.json" },
    PageOPViewItems: {
        index: 0,
        path: "op/listop/opview/components/items.json",
    },

    //orçamento
    PageListBudgest: { index: 0, path: "budgets/list.json" },
    Inventory: { index: 0, path: "inventory/list.json" },
    InventorySummary: { index: 0, path: "inventory/inventoryItem/list.json" },
};
