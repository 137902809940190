import type { ComponentProps, ReactNode } from "react";

import { forwardRef, useMemo } from "react";

import {
    buttonIconCommonStyle,
    buttonIconSizes,
    buttonIconVariants,
} from "./interface";
import type { ButtonIconSizes, ButtonIconVariants } from "./interface";

import Icon from "../Icons";
import type { IconNames, IconVariants } from "../Icons/interface";
import { ReactComponent as Spinner } from "../Icons/mocks/spinner/linear.svg";

import { classes } from "@utils/styles/tailwind/v4";

export interface ButtonIconProps extends ComponentProps<"button"> {
    icon: IconNames | Exclude<ReactNode, string>;
    iconVariant?: IconVariants;
    label: string;
    variant?: ButtonIconVariants;
    size?: ButtonIconSizes;
    isDisabled?: boolean;
    isLoading?: boolean;
    isRounded?: boolean;
    iconStyle?: string;
}

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
    function ButtonIcon(
        {
            icon,
            iconVariant = "linear",
            label,
            variant = "solid",
            size = "lg",
            isRounded,
            isDisabled,
            isLoading,
            iconStyle = "",
            className,
            ...rest
        },
        ref,
    ) {
        const currentStyle = useMemo(
            () =>
                classes`${variant !== "unstyled" && buttonIconCommonStyle} ${
                    buttonIconSizes[size]
                } ${buttonIconVariants[variant]} ${
                    isRounded && "rounded-full"
                } ${className}`,
            [variant, size, isRounded, className],
        );

        return (
            <button
                ref={ref}
                aria-label={label}
                className={currentStyle}
                disabled={isDisabled}
                {...rest}>
                {isLoading ? (
                    <Spinner
                        aria-hidden="true"
                        aria-busy="true"
                        focusable="false"
                        role="img"
                        className={`${
                            size === "lg" ? "w-36 h-36" : "w-24 h-24"
                        } aspect-square animate-spin`}
                    />
                ) : (
                    <>
                        {typeof icon === "string" ? (
                            <Icon
                                icon={icon as IconNames}
                                variant={iconVariant}
                                className={`w-full h-full ${iconStyle}`}
                            />
                        ) : (
                            icon
                        )}
                    </>
                )}
            </button>
        );
    },
);

export default ButtonIcon;

export * from "./interface";
