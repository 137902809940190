import ArrowDownIcon from "./linear";

export default ArrowDownIcon;
export {
    default as ArrowDownIcon,
    iconInfo as ArrowDownIconInfo,
} from "./linear";

export {
    default as ArrowDownBoldIcon,
    iconInfo as ArrowDownBoldIconInfo,
} from "./bold";
export {
    default as ArrowDownBrokenIcon,
    iconInfo as ArrowDownBrokenIconInfo,
} from "./broken";
export {
    default as ArrowDownBulkIcon,
    iconInfo as ArrowDownBulkIconInfo,
} from "./bulk";
export {
    default as ArrowDownLinearIcon,
    iconInfo as ArrowDownLinearIconInfo,
} from "./linear";
export {
    default as ArrowDownOutlineIcon,
    iconInfo as ArrowDownOutlineIconInfo,
} from "./outline";
export {
    default as ArrowDownTwotoneIcon,
    iconInfo as ArrowDownTwotoneIconInfo,
} from "./twotone";
