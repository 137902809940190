import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DocumentPreviousBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M9.65 15H7L9 17" stroke="currentColor" />
            <path d="M13 15H12" stroke="currentColor" />
            <path d="M7 15L9 13" stroke="currentColor" />
            <path d="M2 9C2 4 4 2 9 2H14" stroke="currentColor" />
            <path
                d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V12.98"
                stroke="currentColor"
            />
            <path d="M18 10C15 10 14 9 14 6V2L22 10" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
