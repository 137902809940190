import AddIcon from "./linear";

export default AddIcon;
export { default as AddIcon, iconInfo as AddIconInfo } from "./linear";

export { default as AddBoldIcon, iconInfo as AddBoldIconInfo } from "./bold";
export {
    default as AddBrokenIcon,
    iconInfo as AddBrokenIconInfo,
} from "./broken";
export { default as AddBulkIcon, iconInfo as AddBulkIconInfo } from "./bulk";
export {
    default as AddLinearIcon,
    iconInfo as AddLinearIconInfo,
} from "./linear";
export {
    default as AddOutlineIcon,
    iconInfo as AddOutlineIconInfo,
} from "./outline";
export {
    default as AddTwotoneIcon,
    iconInfo as AddTwotoneIconInfo,
} from "./twotone";
