import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowLeftRightBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M17.2793 10.45L20.9993 6.72998L17.2793 3.01001"
                stroke="currentColor"
            />
            <path d="M10.0098 6.72998H20.9998" stroke="currentColor" />
            <path d="M3 6.72998H5.98999" stroke="currentColor" />
            <path
                d="M6.71997 13.55L3 17.2701L6.71997 20.9901"
                stroke="currentColor"
            />
            <path d="M3 17.27H14.09" stroke="currentColor" />
            <path d="M20.9993 17.27H18.0293" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
