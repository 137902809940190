import CreditCardIcon from "./linear";

export default CreditCardIcon;
export {
    default as CreditCardIcon,
    iconInfo as CreditCardIconInfo,
} from "./linear";

export {
    default as CreditCardBoldIcon,
    iconInfo as CreditCardBoldIconInfo,
} from "./bold";
export {
    default as CreditCardBrokenIcon,
    iconInfo as CreditCardBrokenIconInfo,
} from "./broken";
export {
    default as CreditCardBulkIcon,
    iconInfo as CreditCardBulkIconInfo,
} from "./bulk";
export {
    default as CreditCardLinearIcon,
    iconInfo as CreditCardLinearIconInfo,
} from "./linear";
export {
    default as CreditCardOutlineIcon,
    iconInfo as CreditCardOutlineIconInfo,
} from "./outline";
export {
    default as CreditCardTwotoneIcon,
    iconInfo as CreditCardTwotoneIconInfo,
} from "./twotone";
