import { createContext, useContext } from "react";

import { moduleRelation } from "./relations";
import type { ModuleItem } from "./interface";

export interface AppModuleManagerContext {
    currentModule: ModuleItem | null;
    modules: ModuleItem[];
    hydrated: boolean;
    addModules: (
        newMds: (Partial<
            Pick<ModuleItem, "oldKey" | "params" | "search" | "title">
        > & {
            tag: keyof typeof moduleRelation;
            order?: number;
            relative?: boolean;
        })[],
    ) => void;
    removeModules: (
        mds:
            | Partial<Pick<ModuleItem, "key" | "oldKey">>[]
            | ((
                  prev: ModuleItem[],
              ) => Partial<Pick<ModuleItem, "key" | "oldKey">>[]),
    ) => void;
    setModule: (md: Partial<Pick<ModuleItem, "key" | "oldKey">>) => void;
    updateModules: (
        mds: (Partial<
            Pick<ModuleItem, "key" | "oldKey" | "params" | "search" | "title">
        > & { moveTo?: string })[],
    ) => void;
}

export const AppModuleManagerContext = createContext<AppModuleManagerContext>({
    currentModule: null,
    modules: [],
    hydrated: false,
    addModules: () => null,
    removeModules: () => null,
    setModule: () => null,
    updateModules: () => null,
});

export function useAppModuleManager(): AppModuleManagerContext {
    return useContext(AppModuleManagerContext);
}
