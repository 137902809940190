import type {
    CreateSelectorSetupFunction,
    ExtendedSelector,
} from "./interface";

import { DEFAULT_STATUS } from "../helpers";
import type { StatusInfo, StatusState } from "../helpers";

export function getStatus<
    S,
    R,
    Params extends any[] = [],
    Extra extends Record<string, any> = NonNullable<unknown>,
>(
    creator: CreateSelectorSetupFunction<S | undefined, R>,
    mapState: (
        state?: S,
        ...params: [...Params]
    ) => Pick<StatusState, "message" | "status"> | undefined,
    extraData?: (state: S | undefined, ...params: [...Params]) => Extra,
): ExtendedSelector<R, StatusInfo & Extra, Params, "root"> {
    return creator(
        (state, ...params: [...Params]) => {
            const { status, message } = mapState(state, ...params) || {};
            const extra = extraData?.(state, ...params) || ({} as Extra);

            const inputs: [string, any][] = [];

            const keys = Object.getOwnPropertyNames(extra);
            for (const key of keys) {
                inputs.push([key, extra[key as keyof Extra]]);
            }

            return [status, message || null, ...inputs];
        },
        (status, message, ...extra) =>
            ({
                ...Object.fromEntries(extra),
                failure: status === DEFAULT_STATUS.FAILURE,
                loading: status === DEFAULT_STATUS.LOADING,
                message,
                pending: !status || status === DEFAULT_STATUS.PENDING,
                processing: status === DEFAULT_STATUS.PROCESSING,
                success: status === DEFAULT_STATUS.SUCCESS,
            } as StatusInfo & Extra),
    );
}
