import type { TailwindPattern } from "../interface";

const outlinePattern: TailwindPattern = {
    base: /outline/,
    variants: [
        { base: /outline-offset/, arbitrary: "size" },
        /outline(?= |$)|outline-(none|dashed|dotted|double)/,
        {
            base: /outline/,
            sufixes: [/-\d+/],
            arbitrary: "size",
        },
        { base: /outline/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default outlinePattern;

export const outlineSample = [
    ["outline-offset-4", "outline-offset-[3px]"],
    [
        "outline",
        "outline-none",
        "outline-dashed",
        "outline-dotted",
        "outline-double",
    ],
    ["outline-3", "outline-[5px]"],
    [
        "outline-inherit",
        "outline-current",
        "outline-transparent",
        "outline-white",
        "outline-red-300",
        "outline-white/15",
        "outline-red-500/20",
        "outline-[#ffffff]",
        "outline-[#ffffff]/30",
    ],
];
