import type { DataStructure } from "@redux/plugins/data-fetcher/interface";
import type { DraftState } from "@redux/plugins/draft-manager/interface";

import type { TaxModel } from "@services/tax-models/interface";

export type TaxModelDraftData = Partial<
    Pick<TaxModel, "default" | "description" | "name" | "type">
>;

export interface TaxModelData extends TaxModel {
    templatesCount: number;
}

export type State = DataStructure<TaxModelData, null> &
    DraftState<TaxModelDraftData>;

export const moduleId = "taxesModelsModelMain" as const;
