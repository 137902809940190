import { combineReducers } from "redux";
import type { Reducer } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { internalId } from "./interface";
import type { ModuleFetcher } from "./interface";

import { processPersistency } from "../persistency";

import { formatModuleName, splitModuleName } from "../modules/helpers";
import type { ModuleData } from "../modules/interface";

export function modules2Reducer(modules: ModuleData[]): Reducer {
    const newReducer = combineReducers({
        [internalId]: () => modules.map(formatModuleName),
        ...modules.reduce((rel, md) => {
            const name = formatModuleName(md);
            rel[name] = md.persist
                ? persistReducer(
                      processPersistency(md.persist, name),
                      md.reducer,
                  )
                : md.reducer;
            return rel;
        }, {} as Record<string, any>),
    });

    return persistReducer(
        {
            key: "root",
            storage,
            whitelist: [internalId],
            timeout: 1,
        },
        newReducer,
    );
}

export async function fetchModules(
    fetcher: ModuleFetcher,
    names: string[],
): Promise<ModuleData[]> {
    const promises = names.map(async (name) => {
        const { id, hash } = splitModuleName(name);
        return await fetcher(id)
            .then((md) =>
                hash && md?.createModule
                    ? md.createModule({ hash })
                    : md?.default,
            )
            .catch(() => null);
    });
    const responses = await Promise.all(promises);
    return responses.filter((resp): resp is ModuleData => !!resp);
}
