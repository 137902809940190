import type { ErrorTestFunction } from "../interface";

import {
    validateCNPJ,
    validateCPF,
    validateDocument,
} from "@utils/inputs/validators/document";

export type DefaultValidatorsTypes = "cpf" | "cnpj" | "document";

export type ValidatorFunction = (arg: string) => boolean | Promise<boolean>;

export const defaultValidators: Record<
    DefaultValidatorsTypes,
    ValidatorFunction
> = {
    cnpj: validateCNPJ,
    cpf: validateCPF,
    document: validateDocument,
};

export function validate(
    type: string,
    custom?: Record<string, ValidatorFunction>,
): ErrorTestFunction {
    const validator: ValidatorFunction =
        defaultValidators[type as DefaultValidatorsTypes] ??
        custom?.[type] ??
        (() => false);
    return async (field, { value }) =>
        !(await validator(value ?? "")) && `Valor de ${field} inválido`;
}
