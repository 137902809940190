import type { CarrierFilterLoaderParams } from "@redux/filters/carrier";
import type { OrderStatusFilterLoaderParams } from "@redux/filters/order";

import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type { PaginationState } from "@redux/plugins/pagination-manager/interface";

import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";

import type {
    OrderSku,
    OrderItem as _OrderItem,
} from "@old/services/orders/interface";
import type { GetOrdersParams } from "@old/services/orders/getAll";

export type PaginationParams = Partial<Omit<GetOrdersParams, "Page">>;

export type OrderItem = _OrderItem;

export type Ordercomplement = {
    skus: Pick<
        OrderSku,
        | "Comments"
        | "BarCode"
        | "IDSku"
        | "IDSkuCompany"
        | "MainImageURL"
        | "PriceSelling"
        | "PriceSellingTotal"
        | "Quantity"
        | "QuantityNonconformity"
        | "SkuName"
        | "ValueDiscount"
        | "WarehouseName"
    >[];
};

export type FilterLoaderParams = CarrierFilterLoaderParams &
    OrderStatusFilterLoaderParams;

export interface State {
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
    orders: PaginationState<OrderItem, Ordercomplement, PaginationParams>;
}

export const moduleId = "orders" as const;
