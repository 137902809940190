import LockIcon from "./linear";

export default LockIcon;
export { default as LockIcon, iconInfo as LockIconInfo } from "./linear";

export { default as LockBoldIcon, iconInfo as LockBoldIconInfo } from "./bold";
export {
    default as LockBrokenIcon,
    iconInfo as LockBrokenIconInfo,
} from "./broken";
export { default as LockBulkIcon, iconInfo as LockBulkIconInfo } from "./bulk";
export {
    default as LockLinearIcon,
    iconInfo as LockLinearIconInfo,
} from "./linear";
export {
    default as LockOutlineIcon,
    iconInfo as LockOutlineIconInfo,
} from "./outline";
export {
    default as LockTwotoneIcon,
    iconInfo as LockTwotoneIconInfo,
} from "./twotone";
