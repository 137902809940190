import type { FC, ReactNode } from "react";

import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { parseParameters, parsePrivileges } from "./helpers";
import { CURRENT_VERSION, SessionContext } from "./interface";

import { LOCAL_STORAGE_NAMES } from "@constants/localStorage";

import useLocalStorage from "@hooks/storages/useLocalStorage";

import { hasSuccessed } from "@services/helpers";
import postLogin from "@services/login/postLogin";

import getPrivilegesGroups from "@old/services/accounts/getPrivilegesGroups";
import getCompanyBranches from "@old/services/companies/getBranches";

import config from "@old/util/configApp";

interface SessionContextProviderProps {
    children?: ReactNode;
}

const SessionContextProvider: FC<SessionContextProviderProps> = ({
    children,
}) => {
    const { getItem, removeItems, setItem } = useLocalStorage();

    const [data, setData] = useState<SessionContext["data"]>({});
    const dataRef = useRef(data);
    const [hydrated, setHydrated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState("");

    const updateStorage = useCallback(
        (data: SessionContext["data"]) => {
            dataRef.current = data;
            setItem(LOCAL_STORAGE_NAMES.SESSION_DATA, {
                data,
                updated_at: new Date().toISOString(),
                _version: CURRENT_VERSION,
            });
        },
        [setItem],
    );

    const updateToken = useCallback(
        (token: string | null) => {
            if (token) {
                setItem(LOCAL_STORAGE_NAMES.ACCESS_TOKEN, token);
                axios.defaults.headers.common["Authorization"] = token
                    ? "Bearer " + token
                    : "";
                config.ApiToken = token;
            } else {
                removeItems([LOCAL_STORAGE_NAMES.ACCESS_TOKEN]);
                axios.defaults.headers.common["Authorization"] = "";
                config.ApiToken = "";
            }
        },
        [setItem, removeItems],
    );

    useEffect(() => {
        if (hydrated) return;
        (async () => {
            setLoading(true);

            const persistedToken = getItem(LOCAL_STORAGE_NAMES.ACCESS_TOKEN);
            const persistedData = getItem(LOCAL_STORAGE_NAMES.SESSION_DATA);

            if (
                !persistedToken ||
                !persistedData ||
                persistedData._version !== CURRENT_VERSION
            ) {
                updateToken(null);
                updateStorage({});
            } else {
                updateToken(persistedToken);

                dataRef.current = persistedData.data;
                setData(persistedData.data);

                if (persistedData.data) {
                    const updatedAt = new Date(persistedData.updated_at);
                    const now = Date.now();
                    if (now - updatedAt.getTime() > 1_800_000) {
                        const companyBranches = await getCompanyBranches();
                        const privileges = parsePrivileges(
                            await getPrivilegesGroups(),
                        );
                        updateStorage({
                            ...dataRef.current,
                            accountId: companyBranches?.IDCompany || 0,
                            accountName: companyBranches?.AccountName || "",
                            branches: companyBranches?.Branch?.map(
                                ({ AccountName, IDCompany }) => ({
                                    value: IDCompany,
                                    label: AccountName,
                                }),
                            ),
                            privileges,
                        });
                    }
                }
            }

            setHydrated(true);
            setLoading(false);
        })();
    }, [hydrated, loading, getItem, updateToken, updateStorage]);

    const login = useCallback<SessionContext["login"]>(
        async (payload, callback) => {
            setProcessing(true);
            setError("");

            const response = await postLogin(payload);

            if (hasSuccessed(response)) {
                const { body, token } = response.data;
                updateToken(token);

                const companyBranches = await getCompanyBranches();
                const privileges = parsePrivileges(await getPrivilegesGroups());
                const parameters = parseParameters(body.parameters);

                const {
                    companyAdminMaster,
                    companyCpfCnpj,
                    companyLogoUrl,
                    companyNameCorporateName,
                    externalDashboard,
                    userName,
                    email,
                } = body;

                updateStorage({
                    accountId: companyBranches?.IDCompany || 0,
                    accountName: companyBranches?.AccountName || "",
                    branches: companyBranches?.Branch?.map(
                        ({ AccountName, IDCompany }) => ({
                            value: IDCompany,
                            label: AccountName,
                        }),
                    ),
                    company: {
                        corporateName: companyNameCorporateName,
                        documentType:
                            companyCpfCnpj.length > 11 ? "cnpj" : "cpf",
                        logoUrl: companyLogoUrl,
                    },
                    externalDashboard: !!externalDashboard,
                    isAdmin: !!companyAdminMaster,
                    parameters,
                    privileges,
                    userEmail: email,
                    userName: userName,
                });
                setData(dataRef.current);

                if (localStorage.getItem("THEME_COLOR") === null) {
                    localStorage.setItem(
                        "THEME_COLOR",
                        body.theme || "light_blue",
                    );
                    localStorage.setItem("THEME", "THEME_TYPE_SEMI_DARK");
                    window.location.reload();
                }

                callback?.();
            } else {
                setError("Usuário ou senha inválido.");
            }

            setProcessing(false);
        },
        [updateToken, updateStorage],
    );

    const logout = useCallback<SessionContext["logout"]>(
        (callback) => {
            updateToken(null);
            updateStorage({});
            dataRef.current = {};
            setData({});
            setError("");
            callback?.();
        },
        [updateToken, updateStorage],
    );

    const state = useMemo<SessionContext>(
        () => ({
            data,
            error,
            hydrated,
            loading,
            logged: typeof data.userName !== "undefined",
            login,
            logout,
            processing,
        }),
        [data, error, hydrated, loading, login, logout, processing],
    );

    return (
        <SessionContext.Provider value={state}>
            {children}
        </SessionContext.Provider>
    );
};

export default SessionContextProvider;

export * from "./utils";
