import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";

import type { DynamicMiddleware } from "@redux/utils/middlewares/interface";

export const blacklist = [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE];

const garbageCollectorMiddleware: DynamicMiddleware<any> =
    ({ modules, removeModules, updateModule }) =>
    (next) =>
    (action) => {
        if (!blacklist.includes(action.type)) {
            const [name] = action.type.split("/");
            const module = modules.find((md) => md.name == name);
            if (module) {
                updateModule(module, { updatedAt: Date.now() });
            }

            const now = Date.now();
            const garbage = [];
            for (const md of modules) {
                if (md.updatedAt === null) continue;
                const dt = now - md.updatedAt;
                if (
                    (md.fixed && dt > 259_200_000) ||
                    (!md.fixed && dt > 86_400_000)
                ) {
                    garbage.push(md);
                }
            }
            if (garbage.length) removeModules(garbage, true);
        }
        return next(action);
    };

export default garbageCollectorMiddleware;
