import type { TailwindPattern } from "../interface";

const boxPattern: TailwindPattern = {
    base: /box/,
    variants: [/box-decoration/, /box/],
};

export default boxPattern;

export const boxSample = [
    ["box-decoration-clone", "box-decoration-slice"],
    ["box-border", "box-content"],
];
