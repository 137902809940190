export default function createUUID(): string {
    if (typeof window === "undefined" || typeof crypto === "undefined")
        return "";
    if (typeof crypto.randomUUID === "function") {
        return crypto.randomUUID();
    }
    return Buffer.from(crypto.getRandomValues(new Uint32Array(4)).buffer)
        .toString("hex")
        .replace(
            /\B((?<=\w{8})(?=\w{24})|(?<=\w{12})(?=\w{20})|(?<=\w{16})(?=\w{16})|(?<=\w{20})(?=\w{12}))/g,
            "-",
        );
}
