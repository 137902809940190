import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronLeftBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 8.1897 7.9399 L 5.5697 10.5599 C 4.7997 11.3299 4.7997 12.5899 5.5697 13.3599 L 12.0897 19.8799"
                stroke="currentColor"
            />
            <path d="M 12.0908 4.04 L 11.0508 5.08" stroke="currentColor" />

            <path
                d="M 14.1897 7.9399 L 11.5697 10.5599 C 10.7997 11.3299 10.7997 12.5899 11.5697 13.3599 L 18.0897 19.8799"
                stroke="currentColor"
            />
            <path d="M 18.0908 4.04 L 17.0508 5.08" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
