import type { DEFAULT_STATUS } from "@redux/utils/helpers";
import type { PersistentState } from "@redux/utils/interface";

import type {
    AccountPayload,
    AccountPayloadPayment,
    AccountPaymentModels,
} from "@services/accounts/payloads/interface";
import type { PaymentMethod } from "@services/miscellaneous/getPaymentMethods";
import type { ConsultantData } from "@services/supabase/consultants";

export enum FlowTypes {
    ASAAS,
    HOTMART,
}

export enum FlowSteps {
    ASAAS_ACCOUNT_INFO,
    ASAAS_PAYMENT,
    HOTMART_DOCUMENT_VALIDATION,
    HOTMART_ACCOUNT_INFO,
    HOTMART_PAYMENT,
    CONFIRMATION,
}

export enum DocumentTypes {
    CNPJ = "cnpj",
    CPF = "cpf",
}

export interface SignUpData {
    accountName?: string;
    address?: {
        city: string;
        complement?: string;
        neighborhood?: string;
        number: number;
        province?: string;
        street: string;
        zip: string;
    };
    birthdate?: string;
    creaditCard?: {
        expirationDate: [string, string];
        installments?: number;
        name: string;
        number: string;
        securityCode: string;
    };
    document?: string;
    documentType?: DocumentTypes;
    email?: string;
    financialModel?: AccountPaymentModels;
    phone?: string;
    paymentMethod?: string;
    userName?: string;
}

export interface PayloadInfo
    extends Partial<Pick<AccountPayload, "_id" | "accountName" | "gateway">> {
    consultantId?: string;
    finished: boolean;
    payment?: Pick<AccountPayloadPayment, "qrCode" | "value">;
    updatedAt: string;
}

export type State = PersistentState<{
    consultant: ConsultantData | null;
    data: SignUpData;
    flow: {
        step: FlowSteps | null;
        type: FlowTypes | null;
    };
    payloadInfo: PayloadInfo;
    paymentMethods: {
        data: PaymentMethod[];
        status: DEFAULT_STATUS;
    };
    message: string | null;
    status: DEFAULT_STATUS;
}>;

export const moduleId = "loginSignup" as const;
