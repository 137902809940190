import TrashIcon from "./linear";

export default TrashIcon;
export { default as TrashIcon, iconInfo as TrashIconInfo } from "./linear";

export {
    default as TrashBoldIcon,
    iconInfo as TrashBoldIconInfo,
} from "./bold";
export {
    default as TrashBrokenIcon,
    iconInfo as TrashBrokenIconInfo,
} from "./broken";
export {
    default as TrashBulkIcon,
    iconInfo as TrashBulkIconInfo,
} from "./bulk";
export {
    default as TrashLinearIcon,
    iconInfo as TrashLinearIconInf,
} from "./linear";
export {
    default as TrashOutlineIcon,
    iconInfo as TrashOutlineIconInfo,
} from "./outline";
export {
    default as TrashTwotoneIcon,
    iconInfo as TrashTwotoneIconInfo,
} from "./twotone";
