export default function FormatCPFCNPJ(value) {
    if (value !== null && value !== "" && value !== undefined) {
        const cnpjCpf =
            typeof value === "number" ? value : value?.replace(/\D/g, "");

        if (cnpjCpf.length === 11) {
            return cnpjCpf?.replace(
                /(\d{3})(\d{3})(\d{3})(\d{2})/g,
                "$1.$2.$3-$4",
            );
        } else if (cnpjCpf.length === 14) {
            return cnpjCpf?.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5",
            );
        } else {
            return value;
        }
    } else {
        return value;
    }
}
