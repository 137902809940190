import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ProfileBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                fill="currentColor"
            />
            <path
                d="M 17.0809 14.1606 C 14.2909 12.3006 9.7409 12.3006 6.9309 14.1606 C 5.6609 15.0006 4.9609 16.1506 4.9609 17.3806 C 4.9609 18.6106 5.6609 19.7506 6.9209 20.5906 C 8.3209 21.5306 10.1609 22.0006 12.0009 22.0006 C 13.8409 22.0006 15.6809 21.5306 17.0809 20.5906 C 18.3409 19.7406 19.0409 18.6006 19.0409 17.3606 C 19.0309 16.1406 18.3409 14.9906 17.0809 14.1606 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
