import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronLeftBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 10.9783 5.3188 L 7.7683 8.5288 L 5.7983 10.4888 C 4.9683 11.3188 4.9683 12.6688 5.7983 13.4988 L 10.9783 18.6788 C 11.6583 19.3588 12.8183 18.8688 12.8183 17.9188 V 15.2788 L 11.7983 14.2588 C 10.2983 12.7588 10.2983 11.2288 11.7983 9.7288 L 12.8183 8.7088 V 6.0788 C 12.8183 5.1188 11.6583 4.6388 10.9783 5.3188 Z"
                fill="currentColor"
            />
            <path
                d="M 16.9783 5.3188 L 13.7683 8.5288 L 11.7983 10.4888 C 10.9683 11.3188 10.9683 12.6688 11.7983 13.4988 L 16.9783 18.6788 C 17.6583 19.3588 18.8183 18.8688 18.8183 17.9188 V 12.3088 V 6.0788 C 18.8183 5.1188 17.6583 4.6388 16.9783 5.3188 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
