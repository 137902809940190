import type { TailwindPattern } from "../interface";

const positionPattern: TailwindPattern = {
    base: /static|fixed|absolute|relative|sticky|inset|top|bottom|left|right|start|end|z-/,
    variants: [
        /static|fixed|absolute|relative|sticky/,
        { base: /top/, arbitrary: "position" },
        { base: /bottom/, arbitrary: "position" },
        { base: /left/, arbitrary: "position" },
        { base: /right/, arbitrary: "position" },
        { base: /start/, arbitrary: "position" },
        { base: /end/, arbitrary: "position" },
        { base: /inset-x/, arbitrary: "size" },
        { base: /inset-y/, arbitrary: "size" },
        { base: /inset/, arbitrary: "size" },
        { base: /z-/, arbitrary: "number" },
    ],
    arbitrary: "any",
};

export default positionPattern;

export const positionSample = [
    ["static", "fixed", "absolute", "relative", "sticky"],
    [
        "top-px",
        "top-auto",
        "top-full",
        "top-16",
        "top-1/2",
        "-top-14",
        "top-[11px]",
        "-top-[14px]",
    ],
    [
        "bottom-px",
        "bottom-auto",
        "bottom-full",
        "bottom-16",
        "bottom-1/2",
        "-bottom-14",
        "bottom-[11px]",
        "-bottom-[14px]",
    ],
    [
        "left-px",
        "left-auto",
        "left-full",
        "left-16",
        "left-1/2",
        "-left-14",
        "left-[11px]",
        "-left-[14px]",
    ],
    [
        "right-px",
        "right-auto",
        "right-full",
        "right-16",
        "right-1/2",
        "-right-14",
        "right-[11px]",
        "-right-[14px]",
    ],
    [
        "start-px",
        "start-auto",
        "start-full",
        "start-16",
        "start-1/2",
        "-start-14",
        "start-[11px]",
        "-start-[14px]",
    ],
    [
        "end-px",
        "end-auto",
        "end-full",
        "end-16",
        "end-1/2",
        "-end-14",
        "end-[11px]",
        "-end-[14px]",
    ],
    [
        "inset-x-px",
        "inset-x-auto",
        "inset-x-full",
        "inset-x-16",
        "inset-x-1/2",
        "-inset-x-14",
        "inset-x-[11px]",
        "-inset-x-[14px]",
    ],
    [
        "inset-y-px",
        "inset-y-auto",
        "inset-y-full",
        "inset-y-16",
        "inset-y-1/2",
        "-inset-y-14",
        "inset-y-[11px]",
        "-inset-y-[14px]",
    ],
    [
        "inset-px",
        "inset-auto",
        "inset-full",
        "inset-16",
        "inset-1/2",
        "-inset-14",
        "inset-[11px]",
        "-inset-[14px]",
    ],
    ["z-1", "-z-1", "z-[30]", "-z-[20]"],
];
