export function getPathEnd(path: string): string {
    return path.split("/").slice(-1)[0] ?? "";
}

export function getPathDiff(ref: string, target: string): string {
    return target.replace(ref, "");
}

export function extractParams(path: string): string[] {
    return path.match(/(?<=:)\w+/g) || [];
}

export function applyParams(path: string, params: Record<string, any>): string {
    let parsed = path;
    for (const key in params) {
        if (key === "*") continue;
        parsed = parsed.replace(`:${key}`, `${params[key]}`);
    }
    return parsed;
}
