import GlobalIcon from "./linear";

export default GlobalIcon;
export { default as GlobalIcon, iconInfo as GlobalIconInfo } from "./linear";

export {
    default as GlobalBoldIcon,
    iconInfo as GlobalBoldIconInfo,
} from "./bold";
export {
    default as GlobalBrokenIcon,
    iconInfo as GlobalBrokenIconInfo,
} from "./broken";
export {
    default as GlobalBulkIcon,
    iconInfo as GlobalBulkIconInfo,
} from "./bulk";
export {
    default as GlobalLinearIcon,
    iconInfo as GlobalLinearIconInfo,
} from "./linear";
export {
    default as GlobalOutlineIcon,
    iconInfo as GlobalOutlineIconInfo,
} from "./outline";
export {
    default as GlobalTwotoneIcon,
    iconInfo as GlobalTwotoneIconInfo,
} from "./twotone";
