import MonitorIcon from "./linear";

export default MonitorIcon;
export { default as MonitorIcon, iconInfo as MonitorIconInfo } from "./linear";

export {
    default as MonitorBoldIcon,
    iconInfo as MonitorBoldIconInfo,
} from "./bold";
export {
    default as MonitorBrokenIcon,
    iconInfo as MonitorBrokenIconInfo,
} from "./broken";
export {
    default as MonitorBulkIcon,
    iconInfo as MonitorBulkIconInfo,
} from "./bulk";
export {
    default as MonitorLinearIcon,
    iconInfo as MonitorLinearIconInfo,
} from "./linear";
export {
    default as MonitorOutlineIcon,
    iconInfo as MonitorOutlineIconInfo,
} from "./outline";
export {
    default as MonitorTwotoneIcon,
    iconInfo as MonitorTwotoneIconInfo,
} from "./twotone";
