import { useCallback } from "react";
import type { MutableRefObject } from "react";
import { useStore } from "react-redux";
import type { Action } from "redux";
import type { ThunkAction, ThunkDispatch } from "redux-thunk";

import type { DynamicStore } from "../store/interface";

export default function useAsyncUpdate(
    abort?: MutableRefObject<(() => void) | null>,
) {
    const store = useStore() as DynamicStore<
        Record<string, any>,
        Action,
        { dispatch: ThunkDispatch<Record<string, any>, any, Action> }
    >;

    const asyncUpdate = useCallback(
        async <R>(
            action: ThunkAction<any, Record<string, any>, any, Action>,
            selector: (root: Record<string, any>) => R,
        ): Promise<R> => {
            if (abort?.current) abort.current();
            const promise = store.dispatch(action);
            if (abort) abort.current = promise.abort;
            await promise;
            return selector(store.getState());
        },
        [abort, store],
    );

    return asyncUpdate;
}
