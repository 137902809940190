import axios from "axios";
import config from "@old/util/configApp";
import { Logout } from "@old/util/Functions";

import { translateErrorMessage } from "@utils/errorTranslations/translateErrorMessage";
import { apiErrorTranslations } from "@utils/errorTranslations/data/apiErrorTranslations";

export default async function ControllerApi(
    method,
    URLSearchParams,
    Data,
    AccountName,
    Index,
) {
    var SANDBOX,
        RESPONSE,
        VALIDATION = true;

    const token = config.ApiToken;

    const axiosConfig = {
        headers: {
            "Content-Type":
                Data instanceof FormData
                    ? "multipart/form-data"
                    : config.ApiContentType,
            Authorization: `Bearer ${token}`,
        },
    };

    switch (method) {
        case "POST":
            if (Data === "") {
                RESPONSE = {
                    status: "warning",
                    message: "Dados em branco ou indefinido",
                    response: "",
                    index: Index,
                    errorCode: "",
                    errorHelp: "",
                };

                VALIDATION = false;
            }

            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .post(SANDBOX, Data, axiosConfig)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "POST_DATA":
            const axiosConfigData = {
                headers: {
                    "content-type": "multipart/form-data",
                },
            };

            if (Data === "") {
                RESPONSE = {
                    status: "warning",
                    message: "Dados em branco ou indefinido",
                    response: "",
                    index: Index,
                    errorCode: "",
                    errorHelp: "",
                };

                VALIDATION = false;
            }

            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .post(SANDBOX, Data, axiosConfigData)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message: result?.includes("instance failed")
                                        ? "Validar informações em branco."
                                        : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "POST_URL":
            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .post(SANDBOX)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : translateErrorMessage(
                                                  result,
                                                  apiErrorTranslations,
                                              ),
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "PUT":
            if (Data === "") {
                RESPONSE = {
                    status: "warning",
                    message: "Dados em branco ou indefinido",
                    response: "",
                    index: Index,
                    errorCode: "",
                    errorHelp: "",
                };

                VALIDATION = false;
            }

            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .put(SANDBOX, Data, axiosConfig)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "DELETE":
            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .delete(SANDBOX, axiosConfig)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }

            break;

        case "DELETE_DATA":
            if (VALIDATION === true) {
                const axiosConfigDeleteData = JSON.parse(
                    JSON.stringify({
                        headers: {
                            "Content-Type": config.ApiContentType,
                            Authorization: `Bearer ${token}`,
                        },
                        data: Data,
                    }),
                );

                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .delete(SANDBOX, axiosConfigDeleteData)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "PHP":
            if (AccountName) {
                SANDBOX =
                    config.PhpUrl +
                    "/" +
                    URLSearchParams +
                    "&an=" +
                    AccountName +
                    "&token=" +
                    token;
            } else {
                SANDBOX =
                    config.PhpUrl +
                    "/" +
                    URLSearchParams +
                    "&an=" +
                    config.Subdomain +
                    "&token=" +
                    token;
            }

            await axios
                .get(SANDBOX, axiosConfig)
                .then((res) => {
                    if (res?.data?.errorType) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação",
                            response: "",
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        RESPONSE = {
                            status: "success",
                            message: "Ação realizada com sucesso!",
                            response: res.data,
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    }
                })
                .catch((err) => {
                    var response =
                        err.response === undefined ? "" : err.response.data;

                    if (err.response.status === 403) {
                        Logout();
                    } else if (err.response.status === 504) {
                        RESPONSE = {
                            status: "warning",
                            message:
                                "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else if (
                        err.response.status >= 500 &&
                        err.response.status <= 599
                    ) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação - status 500",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        if (typeof response === "object") {
                            var errorMessage = response.errorMessage;

                            var result = errorMessage
                                ?.replaceAll("[BadRequest] - ", "")
                                .replaceAll("[NotFound] - ", "")
                                .replaceAll("[", "")
                                .replaceAll("]", "")
                                .replaceAll("[BadRequest]- ", "")
                                .replaceAll("[BadRequest] -", "");

                            RESPONSE = {
                                status: "warning",
                                message:
                                    result?.includes("instance failed") ||
                                    result?.includes(
                                        "Endpoint request timed out",
                                    )
                                        ? "Validar informações em branco."
                                        : result,
                                response: "",
                                index: Index,
                                errorCode: response?.errorCode || "",
                                errorHelp: response?.errorHelp || "",
                            };
                        } else {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    }
                });
            break;

        case "PHP_POST":
            if (AccountName) {
                SANDBOX =
                    config.PhpUrl +
                    URLSearchParams +
                    "&an=" +
                    AccountName +
                    "&token=" +
                    token;
            } else {
                SANDBOX =
                    config.PhpUrl +
                    URLSearchParams +
                    "&an=" +
                    config.Subdomain +
                    "&token=" +
                    token;
            }

            await axios
                .post(SANDBOX, axiosConfig)
                .then((res) => {
                    if (res?.data?.errorType) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        RESPONSE = {
                            status: "success",
                            message: "Ação realizada com sucesso!",
                            response: res.data,
                            errorCode: "",
                            errorHelp: "",
                        };
                    }
                })
                .catch((err) => {
                    var response =
                        err.response === undefined ? "" : err.response.data;

                    if (err.response.status === 403) {
                        Logout();
                    } else if (err.response.status === 504) {
                        RESPONSE = {
                            status: "warning",
                            message:
                                "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else if (
                        err.response.status >= 500 &&
                        err.response.status <= 599
                    ) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação - status 500",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        if (typeof response === "object") {
                            var errorMessage = response.errorMessage;

                            var result = errorMessage
                                ?.replaceAll("[BadRequest] - ", "")
                                .replaceAll("[NotFound] - ", "")
                                .replaceAll("[", "")
                                .replaceAll("]", "")
                                .replaceAll("[BadRequest]- ", "")
                                .replaceAll("[BadRequest] -", "");

                            RESPONSE = {
                                status: "warning",
                                message:
                                    result?.includes("instance failed") ||
                                    result?.includes(
                                        "Endpoint request timed out",
                                    )
                                        ? "Validar informações em branco."
                                        : result,
                                response: "",
                                index: Index,
                                errorCode: response?.errorCode || "",
                                errorHelp: response?.errorHelp || "",
                            };
                        } else {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    }
                });
            break;

        case "PHP_URL":
            SANDBOX = URLSearchParams;

            await axios
                .get(SANDBOX, axiosConfig)
                .then((res) => {
                    var response = res.data;
                    response = response.replace(/(\r\n|\n|\r)/gm, "");

                    if (res?.data?.errorType) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        RESPONSE = {
                            status: "success",
                            message: "Ação realizada com sucesso!",
                            response: response,
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    }
                })
                .catch((err) => {
                    var response =
                        err.response === undefined ? "" : err.response.data;

                    if (err.response.status === 403) {
                        Logout();
                    } else if (err.response.status === 504) {
                        RESPONSE = {
                            status: "warning",
                            message:
                                "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else if (
                        err.response.status >= 500 &&
                        err.response.status <= 599
                    ) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação - status 500",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        if (typeof response === "object") {
                            var errorMessage = response.errorMessage;

                            var result = errorMessage
                                ?.replaceAll("[BadRequest] - ", "")
                                .replaceAll("[NotFound] - ", "")
                                .replaceAll("[", "")
                                .replaceAll("]", "")
                                .replaceAll("[BadRequest]- ", "")
                                .replaceAll("[BadRequest] -", "");

                            RESPONSE = {
                                status: "warning",
                                message:
                                    result?.includes("instance failed") ||
                                    result?.includes(
                                        "Endpoint request timed out",
                                    )
                                        ? "Validar informações em branco."
                                        : result,
                                response: "",
                                index: Index,
                                errorCode: response?.errorCode || "",
                                errorHelp: response?.errorHelp || "",
                            };
                        } else {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    }
                });
            break;

        case "PHP_OFFER":
            if (AccountName) {
                SANDBOX =
                    config.PhpUrl +
                    URLSearchParams +
                    "&an=" +
                    AccountName +
                    "&token=" +
                    token;
            } else {
                SANDBOX =
                    config.PhpUrl +
                    URLSearchParams +
                    "&an=" +
                    config.Subdomain +
                    "&token=" +
                    token;
            }

            await axios
                .get(SANDBOX, axiosConfig)
                .then((res) => {
                    if (res?.data?.errorType) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        RESPONSE = {
                            status: "success",
                            message: "Ação realizada com sucesso!",
                            response: res.data,
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    }
                })
                .catch((err) => {
                    var response =
                        err.response === undefined ? "" : err.response.data;

                    if (err.response.status === 403) {
                        Logout();
                    } else if (err.response.status === 504) {
                        RESPONSE = {
                            status: "warning",
                            message:
                                "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else if (
                        err.response.status >= 500 &&
                        err.response.status <= 599
                    ) {
                        RESPONSE = {
                            status: "warning",
                            message: "Erro ao realizar ação - status 500",
                            response: "",
                            index: Index,
                            errorCode: "",
                            errorHelp: "",
                        };
                    } else {
                        if (typeof response === "object") {
                            var errorMessage = response.errorMessage;

                            var result = errorMessage
                                ?.replaceAll("[BadRequest] - ", "")
                                .replaceAll("[NotFound] - ", "")
                                .replaceAll("[", "")
                                .replaceAll("]", "")
                                .replaceAll("[BadRequest]- ", "")
                                .replaceAll("[BadRequest] -", "");

                            RESPONSE = {
                                status: "warning",
                                message:
                                    result?.includes("instance failed") ||
                                    result?.includes(
                                        "Endpoint request timed out",
                                    )
                                        ? "Validar informações em branco."
                                        : result,
                                response: "",
                                errorCode: response?.errorCode || "",
                                errorHelp: response?.errorHelp || "",
                            };
                        } else {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    }
                });
            break;

        case "GET_URL":
            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                await axios
                    .get(SANDBOX, axiosConfig)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response =
                            err.response === undefined ? "" : err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "GET_BY_URL":
            if (VALIDATION === true) {
                SANDBOX = URLSearchParams;

                await axios
                    .get(SANDBOX)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response =
                            err.response === undefined ? "" : err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        case "GET_DATA":
            if (Data === "") {
                RESPONSE = {
                    status: "warning",
                    message: "Dados em branco ou indefinido",
                    response: "",
                    index: Index,
                    errorCode: "",
                    errorHelp: "",
                };

                VALIDATION = false;
            }

            if (VALIDATION === true) {
                if (AccountName) {
                    SANDBOX = `https://${AccountName}.${
                        config.Api + URLSearchParams
                    }`;
                } else {
                    SANDBOX = config.ApiUrl + URLSearchParams;
                }

                const axiosConfigGetData = {
                    headers: {
                        "Content-Type": config.ApiContentType,
                        Authorization: `Bearer ${token}`,
                    },
                    data: Data,
                };

                await axios
                    .get(SANDBOX, Data, axiosConfigGetData)
                    .then((res) => {
                        if (res?.data?.errorType) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            RESPONSE = {
                                status: "success",
                                message: "Ação realizada com sucesso!",
                                response: res.data,
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        }
                    })
                    .catch((err) => {
                        var response = err.response.data;

                        if (err.response.status === 403) {
                            Logout();
                        } else if (err.response.status === 504) {
                            RESPONSE = {
                                status: "warning",
                                message:
                                    "Por Favor, Aguarde alguns minutos antes de tentar novamente",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else if (
                            err.response.status >= 500 &&
                            err.response.status <= 599
                        ) {
                            RESPONSE = {
                                status: "warning",
                                message: "Erro ao realizar ação - status 500",
                                response: "",
                                index: Index,
                                errorCode: "",
                                errorHelp: "",
                            };
                        } else {
                            if (typeof response === "object") {
                                var errorMessage = response.errorMessage;

                                var result = errorMessage
                                    ?.replaceAll("[BadRequest] - ", "")
                                    .replaceAll("[NotFound] - ", "")
                                    .replaceAll("[", "")
                                    .replaceAll("]", "")
                                    .replaceAll("[BadRequest]- ", "")
                                    .replaceAll("[BadRequest] -", "");

                                RESPONSE = {
                                    status: "warning",
                                    message:
                                        result?.includes("instance failed") ||
                                        result?.includes(
                                            "Endpoint request timed out",
                                        )
                                            ? "Validar informações em branco."
                                            : result,
                                    response: "",
                                    index: Index,
                                    errorCode: response?.errorCode || "",
                                    errorHelp: response?.errorHelp || "",
                                };
                            } else {
                                RESPONSE = {
                                    status: "warning",
                                    message: "Erro ao realizar ação",
                                    response: "",
                                    index: Index,
                                    errorCode: "",
                                    errorHelp: "",
                                };
                            }
                        }
                    });
            }
            break;

        default:
            RESPONSE = {
                status: "warning",
                message: 'Metodo : "' + method + '" Nao existe',
                response: "",
                errorCode: "",
                errorHelp: "",
            };
    }

    return RESPONSE;
}
