import type { TailwindPattern } from "../interface";

const svgPattern: TailwindPattern = {
    base: /fill|stroke/,
    variants: [
        { base: /fill/, arbitrary: "any" },
        { base: /stroke/, sufixes: [/-\d+/], arbitrary: "size" },
        { base: /stroke/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default svgPattern;

export const svgSample = [
    [
        "fill-none",
        "fill-inherit",
        "fill-current",
        "fill-transparent",
        "fill-white",
        "fill-red-500",
        "fill-[#ffffff]",
    ],
    ["stroke-0", "stroke-[2px]"],
    [
        "stroke-none",
        "stroke-inherit",
        "stroke-current",
        "stroke-transparent",
        "stroke-white",
        "stroke-red-500",
        "stroke-[#ffffff]",
    ],
];
