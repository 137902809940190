import type { TailwindPattern } from "./interface";

export function applyPattern(
    classes: string,
    pattern: RegExp | TailwindPattern,
    modifier = "",
): string[][] {
    const base = "base" in pattern ? pattern.base : pattern;
    const sufixes = "sufixes" in pattern && pattern.sufixes;
    const variants = "variants" in pattern && pattern.variants;
    const arbitrary = "arbitrary" in pattern && pattern.arbitrary;

    const parsedModifier = modifier.replace(/([[\](){}])/g, "\\$1");

    const patterns: string[] = [];

    if (sufixes) {
        for (const sufix of sufixes) {
            patterns.push(`(${base.source}${sufix.source}(?!-?\\[))`);
        }
    } else {
        patterns.push(`(${base.source}(?!-?\\[))`);
    }

    if (arbitrary) {
        let arbitraryPattern = "";
        switch (arbitrary) {
            case "angle":
                arbitraryPattern =
                    "-?\\[(_?-?[0-9]+\\.?[0-9]*(deg|rad|grad|turn)){1,}\\]";
                break;
            case "image":
                arbitraryPattern = "-?\\[url\\(.*?\\)\\]";
                break;
            case "number":
                arbitraryPattern =
                    "-?\\[\\w*:?(_?(calc\\()?-?\\+?[0-9]+(\\.[0-9]+|\\/[0-9]+)?%?\\)?){1,}\\]";
                break;
            case "position":
                arbitraryPattern =
                    "-?\\[(_?((-?[0-9]+\\.?[0-9]*(px|em|rem|vw|vh|fr|%)?)|center|top|bottom|left|right)){1,}\\]";
                break;
            case "shadow":
                arbitraryPattern =
                    "-?\\[(-?[0-9]+\\.?[0-9]*(px|em|rem|vw|vh|fr|%)?_){4}(#[a-fA-F0-9]{3,8}|rgba?\\(([0-9]?[0-9]?[0-9]?,?){3}([0-9]\\.?[0-9]*)?\\))\\]";
                break;
            case "size":
                arbitraryPattern =
                    "-?\\[(length:)?(_?(calc\\()?-?\\+?[0-9]+\\.?[0-9]*(px|em|rem|vw|vh|fr|%)?\\)?){1,}\\]";
                break;
            case "time":
                arbitraryPattern = "-?\\[(_?[0-9]+\\.?[0-9]*m?s){1,}\\]";
                break;
            case "any":
                arbitraryPattern = "-?\\[[^\\s]+\\]";
                break;
            default:
                break;
        }

        patterns.push(`(${base.source}${arbitraryPattern})`);
    }

    const regex = new RegExp(
        `(?<= ${parsedModifier}|^${parsedModifier})(-?${
            patterns.length > 1 ? `(${patterns.join("|")})` : patterns.join("|")
        }[^\\s]*)(?= |$)`,
        "g",
    );

    const matches = classes.match(regex) || [];

    if (!matches.length) return [];

    const result: string[][] = [];

    if (variants) {
        let remain = matches.join(" ");
        for (const vrt of variants) {
            const vrtMatches = applyPattern(remain, vrt);
            for (const match of vrtMatches) {
                result.push(match);
                match.forEach((mt) => {
                    remain = remain.replace(mt, "");
                });
            }
        }
    } else {
        result.push(matches);
    }

    return result.filter((mt) => mt.length);
}

export function parseTemplateVariable(value?: any): string {
    if (Array.isArray(value)) {
        return value
            .map(parseTemplateVariable)
            .filter((el) => !!el)
            .join(" ");
    } else if (typeof value === "string") {
        return value;
    }
    return "";
}
