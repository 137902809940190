import PrinterIcon from "./linear";

export default PrinterIcon;
export { default as PrinterIcon, iconInfo as PrinterIconInfo } from "./linear";

export {
    default as PrinterBoldIcon,
    iconInfo as PrinterBoldIconInfo,
} from "./bold";
export {
    default as PrinterBrokenIcon,
    iconInfo as PrinterBrokenIconInfo,
} from "./broken";
export {
    default as PrinterBulkIcon,
    iconInfo as PrinterBulkIconInfo,
} from "./bulk";
export {
    default as PrinterLinearIcon,
    iconInfo as PrinterLinearIconInfo,
} from "./linear";
export {
    default as PrinterOutlineIcon,
    iconInfo as PrinterOutlineIconInfo,
} from "./outline";
export {
    default as PrinterTwotoneIcon,
    iconInfo as PrinterTwotoneIconInfo,
} from "./twotone";
