import type { FC } from "react";

import { useCallback, useContext, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import type { ThunkDispatch } from "redux-thunk";

import { NotificationContainer } from "react-notifications";

import MainPageNavbar from "./components/Navbar";
import MainPageSideBar from "./components/Sidebar";
import TopBar from "./components/TopBar";

import Tabs, { TabList, TabPanels } from "@components/@basic/Tabs";
import type { SortingInfo, TabInfo } from "@components/@basic/Tabs/interface";
import { useToast } from "@components/@basic/Toast";

import { LOCAL_STORAGE_NAMES } from "@constants/localStorage";

import { useOnboarding } from "@contexts/onboarding";

import useLocalStorage from "@hooks/storages/useLocalStorage";

import Modules from "@modules";
import { moduleRelation } from "@modules/relations";
import { useAppModuleManager } from "@modules/utils";

import { updateWindowWidth } from "@old/appRedux/actions";

import { MainContext } from "@old/contexts/MainContext";

import GetURLSearchParams from "@old/controllers/get/GetRouteApi";

const MainPage: FC = () => {
    const { AppendPrependTabs, OnChangeTabActive } = useContext(MainContext);
    const updateTabs = useRef(AppendPrependTabs).current;

    const { updateChecks } = useOnboarding();
    const {
        hydrated: hydratedModules,
        modules,
        currentModule,
        removeModules,
        setModule,
        updateModules,
    } = useAppModuleManager();

    const { getItem, setItem } = useLocalStorage();

    const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
    const toast = useToast();

    useEffect(() => {
        const handleResize = () => {
            dispatch(updateWindowWidth(window.innerWidth));
        };
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [dispatch]);

    useEffect(() => {
        (async () => {
            const response = await GetURLSearchParams(
                "/app?page=PageNotification",
            );
            if (response[0]?.message) {
                toast.dispatch({
                    status: "error",
                    description: response[0]?.message,
                });
            }
        })();
    }, [toast]);

    useEffect(() => {
        if (!getItem(LOCAL_STORAGE_NAMES.NOTIFICATION)) {
            setItem(LOCAL_STORAGE_NAMES.NOTIFICATION, "1");
        }
    }, [getItem, setItem]);

    const parseTabs = useCallback((): TabInfo[] => {
        if (!hydratedModules) return [];
        return modules.map<TabInfo>(
            ({ key, oldKey, tag, title, fixed, params, search }) => ({
                key,
                label: typeof title === "function" ? title(params) : title,
                slug: key,
                closable: !fixed,
                draggable: !fixed,
                persist: true,
                lazy: true,
                Component: () => (
                    <Modules
                        tag={tag as keyof typeof moduleRelation}
                        mainKey={key}
                        oldKey={oldKey}
                        URLSearchParams={search || ""}
                    />
                ),
            }),
        );
    }, [hydratedModules, modules]);

    const handleChangeTab = (key: string) => {
        setModule({ key });
        updateChecks();
    };
    const handleCloseTab = useCallback(
        (key: string) => {
            removeModules([{ key }]);
            const md = modules.find((md) => md.key === key);
            if (md?.oldKey) updateTabs(md.oldKey, "prepend");
        },
        [modules, removeModules, updateTabs],
    );
    const handleCloseAllTabs = useCallback(() => {
        removeModules((prev) => prev.map(({ key }) => ({ key })));
        OnChangeTabActive("close");
    }, [removeModules, OnChangeTabActive]);
    const handleSortTabs = useCallback(
        (info: SortingInfo | null) => {
            if (info) {
                updateModules([{ key: info.from, moveTo: info.to }]);
            }
        },
        [updateModules],
    );

    return (
        <>
            <TopBar />
            <div className="bg-lightGrey w-screen h-screen grid grid-cols-1 lg:grid-cols-[240px_1fr] grid-rows-[58px_1fr] lg:grid-rows-[72px_1fr]">
                <MainPageNavbar />
                <MainPageSideBar className="hidden lg:flex" />
                <main className="flex flex-col w-full h-full px-16 md:px-32 pt-12">
                    <Tabs
                        tabs={parseTabs()}
                        current={currentModule}
                        onChange={handleChangeTab}
                        onClose={handleCloseTab}
                        onCloseAll={handleCloseAllTabs}
                        onSort={handleSortTabs}>
                        <TabList isDraggable className="bg-lightGrey" />
                        <TabPanels />
                    </Tabs>
                </main>
                <NotificationContainer />
            </div>
        </>
    );
};

export default MainPage;
