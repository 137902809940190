import type { MutableRefObject } from "react";

export function processRefList(
    list: MutableRefObject<(HTMLElement | null)[]>,
    {
        flush = false,
        beforeFlush,
    }: { flush?: boolean; beforeFlush?: (el: HTMLElement) => void } = {},
): <T extends HTMLElement>(ref: T | null) => void {
    return (ref) => {
        list.current = list.current.filter((el) => {
            if (!el) return false;
            if (flush) {
                const exists = document.body.contains(el);
                if (!exists && beforeFlush) {
                    beforeFlush(el);
                }
                return exists;
            }
            return true;
        });
        if (ref && !list.current.includes(ref)) {
            list.current.push(ref);
        }
    };
}

export function combineRefs<E extends HTMLElement>(
    ...refs: (
        | MutableRefObject<E | null>
        | ((ref: E | null) => void)
        | null
        | undefined
    )[]
): (ref: E | null) => void {
    return (ref) => {
        for (const r of refs) {
            if (typeof r === "function") {
                r(ref);
            } else if (r) {
                r.current = ref;
            }
        }
    };
}
