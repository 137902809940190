import CameraIcon from "./linear";

export default CameraIcon;
export { default as CameraIcon, iconInfo as CameraIconInfo } from "./linear";

export {
    default as CameraBoldIcon,
    iconInfo as CameraBoldIconInfo,
} from "./bold";
export {
    default as CameraBrokenIcon,
    iconInfo as CameraBrokenIconInfo,
} from "./broken";
export {
    default as CameraBulkIcon,
    iconInfo as CameraBulkIconInfo,
} from "./bulk";
export {
    default as CameraLinearIcon,
    iconInfo as CameraLinearIconInfo,
} from "./linear";
export {
    default as CameraOutlineIcon,
    iconInfo as CameraOutlineIconInfo,
} from "./outline";
export {
    default as CameraTwotoneIcon,
    iconInfo as CameraTwotoneIconInfo,
} from "./twotone";
