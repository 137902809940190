import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "LocationOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M12.0009 14.1699C9.87086 14.1699 8.13086 12.4399 8.13086 10.2999C8.13086 8.15994 9.87086 6.43994 12.0009 6.43994C14.1309 6.43994 15.8709 8.16994 15.8709 10.3099C15.8709 12.4499 14.1309 14.1699 12.0009 14.1699ZM12.0009 7.93994C10.7009 7.93994 9.63086 8.99994 9.63086 10.3099C9.63086 11.6199 10.6909 12.6799 12.0009 12.6799C13.3109 12.6799 14.3709 11.6199 14.3709 10.3099C14.3709 8.99994 13.3009 7.93994 12.0009 7.93994Z"
                fill="currentColor"
            />
            <path
                d="M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25C11.9997 1.25 11.9997 1.25 12.0097 1.25C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
