import type { FC, ReactNode } from "react";

import { useEffect, useMemo, useState } from "react";

import { parseMenuRelation } from "./helpers";
import { MenuContext } from "./interface";
import type { MenuRelationGroup } from "./interface";

import { useSession } from "@contexts/session";

import getMenu from "@old/services/accounts/getMenu";

interface MenuContextProviderProps {
    children?: ReactNode;
}

const MenuContextProvider: FC<MenuContextProviderProps> = ({ children }) => {
    const {
        data: { isAdmin },
        hydrated: hydratedSession,
        logout,
    } = useSession();

    const [groups, setGroups] = useState<MenuRelationGroup[]>([]);
    const [hydrated, setHydrated] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!hydratedSession || typeof isAdmin === "undefined") return;
        (async () => {
            setLoading(true);

            const response = await getMenu();

            if (!response.length) {
                logout();
            } else {
                setGroups(parseMenuRelation(response, isAdmin));
                setHydrated(true);
                setLoading(false);
            }
        })();
    }, [hydratedSession, isAdmin, logout]);

    const state = useMemo<MenuContext>(
        () => ({
            groups,
            hydrated,
            loading,
        }),
        [groups, hydrated, loading],
    );

    return (
        <MenuContext.Provider value={state}>{children}</MenuContext.Provider>
    );
};

export default MenuContextProvider;
