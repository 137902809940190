import { createContext, useContext } from "react";

import type { ModalVariants } from "./interface";

export interface ModalContext {
    aria: {
        label: string;
        description: string;
        expanded: boolean;
    };
    variant: ModalVariants;
    onClose?: () => void;
}

export const ModalContext = createContext<ModalContext>({
    aria: { description: "", expanded: false, label: "" },
    variant: "primary",
});

export function useModalContext(): ModalContext {
    return useContext(ModalContext);
}
