import ProfileTickIcon from "./linear";

export default ProfileTickIcon;
export {
    default as ProfileTickIcon,
    iconInfo as ProfileTickIconInfo,
} from "./linear";

export {
    default as ProfileTickBoldIcon,
    iconInfo as ProfileTickBoldIconInfo,
} from "./bold";
export {
    default as ProfileTickBrokenIcon,
    iconInfo as ProfileTickBrokenIconInfo,
} from "./broken";
export {
    default as ProfileTickBulkIcon,
    iconInfo as ProfileTickBulkIconInfo,
} from "./bulk";
export {
    default as ProfileTickLinearIcon,
    iconInfo as ProfileTickLinearIconInfo,
} from "./linear";
export {
    default as ProfileTickOutlineIcon,
    iconInfo as ProfileTickOutlineIconInfo,
} from "./outline";
export {
    default as ProfileTickTwotoneIcon,
    iconInfo as ProfileTickTwotoneIconInfo,
} from "./twotone";
