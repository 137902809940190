import { createContext, useContext } from "react";

import type { SortingInfo, TabInfo } from "./interface";

export interface TabsContext {
    tabs: TabInfo[];
    current: string | null;
    onClose: (key: string) => void;
    onCloseAll: () => void;
    onChange: (key: string) => void;
    onSort: (sorting: (current: TabInfo[]) => SortingInfo | null) => void;
}

export const TabsContext = createContext<TabsContext>({
    current: null,
    tabs: [],
    onChange: () => null,
    onClose: () => null,
    onCloseAll: () => null,
    onSort: () => null,
});

export function useTabsContext(): TabsContext {
    return useContext(TabsContext);
}

export function formatTabId(slug: string): string {
    return `tab-${slug}`;
}

export function parseTabId(id: string): string {
    return id.replace(/^tab-/, "");
}

export function formatPanelId(slug: string): string {
    return `panel-${slug}`;
}
