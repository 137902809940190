export type Breakpoints = "base" | "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

export const breakpointQueries: Record<Breakpoints, string> = {
    base: "(min-width: 0px)",
    xs: "(min-width: 480px)",
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 992px)",
    xl: "(min-width: 1280px)",
    "2xl": "(min-width: 1536px)",
};

export const breakPointHierarchy: Breakpoints[] = [
    "base",
    "xs",
    "sm",
    "md",
    "lg",
    "xl",
    "2xl",
];
