import Profile2usersIcon from "./linear";

export default Profile2usersIcon;
export {
    default as Profile2usersIcon,
    iconInfo as Profile2usersIconInfo,
} from "./linear";

export {
    default as Profile2usersBoldIcon,
    iconInfo as Profile2usersBoldIconInfo,
} from "./bold";
export {
    default as Profile2usersBrokenIcon,
    iconInfo as Profile2usersBrokenIconInfo,
} from "./broken";
export {
    default as Profile2usersBulkIcon,
    iconInfo as Profile2usersBulkIconInfo,
} from "./bulk";
export {
    default as Profile2usersLinearIcon,
    iconInfo as Profile2usersLinearIconInfo,
} from "./linear";
export {
    default as Profile2usersOutlineIcon,
    iconInfo as Profile2usersOutlineIconInfo,
} from "./outline";
export {
    default as Profile2usersTwotoneIcon,
    iconInfo as Profile2usersTwotoneIconInfo,
} from "./twotone";
