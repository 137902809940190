import LinkIcon from "./linear";

export default LinkIcon;
export { default as LinkIcon, iconInfo as LinkIconInfo } from "./linear";

export { default as LinkBoldIcon, iconInfo as LinkBoldIconInfo } from "./bold";
export {
    default as LinkBrokenIcon,
    iconInfo as LinkBrokenIconInfo,
} from "./broken";
export { default as LinkBulkIcon, iconInfo as LinkBulkIconInfo } from "./bulk";
export {
    default as LinkLinearIcon,
    iconInfo as LinkLinearIconInfo,
} from "./linear";
export {
    default as LinkOutlineIcon,
    iconInfo as LinkOutlineIconInfo,
} from "./outline";
export {
    default as LinkTwotoneIcon,
    iconInfo as LinkTwotoneIconInfo,
} from "./twotone";
