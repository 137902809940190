import ArrowCircleRightIcon from "./linear";

export default ArrowCircleRightIcon;
export {
    default as ArrowCircleRightIcon,
    iconInfo as ArrowCircleRightIconInfo,
} from "./linear";

export {
    default as ArrowCircleRightBoldIcon,
    iconInfo as ArrowCircleRightBoldIconInfo,
} from "./bold";
export {
    default as ArrowCircleRightBrokenIcon,
    iconInfo as ArrowCircleRightBrokenIconInfo,
} from "./broken";
export {
    default as ArrowCircleRightBulkIcon,
    iconInfo as ArrowCircleRightBulkIconInfo,
} from "./bulk";
export {
    default as ArrowCircleRightLinearIcon,
    iconInfo as ArrowCircleRightLinearIconInfo,
} from "./linear";
export {
    default as ArrowCircleRightOutlineIcon,
    iconInfo as ArrowCircleRightOutlineIconInfo,
} from "./outline";
export {
    default as ArrowCircleRightTwotoneIcon,
    iconInfo as ArrowCircleRightTwotoneIconInfo,
} from "./twotone";
