export default function getSubdomain() {
    if (typeof window === "undefined") return "";

    const search = new URLSearchParams(window.location.search);
    const query = search.get("accountName");

    const overwrite = query || process.env.REACT_APP_SUBDOMAIN;

    let subdomain = overwrite || "";
    if (!overwrite && window.location.hostname !== "localhost") {
        const parts = window.location.hostname.split(".");
        if (parts.length > 1) subdomain = parts[0];
    }

    return subdomain;
}
