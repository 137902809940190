import axios from "axios";

import { LOCAL_STORAGE_NAMES } from "@constants/localStorage";

import { LSInstance } from "@hooks/storages/useLocalStorage";

const franchise = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_ENDPOINT,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

franchise.interceptors.request.use((req) => {
    if (req.headers.Authorization) return req;
    const token = LSInstance.getItem("token" as LOCAL_STORAGE_NAMES);
    const newToken = LSInstance.getItem(LOCAL_STORAGE_NAMES.ACCESS_TOKEN);
    if (newToken || token) {
        req.headers.Authorization = `Bearer ${newToken || token}`;
    }
    return req;
});

export default franchise;
