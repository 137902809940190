import type { DragEvent, DragEventHandler } from "react";
import { useCallback, useRef, useState } from "react";

import useThrottle from "../useThrottle";

interface UseDrapAndDropOptions {
    onDragStart?: (dragged: HTMLElement | null) => void;
    onDragOver?: (
        target: HTMLElement | null,
        dragged: HTMLElement | null,
    ) => void;
    onDragEnd?: (
        target: HTMLElement | null,
        dragged: HTMLElement | null,
    ) => void;
}

interface UseDrapAndDrop {
    (options?: UseDrapAndDropOptions): {
        onDragStart: DragEventHandler;
        onDrag: DragEventHandler;
        onDragEnd: DragEventHandler;
        onDragOver: DragEventHandler;
        dragged: HTMLElement | null;
        hoverTarget: HTMLElement | null;
    };
}

export default (function useDragAndDrop(options?: UseDrapAndDropOptions) {
    const _onDragStart = useRef(options?.onDragStart).current;
    const _onDragOver = useRef(options?.onDragOver).current;
    const _onDragEnd = useRef(options?.onDragEnd).current;

    const [hoverTarget, setHoverTarget] = useState<HTMLElement | null>(null);
    const [dragged, setDragged] = useState<HTMLElement | null>(null);
    const hoverTargetRef = useRef(hoverTarget);
    const draggedRef = useRef(dragged);
    const copy = useRef<HTMLElement | null>(null);

    const onDragStart = useCallback<DragEventHandler>(
        (event) => {
            const placeholder = document.createElement("div");
            event.dataTransfer.setDragImage(placeholder, 0, 0);

            const target = event.target as HTMLElement;
            setDragged(target);
            draggedRef.current = target;

            const top = event.clientY;
            const left = event.clientX;

            const el = target.cloneNode(true) as HTMLElement;
            el.style.position = "fixed";
            el.style.top = `${top}px`;
            el.style.left = `${left}px`;
            el.style.pointerEvents = "none";
            el.style.touchAction = "none";
            el.style.cursor = "move";
            el.style.opacity = "0";
            document.body.appendChild(el);

            copy.current = el;

            _onDragStart?.(target);
        },
        [_onDragStart],
    );
    const onDragEnd = useCallback<DragEventHandler>(() => {
        if (copy.current && document.body.contains(copy.current)) {
            document.body.removeChild(copy.current);
        }
        _onDragEnd?.(hoverTargetRef.current, draggedRef.current);
        copy.current = null;
        setHoverTarget(null);
        hoverTargetRef.current = null;
        setDragged(null);
        draggedRef.current = null;
    }, [_onDragEnd]);
    const onDrag = useThrottle((event: DragEvent) => {
        if (copy.current) {
            copy.current.style.top = `${event.clientY}px`;
            copy.current.style.left = `${event.clientX}px`;
            copy.current.style.opacity = "0.8";
        }
    }, 50);
    const onDragOver = useThrottle((event: DragEvent) => {
        if (hoverTargetRef.current !== event.target) {
            hoverTargetRef.current = event.target as HTMLElement;
            setHoverTarget(hoverTargetRef.current);
            _onDragOver?.(hoverTargetRef.current, draggedRef.current);
        }
    }, 50);

    return { dragged, hoverTarget, onDrag, onDragEnd, onDragStart, onDragOver };
} satisfies UseDrapAndDrop);
