import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronRightBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M 16.2298 15.48 L 13.15 13.1686 C 13.3998 12.2333 13.6998 11.2333 12.1998 9.7333 L 11.1798 8.7212 V 6.08 C 11.1798 5.12 12.3398 4.64 13.0198 5.32 L 18.1998 10.49 C 19.0298 11.32 19.0298 12.67 18.1998 13.5 L 16.2298 15.48 Z"
                fill="currentColor"
            />
            <path
                d="M 11.1805 17.9201 V 15.2688 L 12.1978 14.2567 C 12.4978 13.9567 12.85 13.6686 13.15 13.1686 L 16.2305 15.4801 L 13.0205 18.6901 C 12.3405 19.3601 11.1805 18.8801 11.1805 17.9201 Z"
                fill="currentColor"
            />

            <path
                opacity="0.4"
                d="M 10.2298 15.48 L 5.1798 11.69 V 6.08 C 5.1798 5.12 6.3398 4.64 7.0198 5.32 L 12.1998 10.49 C 13.0298 11.32 13.0298 12.67 12.1998 13.5 L 10.2298 15.48 Z"
                fill="currentColor"
            />
            <path
                d="M 5.1805 17.9201 V 11.6901 L 10.2305 15.4801 L 7.0205 18.6901 C 6.3405 19.3601 5.1805 18.8801 5.1805 17.9201 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
