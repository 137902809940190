import type { ComponentType, FC } from "react";

import { useContext } from "react";

import { OnboardingContext } from "./interface";

export function useOnboarding(): OnboardingContext {
    return useContext(OnboardingContext);
}

export function withOnboardingProps<Props extends JSX.IntrinsicAttributes>(
    Component: ComponentType<Props>,
): FC<Props> {
    const Wrapped: FC<Props> = (props) => {
        const onboarding = useContext(OnboardingContext);
        return <Component {...props} _onboarding={onboarding} />;
    };
    Wrapped.displayName = `withOnboardingProps(${Component.displayName})`;
    return Wrapped;
}
