import type { ComponentType, FC } from "react";

import { useContext, useEffect, useRef } from "react";

import { ModalManagerContext } from "./interface";
import type {
    FilterInjectedProps,
    ModalRelation,
    PushModal,
    SetModal,
} from "./interface";
import { commonModals } from "./relations";
import type { ModalCommonTags } from "./relations";

type ModalCustomRelation = ModalRelation &
    Partial<Record<ModalCommonTags, never>>;

export interface UseModalManagerReturn<Relation extends ModalCustomRelation>
    extends Pick<ModalManagerContext, "clear" | "pop"> {
    push: PushModal<Relation & typeof commonModals>;
    set: SetModal<Relation & typeof commonModals>;
}

export function useModalManager<
    Relation extends ModalCustomRelation = NonNullable<unknown>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
>(relation?: Relation): UseModalManagerReturn<Relation> {
    const { clear, pop, push, set } = useContext(ModalManagerContext);
    return { clear, pop, push, set };
}

export function withModalManager<Relation extends ModalCustomRelation>(
    relation: Relation,
): <Props extends Record<string, any>>(
    Component: ComponentType<Props>,
) => FC<FilterInjectedProps<Props>> {
    return <Props extends Record<string, any>>(
        Component: ComponentType<Props>,
    ) => {
        const Wrapped = function Wrapped(props: FilterInjectedProps<Props>) {
            const modalManager = useContext(ModalManagerContext);

            const appendPool = useRef(modalManager.appendPool).current;
            const removePool = useRef(modalManager.removePool).current;
            const relationRef = useRef(relation);

            useEffect(() => {
                const tags: string[] = [];

                for (const tag in relationRef.current) {
                    const Component = relationRef.current[tag];
                    appendPool(tag, Component);
                    tags.push(tag);
                }

                return () => {
                    for (const tag of tags) {
                        removePool(tag);
                    }
                };
            }, [appendPool, removePool]);

            return (
                <Component {...(props as Props)} _modalManager={modalManager} />
            );
        };
        Wrapped.displayName = `withModalManager(${Component.displayName})`;
        return Wrapped;
    };
}
