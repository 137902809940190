export * from "./add";
export * from "./arrow-circle-down";
export * from "./arrow-circle-left";
export * from "./arrow-circle-right";
export * from "./arrow-circle-up";
export * from "./arrow-down";
export * from "./arrow-left";
export * from "./arrow-left-right";
export * from "./arrow-right";
export * from "./arrow-up";
export * from "./arrow-up-down";
export * from "./bell";
export * from "./box-add";
export * from "./box-tick";
export * from "./buildings";
export * from "./calculator";
export * from "./calendar";
export * from "./caret-down";
export * from "./caret-left";
export * from "./caret-right";
export * from "./caret-up";
export * from "./chevron-circle-down";
export * from "./chevron-circle-left";
export * from "./chevron-circle-right";
export * from "./chevron-circle-up";
export * from "./chevron-down";
export * from "./chevron-left";
export * from "./chevron-right";
export * from "./chevron-up";
export * from "./close";
export * from "./component";
export * from "./copy";
export * from "./credit-card";
export * from "./credit-cards";
export * from "./danger";
export * from "./document";
export * from "./document-forward";
export * from "./document-previous";
export * from "./document-upload";
export * from "./dollar-circle";
export * from "./double-chevron-left";
export * from "./double-chevron-right";
export * from "./edit";
export * from "./export";
export * from "./eye";
export * from "./eye-slash";
export * from "./filter";
export * from "./grid";
export * from "./home";
export * from "./import";
export * from "./info";
export * from "./info-circle";
export * from "./list";
export * from "./location";
export * from "./lock";
export * from "./login";
export * from "./logout";
export * from "./magic-pen";
export * from "./menu";
export * from "./message";
export * from "./message-notification";
export * from "./minus";
export * from "./money-send";
export * from "./monitor";
export * from "./more";
export * from "./more-vertical";
export * from "./notification";
export * from "./pix";
export * from "./printer";
export * from "./profile";
export * from "./profile-2users";
export * from "./profile-tick";
export * from "./question";
export * from "./refresh";
export * from "./reload";
export * from "./search-normal";
export * from "./setting";
export * from "./shop";
export * from "./shop-add";
export * from "./shopping-cart";
export * from "./slash";
export * from "./spinner";
export * from "./status-up";
export * from "./teacher";
export * from "./tick";
export * from "./tick-circle";
export * from "./trash";
export * from "./trend-down";
export * from "./trend-up";
export * from "./unlock";
export * from "./user";
export * from "./wallet";
export * from "./wallet-side-bar";
export * from "./warning";
