import type { HubResource, HubResourceTypes } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "@old/configs/rest/lambdas";

interface GetHubCategoriesParams {
    ResourceType: HubResourceTypes;
}

export default async function getHubResourcesInfo(
    typeCompanyIntegrationId: string | number,
    params: GetHubCategoriesParams,
    { signal }: RequestOptions = {},
): Promise<HubResource[]> {
    try {
        const { data } = await lambdas.get<HubResource[]>(
            `/hub/resource/${typeCompanyIntegrationId}`,
            { signal, params },
        );

        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
