import ArrowRightIcon from "./linear";

export default ArrowRightIcon;
export {
    default as ArrowRightIcon,
    iconInfo as ArrowRightIconInfo,
} from "./linear";

export {
    default as ArrowRightBoldIcon,
    iconInfo as ArrowRightBoldIconInfo,
} from "./bold";
export {
    default as ArrowRightBrokenIcon,
    iconInfo as ArrowRightBrokenIconInfo,
} from "./broken";
export {
    default as ArrowRightBulkIcon,
    iconInfo as ArrowRightBulkIconInfo,
} from "./bulk";
export {
    default as ArrowRightLinearIcon,
    iconInfo as ArrowRightLinearIconInfo,
} from "./linear";
export {
    default as ArrowRightOutlineIcon,
    iconInfo as ArrowRightOutlineIconInfo,
} from "./outline";
export {
    default as ArrowRightTwotoneIcon,
    iconInfo as ArrowRightTwotoneIconInfo,
} from "./twotone";
