import type {
    MenuRelationGroup,
    MenuRelationItem,
} from "@contexts/menu/interface";

function normalize(str: string) {
    return str.toLocaleLowerCase().normalize("NFD").replace(/\W/g, "");
}

export function groupFilter(
    search: string,
): (group: MenuRelationGroup) => boolean {
    const base = normalize(search);
    return ({ children }) =>
        !base || children.some((item) => normalize(item.label).includes(base));
}

export function itemFilter(
    search: string,
): (item: MenuRelationItem) => boolean {
    const base = normalize(search);
    return ({ label }) => !base || normalize(label).includes(base);
}

export function parseMenuItemAction(
    item: MenuRelationItem,
    resources: {
        append: (item: MenuRelationItem) => void;
        switch: (link: string) => void;
        callback?: () => void;
    },
): () => void {
    switch (item.action) {
        case "append":
            return () => (resources.append(item), resources.callback?.());
        case "switch":
            return () => (resources.switch(item.link), resources.callback?.());
        default:
            return () => resources.callback?.();
    }
}
