import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronLeftTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 11.9998 19.9201 L 5.4798 13.4001 C 4.7098 12.6301 4.7098 11.3701 5.4798 10.6001 L 11.9998 4.0801"
                stroke="currentColor"
            />
            <path
                d="M 17.9998 19.9201 L 11.4798 13.4001 C 10.7098 12.6301 10.7098 11.3701 11.4798 10.6001 L 17.9998 4.0801"
                stroke="currentColor"
                opacity="0.4"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
