import type { FC } from "react";

import OnboardingProgressBar from "./ProgressBar";

import { parseOnboardingFlow } from "./helpers";

import Button from "@components/@basic/Button";

import { useModalManager } from "@contexts/modal-manager-v2";
import { ModalCommonTags } from "@contexts/modal-manager-v2/relations";
import { useOnboarding } from "@contexts/onboarding";
import { useSession } from "@contexts/session";

const OnboardingResume: FC = () => {
    const {
        data: { isAdmin },
    } = useSession();

    const { checks, isPF, isProcessing } = useOnboarding();
    const { set } = useModalManager();

    const handleOpenModal = () =>
        set([{ tag: ModalCommonTags.ONBOARDING_STEPS }]);

    const onboarding = parseOnboardingFlow(checks, isPF);

    if (typeof isAdmin === "undefined" || isAdmin) return null;

    if (!checks || isProcessing)
        return (
            <div className="bg-gray-300 w-full min-h-176 rounded-8 animate-pulse" />
        );

    if (!onboarding) return null;

    const { index, title, resume } = onboarding.block;

    return (
        <div className="bg-doladoBlue rounded-8 p-16 space-y-8">
            <h2 className="text-sm text-white font-medium">
                {index}. {title}
            </h2>
            <OnboardingProgressBar
                steps={onboarding.steps}
                current={onboarding.progress}
                textStyle="text-white"
            />
            <p className="text-xs text-white">{resume}</p>
            <Button
                size="md"
                className="w-full bg-white text-doladoBlue"
                onClick={handleOpenModal}>
                Continuar
            </Button>
        </div>
    );
};

export default OnboardingResume;
