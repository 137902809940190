import CalendarIcon from "./linear";

export default CalendarIcon;
export {
    default as CalendarIcon,
    iconInfo as CalendarIconInfo,
} from "./linear";

export {
    default as CalendarBoldIcon,
    iconInfo as CalendarBoldIconInfo,
} from "./bold";
export {
    default as CalendarBrokenIcon,
    iconInfo as CalendarBrokenIconInfo,
} from "./broken";
export {
    default as CalendarBulkIcon,
    iconInfo as CalendarBulkIconInfo,
} from "./bulk";
export {
    default as CalendarLinearIcon,
    iconInfo as CalendarLinearIconInfo,
} from "./linear";
export {
    default as CalendarOutlineIcon,
    iconInfo as CalendarOutlineIconInfo,
} from "./outline";
export {
    default as CalendarTwotoneIcon,
    iconInfo as CalendarTwotoneIconInfo,
} from "./twotone";
