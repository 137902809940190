export default async function getHubWarrantyTypes() {
    return [
        {
            value: "Garantia do Vendedor",
            label: "Garantia do Vendedor",
            comments: "",
        },
        {
            value: "Garantia de fábrica",
            label: "Garantia de fábrica",
            comments: "",
        },
        { value: "Sem garantia", label: "Sem garantia", comments: "" },
    ];
}
