import type { FC, ReactNode } from "react";

import { useCallback, useEffect, useRef, useState } from "react";

import { PopoverContext } from "./helpers";
import type {
    ExternalPortalTrigger,
    PopoverPositions,
    PopoverSizes,
} from "./interface";

import { classes } from "@utils/styles/tailwind/v4";

interface PopoverProps {
    children?: ReactNode | ((args: { isOpen: boolean }) => ReactNode);
    className?: string;
    closeOnBlur?: boolean;
    externalPortalTrigger?: ExternalPortalTrigger;
    isOpen?: boolean;
    onClose?: () => void;
    position?: PopoverPositions;
    size?: PopoverSizes;
    usePortal?: boolean;
}

const Popover: FC<PopoverProps> = ({
    position = "bottom",
    size = "md",
    isOpen: _isOpen,
    onClose: _onClose,
    closeOnBlur = true,
    usePortal = false,
    externalPortalTrigger,
    className,
    children,
}) => {
    const anchorRef = useRef<HTMLElement>(null);
    const triggerRef = useRef<HTMLElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    const isOpenRef = useRef(isOpen);
    const state = _isOpen ?? isOpen;

    const onClose = useCallback(() => {
        isOpenRef.current = false;
        setIsOpen(false);
        _onClose?.();
    }, [_onClose]);

    const portalTrigger = useRef(externalPortalTrigger).current;

    useEffect(() => {
        if (!triggerRef.current || !contentRef.current) return;

        const toggleContent = () => {
            setIsOpen((prev) => !prev);
            isOpenRef.current = !isOpenRef.current;
            if (!isOpenRef.current) _onClose?.();
        };

        const trigger = triggerRef.current;
        trigger.addEventListener("click", toggleContent);
        return () => {
            trigger.removeEventListener("click", toggleContent);
        };
    }, [position, _onClose]);

    useEffect(() => {
        if (
            !closeOnBlur ||
            !state ||
            (!anchorRef.current && !triggerRef.current) ||
            !contentRef.current
        )
            return;

        const handleClick = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            if (
                !anchorRef.current?.contains(target) &&
                !triggerRef.current?.contains(target) &&
                !contentRef.current?.contains(target)
            ) {
                onClose();
            }
        };

        window.addEventListener("mousedown", handleClick);
        return () => {
            window.removeEventListener("mousedown", handleClick);
        };
    }, [closeOnBlur, state, onClose]);

    useEffect(() => {
        if (!triggerRef.current || !contentRef.current || !usePortal) return;

        const handle = () => {
            if (!triggerRef.current || !contentRef.current) return;
            const contentParent = contentRef.current.parentNode as HTMLElement;
            if (!contentParent) return;

            const { height, left, top, width } =
                triggerRef.current.getBoundingClientRect();

            contentParent.style.width = width + "px";
            contentParent.style.height = height + "px";
            contentParent.style.left = left + "px";
            contentParent.style.top = top + "px";
        };

        const observer = new ResizeObserver(handle);
        observer.observe(document.body);
        observer.observe(triggerRef.current);

        if (portalTrigger) portalTrigger.setup(handle);

        return () => {
            observer.disconnect();
            if (portalTrigger) portalTrigger.clear(handle);
        };
    }, [usePortal, portalTrigger]);

    return (
        <PopoverContext.Provider
            value={{
                anchorRef,
                triggerRef,
                contentRef,
                position,
                size,
                isOpen: state,
                usePortal,
                onClose,
            }}>
            <div className={classes`relative ${className}`}>
                {typeof children === "function"
                    ? children({ isOpen: state })
                    : children}
            </div>
        </PopoverContext.Provider>
    );
};

export default Popover;

export { default as PopoverAnchor } from "./Anchor";
export { default as PopoverBody } from "./Body";
export { default as PopoverContent } from "./Content";
export { default as PopoverFooter } from "./Footer";
export { default as PopoverHeader } from "./Header";
export { default as PopoverTrigger } from "./Trigger";
