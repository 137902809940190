import ArrowLeftIcon from "./linear";

export default ArrowLeftIcon;
export {
    default as ArrowLeftIcon,
    iconInfo as ArrowLeftIconInfo,
} from "./linear";

export {
    default as ArrowLeftBoldIcon,
    iconInfo as ArrowLeftBoldIconInfo,
} from "./bold";
export {
    default as ArrowLeftBrokenIcon,
    iconInfo as ArrowLeftBrokenIconInfo,
} from "./broken";
export {
    default as ArrowLeftBulkIcon,
    iconInfo as ArrowLeftBulkIconInfo,
} from "./bulk";
export {
    default as ArrowLeftLinearIcon,
    iconInfo as ArrowLeftLinearIconInfo,
} from "./linear";
export {
    default as ArrowLeftOutlineIcon,
    iconInfo as ArrowLeftOutlineIconInfo,
} from "./outline";
export {
    default as ArrowLeftTwotoneIcon,
    iconInfo as ArrowLeftTwotoneIconInfo,
} from "./twotone";
