import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowLeftRightLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M17.28 10.45L21 6.72998L17.28 3.01001"
                stroke="currentColor"
            />
            <path d="M3 6.72998H21" stroke="currentColor" />
            <path
                d="M6.71997 13.55L3 17.2701L6.71997 20.9901"
                stroke="currentColor"
            />
            <path d="M21 17.27H3" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
