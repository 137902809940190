import type { ComponentType } from "react";

import { createContext } from "react";

export type StackItem = {
    key: string;
    tag: string;
    props?: Record<string, any>;
};

export type ModalRelation = Record<string, ComponentType<any>>;

export const modalManagerPropsId = "_modalManager" as const;

export type FilterInjectedProps<Props extends Record<string, any>> =
    typeof modalManagerPropsId extends keyof Props
        ? Pick<Props, Exclude<keyof Props, typeof modalManagerPropsId>>
        : Props;

export type StackEntry<
    Relation extends ModalRelation = ModalRelation,
    Tag extends keyof Relation = string,
> = {
    tag: Tag;
    props?: Relation[Tag] extends ComponentType<infer Props>
        ? Props extends Record<string, any>
            ? FilterInjectedProps<Props>
            : Record<string, never>
        : Record<string, never>;
};

export interface PushModal<Relation extends ModalRelation = ModalRelation> {
    <Tag extends keyof Relation>(
        tag: Tag,
        props?: Relation[Tag] extends ComponentType<infer Props>
            ? Props extends Record<string, any>
                ? FilterInjectedProps<Props>
                : Record<string, never>
            : Record<string, never>,
    ): [string, () => void];
}

export interface SetModal<Relation extends ModalRelation = ModalRelation> {
    <Tag extends keyof Relation>(entries: StackEntry<Relation, Tag>[]): void;
}

export interface ModalManagerInjectedProps<
    Relation extends ModalRelation = ModalRelation,
> {
    [modalManagerPropsId]: Pick<ModalManagerContext, "clear" | "pop"> & {
        close: () => void;
        push: PushModal<Relation>;
        set: SetModal<Relation>;
        itemKey: string;
        zIndex: number;
    };
}

export type ModalManagerComponent<
    Props extends Record<string, any> = NonNullable<unknown>,
    Relation extends ModalRelation = ModalRelation,
> = Props extends Record<string, never>
    ? ComponentType<ModalManagerInjectedProps<Relation>>
    : ComponentType<Props & ModalManagerInjectedProps<Relation>>;

export interface ModalManagerContext<
    Relation extends ModalRelation = ModalRelation,
> {
    appendPool: (tag: string, Component: ModalManagerComponent) => void;
    clear: (keys: string[] | ((stack: StackItem[]) => string[])) => void;
    pop: () => void;
    push: PushModal<Relation>;
    removePool: (tag: string) => void;
    set: SetModal<Relation>;
}

export const ModalManagerContext = createContext<ModalManagerContext>({
    appendPool: () => "",
    clear: () => null,
    pop: () => null,
    push: () => ["", () => null],
    removePool: () => null,
    set: () => null,
});
