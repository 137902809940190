import { useEffect, useState } from "react";

import { breakpointQueries } from "./interface";

export default function useIsTablet(): boolean | undefined {
    const [isTablet, setIsTablet] = useState<boolean>();

    useEffect(() => {
        const handle = (event: MediaQueryListEvent) =>
            setIsTablet(!event.matches);
        const mql = window.matchMedia(breakpointQueries.lg);
        mql.addEventListener("change", handle);
        setIsTablet(!mql.matches);
        return () => {
            mql.removeEventListener("change", handle);
        };
    }, []);

    return isTablet;
}
