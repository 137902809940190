import { createContext, useContext } from "react";

import type { DrawerPositions, DrawerVariants } from "./interface";

export interface DrawerContext {
    variant: DrawerVariants;
    aria: {
        label: string;
        description: string;
        expanded: boolean;
    };
    position: DrawerPositions;
    onClose?: () => void;
}

export const DrawerContext = createContext<DrawerContext>({
    aria: { description: "", expanded: false, label: "" },
    position: "left",
    variant: "primary",
});

export function useDrawerContext(): DrawerContext {
    return useContext(DrawerContext);
}
