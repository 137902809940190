import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "StatusUpBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M6.88086 18.1501V16.0801" stroke="currentColor" />
            <path d="M12 18.15V14.01" stroke="currentColor" />
            <path d="M17.1191 18.1499V11.9299" stroke="currentColor" />
            <path
                d="M17.1209 5.8501L16.6609 6.3901C14.1109 9.3701 10.6909 11.4801 6.88086 12.4301"
                stroke="currentColor"
            />
            <path d="M14.1895 5.8501H17.1195V8.7701" stroke="currentColor" />
            <path
                d="M2 12.97V15C2 20 4 22 9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9"
                stroke="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
