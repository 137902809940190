import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowUpDownLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M10.45 6.71997L6.72998 3L3.01001 6.71997"
                stroke="currentColor"
            />
            <path d="M6.72998 21V3" stroke="currentColor" />
            <path
                d="M13.55 17.28L17.2701 21L20.9901 17.28"
                stroke="currentColor"
            />
            <path d="M17.27 3V21" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
