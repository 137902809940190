import { useState, FC } from "react";
import ButtonIcon from "@components/@basic/ButtonIcon";
import { CloseIconInfo } from "@components/@basic/Icons/Mocks";

interface TopBarProps {
    communityLink?: string;
    boldText?: string;
    regularText?: string;
    buttonText?: string;
}

const TopBar: FC<TopBarProps> = ({
    communityLink,
    boldText,
    regularText,
    buttonText,
}) => {
    const [showAlert, setShowAlert] = useState(true);

    const handleWhatsAppClick = () => {
        window.open(communityLink, "_blank");
    };

    const shouldRender =
        boldText || regularText || (buttonText && communityLink);

    if (!shouldRender) {
        return null;
    }

    return (
        <div className="flex justify-center items-center bg-darkNavy w-full p-16">
            <p className="m-0 text-sm text-lightGrey-50">
                {boldText && <b>{boldText}</b>}
                {regularText}
                {buttonText && communityLink && (
                    <span
                        className="cursor-pointer text-lightGrey-50 underline hover:opacity-70"
                        onClick={handleWhatsAppClick}>
                        {buttonText}
                    </span>
                )}
            </p>

            {showAlert && (
                <ButtonIcon
                    variant="unstyled"
                    size="unset"
                    className="w-24 h-24 p-2 absolute top-14 right-40 text-lightGrey-50"
                    label="close"
                    title="Fechar"
                    icon={CloseIconInfo}
                    onClick={() => setShowAlert(false)}
                />
            )}
        </div>
    );
};

export default TopBar;
