export type ModalVariants = "primary" | "secondary" | "unstyled";
export type ModalSizes = "xs" | "sm" | "md" | "lg" | "xl" | "full" | "unset";
export type ModalScrollBehaviors = "inside" | "outside";

export const portalId = "modal-portal";
export const ariaLabel = "modal-header";
export const ariaDescription = "modal-body";

export const modalVariants: Record<ModalVariants, string> = {
    primary: "bg-white text-gray-700",
    secondary: "bg-gray-700 text-white",
    unstyled: "",
};

export const modalSizes: Record<ModalSizes, string> = {
    xl: "max-w-576",
    lg: "max-w-512",
    md: "max-w-448",
    sm: "max-w-384",
    xs: "max-w-320",
    full: "max-w-full",
    unset: "",
};

export const modalScrollBehaviors: Record<ModalScrollBehaviors, string> = {
    inside: "max-h-full-minus-48 md:max-h-full-minus-128",
    outside: "max-h-max",
};

export const modalCommonStyle =
    "flex flex-col w-full rounded-4 shadow-md overflow-y-hidden pointer-events-auto touch-auto";

export interface AnimationRelation {
    in: string;
    out: string;
}

export const modalAnimations: AnimationRelation = {
    in: "animate-grow scale-100",
    out: "animate-shrink scale-0",
};
