import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type { PaginationState } from "@redux/plugins/pagination-manager/interface";

import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";

import type { Consumer } from "@old/services/consumers/interface";
import type { GetConsumersParams } from "@old/services/consumers/getAll";

export type PaginationParams = Partial<Omit<GetConsumersParams, "Page">>;

export type ConsumerItem = Consumer;

export interface State {
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
    consumers: PaginationState<
        ConsumerItem,
        NonNullable<unknown>,
        PaginationParams
    >;
}

export const moduleId = "consumers" as const;
