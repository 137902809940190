import { KEY_PREFIX } from "redux-persist";
import type { PersistConfig } from "redux-persist";

import {
    checkConfigs,
    getPathBases,
    parseTransformers,
    mergeStates,
} from "./helpers";
import type { PersistConfigs } from "./interface";

import { formatModuleName } from "../modules/helpers";
import type { ModuleData } from "../modules/interface";

export function processPersistency(
    configs: Omit<PersistConfigs<any>, "key">,
    key: string,
): PersistConfig<any> {
    checkConfigs(configs);

    const pathsBL = configs.blacklist?.map((path) => path.split("."));
    const pathsWL = configs.whitelist?.map((path) => path.split("."));

    const basesBL = getPathBases(pathsBL || [], false);
    const blacklist = basesBL.size ? [...basesBL] : undefined;

    const basesWL = getPathBases(pathsWL || []);
    const whitelist = basesWL.size ? [...basesWL] : undefined;

    const transform = parseTransformers(configs.transforms, {
        blacklist: pathsBL,
        whitelist: pathsWL,
    });

    const stateReconciler = (
        inboundState: any,
        originalState: any,
        reducerState: any,
    ) => {
        return mergeStates("", originalState, reducerState, inboundState, {
            blacklist: pathsBL,
            whitelist: pathsWL,
            debug: configs.debug,
        });
    };

    return {
        ...configs,
        key,
        whitelist,
        blacklist,
        transforms: transform && [transform],
        stateReconciler,
    };
}

export async function purgePersistency(
    module: Pick<ModuleData, "hash" | "id" | "persist">,
): Promise<boolean> {
    if (!module.persist) return false;
    module.persist.storage.removeItem(KEY_PREFIX + formatModuleName(module));
    return true;
}
