import CloseIcon from "./linear";

export default CloseIcon;
export { default as CloseIcon, iconInfo as CloseIconInfo } from "./linear";

export {
    default as CloseBoldIcon,
    iconInfo as CloseBoldIconInfo,
} from "./bold";
export {
    default as CloseBrokenIcon,
    iconInfo as CloseBrokenIconInfo,
} from "./broken";
export {
    default as CloseBulkIcon,
    iconInfo as CloseBulkIconInfo,
} from "./bulk";
export {
    default as CloseLinearIcon,
    iconInfo as CloseLinearIconInfo,
} from "./linear";
export {
    default as CloseOutlineIcon,
    iconInfo as CloseOutlineIconInfo,
} from "./outline";
export {
    default as CloseTwotoneIcon,
    iconInfo as CloseTwotoneIconInfo,
} from "./twotone";
