import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "TrendDownBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                fill="currentColor"
            />
            <path
                d="M 16.8295 14.59 C 16.7595 14.76 16.6195 14.9 16.4495 14.97 C 16.3695 15 16.2795 15.02 16.1895 15.02 H 14.3295 C 13.9395 15.02 13.6295 14.71 13.6295 14.32 C 13.6295 13.93 13.9395 13.62 14.3295 13.62 H 14.5095 L 12.3995 11.51 L 11.3795 13.03 C 11.2595 13.2 11.0795 13.32 10.8695 13.34 C 10.6495 13.36 10.4595 13.29 10.3095 13.14 L 7.3295 10.16 C 7.0594 9.89 7.0594 9.45 7.3295 9.17 C 7.4695 9.03 7.6395 8.97 7.8194 8.97 C 7.9994 8.97 8.1794 9.04 8.3095 9.17 L 10.6895 11.55 L 11.7095 10.03 C 11.8295 9.86 12.0095 9.74 12.2195 9.72 C 12.4395 9.7 12.6295 9.77 12.7795 9.92 L 15.4995 12.64 V 12.46 C 15.4995 12.07 15.8095 11.76 16.1995 11.76 C 16.5895 11.76 16.8995 12.07 16.8995 12.46 V 14.33 C 16.8795 14.42 16.8695 14.51 16.8295 14.59 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
