import ShoppingCartIcon from "./linear";

export default ShoppingCartIcon;
export {
    default as ShoppingCartIcon,
    iconInfo as ShoppingCartIconInfo,
} from "./linear";

export {
    default as ShoppingCartBoldIcon,
    iconInfo as ShoppingCartBoldIconInfo,
} from "./bold";
export {
    default as ShoppingCartBrokenIcon,
    iconInfo as ShoppingCartBrokenIconInfo,
} from "./broken";
export {
    default as ShoppingCartBulkIcon,
    iconInfo as ShoppingCartBulkIconInfo,
} from "./bulk";
export {
    default as ShoppingCartLinearIcon,
    iconInfo as ShoppingCartLinearIconInfo,
} from "./linear";
export {
    default as ShoppingCartOutlineIcon,
    iconInfo as ShoppingCartOutlineIconInfo,
} from "./outline";
export {
    default as ShoppingCartTwotoneIcon,
    iconInfo as ShoppingCartTwotoneIconInfo,
} from "./twotone";
