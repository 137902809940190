import { createContext, useContext } from "react";

import type { ToastData } from "./interface";

export interface ToastContext {
    close: (id: string) => void;
    closeAll: () => void;
    dispatch: (data: Partial<ToastData>) => string;
    update: (id: string, data: Partial<ToastData>) => string;
}

export const ToastContext = createContext<ToastContext>({
    close: () => null,
    closeAll: () => null,
    dispatch: () => "",
    update: () => "",
});

export function useToast(): ToastContext {
    return useContext(ToastContext);
}
