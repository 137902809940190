export default function Subdomain() {
    //Domain

    const overwrite = process.env.REACT_APP_SUBDOMAIN;

    let subdomain = overwrite || "";
    if (!overwrite && window.location.hostname !== "localhost") {
        const parts = window.location.hostname.split(".");
        if (parts.length > 1) subdomain = parts[0];
    }

    return subdomain;

    // var url = window.location.href;
    // // console.log("url ->", url);
    // var urlLength = url.substring(url.indexOf("/"));
    // // console.log("urlLength ->", urlLength);
    // urlLength = urlLength.length;
    // // console.log("urlLength ->", urlLength);

    // var localhost = url.substring(0, 16);
    // // console.log("localhost ->", localhost);

    // var URLFull = url.substring(0, url.length - urlLength);
    // // console.log("URLFull ->", URLFull);

    // var site_domain_name = URLFull;
    // // console.log("site_domain_name ->", site_domain_name);

    // var strsArray = site_domain_name.split(".");
    // // console.log("strsArray ->", strsArray);
    // var strsArrayLen = strsArray.length;
    // // console.log("strsArrayLen ->", strsArrayLen);

    // var domain;

    // if (localhost === "http://localhost") {
    //     domain = site_domain_name;
    // } else {
    //     domain =
    //         strsArray[strsArrayLen - 3] +
    //         "." +
    //         strsArray[strsArrayLen - 2] +
    //         "." +
    //         strsArray[strsArrayLen - 1];
    // }

    // // console.log("domain ->", domain);

    // // console.log("host ->", window.location.host.split("."));

    // var subdomain = window.location.host.split(".")[1]
    //     ? window.location.host.split(".")[0]
    //     : false;

    // // console.log("subdomain ->", subdomain);

    // var setSubdomain = "seller";

    // if (
    //     subdomain === "testprefix" ||
    //     subdomain === "false" ||
    //     subdomain === false ||
    //     domain === "sa-east-1.amazonaws.com" ||
    //     domain === "compute.amazonaws.com"
    // ) {
    //     subdomain = setSubdomain;
    // }

    // console.log("subdomain ->", subdomain);

    // return subdomain;
}
