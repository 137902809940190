import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "TrendDownBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 16.19 22 H 7.81 C 4.17 22 2 19.83 2 16.19 V 7.82 C 2 4.17 4.17 2 7.81 2 H 16.18 C 19.82 2 21.99 4.17 21.99 7.81 V 16.19 C 22 19.83 19.83 22 16.19 22 Z M 16.88 12.47 C 16.88 12.08 16.57 11.77 16.18 11.77 C 15.79 11.77 15.48 12.08 15.48 12.47 V 12.65 L 12.76 9.93 C 12.61 9.78 12.41 9.71 12.2 9.73 C 11.99 9.75 11.8 9.86 11.69 10.04 L 10.67 11.56 L 8.29 9.18 C 8.15 9.04 7.98 8.98 7.8 8.98 C 7.62 8.98 7.44 9.05 7.31 9.18 C 7.04 9.45 7.04 9.89 7.31 10.17 L 10.29 13.15 C 10.44 13.3 10.64 13.37 10.85 13.35 C 11.06 13.33 11.25 13.22 11.36 13.04 L 12.38 11.52 L 14.49 13.63 H 14.31 C 13.92 13.63 13.61 13.94 13.61 14.33 C 13.61 14.72 13.92 15.03 14.31 15.03 H 16.17 C 16.26 15.03 16.35 15.01 16.44 14.98 C 16.61 14.91 16.75 14.77 16.82 14.6 C 16.86 14.51 16.87 14.42 16.87 14.33 V 12.47 H 16.88 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
