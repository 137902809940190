import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "LinkLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M14.99 17.5H16.5C19.52 17.5 22 15.03 22 12C22 8.98 19.53 6.5 16.5 6.5H14.99"
                stroke="currentColor"
            />
            <path
                d="M9 6.5H7.5C4.47 6.5 2 8.97 2 12C2 15.02 4.47 17.5 7.5 17.5H9"
                stroke="currentColor"
            />
            <path d="M8 12H16" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
