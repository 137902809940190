import CaretRightIcon from "./linear";

export default CaretRightIcon;
export {
    default as CaretRightIcon,
    iconInfo as CaretRightIconInfo,
} from "./linear";

export {
    default as CaretRightLinearIcon,
    iconInfo as CaretRightLinearIconInfo,
} from "./linear";
