import type { SkuSpecificationDefinition } from "../interface";

import type { ErrorData } from "../../../interface";

import type { RequestOptions } from "../../../interface";

import lambdas from "@old/configs/rest/lambdas";

interface GetSkuSpecificationsDefinitionsParams {
    CategoryId: string | number;
    IDCompanyIntegration?: string | number;
    IDTypeSalesChannelResource: string | number;
}

export default async function getSkuSpecificationsDefinitions(
    typeCompanyIntegrationId: string | number,
    params: GetSkuSpecificationsDefinitionsParams,
    { signal }: RequestOptions = {},
): Promise<SkuSpecificationDefinition[]> {
    try {
        const { data } = await lambdas.post<
            SkuSpecificationDefinition[] | ErrorData
        >(`/hub/resource/${typeCompanyIntegrationId}`, undefined, {
            signal,
            params,
        });

        if (Array.isArray(data)) return data;
        console.log(data);
        return [];
    } catch (error) {
        console.log(error);
        return [];
    }
}
