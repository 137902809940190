import { compose } from "redux";
import type { Dispatch } from "redux";
import type { Config } from "@redux-devtools/extension";

import type {
    DynamicStoreEnhancer,
    DynamicStoreEnhancerStoreCreator,
    DynamicMiddleware,
    DynamicMiddlewareApi,
} from "./interface";

export function devToolsMiddleware(
    configs: Config = {},
): DynamicStoreEnhancer<any> {
    return (create) => (initModules, moduleFetcher, preloadedState) => {
        const store = create(initModules, moduleFetcher, preloadedState);
        const originalDispatch = store.dispatch;

        if (
            process.env.NODE_ENV === "development" &&
            typeof window === "object" &&
            window.__REDUX_DEVTOOLS_EXTENSION__
        ) {
            const devTools =
                window.__REDUX_DEVTOOLS_EXTENSION__.connect(configs);
            devTools.init(store.getState());

            const dispatch: Dispatch = function (action) {
                const r = originalDispatch(action);
                devTools.send(action, store.getState());
                return r;
            };

            store.dispatch = dispatch;
        }

        return store;
    };
}

type ExtractDipatchExtension<M extends DynamicMiddleware<any, any>[]> = {
    [K in Extract<keyof M, number>]: M[K] extends DynamicMiddleware<
        any,
        Dispatch & infer D
    >
        ? D
        : never;
}[Extract<keyof M, number>];

export function applyDynamicMiddleware<
    S = any,
    Middlewares extends DynamicMiddleware<S, any>[] = [],
>(
    ...middlewares: [...Middlewares]
): DynamicStoreEnhancer<{ dispatch: ExtractDipatchExtension<Middlewares> }> {
    return ((create) => (initModules, moduleFetcher, preloadedState) => {
        const store = create(initModules, moduleFetcher, preloadedState);

        let dispatch: Dispatch = () => {
            throw new Error(
                "Dispatching while constructing your middleware is not allowed. " +
                    "Other middleware would not be applied to this dispatch.",
            );
        };

        const middlewareApi: DynamicMiddlewareApi<S> = {
            addModules: store.addModules,
            removeModules: store.removeModules,
            toggleModule: store.toggleModule,
            getState: store.getState as () => S,
            dispatch: (action, ...args) => dispatch(action, ...args),
        };
        const chain = middlewares.map((middleware) =>
            middleware(middlewareApi),
        );
        dispatch = compose<typeof dispatch>(...chain)(store.dispatch);

        store.dispatch = dispatch;

        return store;
    }) as DynamicStoreEnhancer<{
        dispatch: ExtractDipatchExtension<Middlewares>;
    }>;
}

export function composeEnhancers(
    ...enhancers: DynamicStoreEnhancer<any>[]
): DynamicStoreEnhancer<any> {
    return (create) =>
        compose<DynamicStoreEnhancerStoreCreator>(...enhancers)(create);
}
