export interface Route {
    params?: Record<string, string>;
    queries?: Record<string, string | string[]>;
    body?: Record<string, any>;
    response?: Record<"success" | "failure", any>;
}

export type UseRoute<R extends Route> = R;
export type UseRouteAPI<R extends Partial<Record<"GET" | "POST", Route>>> = R;

export type ParseRouteParams<R extends Route> = NonNullable<R["params"]>;
export type ParseRouteSearchParams<R extends Route> = NonNullable<R["queries"]>;

export enum routeNames {
    APP_ACCOUNTS_PAYLOADS = "/app/accounts/payloads",
    APP_ANALYTICS = "/app/analytics",
    APP_COMPANY_DETAILS = "/app/companies/:key",
    APP_CONSUMERS = "/app/consumers",
    APP_CONSUMERS_DETAILS = "/app/consumers/:key",
    APP_WALLET = "/app/wallets",
    APP_HOME = "/app/main",
    APP_OFFERS = "/app/offers",
    APP_OFFERS_DETAILS = "/app/offers/:key",
    APP_ORDERS = "/app/orders",
    APP_ORDERS_DETAILS = "/app/orders/:key",
    APP_POLICIES_TERMS_OF_USE = "/app/terms-of-use",
    APP_PRODUCT_DETAILS = "/app/catalog/:key",
    APP_ROOT = "/app",
    APP_SKUS = "/app/catalog",
    APP_SKUS_PURCHASE_DETAILS = "/app/purchases/:key",

    LOGIN = "/signin",
    LOGIN_REDIRECT = "/signin-redirect",
    PASSWORD_FORGOT = "/password-forgot",
    PASSWORD_RESET = "/password-reset",
    SIGNUP = "/signup",
}

export interface Routes {
    [routeNames.APP_ANALYTICS]: UseRoute<Record<string, never>>;
    [routeNames.APP_COMPANY_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_CONSUMERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_CONSUMERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_HOME]: UseRoute<Record<string, never>>;
    [routeNames.APP_OFFERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_OFFERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_ORDERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_ORDERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_PRODUCT_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_ROOT]: UseRoute<Record<string, never>>;
    [routeNames.APP_SKUS]: UseRoute<Record<string, never>>;
    [routeNames.APP_SKUS_PURCHASE_DETAILS]: UseRoute<{
        params: { key: string };
    }>;

    [routeNames.LOGIN]: UseRoute<Record<string, never>>;
    [routeNames.LOGIN_REDIRECT]: UseRoute<Record<string, never>>;
    [routeNames.PASSWORD_FORGOT]: UseRoute<Record<string, never>>;
    [routeNames.PASSWORD_RESET]: UseRoute<Record<string, never>>;
    [routeNames.SIGNUP]: UseRoute<{
        queries: {
            id?: string;
        };
    }>;
}
