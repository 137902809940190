export function ModalSize(size, height) {
    var widthModal, heightModal;

    const { innerHeight, innerWidth } = window;

    switch (size) {
        case "small":
            if (innerWidth < 576) {
                widthModal = (innerWidth / 100) * 95;
            } else if (innerWidth > 576 && innerWidth < 768) {
                widthModal = (innerWidth / 100) * 85;
            } else if (innerWidth > 768 && innerWidth < 992) {
                widthModal = (innerWidth / 100) * 65;
            } else if (innerWidth > 992 && innerWidth < 1200) {
                widthModal = (innerWidth / 100) * 45;
            } else if (innerWidth > 1200 && innerWidth < 1600) {
                widthModal = (innerWidth / 100) * 35;
            } else {
                widthModal = (innerWidth / 100) * 35;
            }

            break;

        case "medium":
            if (innerWidth < 576) {
                widthModal = (innerWidth / 100) * 95;
            } else if (innerWidth > 576 && innerWidth < 768) {
                widthModal = (innerWidth / 100) * 85;
            } else if (innerWidth > 768 && innerWidth < 992) {
                widthModal = (innerWidth / 100) * 75;
            } else if (innerWidth > 992 && innerWidth < 1200) {
                widthModal = (innerWidth / 100) * 65;
            } else if (innerWidth > 1200 && innerWidth < 1600) {
                widthModal = (innerWidth / 100) * 55;
            } else {
                widthModal = (innerWidth / 100) * 45;
            }

            break;

        default:
            widthModal = (innerWidth / 100) * 95;
            break;
    }

    if (innerHeight <= 568) {
        heightModal =
            !size || size === "large"
                ? (innerHeight / 100) * 73
                : size === "medium"
                ? (innerHeight / 100) * 73
                : 300;
        height = heightModal;
    } else if (innerHeight > 568 && innerHeight <= 667) {
        heightModal =
            !size || size === "large"
                ? (innerHeight / 100) * 75
                : size === "medium"
                ? (innerHeight / 100) * 63
                : 150;
    } else if (innerHeight > 667 && innerHeight <= 768) {
        heightModal =
            !size || size === "large"
                ? (innerHeight / 100) * 80
                : size === "medium"
                ? (innerHeight / 100) * 53
                : 150;
    } else {
        heightModal =
            !size || size === "large"
                ? (innerHeight / 100) * 80
                : size === "medium"
                ? 400
                : 150;
    }

    heightModal = height ? height : heightModal;

    return {
        width: widthModal,
        height: heightModal,
    };
}
