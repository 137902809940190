import { INIT_URL, SWITCH_LANGUAGE } from "../../constants/ActionTypes";
import {
    LAYOUT_TYPE,
    LAYOUT_TYPE_FULL,
    NAV_STYLE,
    NAV_STYLE_FIXED,
    THEME_COLOR,
    THEME_TYPE,
    THEME_TYPE_LITE,
    UPDATE_RTL_STATUS,
} from "../../constants/ThemeSetting";

const thema = localStorage.getItem("THEME");
const color = localStorage.getItem("THEME_COLOR");
const style = localStorage.getItem("THEME_STYLE");
const type = localStorage.getItem("THEME_TYPE");

const initialSettings = {
    navStyle:
        style === "" || style === undefined || style === null
            ? NAV_STYLE_FIXED
            : style,
    layoutType:
        type === "" || type === undefined || type === null
            ? LAYOUT_TYPE_FULL
            : type,
    themeType:
        thema === "" || thema === undefined || thema === null
            ? THEME_TYPE_LITE
            : thema,
    themeColor:
        color === "" || color === undefined || color === null ? "" : color,

    isDirectionRTL: false,
    locale: {
        languageId: "portuguese",
        locale: "pt",
        name: "Português",
        icon: "br",
    },
    initURL: "",
};

const SettingsReducer = (state = initialSettings, action) => {
    switch (action.type) {
        case THEME_TYPE:
            return {
                ...state,
                themeType: action.themeType,
            };
        case THEME_COLOR:
            return {
                ...state,
                themeColor: action.themeColor,
            };

        case UPDATE_RTL_STATUS:
            return {
                ...state,
                isDirectionRTL: action.rtlStatus,
            };

        case NAV_STYLE:
            return {
                ...state,
                navStyle: action.navStyle,
            };
        case LAYOUT_TYPE:
            return {
                ...state,
                layoutType: action.layoutType,
            };

        case SWITCH_LANGUAGE:
            return {
                ...state,
                locale: action.payload,
            };

        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload,
            };
        }
        default:
            return state;
    }
};

export default SettingsReducer;
