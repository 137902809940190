import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "MoreVerticalOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 9.25 5 C 9.25 3.48 10.48 2.25 12 2.25 C 13.52 2.25 14.75 3.48 14.75 5 C 14.75 6.52 13.52 7.75 12 7.75 C 10.48 7.75 9.25 6.52 9.25 5 Z M 13.25 5 C 13.25 4.31 12.69 3.75 12 3.75 C 11.31 3.75 10.75 4.31 10.75 5 C 10.75 5.69 11.31 6.25 12 6.25 C 12.69 6.25 13.25 5.69 13.25 5 Z"
                fill="currentColor"
            />
            <path
                d="M 9.25 19 C 9.25 17.48 10.48 16.25 12 16.25 C 13.52 16.25 14.75 17.48 14.75 19 C 14.75 20.52 13.52 21.75 12 21.75 C 10.48 21.75 9.25 20.52 9.25 19 Z M 13.25 19 C 13.25 18.31 12.69 17.75 12 17.75 C 11.31 17.75 10.75 18.31 10.75 19 C 10.75 19.69 11.31 20.25 12 20.25 C 12.69 20.25 13.25 19.69 13.25 19 Z"
                fill="currentColor"
            />
            <path
                d="M 9.25 12 C 9.25 10.48 10.48 9.25 12 9.25 C 13.52 9.25 14.75 10.48 14.75 12 C 14.75 13.52 13.52 14.75 12 14.75 C 10.48 14.75 9.25 13.52 9.25 12 Z M 13.25 12 C 13.25 11.31 12.69 10.75 12 10.75 C 11.31 10.75 10.75 11.31 10.75 12 C 10.75 12.69 11.31 13.25 12 13.25 C 12.69 13.25 13.25 12.69 13.25 12 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
