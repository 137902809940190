import type { FC } from "react";

import { useEffect } from "react";

import { useModalManager } from "@contexts/modal-manager-v2";
import { ModalCommonTags } from "@contexts/modal-manager-v2/relations";

import { useOnboarding } from "@contexts/onboarding";
import { OnboardingChecks } from "@contexts/onboarding/interface";

import { useSession } from "@contexts/session";

const OnboardingTermsOfUseTrigger: FC = () => {
    const {
        data: { isAdmin },
    } = useSession();

    const { set } = useModalManager({});

    const { checks } = useOnboarding();

    useEffect(() => {
        if (typeof isAdmin === "undefined" || isAdmin || !checks) return;
        const confirmation = checks.includes(OnboardingChecks.ACCEPT_TERMS);
        if (!confirmation) {
            set([{ tag: ModalCommonTags.ONBOARDING_TERMS_OF_USE }]);
        }
    }, [isAdmin, checks, set]);

    return null;
};

export default OnboardingTermsOfUseTrigger;
