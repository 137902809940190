import type { CompanyIntegrationParameter } from "./interface";

import type { RequestOptions } from "../../../interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

export default async function getCompanyIntegrationParams(
    companyIntegrationId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<CompanyIntegrationParameter[]> {
    try {
        const { data } = await lambdas.get<CompanyIntegrationParameter[]>(
            `/company/integration/${companyIntegrationId}/parameter`,
            { baseURL: mountBaseUrl(accountName), signal },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
