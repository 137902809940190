import type { FC, ReactNode } from "react";

import { usePopoverContext } from "./helpers";

import ButtonIcon from "../ButtonIcon";
import { ReactComponent as CloseIcon } from "../Icons/mocks/close/linear.svg";

import { classes } from "@utils/styles/tailwind/v4";

interface PopoverHeaderProps {
    className?: string;
    children?: ReactNode;
    hideButton?: boolean;
}

const PopoverHeader: FC<PopoverHeaderProps> = ({
    className,
    hideButton,
    children,
}) => {
    const { onClose } = usePopoverContext();
    return (
        <header className={classes`relative px-16 pt-16 pb-10 ${className}`}>
            {!hideButton && (
                <ButtonIcon
                    variant="ghost"
                    size="sm"
                    icon={<CloseIcon role="img" className="text-black/20" />}
                    label="close-drawer"
                    title="Fechar"
                    className="absolute top-8 right-8 hover:bg-transparent"
                    onClick={onClose}
                />
            )}
            {children}
        </header>
    );
};

export default PopoverHeader;
