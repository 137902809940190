import ImportIcon from "./linear";

export default ImportIcon;
export { default as ImportIcon, iconInfo as ImportIconInfo } from "./linear";

export {
    default as ImportBoldIcon,
    iconInfo as ImportBoldIconInfo,
} from "./bold";
export {
    default as ImportBrokenIcon,
    iconInfo as ImportBrokenIconInfo,
} from "./broken";
export {
    default as ImportBulkIcon,
    iconInfo as ImportBulkIconInfo,
} from "./bulk";
export {
    default as ImportLinearIcon,
    iconInfo as ImportLinearIconInfo,
} from "./linear";
export {
    default as ImportOutlineIcon,
    iconInfo as ImportOutlineIconInfo,
} from "./outline";
export {
    default as ImportTwotoneIcon,
    iconInfo as ImportTwotoneIconInfo,
} from "./twotone";
