import type { SkuImage } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas from "@old/configs/rest/lambdas";

export default async function getSkuImages(
    skuId: string | number,
    { signal }: RequestOptions = {},
): Promise<SkuImage[]> {
    try {
        const { data } = await lambdas.get<SkuImage[]>(`/sku/image/${skuId}`, {
            signal,
        });
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
