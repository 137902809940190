import type { HubQuestionAnswerTemplate } from "./interface";

import type { RequestOptions } from "../../interface";

import lambdas from "@old/configs/rest/lambdas";

interface GetHubQuestionAnswerTemplatesParams {
    AnswerType: 1 | 2;
}

export default async function getHubQuestionAnswerTemplates(
    params: GetHubQuestionAnswerTemplatesParams,
    { signal }: RequestOptions = {},
): Promise<HubQuestionAnswerTemplate[]> {
    try {
        const { data } = await lambdas.get<HubQuestionAnswerTemplate[]>(
            "/hub/product/question-answer-template",
            { signal, params },
        );
        return data;
    } catch (error) {
        console.log(error);
        return [];
    }
}
