import MessageIcon from "./linear";

export default MessageIcon;
export { default as MessageIcon, iconInfo as MessageIconInfo } from "./linear";

export {
    default as MessageBoldIcon,
    iconInfo as MessageBoldIconInfo,
} from "./bold";
export {
    default as MessageBrokenIcon,
    iconInfo as MessageBrokenIconInfo,
} from "./broken";
export {
    default as MessageBulkIcon,
    iconInfo as MessageBulkIconInfo,
} from "./bulk";
export {
    default as MessageLinearIcon,
    iconInfo as MessageLinearIconInfo,
} from "./linear";
export {
    default as MessageOutlineIcon,
    iconInfo as MessageOutlineIconInfo,
} from "./outline";
export {
    default as MessageTwotoneIcon,
    iconInfo as MessageTwotoneIconInfo,
} from "./twotone";
