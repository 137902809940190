import ProfileIcon from "./linear";

export default ProfileIcon;
export { default as ProfileIcon, iconInfo as ProfileIconInfo } from "./linear";

export {
    default as ProfileBoldIcon,
    iconInfo as ProfileBoldIconInfo,
} from "./bold";
export {
    default as ProfileBrokenIcon,
    iconInfo as ProfileBrokenIconInfo,
} from "./broken";
export {
    default as ProfileBulkIcon,
    iconInfo as ProfileBulkIconInfo,
} from "./bulk";
export {
    default as ProfileLinearIcon,
    iconInfo as ProfileLinearIconInfo,
} from "./linear";
export {
    default as ProfileOutlineIcon,
    iconInfo as ProfileOutlineIconInfo,
} from "./outline";
export {
    default as ProfileTwotoneIcon,
    iconInfo as ProfileTwotoneIconInfo,
} from "./twotone";
