import EditIcon from "./linear";

export default EditIcon;
export { default as EditIcon, iconInfo as EditIconInfo } from "./linear";

export { default as EditBoldIcon, iconInfo as EditBoldIconInfo } from "./bold";
export {
    default as EditBrokenIcon,
    iconInfo as EditBrokenIconInfo,
} from "./broken";
export { default as EditBulkIcon, iconInfo as EditBulkIconInfo } from "./bulk";
export {
    default as EditLinearIcon,
    iconInfo as EditLinearIconInfo,
} from "./linear";
export {
    default as EditOutlineIcon,
    iconInfo as EditOutlineIconInfo,
} from "./outline";
export {
    default as EditTwotoneIcon,
    iconInfo as EditTwotoneIconInfo,
} from "./twotone";
