import type { TailwindPattern } from "../interface";

const interactivityPattern: TailwindPattern = {
    base: /accent|appearance|cursor|caret|pointer-events|resize|touch|select|will-change/,
    variants: [
        { base: /accent/, arbitrary: "any" },
        /appearance/,
        { base: /cursor/, arbitrary: "any" },
        { base: /caret/, arbitrary: "any" },
        /pointer-events/,
        /resize/,
        /touch/,
        /select/,
        { base: /will-change/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default interactivityPattern;

export const interactivitySample = [
    [
        "accent-inherit",
        "accent-current",
        "accent-transparent",
        "accent-white",
        "accent-red-300",
        "accent-white/10",
        "accent-red-300/15",
        "accent-[#ffffff]",
        "accent-[#ffffff]/30",
    ],
    ["appearance-none", "appearance-auto"],
    [
        "cursor-auto",
        "cursor-default",
        "cursor-pointer",
        "cursor-wait",
        "cursor-text",
        "cursor-move",
        "cursor-help",
        "cursor-not-allowed",
        "cursor-none",
        "cursor-context-menu",
        "cursor-progress",
        "cursor-cell",
        "cursor-crosshair",
        "cursor-vertical-text",
        "cursor-alias",
        "cursor-copy",
        "cursor-no-drop",
        "cursor-grab",
        "cursor-grabbing",
        "cursor-all-scroll",
        "cursor-col-resize",
        "cursor-row-resize",
        "cursor-n-resize",
        "cursor-e-resize",
        "cursor-s-resize",
        "cursor-w-resize",
        "cursor-ne-resize",
        "cursor-nw-resize",
        "cursor-se-resize",
        "cursor-sw-resize",
        "cursor-ew-reisze",
        "cursor-ns-resize",
        "cursor-nesw-resize",
        "cursor-nwse-resize",
        "cursor-zoom-in",
        "cursor-zomm-out",
        "cursor-[url(hand.cur),_pointer]",
    ],
    [
        "caret-inherit",
        "caret-current",
        "caret-transparent",
        "caret-white",
        "caret-red-300",
        "caret-white/10",
        "caret-red-300/15",
        "caret-[#ffffff]",
        "caret-[#ffffff]/30",
    ],
    ["pointer-events-none", "pointer-events-auto"],
    ["resize", "resize-none", "resize-x", "resize-y"],
    [
        "touch-auto",
        "touch-none",
        "touch-pan-x",
        "touch-pan-y",
        "touch-pan-left",
        "touch-pan-right",
        "touch-pan-up",
        "touch-pan-down",
        "touch-pinch-zoom",
        "touch-manipulation",
    ],
    ["select-none", "select-text", "select-all", "select-auto"],
    [
        "will-change-auto",
        "will-change-scroll",
        "will-change-contents",
        "will-change-transform",
        "will-change-[top,left]",
    ],
];
