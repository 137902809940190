import type { Action } from "redux";
import type { ThunkDispatch } from "redux-thunk";

import {
    useDispatch as _useDispatch,
    useSelector as _useSelector,
    useStore as _useStore,
} from "react-redux";

import type { DynamicStore } from "./utils/store/interface";

import type { RootState } from "./modules";

export type Dispatch = ThunkDispatch<RootState, void, Action>;

export function useDispatch(): Dispatch {
    return _useDispatch();
}

export function useSelector<S extends RootState & { [k: string]: any }, R>(
    cb: (state: S) => R,
): R {
    return _useSelector(cb);
}

export function useStore(): DynamicStore<
    RootState,
    Action,
    { dispatch: Dispatch }
> {
    return _useStore() as DynamicStore<
        RootState,
        Action,
        { dispatch: Dispatch }
    >;
}

export * from "./utils/hooks";
