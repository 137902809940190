import type {
    OnboardingRequestResponse,
    OnboardingStatusInfo,
} from "./interface";

import franchise from "@configs/rest/franchise";

export default async function getOnboardingStatus(
    accountId: number,
): Promise<OnboardingStatusInfo | null> {
    try {
        const { data } = await franchise.get<OnboardingRequestResponse>(
            `/v1/companies/${accountId}/onboarding`,
        );
        return data.company.onboarding;
    } catch (error) {
        console.log(error);
        return null;
    }
}
