import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowUpDownTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <g opacity="0.4">
                <path
                    d="M10.4498 6.71997L6.72974 3L3.00977 6.71997"
                    stroke="currentColor"
                />
                <path d="M6.73047 21V3" stroke="currentColor" />
            </g>
            <path
                d="M13.5508 17.2803L17.2708 21.0002L20.9908 17.2803"
                stroke="currentColor"
            />
            <path d="M17.2695 3V21" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
