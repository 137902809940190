import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "GlobalBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M7.65055 20.9098C7.62055 20.9098 7.58055 20.9298 7.55055 20.9298C5.61055 19.9698 4.03055 18.3798 3.06055 16.4398C3.06055 16.4098 3.08055 16.3698 3.08055 16.3398C4.30055 16.6998 5.56055 16.9698 6.81055 17.1798C7.03055 18.4398 7.29055 19.6898 7.65055 20.9098Z"
                fill="currentColor"
            />
            <path
                opacity="0.4"
                d="M20.9391 16.4498C19.9491 18.4398 18.2991 20.0498 16.2891 21.0198C16.6691 19.7498 16.9891 18.4698 17.1991 17.1798C18.4591 16.9698 19.6991 16.6998 20.9191 16.3398C20.9091 16.3798 20.9391 16.4198 20.9391 16.4498Z"
                fill="currentColor"
            />
            <path
                opacity="0.4"
                d="M21.0191 7.70998C19.7591 7.32998 18.4891 7.01998 17.1991 6.79998C16.9891 5.50998 16.6791 4.22998 16.2891 2.97998C18.3591 3.96998 20.0291 5.63998 21.0191 7.70998Z"
                fill="currentColor"
            />
            <path
                opacity="0.4"
                d="M7.65047 3.09006C7.29047 4.31006 7.03047 5.55006 6.82047 6.81006C5.53047 7.01006 4.25047 7.33006 2.98047 7.71006C3.95047 5.70006 5.56047 4.05006 7.55047 3.06006C7.58047 3.06006 7.62047 3.09006 7.65047 3.09006Z"
                fill="currentColor"
            />
            <path
                d="M15.4898 6.59C13.1698 6.33 10.8298 6.33 8.50977 6.59C8.75977 5.22 9.07977 3.85 9.52977 2.53C9.54977 2.45 9.53977 2.39 9.54977 2.31C10.3398 2.12 11.1498 2 11.9998 2C12.8398 2 13.6598 2.12 14.4398 2.31C14.4498 2.39 14.4498 2.45 14.4698 2.53C14.9198 3.86 15.2398 5.22 15.4898 6.59Z"
                fill="currentColor"
            />
            <path
                d="M6.59 15.4898C5.21 15.2398 3.85 14.9198 2.53 14.4698C2.45 14.4498 2.39 14.4598 2.31 14.4498C2.12 13.6598 2 12.8498 2 11.9998C2 11.1598 2.12 10.3398 2.31 9.55977C2.39 9.54977 2.45 9.54977 2.53 9.52977C3.86 9.08977 5.21 8.75977 6.59 8.50977C6.34 10.8298 6.34 13.1698 6.59 15.4898Z"
                fill="currentColor"
            />
            <path
                d="M22.0002 11.9998C22.0002 12.8498 21.8802 13.6598 21.6902 14.4498C21.6102 14.4598 21.5502 14.4498 21.4702 14.4698C20.1402 14.9098 18.7802 15.2398 17.4102 15.4898C17.6702 13.1698 17.6702 10.8298 17.4102 8.50977C18.7802 8.75977 20.1502 9.07977 21.4702 9.52977C21.5502 9.54977 21.6102 9.55977 21.6902 9.55977C21.8802 10.3498 22.0002 11.1598 22.0002 11.9998Z"
                fill="currentColor"
            />
            <path
                d="M15.4898 17.4102C15.2398 18.7902 14.9198 20.1502 14.4698 21.4702C14.4498 21.5502 14.4498 21.6102 14.4398 21.6902C13.6598 21.8802 12.8398 22.0002 11.9998 22.0002C11.1498 22.0002 10.3398 21.8802 9.54977 21.6902C9.53977 21.6102 9.54977 21.5502 9.52977 21.4702C9.08977 20.1402 8.75977 18.7902 8.50977 17.4102C9.66977 17.5402 10.8298 17.6302 11.9998 17.6302C13.1698 17.6302 14.3398 17.5402 15.4898 17.4102Z"
                fill="currentColor"
            />
            <path
                d="M15.7633 15.7633C13.2622 16.0789 10.7378 16.0789 8.23667 15.7633C7.92111 13.2622 7.92111 10.7378 8.23667 8.23667C10.7378 7.92111 13.2622 7.92111 15.7633 8.23667C16.0789 10.7378 16.0789 13.2622 15.7633 15.7633Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
