import type { FC, ReactNode } from "react";

import { useModalContext } from "./helpers";

import ButtonIcon from "../ButtonIcon";
import { CloseIconInfo } from "../Icons/Mocks/close";

import { classes } from "@utils/styles/tailwind/v4";

interface ModalHeaderProps {
    buttonStyle?: string;
    children?: ReactNode;
    className?: string;
    hideButton?: boolean;
    iconStyle?: string;
}

const ModalHeader: FC<ModalHeaderProps> = ({
    hideButton,
    className,
    buttonStyle = "",
    iconStyle = "",
    children,
}) => {
    const { aria, onClose } = useModalContext();
    return (
        <div id={aria.label} className={classes`relative p-16 ${className}`}>
            {!hideButton && (
                <ButtonIcon
                    variant="ghost"
                    size="md"
                    icon={CloseIconInfo}
                    label="close-drawer"
                    title="Fechar"
                    iconStyle={`text-inherit ${iconStyle}`}
                    className={`absolute top-16 right-16 hover:bg-transparent text-inherit ${buttonStyle}`}
                    onClick={onClose}
                />
            )}
            {children}
        </div>
    );
};

export default ModalHeader;
