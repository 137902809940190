import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronLeftBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M 7.7702 8.52 L 10.85 10.8314 C 10.6002 11.7667 10.3002 12.7667 11.8002 14.2667 L 12.8202 15.2788 V 17.92 C 12.8202 18.88 11.6602 19.36 10.9802 18.68 L 5.8002 13.51 C 4.9702 12.68 4.9702 11.33 5.8002 10.5 L 7.7702 8.52 Z"
                fill="currentColor"
            />
            <path
                d="M 12.8195 6.0799 V 8.7312 L 11.8022 9.7433 C 11.5022 10.0433 11.15 10.3314 10.85 10.8314 L 7.7695 8.5199 L 10.9795 5.3099 C 11.6595 4.6399 12.8195 5.1199 12.8195 6.0799 Z"
                fill="currentColor"
            />

            <path
                opacity="0.4"
                d="M 13.7702 8.52 L 18.8202 12.31 V 17.92 C 18.8202 18.88 17.6602 19.36 16.9802 18.68 L 11.8002 13.51 C 10.9702 12.68 10.9702 11.33 11.8002 10.5 L 13.7702 8.52 Z"
                fill="currentColor"
            />
            <path
                d="M 18.8195 6.0799 V 12.3099 L 13.7695 8.5199 L 16.9795 5.3099 C 17.6595 4.6399 18.8195 5.1199 18.8195 6.0799 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
