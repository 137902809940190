import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "MoreVerticalBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M 22 16.19 V 7.81 C 22 4.17 19.83 2 16.19 2 H 7.82 C 4.17 2 2 4.17 2 7.81 V 16.18 C 2 19.82 4.17 21.99 7.81 21.99 H 16.19 C 19.83 22 22 19.83 22 16.19 Z"
                fill="currentColor"
            />
            <path
                d="M 13.3101 11.9995 C 13.3101 11.2795 12.7201 10.6895 12.0001 10.6895 C 11.2801 10.6895 10.6901 11.2795 10.6901 11.9995 C 10.6901 12.7195 11.2801 13.3095 12.0001 13.3095 C 12.7201 13.3095 13.3101 12.7195 13.3101 11.9995 Z"
                fill="currentColor"
            />
            <path
                d="M 13.3101 6.9995 C 13.3101 6.2794 12.7201 5.6894 12.0001 5.6894 C 11.2801 5.6894 10.6901 6.2794 10.6901 6.9995 C 10.6901 7.7195 11.2801 8.3095 12.0001 8.3095 C 12.7201 8.3095 13.3101 7.7195 13.3101 6.9995 Z"
                fill="currentColor"
            />
            <path
                d="M 13.3101 16.9995 C 13.3101 16.2795 12.7201 15.6895 12.0001 15.6895 C 11.2801 15.6895 10.6901 16.2795 10.6901 16.9995 C 10.6901 17.7195 11.2801 18.3095 12.0001 18.3095 C 12.7201 18.3095 13.3101 17.7195 13.3101 16.9995 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
