import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "MoreVerticalLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 14 5 C 14 3.9 13.1 3 12 3 C 10.9 3 10 3.9 10 5 C 10 6.1 10.9 7 12 7 C 13.1 7 14 6.1 14 5 Z"
                stroke="currentColor"
            />
            <path
                d="M 14 19 C 14 17.9 13.1 17 12 17 C 10.9 17 10 17.9 10 19 C 10 20.1 10.9 21 12 21 C 13.1 21 14 20.1 14 19 Z"
                stroke="currentColor"
            />
            <path
                d="M 14 12 C 14 10.9 13.1 10 12 10 C 10.9 10 10 10.9 10 12 C 10 13.1 10.9 14 12 14 C 13.1 14 14 13.1 14 12 Z"
                stroke="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
