import type { Action } from "redux";
import { createTransform, REHYDRATE } from "redux-persist";
import type {
    PersistorAction,
    Transform as BaseTransform,
} from "redux-persist";

import type {
    PersistConfigs,
    PersistedObject,
    PersistentState,
    Transform,
} from "./interface";

export function getPathBases(paths: string[][], whitelist = true) {
    return new Set(
        paths
            .filter((path) =>
                whitelist ? path.length >= 1 : path.length === 1,
            )
            .map((path) => path[0]),
    );
}

export function filterPaths(base: string, paths: string[][]) {
    return paths
        .filter((path) => path[0] === base && path.length > 1)
        .map((path) => path.slice(1));
}

export function serializeState(
    state: unknown,
    paths: string[][],
    whitelist = true,
) {
    if (typeof state !== "object" || state === null) return state;

    const bases = getPathBases(paths, whitelist);
    const keys = Object.getOwnPropertyNames(state);

    const temp: PersistedObject = {
        _isArray: Array.isArray(state),
    };

    for (const key of keys) {
        if (temp._isArray && key === "length") {
            temp[key] = state[key as keyof typeof state];
            continue;
        }

        if (
            !bases.size ||
            (whitelist && bases.has(key)) ||
            (!whitelist && !bases.has(key))
        ) {
            const parsedPaths = filterPaths(key, paths);
            temp[key] = serializeState(
                state[key as keyof typeof state],
                parsedPaths,
                whitelist,
            );
        }
    }

    return temp;
}

export function deserializeState(state: unknown): any {
    if (typeof state !== "object" || state === null) return state;

    const { _isArray, ...rest } = state as PersistedObject;

    if (_isArray) {
        return Array.from(rest as Record<"length" | number, any>, (el) =>
            deserializeState(el),
        );
    }

    const temp: Record<string, any> = {};
    const keys = Object.getOwnPropertyNames(rest);
    for (const key of keys) {
        temp[key] = deserializeState(rest[key as keyof typeof rest]);
    }

    return temp;
}

interface TransformStateConfigs {
    inbound?: boolean;
    whitelist?: string[][];
    blacklist?: string[][];
}

export function parseState(
    ref: unknown,
    key: string | number | symbol,
    { inbound = true, whitelist, blacklist }: TransformStateConfigs,
) {
    if (typeof key !== "string" || (!whitelist && !blacklist)) return ref;

    if (inbound) {
        const list = whitelist ?? blacklist ?? [];
        const bases = getPathBases(list, !!whitelist);

        if ((whitelist && !bases.has(key)) || (!whitelist && bases.has(key)))
            return;

        const paths = filterPaths(key, list);

        return serializeState(ref, paths, !!whitelist);
    }

    return deserializeState(ref);
}

interface MergeStatesConfigs {
    debug?: boolean;
    whitelist?: string[][];
    blacklist?: string[][];
}

export function mergeStates(
    base: string,
    original: unknown,
    target: unknown,
    input: unknown,
    configs: MergeStatesConfigs,
) {
    const { blacklist, whitelist, debug } = configs;

    const isWhite = !!whitelist?.length;
    const list = (isWhite ? whitelist : blacklist) || [];
    const bases = getPathBases(list, isWhite);
    const pathsBL = base ? filterPaths(base, blacklist || []) : blacklist;
    const pathsWL = base ? filterPaths(base, whitelist || []) : whitelist;

    if (
        base &&
        ((isWhite && !bases.has(base)) || (!isWhite && bases.has(base)))
    ) {
        if (debug) console.log("return target");
        return target;
    }

    if (typeof target !== "object" || target === null) {
        if (typeof input !== "undefined") {
            if (debug) console.log("return input");
            return input;
        }
        if (debug) console.log("return target");
        return target;
    }

    if (typeof input === "undefined") {
        if (debug) console.log("return target");
        return target;
    }
    if (typeof input !== "object" || input === null) {
        if (debug) console.log("return input");
        return input;
    }

    if (Array.isArray(target) && Array.isArray(input)) {
        const tempOriginal = original as any[];

        if (!bases.size && !pathsBL?.length && !pathsWL?.length) {
            if (tempOriginal !== target) {
                if (debug) {
                    console.log(
                        "reducer changed at path:",
                        base,
                        ", skipping rehydration",
                    );
                }
                return target;
            }
            if (debug) console.log("return input");
            return input;
        }

        if (debug) console.log("merge");

        const merged: any[] = [...target];
        for (let i = 0; i < input.length; i++) {
            if (tempOriginal[i] !== target[i]) {
                if (debug) {
                    console.log(
                        "reducer changed at path:",
                        (base ? base + "." : "") + i,
                        ", skipping rehydration",
                    );
                }
                continue;
            }
            merged[i] = mergeStates(
                `${i}`,
                tempOriginal[i],
                target[i],
                input[i],
                {
                    debug,
                    blacklist: pathsBL,
                    whitelist: pathsWL,
                },
            );
        }

        if (debug) console.log("return merged");
        return merged;
    } else if (!Array.isArray(target) && !Array.isArray(input)) {
        const tempOriginal = original as object;

        if (debug) console.log("merge");

        const merged: Record<string, any> = { ...target };
        const keys = Object.getOwnPropertyNames(input);
        for (const key of keys) {
            if (key === "_persist") continue;
            if (
                tempOriginal[key as keyof typeof tempOriginal] !==
                target[key as keyof typeof target]
            ) {
                if (debug) {
                    console.log(
                        "reducer changed at path:",
                        (base ? base + "." : "") + key,
                        ", skipping rehydration",
                    );
                }
                continue;
            }
            merged[key] = mergeStates(
                key,
                tempOriginal[key as keyof typeof tempOriginal],
                target[key as keyof typeof target],
                input[key as keyof typeof input],
                {
                    debug,
                    blacklist: pathsBL,
                    whitelist: pathsWL,
                },
            );
        }

        if (debug) console.log("return merged");
        return merged;
    }

    if (debug) console.log("return input");
    return input;
}

export function checkConfigs({
    blacklist,
    whitelist,
}: Pick<PersistConfigs<any>, "blacklist" | "whitelist">) {
    if (blacklist?.length && whitelist?.length) {
        throw new Error(
            "Persistency configs with both blacklist and whitelist are not allowed",
        );
    }

    if (blacklist || whitelist) {
        const paths = blacklist || whitelist || [];

        const duplicates: Set<string> = new Set();
        const subsets: [string, string][] = [];
        for (const path of paths) {
            const count = paths.filter((p) => p === path).length;
            const sets = paths.filter(
                (p) => p.includes(path) && p.length > path.length,
            );
            if (count > 1) duplicates.add(path);
            if (sets.length > 0)
                subsets.push(...sets.map((p) => [path, p] as [string, string]));
        }
        if (duplicates.size) {
            throw new Error(
                `Persistency ${
                    blacklist ? "blacklist" : "whitelist"
                } with duplicated paths: ${[...duplicates].join(", ")}`,
            );
        }
        if (subsets.length) {
            throw new Error(
                `Persistency ${
                    blacklist ? "blacklist" : "whitelist"
                } with subsets paths: ${subsets
                    .map(([set, sub]) => `[${set},${sub}]`)
                    .join(", ")}`,
            );
        }
    }
}

export function hydrate<S extends Record<string, any>>(
    id: string,
): (state: PersistentState<S>, action: Action) => void {
    return (state, action) => {
        if (
            action.type === REHYDRATE &&
            (action as PersistorAction).key === id
        ) {
            state.hydrated = true;
        }
    };
}

interface ParseTransformersConfigs {
    whitelist?: string[][];
    blacklist?: string[][];
}

export function parseTransformers(
    transforms: Transform | undefined,
    { blacklist, whitelist }: ParseTransformersConfigs,
): BaseTransform<any, any> | undefined {
    if (!blacklist?.length && !whitelist?.length) {
        return (
            (transforms?.inbound || transforms?.outbound) &&
            createTransform(transforms.inbound, transforms.outbound)
        );
    }
    return createTransform(
        (inboundState, key) => {
            let state = inboundState;
            if (transforms?.inbound) {
                state = transforms.inbound(inboundState, key);
            }
            return parseState(state, key, {
                whitelist,
                blacklist,
            });
        },
        (outboundState, key) => {
            let state = parseState(outboundState, key, {
                inbound: false,
                whitelist,
                blacklist,
            });
            if (transforms?.outbound) {
                state = transforms.outbound(state, key);
            }
            return state;
        },
    );
}
