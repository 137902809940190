import BuildingsIcon from "./linear";

export default BuildingsIcon;
export {
    default as BuildingsIcon,
    iconInfo as BuildingsIconInfo,
} from "./linear";

export {
    default as BuildingsBoldIcon,
    iconInfo as BuildingsBoldIconInfo,
} from "./bold";
export {
    default as BuildingsBrokenIcon,
    iconInfo as BuildingsBrokenIconInfo,
} from "./broken";
export {
    default as BuildingsBulkIcon,
    iconInfo as BuildingsBulkIconInfo,
} from "./bulk";
export {
    default as BuildingsLinearIcon,
    iconInfo as BuildingsLinearIconInfo,
} from "./linear";
export {
    default as BuildingsOutlineIcon,
    iconInfo as BuildingsOutlineIconInfo,
} from "./outline";
export {
    default as BuildingsTwotoneIcon,
    iconInfo as BuildingsTwotoneIconInfo,
} from "./twotone";
