import type { FC } from "react";

import { classes } from "@old/utils/styles/tailwind/v3";

interface OnboardingProgressBarProps {
    current: number;
    steps: number;
    textStyle?: string;
}

const OnboardingProgressBar: FC<OnboardingProgressBarProps> = ({
    current,
    steps,
    textStyle = "",
}) => {
    const value = Math.max(0, Math.min(steps, current));
    const progress = (value / steps) * 100;
    return (
        <div>
            <div
                className={classes`flex justify-between pb-4 text-sm text-gray-700 ${textStyle}`}>
                <p className="m-0">
                    {value}/{steps}
                </p>
                <p className="m-0">{progress.toFixed(0)}%</p>
            </div>
            <div
                role="progressbar"
                className={`relative bg-lightGrey-600 h-12 rounded-4 overflow-auto`}>
                <div
                    className="absolute inset-y-0 left-0 bg-olive rounded-4"
                    style={{ width: `${progress.toFixed(2)}%` }}
                />
            </div>
        </div>
    );
};

export default OnboardingProgressBar;
