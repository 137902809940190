import type { TailwindPattern } from "../interface";

const transitionsPattern: TailwindPattern = {
    base: /transition|duration|delay|ease|animate/,
    variants: [
        { base: /transition/, arbitrary: "any" },
        { base: /duration/, arbitrary: "time" },
        { base: /delay/, arbitrary: "time" },
        { base: /ease/, arbitrary: "any" },
        { base: /animate/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default transitionsPattern;

export const transitionsSample = [
    [
        "transition",
        "transition-none",
        "transition-all",
        "transition-colors",
        "transition-opacity",
        "transition-shadow",
        "transition-transform",
        "transition-[height]",
    ],
    ["duration-150", "duration-[2000ms]"],
    ["delay-150", "delay-[2000ms]"],
    [
        "ease-linear",
        "ease-in",
        "ease-out",
        "ease-in-out",
        "ease-[cubic-bezier(0.95,0.05,0.795,0.035)]",
    ],
    [
        "animate-none",
        "animate-spin",
        "animate-ping",
        "animate-pulse",
        "animate-bounce",
        "animate-[wiggle_1s_ease-in-out_infinite]",
    ],
];
