export type ButtonVariants =
    | "solid"
    | "outlined"
    | "ghost"
    | "link"
    | "unstyled";
export type ButtonSizes = "sm" | "md" | "lg" | "unset";

export type ButtonGroupDirections = "horizontal" | "vertical";

export const buttonVariants: Record<ButtonVariants, string> = {
    solid: "bg-doladoBlue rounded-4 font-normal text-lightGrey hover:invert-20",
    outlined:
        "border border-grey-100 rounded-4 font-normal text-grey hover:invert-40",
    ghost: "bg-black/0 hover:bg-black/10 rounded-4 font-normal text-grey",
    link: "w-max h-max min-h-max rounded-4 p-0 text-grey hover:underline",
    unstyled: "",
};

export const buttonSizes: Record<ButtonSizes, string> = {
    lg: "min-h-40 px-16 py-4 text-sm",
    md: "min-h-32 px-16 py-2 text-sm",
    sm: "min-h-24 px-8 py-2 text-xs",
    unset: "",
};

export const buttonIconsSizes: Record<ButtonSizes, string> = {
    lg: "w-36 h-36",
    md: "w-24 h-24",
    sm: "w-16 h-16",
    unset: "",
};

export const buttonCommonStyle =
    "flex justify-center items-center disabled:bg-lightGrey-600 disabled:text-grey-400 disabled:hover:bg-lightGrey-600 disabled:hover:invert-0 disabled:hover:text-grey-400";

export const buttonGroupCommonStyle = "flex [&>*]:rounded-none";

export const buttonGroupDirectionStyles: Record<ButtonGroupDirections, string> =
    {
        horizontal:
            "flex-row [&>*:not(:first-child)]:border-l-0 [&>*:first-child]:rounded-l-4 [&>*:last-child]:rounded-r-4",
        vertical:
            "flex-col [&>*:not(:first-child)]:border-t-0 [&>*:first-child]:rounded-t-4 [&>*:last-child]:rounded-b-4",
    };
