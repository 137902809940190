import type { ComponentProps, FC, ReactNode } from "react";

import { useInputOverwrite } from "../utils";

import { classes } from "@utils/styles/tailwind/v3";

interface FormErrorMessageProps extends ComponentProps<"p"> {
    children?: ReactNode;
}

const FormErrorMessage: FC<FormErrorMessageProps> = ({
    className = "",
    children,
    ...props
}) => {
    const { isInvalid } = useInputOverwrite({});
    if (!isInvalid) return null;
    return (
        <p
            className={classes`text-red text-xs mt-8 mb-0 ${className}`}
            {...props}>
            {children}
        </p>
    );
};

export default FormErrorMessage;
