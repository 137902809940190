import DoubleChevronRightIcon from "./linear";

export default DoubleChevronRightIcon;
export {
    default as DoubleChevronRightIcon,
    iconInfo as DoubleChevronRightIconInfo,
} from "./linear";

export {
    default as DoubleChevronRightBoldIcon,
    iconInfo as DoubleChevronRightBoldIconInfo,
} from "./bold";
export {
    default as DoubleChevronRightBrokenIcon,
    iconInfo as DoubleChevronRightBrokenIconInfo,
} from "./broken";
export {
    default as DoubleChevronRightBulkIcon,
    iconInfo as DoubleChevronRightBulkIconInfo,
} from "./bulk";
export {
    default as DoubleChevronRightLinearIcon,
    iconInfo as DoubleChevronRightLinearIconInfo,
} from "./linear";
export {
    default as DoubleChevronRightOutlineIcon,
    iconInfo as DoubleChevronRightOutlineIconInfo,
} from "./outline";
export {
    default as DoubleChevronRightTwotoneIcon,
    iconInfo as DoubleChevronRightTwotoneIconInfo,
} from "./twotone";
