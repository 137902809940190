import type { FC } from "react";

import { Suspense, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { stepsRelation } from "./Steps";

import Loading from "@components/@basic/Loading";
import { useToast } from "@components/@basic/Toast";

import useTakeBlip from "@hooks/useTakeBlip";

import { withModule, useDispatch, useSelector } from "@redux";
import module, { actions, selectors } from "@redux/modules/login/signup";

import { ConsultantGatewayPgtoType } from "@services/supabase/consultants";

const SignUpPage: FC = () => {
    const { live, hydrated } = useSelector(selectors.getModuleStatus);
    const { pending, loading, failure, success, message } = useSelector(
        selectors.getStatus,
    );
    const { step } = useSelector(selectors.getFlowData);
    const consultant = useSelector(selectors.getConsultant);

    const [search] = useSearchParams();
    const consultantId = search.get("id") || "";
    const finished = !!search.get("finished");

    const dispatch = useDispatch();
    const toast = useToast();

    useTakeBlip(
        "Y2VudHJhbGRldmVuZGFzNTo0NGMzZTU5MC0zMWFhLTQxN2QtYmI1OS0xMmY1MGRjZjFmYWQ=",
    );

    useEffect(() => {
        if (!live || !hydrated || !pending) return;
        dispatch(actions.initialize({ consultantId, finished }));
    }, [live, hydrated, pending, consultantId, finished, dispatch]);

    useEffect(() => {
        if (!live || !hydrated || pending || !consultant?.gateway_pgto) return;
        if (
            consultant.gateway_pgto === ConsultantGatewayPgtoType.HOTMART &&
            typeof document !== "undefined"
        ) {
            const script = document.createElement("script");
            script.src =
                "https://checkout.hotmart.com/lib/hotmart-checkout-elements.js";
            document.head.appendChild(script);
        }
    }, [live, hydrated, pending, consultant?.gateway_pgto]);

    useEffect(() => {
        if (failure && message) {
            toast.dispatch({
                status: "warning",
                title: "Atenção",
                description: message,
                duration: 5000,
            });
        }

        if (success && message) {
            toast.dispatch({
                status: "success",
                title: message,
            });
        }
    }, [failure, success, message, toast]);

    if (pending || loading) {
        return (
            <div className="flex flex-col justify-center items-center w-full h-screen">
                <Loading />
            </div>
        );
    }

    const Step = step !== null && stepsRelation[step];
    return (
        <Suspense
            fallback={
                <div className="flex flex-col justify-center items-center w-full h-screen">
                    <Loading />
                </div>
            }>
            {Step && <Step />}
        </Suspense>
    );
};

export default withModule({ module })(SignUpPage);
