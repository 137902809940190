import type { MenuRelationGroup, MenuRelationItem } from "./interface";

import { MenuGroup } from "@old/services/accounts/interface";

import { padEnd } from "@utils/arrays/padding";

export const mockedMenuItems: MenuRelationGroup[] = [
    {
        key: 1,
        label: "",
        icon: "",
        children: [
            {
                key: "pricer",
                label: "Precificador",
                icon: ["calculator", "linear"],
                link: "pricer",
                index: 4,
                action: "append",
                isClientOnly: false,
            },
        ],
    },
    {
        key: 3,
        label: "",
        icon: "",
        children: [
            {
                key: "policies/terms-of-use",
                label: "Termos de Uso",
                icon: ["buildings-2", "linear"],
                link: "policies/terms-of-use",
                index: -1,
                action: "append",
            },
        ],
    },
    {
        key: 13,
        label: "",
        icon: "",
        children: [
            {
                key: "dash",
                label: "Página inicial",
                icon: ["home", "linear"],
                link: "dash",
                index: 0,
                action: "append",
            },
        ],
    },
];

export function parseMenuRelation(
    menuList: MenuGroup[],
    isAdmin: boolean,
): MenuRelationGroup[] {
    const relation = menuList.map<MenuRelationGroup>(
        ({ iconName, IDModuleGroup, Module, ModuleGroupDescription }) => ({
            key: IDModuleGroup,
            label: ModuleGroupDescription,
            icon: iconName || "",
            children: Module.map(({ header, icon, link }, index) => ({
                action: "append",
                icon: padEnd(
                    icon?.split(" ") ?? [],
                    2,
                    "",
                ) as MenuRelationItem["icon"],
                index,
                key: link,
                label: header,
                link,
            })),
        }),
    );

    mockedMenuItems.forEach(({ key, children }) => {
        const group = relation.find((grp) => grp.key === key);
        if (group) {
            children.forEach((item) => {
                if (
                    (isAdmin && item.isClientOnly) ||
                    (!isAdmin && item.isAdminOnly)
                )
                    return;
                const pos = group.children.findIndex(
                    (el) => el.index === item.index,
                );
                if (pos !== -1) {
                    group.children.splice(pos, 0, item);
                } else {
                    group.children.push(item);
                }
            });
        }
    });

    return relation;
}
