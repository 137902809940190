import ChevronCircleUpIcon from "./linear";

export default ChevronCircleUpIcon;
export {
    default as ChevronCircleUpIcon,
    iconInfo as ChevronCircleUpIconInfo,
} from "./linear";

export {
    default as ChevronCircleUpBoldIcon,
    iconInfo as ChevronCircleUpBoldIconInfo,
} from "./bold";
export {
    default as ChevronCircleUpBrokenIcon,
    iconInfo as ChevronCircleUpBrokenIconInfo,
} from "./broken";
export {
    default as ChevronCircleUpBulkIcon,
    iconInfo as ChevronCircleUpBulkIconInfo,
} from "./bulk";
export {
    default as ChevronCircleUpLinearIcon,
    iconInfo as ChevronCircleUpLinearIconInfo,
} from "./linear";
export {
    default as ChevronCircleUpOutlineIcon,
    iconInfo as ChevronCircleUpOutlineIconInfo,
} from "./outline";
export {
    default as ChevronCircleUpTwotoneIcon,
    iconInfo as ChevronCircleUpTwotoneIconInfo,
} from "./twotone";
