export function ModalSize(size, height) {
    var widthModal, heightModal;

    switch (size) {
        case "small":
            if (window.innerWidth < 576) {
                widthModal = (window.innerWidth / 100) * 95;
            } else if (window.innerWidth > 576 && window.innerWidth < 768) {
                widthModal = (window.innerWidth / 100) * 85;
            } else if (window.innerWidth > 768 && window.innerWidth < 992) {
                widthModal = (window.innerWidth / 100) * 65;
            } else if (window.innerWidth > 992 && window.innerWidth < 1200) {
                widthModal = (window.innerWidth / 100) * 45;
            } else if (window.innerWidth > 1200 && window.innerWidth < 1600) {
                widthModal = (window.innerWidth / 100) * 35;
            } else {
                widthModal = (window.innerWidth / 100) * 35;
            }

            break;

        case "medium":
            if (window.innerWidth < 576) {
                widthModal = (window.innerWidth / 100) * 95;
            } else if (window.innerWidth > 576 && window.innerWidth < 768) {
                widthModal = (window.innerWidth / 100) * 85;
            } else if (window.innerWidth > 768 && window.innerWidth < 992) {
                widthModal = (window.innerWidth / 100) * 75;
            } else if (window.innerWidth > 992 && window.innerWidth < 1200) {
                widthModal = (window.innerWidth / 100) * 65;
            } else if (window.innerWidth > 1200 && window.innerWidth < 1600) {
                widthModal = (window.innerWidth / 100) * 55;
            } else {
                widthModal = (window.innerWidth / 100) * 45;
            }

            break;

        default:
            widthModal = (window.innerWidth / 100) * 95;
            break;
    }

    if (window.innerHeight <= 568) {
        heightModal =
            !size || size === "large"
                ? (window.innerHeight / 100) * 73
                : size === "medium"
                ? (window.innerHeight / 100) * 73
                : 300;
        height = heightModal;
    } else if (window.innerHeight > 568 && window.innerHeight <= 667) {
        heightModal =
            !size || size === "large"
                ? (window.innerHeight / 100) * 75
                : size === "medium"
                ? (window.innerHeight / 100) * 63
                : 150;
    } else if (window.innerHeight > 667 && window.innerHeight <= 768) {
        heightModal =
            !size || size === "large"
                ? (window.innerHeight / 100) * 80
                : size === "medium"
                ? (window.innerHeight / 100) * 53
                : 150;
    } else {
        heightModal =
            !size || size === "large"
                ? (window.innerHeight / 100) * 80
                : size === "medium"
                ? 400
                : 150;
    }

    heightModal = height ? height : heightModal;

    return {
        width: widthModal,
        height: heightModal,
    };
}
