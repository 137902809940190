import type { FC } from "react";

import { useEffect, useLayoutEffect, useMemo, useRef } from "react";

import ToastCard from "./Card";

import { animateToastConfig, closeToastConfig } from "./helpers";
import type { AnimationStateData } from "./helpers";
import type { ToastData } from "./interface";

interface ToastContainerProps {
    data: ToastData;
    listLength: number;
    onClose?: (id: string) => void;
}

const ToastContainer: FC<ToastContainerProps> = ({
    data,
    listLength,
    onClose,
}) => {
    const container = useRef<HTMLDivElement>(null);

    const currentPos = useRef<[number, number]>([0, 0]);
    const parentHeight = useRef(0);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const closeToast = useMemo(
        () =>
            closeToastConfig(
                {
                    container: {
                        ref: container,
                        duration: 250,
                    },
                },
                () => onClose?.(data.id),
            ),
        [data.id, onClose],
    );

    const animateToast = useMemo(
        () =>
            animateToastConfig({
                container: {
                    ref: container,
                    duration: (isFirst) => (isFirst ? 150 : 300),
                },
            }),
        [],
    );

    useEffect(() => {
        timeoutRef.current = setTimeout(closeToast, data.duration ?? 3000);
        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [data.duration, closeToast]);

    useLayoutEffect(() => {
        animateToast({
            currentPos,
            parentHeight,
            position: data.position,
        } as AnimationStateData);
    }, [data.position, listLength, animateToast]);

    return (
        <div ref={container} className="p-4">
            {typeof data.render === "function"
                ? data.render(closeToast)
                : data.render || (
                      <ToastCard
                          className={data.className}
                          description={data.description}
                          isClosable={data.isClosable}
                          status={data.status}
                          title={data.title}
                          onClose={closeToast}
                      />
                  )}
        </div>
    );
};

export default ToastContainer;
