import CreditCardsIcon from "./linear";

export default CreditCardsIcon;
export {
    default as CreditCardsIcon,
    iconInfo as CreditCardsIconInfo,
} from "./linear";

export {
    default as CreditCardsBoldIcon,
    iconInfo as CreditCardsBoldIconInfo,
} from "./bold";
export {
    default as CreditCardsBrokenIcon,
    iconInfo as CreditCardsBrokenIconInfo,
} from "./broken";
export {
    default as CreditCardsBulkIcon,
    iconInfo as CreditCardsBulkIconInfo,
} from "./bulk";
export {
    default as CreditCardsLinearIcon,
    iconInfo as CreditCardsLinearIconInfo,
} from "./linear";
export {
    default as CreditCardsOutlineIcon,
    iconInfo as CreditCardsOutlineIconInfo,
} from "./outline";
export {
    default as CreditCardsTwotoneIcon,
    iconInfo as CreditCardsTwotoneIconInfo,
} from "./twotone";
