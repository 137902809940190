export default function Subdomain() {
    const overwrite = process.env.REACT_APP_SUBDOMAIN;

    let subdomain = overwrite || "";
    if (!overwrite && window.location.hostname !== "localhost") {
        const parts = window.location.hostname.split(".");
        if (parts.length > 1) subdomain = parts[0];
    }

    return subdomain;
}
