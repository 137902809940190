import type { MutableRefObject } from "react";

import { createContext, useContext } from "react";

import type { PopoverPositions, PopoverSizes } from "./interface";

export interface PopoverContext {
    anchorRef: MutableRefObject<HTMLElement | null>;
    contentRef: MutableRefObject<HTMLDivElement | null>;
    isOpen: boolean;
    onClose: () => void;
    position: PopoverPositions;
    size: PopoverSizes;
    triggerRef: MutableRefObject<HTMLElement | null>;
    usePortal: boolean;
}

export const PopoverContext = createContext<PopoverContext>({
    anchorRef: { current: null },
    contentRef: { current: null },
    isOpen: false,
    onClose: () => null,
    position: "bottom",
    size: "md",
    triggerRef: { current: null },
    usePortal: false,
});

export function usePopoverContext(): PopoverContext {
    return useContext(PopoverContext);
}
