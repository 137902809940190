import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronRightOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 12.0007 3.33 C 12.1907 3.33 12.3807 3.4 12.5307 3.55 L 19.0507 10.07 C 20.1107 11.13 20.1107 12.87 19.0507 13.93 L 12.5307 20.45 C 12.2407 20.74 11.7607 20.74 11.4707 20.45 C 11.1807 20.16 11.1807 19.68 11.4707 19.39 L 17.9907 12.87 C 18.4707 12.39 18.4707 11.61 17.9907 11.13 L 11.4707 4.61 C 11.1807 4.32 11.1807 3.84 11.4707 3.55 C 11.6207 3.41 11.8107 3.33 12.0007 3.33 Z"
                fill="currentColor"
            />
            <path
                d="M 6.0007 3.33 C 6.1907 3.33 6.3807 3.4 6.5307 3.55 L 13.0507 10.07 C 14.1107 11.13 14.1107 12.87 13.0507 13.93 L 6.5307 20.45 C 6.2407 20.74 5.7607 20.74 5.4707 20.45 C 5.1807 20.16 5.1807 19.68 5.4707 19.39 L 11.9907 12.87 C 12.4707 12.39 12.4707 11.61 11.9907 11.13 L 5.4707 4.61 C 5.1807 4.32 5.1807 3.84 5.4707 3.55 C 5.6207 3.41 5.8107 3.33 6.0007 3.33 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
