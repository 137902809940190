import type { DataStructure } from "@redux/plugins/data-fetcher/interface";
import type { DraftState } from "@redux/plugins/draft-manager/interface";
import type { Fulfillment } from "@redux/modules/fulfillments/interface";

export type FulfillmentDraftData = Pick<
    Fulfillment,
    | "collecction_date"
    | "label_volume"
    | "label_product_fragile"
    | "observations"
    | "order_origin"
>;

export interface State
    extends DataStructure<Fulfillment, null>,
        DraftState<FulfillmentDraftData> {}

export const moduleId = "fulfillmentsFulfillment" as const;
