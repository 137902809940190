import type { FC } from "react";

import { classes } from "@utils/styles/tailwind/v4";

interface LoadingDotProps {
    className?: string;
}

const LoadingDot: FC<LoadingDotProps> = ({ className = "" }) => (
    <div
        className={`bg-doladoBlue-500 w-7 h-7 rounded-full animation-count-1 animation-1-pulse animation-1-duration-1000 animation-1-iter-infinite animation-1-dir-alt animation-1-timing-linear ${className}`}
    />
);

interface LoadingProps {
    className?: string;
}

const Loading: FC<LoadingProps> = ({ className }) => (
    <div
        className={classes`grid grid-cols-2 gap-5 w-max animate-spin ${className}`}>
        <LoadingDot />
        <LoadingDot className="animation-1-delay-250" />
        <LoadingDot className="animation-1-delay-750" />
        <LoadingDot className="animation-1-delay-500" />
    </div>
);

export default Loading;
