import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "TrendUpOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M7.50043 15.2499C7.31043 15.2499 7.12043 15.1799 6.97043 15.0299C6.68043 14.7399 6.68043 14.2599 6.97043 13.9699L10.1704 10.7699C10.3304 10.6099 10.5404 10.5299 10.7704 10.5499C10.9904 10.5699 11.1904 10.6899 11.3204 10.8799L12.4104 12.5199L15.9604 8.96994C16.2504 8.67994 16.7304 8.67994 17.0204 8.96994C17.3104 9.25994 17.3104 9.73994 17.0204 10.0299L12.8204 14.2299C12.6604 14.3899 12.4504 14.4699 12.2204 14.4499C12.0004 14.4299 11.8004 14.3099 11.6704 14.1199L10.5804 12.4799L8.03043 15.0299C7.88043 15.1799 7.69043 15.2499 7.50043 15.2499Z"
                fill="currentColor"
            />
            <path
                d="M16.5 12.25C16.09 12.25 15.75 11.91 15.75 11.5V10.25H14.5C14.09 10.25 13.75 9.91 13.75 9.5C13.75 9.09 14.09 8.75 14.5 8.75H16.5C16.91 8.75 17.25 9.09 17.25 9.5V11.5C17.25 11.91 16.91 12.25 16.5 12.25Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
