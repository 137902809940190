import type { FormEvent } from "react";

import {
    calcRoundDistance,
    parseNumericParams,
} from "../tests/numeric/helpers";
import type { NumericParams } from "../tests/numeric";

export interface ParsingOptions {
    parsers?: Record<string, (vl: any) => any>;
    whitelist?: string[];
    blacklist?: string[];
}

export const defaultParsersTypes = [
    "camelcase",
    "capitalize",
    "kebabcase",
    "lowercase",
    "onlyNumbers",
    "pascalcase",
    "snakecase",
    "toBoolean",
    "toDate",
    "toISODate",
    "toNumber",
    "trim",
    "uppercase",
] as const;
export type DefaultParsersTypes = (typeof defaultParsersTypes)[number];

export const defaultParsers: Record<DefaultParsersTypes, (vl: any) => any> = {
    camelcase: (vl) =>
        typeof vl === "string"
            ? vl
                  .trim()
                  .toLowerCase()
                  .replace(/(\s\b.)/g, (c) => c.trim().toUpperCase())
                  .replace(/\s/g, "")
            : vl,
    capitalize: (vl) =>
        typeof vl === "string"
            ? vl.replace(/(\b.)/g, (c) => c.toUpperCase())
            : vl,
    kebabcase: (vl) =>
        typeof vl === "string"
            ? vl
                  .trim()
                  .toLowerCase()
                  .replace(/(\s\b.)/g, (c) => `-${c.trim()}`)
                  .replace(/\s/g, "")
            : vl,
    lowercase: (vl) => (typeof vl === "string" ? vl.toLowerCase() : vl),
    onlyNumbers: (vl) => vl.match(/\d/g)?.join("") ?? "",
    pascalcase: (vl) =>
        typeof vl === "string"
            ? vl
                  .trim()
                  .toLowerCase()
                  .replace(/(\b.)/g, (c) => c.trim().toUpperCase())
                  .replace(/\s/g, "")
            : vl,
    snakecase: (vl) =>
        typeof vl === "string"
            ? vl
                  .trim()
                  .toLowerCase()
                  .replace(/(\s\b.)/g, (c) => `_${c.trim()}`)
                  .replace(/\s/g, "")
            : vl,
    toBoolean: (vl) => (typeof vl === "string" ? vl === "true" : !!vl),
    toDate: (vl) => (typeof vl === "string" ? new Date(vl) : vl),
    toISODate: (vl) => {
        switch (typeof vl) {
            case "string": {
                const temp = new Date(vl);
                return !isNaN(temp as unknown as number)
                    ? temp.toISOString()
                    : vl;
            }
            case "object":
                return vl instanceof Date && !isNaN(vl as unknown as number)
                    ? vl.toISOString()
                    : vl;
            default:
                return vl;
        }
    },
    toNumber: (vl) => parseFloat(vl),
    trim: (vl) => (typeof vl === "string" ? vl.trim() : vl),
    uppercase: (vl) => (typeof vl === "string" ? vl.toUpperCase() : vl),
};

export function parseFormData<Data extends Record<string, any>>(
    event: FormEvent,
    options: ParsingOptions = {},
): Data {
    let form: HTMLFormElement;
    if (event.target instanceof HTMLFormElement) {
        form = event.target;
    } else if (event.currentTarget instanceof HTMLFormElement) {
        form = event.currentTarget;
    } else {
        throw new Error("Invalid Event Instance");
    }

    const data = new Map();

    for (const el of form.elements) {
        if (
            !(el instanceof HTMLInputElement) &&
            !(el instanceof HTMLSelectElement) &&
            !(el instanceof HTMLTextAreaElement)
        )
            continue;

        if (!el.name || (el.disabled && !el.dataset.persist)) continue;
        if (options.whitelist && !options.whitelist.includes(el.name)) continue;
        if (options.blacklist && options.blacklist.includes(el.name)) continue;

        const { name, value, dataset } = el;

        switch (el.type) {
            case "checkbox": {
                const temp = el as HTMLInputElement;
                if (value === "on") {
                    data.set(name, temp.checked);
                } else {
                    const oldValues = (data.get(name) ?? "").split(
                        ",",
                    ) as string[];
                    data.set(
                        name,
                        (temp.checked ? [...oldValues, value] : oldValues)
                            .filter((el) => !!el)
                            .join(","),
                    );
                }
                break;
            }
            case "radio": {
                const temp = el as HTMLInputElement;
                if (temp.checked) data.set(name, value);
                break;
            }
            case "number": {
                let num = parseFloat(value);

                let params: NumericParams = {};

                const temp = el as HTMLInputElement;

                if (temp.max || temp.min || temp.step) {
                    params = {
                        ...(!!temp.min && { gte: parseFloat(temp.min) }),
                        ...(!!temp.max && { lte: parseFloat(temp.max) }),
                        ...(!!temp.step && { step: parseFloat(temp.step) }),
                    };
                } else {
                    params = parseNumericParams(dataset.numeric);
                }

                const { gt, gte, lt, lte, step } = params;

                if (typeof gte !== "undefined" && num < gte) {
                    num = gte;
                } else if (typeof lte !== "undefined" && num > lte) {
                    num = lte;
                } else if (typeof gt !== "undefined" && num <= gt) {
                    num = gt + (step ?? 1);
                } else if (typeof lt !== "undefined" && num >= lt) {
                    num = lt - (step ?? 1);
                }

                if (typeof step !== "undefined") {
                    const dist = calcRoundDistance(num, step);
                    if (Math.abs(dist) > 1e-8) num += dist;
                }

                data.set(name, num);
                break;
            }
            default:
                data.set(name, value);
                break;
        }

        if (dataset.parser) {
            const parsers = dataset.parser.split("+");
            let temp = data.get(name) ?? "";
            parsers.forEach((prs) => {
                temp =
                    defaultParsers[prs as DefaultParsersTypes]?.(temp) ??
                    options.parsers?.[prs]?.(temp) ??
                    temp;
            });
            data.set(name, temp);
        }
    }

    return Object.fromEntries(data.entries()) as Data;
}
