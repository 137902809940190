import React from "react";
import {
    CommentOutlined,
    ShoppingOutlined,
    DollarOutlined,
    ShoppingCartOutlined,
    FileSyncOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    StockOutlined,
    ApiOutlined,
    LockOutlined,
    ContainerOutlined,
    AppstoreOutlined,
    BankOutlined,
    FullscreenOutlined,
    AreaChartOutlined,
    ImportOutlined,
    FormOutlined,
    LineOutlined,
    SettingOutlined,
    CheckSquareOutlined,
    EditOutlined,
    ExceptionOutlined,
    DeleteOutlined,
    ReloadOutlined,
    VerticalAlignTopOutlined,
    QuestionCircleOutlined,
    DownOutlined,
    SearchOutlined,
    EllipsisOutlined,
    UploadOutlined,
    MailOutlined,
    UserOutlined,
    InboxOutlined,
    FilterOutlined,
    CloseOutlined,
    ArrowUpOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    CloudDownloadOutlined,
    CopyOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    PlusOutlined,
    MenuOutlined,
    CarryOutOutlined,
    DownloadOutlined,
    DiffOutlined,
    SendOutlined,
    UnorderedListOutlined,
    DeliveredProcedureOutlined,
    BlockOutlined,
    SelectOutlined,
    CheckOutlined,
    SaveOutlined,
    ArrowRightOutlined,
    PrinterOutlined,
    RollbackOutlined,
    CloudUploadOutlined,
    LoginOutlined,
    FileImageOutlined,
    WarningFilled,
    CalendarOutlined,
    DatabaseOutlined,
    GlobalOutlined,
    BarcodeOutlined,
    DeploymentUnitOutlined,
    ReadOutlined,
    LoadingOutlined,
    ProfileOutlined,
    TagOutlined,
    AppstoreAddOutlined,
    PictureOutlined,
    NumberOutlined,
    CheckCircleOutlined,
    StarOutlined,
    CloseCircleOutlined,
    BarsOutlined,
    DownSquareOutlined,
    StarFilled,
    DragOutlined,
    RetweetOutlined,
    WarningOutlined,
    LeftOutlined,
    RightOutlined,
    ArrowLeftOutlined,
    ExclamationCircleOutlined,
    BorderOutlined,
    UnlockOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    MinusOutlined,
    PieChartOutlined,
    PieChartFilled,
    SafetyCertificateOutlined,
    RiseOutlined,
    FallOutlined,
    SyncOutlined,
    PauseCircleOutlined,
    StopOutlined,
    EnvironmentOutlined,
    InsertRowAboveOutlined,
    CheckCircleFilled,
    BulbOutlined,
    ThunderboltFilled,
    InteractionOutlined,
    RocketFilled,
    IdcardOutlined,
    CarOutlined,
    CameraOutlined,
    NotificationOutlined,
    DownCircleOutlined,
    RightCircleOutlined,
    PercentageOutlined,
    InfoCircleOutlined,
    GoogleOutlined,
    BellOutlined,
    BookFilled,
    SwapOutlined,
    HistoryOutlined,
    RedoOutlined,
    PoweroffOutlined,
} from "@ant-design/icons";

export function Icon(props) {
    var RESPONSE;
    switch (props.name) {
        case "PoweroffOutlined":
            RESPONSE = <PoweroffOutlined {...props} />;
            break;
        case "CommentOutlined":
            RESPONSE = <CommentOutlined {...props} />;
            break;
        case "NotificationOutlined":
            RESPONSE = <NotificationOutlined {...props} />;
            break;
        case "BellOutlined":
            RESPONSE = <BellOutlined {...props} />;
            break;
        case "ShoppingOutlined":
            RESPONSE = <ShoppingOutlined {...props} />;
            break;
        case "DollarOutlined":
            RESPONSE = <DollarOutlined {...props} />;
            break;
        case "CarOutlined":
            RESPONSE = <CarOutlined {...props} />;
            break;
        case "RiseOutlined":
            RESPONSE = <RiseOutlined {...props} />;
            break;
        case "StopOutlined":
            RESPONSE = <StopOutlined {...props} />;
            break;
        case "CheckCircleFilled":
            RESPONSE = <CheckCircleFilled {...props} />;
            break;
        case "PauseCircleOutlined":
            RESPONSE = <PauseCircleOutlined {...props} />;
            break;
        case "SyncOutlined":
            RESPONSE = <SyncOutlined {...props} />;
            break;
        case "FallOutlined":
            RESPONSE = <FallOutlined {...props} />;
            break;

        case "PieChartFilled":
            RESPONSE = <PieChartFilled {...props} />;
            break;

        case "SafetyCertificateOutlined":
            RESPONSE = <SafetyCertificateOutlined {...props} />;
            break;

        case "InsertRowAboveOutlined":
            RESPONSE = <InsertRowAboveOutlined {...props} />;
            break;

        case "FileSyncOutlined":
            RESPONSE = <FileSyncOutlined {...props} />;
            break;

        case "PieChartOutlined":
            RESPONSE = <PieChartOutlined {...props} />;
            break;

        case "DoubleLeftOutlined":
            RESPONSE = <DoubleLeftOutlined {...props} />;
            break;

        case "MinusOutlined":
            RESPONSE = <MinusOutlined {...props} />;
            break;

        case "DoubleRightOutlined":
            RESPONSE = <DoubleRightOutlined {...props} />;
            break;

        case "DragOutlined":
            RESPONSE = <DragOutlined {...props} />;
            break;

        case "UnlockOutlined":
            RESPONSE = <UnlockOutlined {...props} />;
            break;

        case "ExclamationCircleOutlined":
            RESPONSE = <ExclamationCircleOutlined {...props} />;
            break;

        case "BorderOutlined":
            RESPONSE = <BorderOutlined {...props} />;
            break;

        case "LeftORightOutlinedutlined":
            RESPONSE = <RightOutlined {...props} />;
            break;

        case "LeftOutlined":
            RESPONSE = <LeftOutlined {...props} />;
            break;

        case "WarningOutlined":
            RESPONSE = <WarningOutlined {...props} />;
            break;

        case "StarFilled":
            RESPONSE = <StarFilled {...props} />;
            break;

        case "UploadOutlined":
            RESPONSE = <UploadOutlined {...props} />;
            break;

        case "BarsOutlined":
            RESPONSE = <BarsOutlined {...props} />;
            break;

        case "DownSquareOutlined":
            RESPONSE = <DownSquareOutlined {...props} />;
            break;

        case "ArrowLeftOutlined":
            RESPONSE = <ArrowLeftOutlined {...props} />;
            break;

        case "StarOutlined":
            RESPONSE = <StarOutlined {...props} />;
            break;

        case "CloseCircleOutlined":
            RESPONSE = <CloseCircleOutlined {...props} />;
            break;

        case "NumberOutlined":
            RESPONSE = <NumberOutlined {...props} />;
            break;

        case "LineOutlined":
            RESPONSE = <LineOutlined {...props} />;
            break;

        case "SaveOutlined":
            RESPONSE = <SaveOutlined {...props} />;
            break;

        case "LoginOutlined":
            RESPONSE = <LoginOutlined {...props} />;
            break;

        case "CheckSquareOutlined":
            RESPONSE = <CheckSquareOutlined {...props} />;
            break;

        case "CloudUploadOutlined":
            RESPONSE = <CloudUploadOutlined {...props} />;
            break;

        case "CheckOutlined":
            RESPONSE = <CheckOutlined {...props} />;
            break;

        case "MenuOutlined":
            RESPONSE = <MenuOutlined {...props} />;
            break;

        case "VerticalAlignTopOutlined":
            RESPONSE = <VerticalAlignTopOutlined {...props} />;
            break;

        case "FullscreenOutlined":
            RESPONSE = <FullscreenOutlined {...props} />;
            break;

        case "PlusOutlined":
            RESPONSE = <PlusOutlined {...props} />;
            break;
        case "FileExcelOutlined":
            RESPONSE = <FileExcelOutlined {...props} />;
            break;
        case "FilePdfOutlined":
            RESPONSE = <FilePdfOutlined {...props} />;
            break;
        case "CopyOutlined":
            RESPONSE = <CopyOutlined {...props} />;
            break;
        case "CloudDownloadOutlined":
            RESPONSE = <CloudDownloadOutlined {...props} />;
            break;
        case "EyeInvisibleOutlined":
            RESPONSE = <EyeInvisibleOutlined {...props} />;
            break;
        case "EyeOutlined":
            RESPONSE = <EyeOutlined {...props} />;
            break;
        case "ArrowUpOutlined":
            RESPONSE = <ArrowUpOutlined {...props} />;
            break;
        case "FilterOutlined":
            RESPONSE = <FilterOutlined {...props} />;
            break;
        case "CloseOutlined":
            RESPONSE = <CloseOutlined {...props} />;
            break;
        case "InboxOutlined":
            RESPONSE = <InboxOutlined {...props} />;
            break;
        case "UserOutlined":
            RESPONSE = <UserOutlined {...props} />;
            break;
        case "MailOutlined":
            RESPONSE = <MailOutlined {...props} />;
            break;
        case "EllipsisOutlined":
            RESPONSE = <EllipsisOutlined {...props} />;
            break;
        case "DownOutlined":
            RESPONSE = <DownOutlined {...props} />;
            break;
        case "SearchOutlined":
            RESPONSE = <SearchOutlined {...props} />;
            break;
        case "QuestionCircleOutlined":
            RESPONSE = <QuestionCircleOutlined {...props} />;
            break;
        case "ReloadOutlined":
            RESPONSE = <ReloadOutlined {...props} />;
            break;
        case "ShoppingCartOutlined":
            RESPONSE = <ShoppingCartOutlined {...props} />;
            break;
        case "EditOutlined":
            RESPONSE = <EditOutlined {...props} />;
            break;
        case "ExceptionOutlined":
            RESPONSE = <ExceptionOutlined {...props} />;
            break;
        case "DeleteOutlined":
            RESPONSE = <DeleteOutlined {...props} />;
            break;
        case "UsergroupAddOutlined":
            RESPONSE = <UsergroupAddOutlined {...props} />;
            break;
        case "HomeOutlined":
            RESPONSE = <HomeOutlined {...props} />;
            break;
        case "StockOutlined":
            RESPONSE = <StockOutlined {...props} />;
            break;
        case "ApiOutlined":
            RESPONSE = <ApiOutlined {...props} />;
            break;
        case "AppstoreOutlined":
            RESPONSE = <AppstoreOutlined {...props} />;
            break;
        case "ContainerOutlined":
            RESPONSE = <ContainerOutlined {...props} />;
            break;
        case "BankOutlined":
            RESPONSE = <BankOutlined {...props} />;
            break;
        case "AreaChartOutlined":
            RESPONSE = <AreaChartOutlined {...props} />;
            break;
        case "importOutlined":
            RESPONSE = <ImportOutlined {...props} />;
            break;
        case "FormOutlined":
            RESPONSE = <FormOutlined {...props} />;
            break;
        case "SettingOutlined":
            RESPONSE = <SettingOutlined {...props} />;
            break;
        case "CarryOutOutlined":
            RESPONSE = <CarryOutOutlined {...props} />;
            break;
        case "DownloadOutlined":
            RESPONSE = <DownloadOutlined {...props} />;
            break;
        case "DiffOutlined":
            RESPONSE = <DiffOutlined {...props} />;
            break;
        case "SendOutlined":
            RESPONSE = <SendOutlined {...props} />;
            break;
        case "UnorderedListOutlined":
            RESPONSE = <UnorderedListOutlined {...props} />;
            break;
        case "DeliveredProcedureOutlined":
            RESPONSE = <DeliveredProcedureOutlined {...props} />;
            break;
        case "LockOutlined":
            RESPONSE = <LockOutlined {...props} />;
            break;
        case "BlockOutlined":
            RESPONSE = <BlockOutlined {...props} />;
            break;
        case "SelectOutlined":
            RESPONSE = <SelectOutlined {...props} />;
            break;

        case "ArrowRightOutlined":
            RESPONSE = <ArrowRightOutlined {...props} />;
            break;

        case "PrinterOutlined":
            RESPONSE = <PrinterOutlined {...props} />;
            break;

        case "RollbackOutlined":
            RESPONSE = <RollbackOutlined {...props} />;
            break;

        case "FileImageOutlined":
            RESPONSE = <FileImageOutlined {...props} />;
            break;

        case "WarningFilled":
            RESPONSE = <WarningFilled {...props} />;
            break;

        case "CalendarOutlined":
            RESPONSE = <CalendarOutlined {...props} />;
            break;

        case "DatabaseOutlined":
            RESPONSE = <DatabaseOutlined {...props} />;
            break;

        case "GlobalOutlined":
            RESPONSE = <GlobalOutlined {...props} />;
            break;

        case "BarcodeOutlined":
            RESPONSE = <BarcodeOutlined {...props} />;
            break;

        case "DeploymentUnitOutlined":
            RESPONSE = <DeploymentUnitOutlined {...props} />;
            break;

        case "ReadOutlined":
            RESPONSE = <ReadOutlined {...props} />;
            break;

        case "LoadingOutlined":
            RESPONSE = <LoadingOutlined {...props} />;
            break;

        case "ProfileOutlined":
            RESPONSE = <ProfileOutlined {...props} />;
            break;

        case "TagOutlined":
            RESPONSE = <TagOutlined {...props} />;
            break;

        case "AppstoreAddOutlined":
            RESPONSE = <AppstoreAddOutlined {...props} />;
            break;

        case "PictureOutlined":
            RESPONSE = <PictureOutlined {...props} />;
            break;

        case "CheckCircleOutlined":
            RESPONSE = <CheckCircleOutlined {...props} />;
            break;

        case "RetweetOutlined":
            RESPONSE = <RetweetOutlined {...props} />;
            break;

        case "EnvironmentOutlined":
            RESPONSE = <EnvironmentOutlined {...props} />;
            break;

        case "BulbOutlined":
            RESPONSE = <BulbOutlined {...props} />;
            break;

        case "ThunderboltFilled":
            RESPONSE = <ThunderboltFilled {...props} />;
            break;

        case "InteractionOutlined":
            RESPONSE = <InteractionOutlined {...props} />;
            break;

        case "RocketFilled":
            RESPONSE = <RocketFilled {...props} />;
            break;

        case "IdcardOutlined":
            RESPONSE = <IdcardOutlined {...props} />;
            break;

        case "CameraOutlined":
            RESPONSE = <CameraOutlined {...props} />;
            break;
        case "DownCircleOutlined":
            RESPONSE = <DownCircleOutlined {...props} />;
            break;
        case "RightCircleOutlined":
            RESPONSE = <RightCircleOutlined {...props} />;
            break;
        case "PercentageOutlined":
            RESPONSE = <PercentageOutlined {...props} />;
            break;
        case "InfoCircleOutlined":
            RESPONSE = <InfoCircleOutlined {...props} />;
            break;
        case "GoogleOutlined":
            RESPONSE = <GoogleOutlined {...props} />;
            break;
        case "BookFilled":
            RESPONSE = <BookFilled {...props} />;
            break;
        case "SwapOutlined":
            RESPONSE = <SwapOutlined {...props} />;
            break;
        case "HistoryOutlined":
            RESPONSE = <HistoryOutlined {...props} />;
            break;
        case "RedoOutlined":
            RESPONSE = <RedoOutlined {...props} />;
            break;

        default:
            RESPONSE = <></>;
    }

    return RESPONSE;
}
