import ChevronCircleRightIcon from "./linear";

export default ChevronCircleRightIcon;
export {
    default as ChevronCircleRightIcon,
    iconInfo as ChevronCircleRightIconInfo,
} from "./linear";

export {
    default as ChevronCircleRightBoldIcon,
    iconInfo as ChevronCircleRightBoldIconInfo,
} from "./bold";
export {
    default as ChevronCircleRightBrokenIcon,
    iconInfo as ChevronCircleRightBrokenIconInfo,
} from "./broken";
export {
    default as ChevronCircleRightBulkIcon,
    iconInfo as ChevronCircleRightBulkIconInfo,
} from "./bulk";
export {
    default as ChevronCircleRightLinearIcon,
    iconInfo as ChevronCircleRightLinearIconInfo,
} from "./linear";
export {
    default as ChevronCircleRightOutlineIcon,
    iconInfo as ChevronCircleRightOutlineIconInfo,
} from "./outline";
export {
    default as ChevronCircleRightTwotoneIcon,
    iconInfo as ChevronCircleRightTwotoneIconInfo,
} from "./twotone";
