import franchise from "@configs/rest/franchise";

export enum PaymentMethodTypes {
    CREDIT_CARD = "CREDIT_CARD",
    PIX = "PIX",
}

export interface PaymentMethod {
    _id: string;
    asaasType: PaymentMethodTypes | null;
    defaultHotmartPayment: boolean;
    days: string;
    hotmartType: string | null;
    maxInstallments: number | null;
}

interface GetPaymentMethodsRequestResponse {
    paymentMethods: PaymentMethod[];
}

export default async function getPaymentMethods(): Promise<PaymentMethod[]> {
    try {
        const { data } = await franchise.get<GetPaymentMethodsRequestResponse>(
            `/v1/payment-methods`,
        );
        return data.paymentMethods;
    } catch (error) {
        console.log(error);
        return [];
    }
}
