import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowLeftRightTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <g opacity="0.4">
                <path
                    d="M17.2812 10.4498L21.0012 6.72974L17.2812 3.00977"
                    stroke="currentColor"
                />
                <path d="M3 6.72949H21" stroke="currentColor" />
            </g>
            <path
                d="M6.71997 13.5498L3 17.2698L6.71997 20.9898"
                stroke="currentColor"
            />
            <path d="M21 17.27H3" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
