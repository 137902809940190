import type { FC, ReactNode } from "react";

import { useModalContext } from "./helpers";

import { classes } from "@utils/styles/tailwind/v4";

interface ModalBodyProps {
    className?: string;
    children?: ReactNode;
}

const ModalBody: FC<ModalBodyProps> = ({ className, children }) => {
    const { aria } = useModalContext();
    return (
        <div
            id={aria.description}
            className={classes`overflow-x-hidden overflow-y-auto ${className}`}>
            {children}
        </div>
    );
};

export default ModalBody;
