import { useContext, useEffect, useRef, useState } from "react";

import { TabsContext } from "./helpers";
import type { TabInfo } from "./interface";

import deepEqual from "@utils/objects/deepEqual";

export function useTabsContext(): TabsContext {
    return useContext(TabsContext);
}

export function useLoadingTabs(
    tabs: string[],
    isLoading: boolean,
    options: { currentTab?: string; isBlocking?: boolean } = {},
) {
    const { setLoading } = useContext(TabsContext);

    const [currentTabs, setCurrentTabs] = useState(tabs);
    const currentTabsRef = useRef(currentTabs);

    useEffect(() => {
        if (!deepEqual(tabs, currentTabsRef.current)) {
            currentTabsRef.current = tabs;
            setCurrentTabs(tabs);
        }
    }, [tabs]);

    useEffect(() => {
        if (isLoading) {
            setLoading(
                options.currentTab || "",
                currentTabs.map((tab) => ({
                    key: tab,
                    status: options.isBlocking ? "blocking" : "loading",
                })),
            );
        } else {
            setLoading(
                options.currentTab || "",
                currentTabs.map((tab) => ({ key: tab, status: "idle" })),
            );
        }
    }, [
        currentTabs,
        isLoading,
        options.currentTab,
        options.isBlocking,
        setLoading,
    ]);
}

export function filterTab(
    tabs: TabInfo[],
    keys: string | string[],
    check: boolean,
) {
    if (check) {
        const temp = typeof keys === "string" ? [keys] : keys;
        for (const key of temp) {
            const idx = tabs.findIndex((tab) => tab.key === key);
            if (idx !== -1) tabs.splice(idx, 1);
        }
    }
}
