import type { TailwindPattern } from "../interface";

const floatsPattern: TailwindPattern = {
    base: /float/,
};

export default floatsPattern;

export const floatsSample = [
    ["float-start", "float-end", "float-right", "float-left", "float-none"],
];
