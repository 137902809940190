import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ImportBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M16.8 9H7.2C4 9 2 11 2 14.2V16.79C2 20 4 22 7.2 22H16.79C19.99 22 21.99 20 21.99 16.8V14.2C22 11 20 9 16.8 9Z"
                fill="currentColor"
            />
            <path
                d="M 8.1202 12.4301 L 11.4702 15.7801 C 11.7602 16.0701 12.2402 16.0701 12.5302 15.7801 L 15.8802 12.4301 C 16.1702 12.1401 16.1702 11.6601 15.8802 11.3701 C 15.5902 11.0801 15.1102 11.0801 14.8202 11.3701 L 12.7502 13.4401 V 2.7501 C 12.7502 2.3401 12.4102 2.0001 12.0002 2.0001 C 11.5902 2.0001 11.2502 2.3401 11.2502 2.7501 V 13.4401 L 9.1802 11.3701 C 9.0302 11.2201 8.8402 11.1501 8.6502 11.1501 C 8.4602 11.1501 8.2702 11.2201 8.1202 11.3701 C 7.8202 11.6601 7.8202 12.1301 8.1202 12.4301 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
