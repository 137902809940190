import { createContext, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSession } from "@contexts/session";
import { useAppModuleManager } from "@modules/utils";

export const MainContext = createContext();

export function MainContextProvider({ children }) {
    const {
        data: {
            accountId,
            accountName,
            branches,
            company,
            externalDashboard,
            isAdmin,
            parameters,
            privileges,
        },
    } = useSession();

    const { addModules, removeModules, setModule } = useAppModuleManager();

    const [mainStateContext, setMainStateContext] = useState({
        AppendPrependTabs: [],
        UserPrivileges: [],
    });

    function AppendPrependTabs(data, action) {
        if (!localStorage.getItem("AppendPrependTabs")) {
            localStorage.setItem("AppendPrependTabs", "[]");
        }

        var localStorageAppendPrependTabs = JSON.parse(
            localStorage.getItem("AppendPrependTabs") || [],
        );

        if (action === "append" && data.key !== undefined) {
            const INDEX = localStorageAppendPrependTabs.findIndex(
                (list) => list.key === data.key,
            );

            if (INDEX === -1) {
                let title;

                if (
                    data.title !== null &&
                    data.title !== undefined &&
                    data.title !== ""
                ) {
                    if (data.title.length > 35) {
                        title = data.title.substring(0, 35) + " ...";
                    } else {
                        title = data.title;
                    }
                }

                var json = {
                    key: data.title,
                    component: data.component,
                    title: title,
                    URLSearchParams: data.URLSearchParams,
                    account: data.account,
                };

                localStorageAppendPrependTabs.push(json);

                localStorage.setItem(
                    "AppendPrependTabs",
                    JSON.stringify(localStorageAppendPrependTabs),
                );

                setMainStateContext((prevState) => ({
                    ...prevState,
                    AppendPrependTabs: localStorageAppendPrependTabs,
                    tab: "record" + data.key,
                }));

                const searchParams = new URLSearchParams(
                    data.URLSearchParams || "",
                );

                addModules([
                    {
                        tag: data.component,
                        oldKey: data.key,
                        search: data.URLSearchParams,
                        title,
                        ...(!!searchParams.size && {
                            params: Object.fromEntries(searchParams),
                        }),
                        relative: true,
                    },
                ]);
            } else {
                if (data.refresh === "true") {
                    var listData = localStorageAppendPrependTabs;
                    var rowIndex = listData.findIndex(
                        (list) => list.key === data.key,
                    );

                    listData[rowIndex].URLSearchParams = data.URLSearchParams;
                    listData[rowIndex].refresh = "";

                    localStorage.setItem(
                        "AppendPrependTabs",
                        JSON.stringify(listData),
                    );

                    setMainStateContext((prevState) => ({
                        ...prevState,
                        AppendPrependTabs: listData,
                        tab: "record" + data.key,
                    }));
                } else {
                    localStorage.setItem(
                        "AppendPrependTabs",
                        JSON.stringify(localStorageAppendPrependTabs),
                    );

                    setMainStateContext((prevState) => ({
                        ...prevState,
                        AppendPrependTabs: localStorageAppendPrependTabs,
                        tab: "record" + data.key,
                    }));
                }

                setModule({ oldKey: data.key });
            }
        } else if (action === "prepend") {
            setTimeout(() => {
                const INDEX = localStorageAppendPrependTabs.findIndex(
                    (list) => list.key === data,
                );

                localStorageAppendPrependTabs.splice(INDEX, 1);

                localStorage.setItem(
                    "AppendPrependTabs",
                    JSON.stringify(localStorageAppendPrependTabs),
                );

                setMainStateContext((prevState) => ({
                    ...prevState,
                    AppendPrependTabs:
                        localStorageAppendPrependTabs.length === 0
                            ? []
                            : localStorageAppendPrependTabs,
                    tab:
                        localStorageAppendPrependTabs.length === 0
                            ? "dash"
                            : "record" +
                              localStorageAppendPrependTabs[
                                  localStorageAppendPrependTabs.length - 1
                              ].key,
                }));

                removeModules([{ oldKey: data.key }]);
            }, 10);
        } else {
            setMainStateContext((prevState) => ({
                ...prevState,
                AppendPrependTabs: localStorageAppendPrependTabs,
                tab: "dash",
            }));

            setModule({ key: "dash" });
        }
    }

    function UpdateAppendPrependTabs(
        component,
        title,
        URLSearchParamsAppend,
        URLSearchParams,
    ) {
        var url =
            URLSearchParams === undefined || URLSearchParams.length === 0
                ? "?" + URLSearchParamsAppend
                : URLSearchParams + "&" + URLSearchParamsAppend;

        var data = mainStateContext.AppendPrependTabs;

        const rowIndex = data.findIndex((list) => list.key === title);

        if (rowIndex >= 0) {
            data[rowIndex].URLSearchParams = url;
        }

        localStorage.setItem("AppendPrependTabs", JSON.stringify(data));
    }

    async function OnChangeTabActive(tab) {
        setMainStateContext((prevState) => ({
            ...prevState,
            tab,
        }));

        if (tab === "close") {
            setMainStateContext((prevState) => ({
                ...prevState,
                AppendPrependTabs: [],
                tab: "dash",
            }));
            localStorage.setItem("AppendPrependTabs", "[]");
            setModule({ key: "dash" });
        } else {
            setModule({ oldKey: tab.replace(/^record/, "") });
        }
    }

    return (
        <MainContext.Provider
            value={{
                AppendPrependTabs,
                accountName: accountName || "",
                accountId: accountId || 0,
                userPrivileges: privileges || {},
                companyParameters: parameters || {},
                UpdateAppendPrependTabs,
                OnChangeTabActive,
                mainStateContext,
                listAccounts: branches || [],
                externalDashboard: externalDashboard,
                isMobile,
                companyAdminMaster: isAdmin,
                companyDocumentType: company?.documentType,
            }}>
            {children}
        </MainContext.Provider>
    );
}
