import type { FC, ReactNode } from "react";

import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";

import ToastContainer from "./Container";

import { toastPositions } from "./interface";
import type { ToastPositions, ToastData } from "./interface";
import { ToastContext } from "./utils";

import createUUID from "@utils/cryptography/createUUID";

interface ToastPortalProps {
    children?: ReactNode;
}

export const ToastPortal: FC<ToastPortalProps> = ({ children }) => {
    const [toasts, setToasts] = useState<ToastData[]>([]);

    const dispatch: ToastContext["dispatch"] = useCallback((data) => {
        const id = data.id ?? createUUID();
        setToasts((prev) => {
            const temp = [...prev];
            const idx = temp.findIndex((toast) => toast.id === id);
            if (idx !== -1) {
                temp[idx] = { ...temp[idx], ...data };
            } else {
                temp.push({
                    ...data,
                    id,
                    position: data.position ?? "top-right",
                });
            }
            return temp;
        });
        return id;
    }, []);

    const close: ToastContext["close"] = useCallback((id) => {
        setToasts((prev) => prev.filter((toast) => toast.id !== id));
    }, []);

    const closeAll: ToastContext["closeAll"] = useCallback(() => {
        setToasts([]);
    }, []);

    const update: ToastContext["update"] = useCallback((id, data) => {
        setToasts((prev) =>
            prev.map((toast) => {
                if (toast.id !== id) return toast;
                return { ...toast, ...data };
            }),
        );
        return id;
    }, []);

    const renderToastRegion = (pos: ToastPositions) => {
        const elements = toasts
            .filter(({ position }) => position === pos)
            .map((data, _, arr) => (
                <ToastContainer
                    key={data.id}
                    data={data}
                    listLength={arr.length}
                    onClose={close}
                />
            ))
            .reverse();
        return (
            <div
                id={`toast-region-${pos}`}
                role="region"
                aria-live="polite"
                className={`absolute flex ${toastPositions[pos]} pointer-events-auto`}>
                {elements}
            </div>
        );
    };

    const state = useMemo<ToastContext>(
        () => ({ close, closeAll, dispatch, update }),
        [close, closeAll, dispatch, update],
    );

    return (
        <ToastContext.Provider value={state}>
            {children}
            <>
                {createPortal(
                    <div
                        id="toast-portal"
                        className="fixed inset-0 z-toast pointer-events-none">
                        {renderToastRegion("bottom")}
                        {renderToastRegion("bottom-left")}
                        {renderToastRegion("bottom-right")}
                        {renderToastRegion("top")}
                        {renderToastRegion("top-left")}
                        {renderToastRegion("top-right")}
                    </div>,
                    document.body,
                )}
            </>
        </ToastContext.Provider>
    );
};

export * from "./utils";
