import type { FC } from "react";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NotificationButton from "./NotificationButton";
import MainPageSideBar from "./Sidebar";

import Button from "@components/@basic/Button";
import ButtonIcon from "@components/@basic/ButtonIcon";
import Drawer from "@components/@basic/Drawer";

import { useSession } from "@contexts/session";

import useIsTablet from "@hooks/breakpoints/useIsTablet";

const MainPageNavbar: FC = () => {
    const [showDrawer, setShowDrawer] = useState(false);
    const onOpenDrawer = () => setShowDrawer(true);
    const onCloseDrawer = () => setShowDrawer(false);

    const {
        data: { isAdmin },
        logout,
    } = useSession();

    const isTablet = useIsTablet();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isTablet) onCloseDrawer();
    }, [isTablet]);

    const handleLogout = () => {
        logout(() => {
            navigate("/");
        });
    };

    return (
        <nav className="col-span-2 flex justify-between items-center bg-white h-full border-b border-b-stone-200 px-16 md:px-32">
            <div className="md:flex-1 flex items-center gap-8">
                <ButtonIcon
                    variant="ghost"
                    size="sm"
                    icon="menu"
                    label="toggle-menu"
                    className="block lg:hidden p-2 text-lightGrey-700"
                    onClick={onOpenDrawer}
                />
                <Drawer
                    size="unset"
                    className="max-w-240"
                    isOpen={showDrawer}
                    onClose={onCloseDrawer}>
                    <MainPageSideBar onClick={onCloseDrawer} />
                </Drawer>

                <img
                    src="/images/logotipo-dolado-blue.svg"
                    alt="dolado"
                    width={100}
                    height={24}
                    className="w-80 md:w-100 object-contain object-center"
                />
            </div>

            <div className="flex-2 flex justify-end items-center gap-8">
                {!isAdmin && <NotificationButton />}

                <ButtonIcon
                    variant="ghost"
                    size="sm"
                    label="logout"
                    icon="logout"
                    title="Sair"
                    className="text-royalBlue-900"
                    onClick={handleLogout}
                />

                <Button
                    size="unset"
                    leftIcon="question"
                    iconStyle="md:bg-white w-18 h-18 mr-0 md:mr-8 rounded-full p-3 text-white md:text-doladoBlue"
                    className="relative bg-royalBlue-500 w-24 md:w-max h-24 md:h-40 rounded-full md:rounded-l-24 md:rounded-tr-4 md:rounded-br-24 px-3 md:px-16 md:after:content-['Ajuda']">
                    <a
                        href="https://web.whatsapp.com/send/?phone=11973079915&text=Preciso+de+assist%C3%AAncia%2C+pode+me+ajudar%3F"
                        target="_blank"
                        rel="external chat"
                        className="after:absolute after:inset-0"></a>
                </Button>
            </div>
        </nav>
    );
};

export default MainPageNavbar;
