import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ComponentOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M11.9998 22.75C10.8298 22.75 9.73977 22.3 8.91977 21.48L2.52975 15.09C1.70975 14.27 1.25977 13.17 1.25977 12.01C1.25977 10.85 1.70975 9.74999 2.52975 8.92999L8.91977 2.53998C9.73977 1.71998 10.8398 1.27002 11.9998 1.27002C13.1598 1.27002 14.2597 1.71998 15.0797 2.53998L21.4698 8.92999C22.2898 9.74999 22.7397 10.85 22.7397 12.01C22.7397 13.17 22.2898 14.27 21.4698 15.09L15.0797 21.48C14.2597 22.3 13.1698 22.75 11.9998 22.75ZM11.9998 2.75C11.2298 2.75 10.5098 3.05002 9.97977 3.58002L3.58975 9.96997C3.04975 10.51 2.75977 11.23 2.75977 11.99C2.75977 12.75 3.05975 13.48 3.58975 14.01L9.97977 20.4C11.0498 21.47 12.9497 21.47 14.0197 20.4L20.4098 14.01C20.9498 13.47 21.2397 12.76 21.2397 11.99C21.2397 11.22 20.9398 10.5 20.4098 9.96997L14.0197 3.58002C13.4897 3.05002 12.7698 2.75 11.9998 2.75Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
