import { applyCallback } from "./helpers";
import type {
    AnimationConfigCallback,
    AnimationElementSetup,
    AnimationElementSetupData,
} from "./interface";

export default function createAnimation<
    Setup extends Record<string, AnimationElementSetup>,
>(setup: Setup): AnimationConfigCallback<Setup> {
    return function (configs, callback) {
        let t0 = -1;
        let id = -1;
        let finished: string[] = [];

        const frame =
            (
                currentId: number,
                prepared: Partial<Record<keyof Setup, any>>,
                ...params: any[]
            ) =>
            (t: number) => {
                if (currentId !== id) return;

                const names = Object.keys(setup);
                if (names.every((nm) => finished.includes(nm))) {
                    callback?.();
                    return;
                }

                if (t0 === -1) t0 = t;

                const dT = t - t0;

                for (const name in setup) {
                    if (finished.includes(name)) continue;

                    const {
                        duration,
                        ref,
                        disabled: isDisabled,
                    } = configs[name];
                    const { step, disabled, end } = setup[name];

                    const data: AnimationElementSetupData = {
                        duration:
                            typeof duration === "function"
                                ? duration(!currentId)
                                : duration,
                        isFirst: !currentId,
                        prepared: prepared[name],
                    };

                    if (isDisabled) {
                        finished.push(name);
                        applyCallback(ref, disabled, data, ...params);
                        continue;
                    }

                    const p = dT / data.duration;

                    applyCallback(ref, step, Math.min(p, 1), data, ...params);

                    if (p >= 1) {
                        finished.push(name);
                        applyCallback(ref, end, data, ...params);
                    }
                }

                if (names.every((nm) => finished.includes(nm))) {
                    callback?.();
                    return;
                }

                requestAnimationFrame(frame(currentId, prepared, ...params));
            };

        return (...params) => {
            const currentId = ++id;
            t0 = -1;
            finished = [];

            const prepared: Partial<Record<keyof Setup, any>> = {};
            for (const name in setup) {
                const { ref, duration } = configs[name];
                applyCallback(
                    ref,
                    (element) =>
                        (prepared[name] = setup[name].prepare?.(
                            element,
                            {
                                isFirst: !currentId,
                                duration:
                                    typeof duration === "function"
                                        ? duration(!currentId)
                                        : duration,
                            },
                            ...params,
                        )),
                );
            }

            requestAnimationFrame(frame(currentId, prepared, ...params));
        };
    };
}
