import { useCallback, useEffect } from "react";

export default function useTakeBlip(apiKey = "") {
    const initBlipChat = useCallback(() => {
        if (typeof BlipChat === "undefined") return;

        const customStyle = `
            #blip-send-message {
                display: flex !important;
            }
        `;

        new BlipChat()
            .withAppKey(apiKey)
            .withButton({
                color: "#0096fa",
                icon: "https://blipmediastore.blip.ai/public-medias/Media_54effa10-c3ad-470f-8e5a-63e5885e60dc",
            })
            .withCustomCommonUrl("https://compredolado.chat.blip.ai/")
            .withCustomStyle(customStyle)
            .build();
    }, [apiKey]);

    useEffect(() => {
        if (document.getElementById("blip-chat-initializer")) return;

        const script = document.createElement("script");
        script.id = "blip-chat-initializer";
        script.src = "https://unpkg.com/blip-chat-widget";
        script.type = "text/javascript";
        script.onload = initBlipChat;

        document.body.appendChild(script);
    }, [initBlipChat]);
}
