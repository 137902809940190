import type { DEFAULT_STATUS } from "@redux/utils/helpers";

import type { ProductItemData } from "@components/ProductItem/interface";

import type {
    CompanyDashBoardOrderStatus,
    TypeCompanyDashboardCategory,
} from "@old/services/companies/dashboard/interface";

export interface Certificate {
    accountName: string;
    companyName: string;
    message: string;
}

export type State = {
    certificates: {
        data: Certificate[];
        status: DEFAULT_STATUS;
    };
    orderStatus: {
        data: CompanyDashBoardOrderStatus[];
        status: DEFAULT_STATUS;
    };
    recommendations: {
        data: ProductItemData[];
        status: DEFAULT_STATUS;
    };
    stats: {
        data: TypeCompanyDashboardCategory[];
        status: DEFAULT_STATUS;
    };
    walletCredit: {
        data: number;
        status: DEFAULT_STATUS;
    };
    lastUpdate: number;
};

export const moduleId = "dashboard" as const;

export type DashboardFields = keyof Pick<
    State,
    | "certificates"
    | "orderStatus"
    | "recommendations"
    | "stats"
    | "walletCredit"
>;
export interface FetchDataParams {
    accountName: string;
    accountId: string | number;
    fields: DashboardFields[];
    isAdmin: boolean;
}
