import type { TailwindPattern } from "../../interface";

const gridDynamicPattern: TailwindPattern = {
    base: /grid-dynamic/,
    variants: [
        { base: /grid-dynamic-max/, arbitrary: "size" },
        { base: /grid-dynamic-min/, arbitrary: "size" },
        /grid-dynamic/,
    ],
    arbitrary: "any",
};

export default gridDynamicPattern;

export const gridDynamicSample = [
    [
        "grid-dynamic-max-auto",
        "grid-dynamic-max-full",
        "grid-dynamic-max-max",
        "grid-dynamic-max-min",
        "grid-dynamic-max-px",
        "grid-dynamic-max-64",
        "grid-dynamic-max-1/2",
        "grid-dynamic-max-[50%]",
    ],
    [
        "grid-dynamic-min-auto",
        "grid-dynamic-min-full",
        "grid-dynamic-min-max",
        "grid-dynamic-min-min",
        "grid-dynamic-min-px",
        "grid-dynamic-min-64",
        "grid-dynamic-min-1/2",
        "grid-dynamic-min-[50%]",
    ],
    ["grid-dynamic"],
];
