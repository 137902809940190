import { RefObject, MutableRefObject } from "react";

export function applyCallback<Params extends any[]>(
    ref: RefObject<HTMLElement> | MutableRefObject<(HTMLElement | null)[]>,
    callback?: (element: HTMLElement, ...params: [...Params]) => void,
    ...params: Params
) {
    if (Array.isArray(ref.current)) {
        ref.current.forEach((element) => {
            if (!element) return;
            callback?.(element, ...params);
        });
    } else if (ref.current) {
        callback?.(ref.current, ...params);
    }
}
