export type PopoverPositions = `${"top" | "bottom"}${"-start" | "-end" | ""}`;

export type PopoverSizes = "lg" | "md" | "sm" | "unset";

export const popoverSizes: Record<PopoverSizes, string> = {
    lg: "w-480",
    md: "w-320",
    sm: "w-160",
    unset: "",
};

export const popoverPosition: Record<PopoverPositions, string> = {
    bottom: "top-full left-1/2 -translate-x-1/2 translate-y-8",
    "bottom-end": "top-full right-0 translate-y-8",
    "bottom-start": "top-full left-0  translate-y-8",
    top: "bottom-full left-1/2 -translate-x-1/2 -translate-y-8",
    "top-end": "bottom-full right-0 -translate-y-8",
    "top-start": "bottom-full left-0  -translate-y-8",
};

export const popoverArrowPosition: Record<PopoverPositions, string> = {
    bottom: "bottom-full left-1/2 -translate-x-1/2 translate-y-1/2",
    "bottom-end": "bottom-full right-0 -translate-x-full translate-y-1/2",
    "bottom-start": "bottom-full left-0 translate-x-full translate-y-1/2",
    top: "top-full left-1/2 -translate-x-1/2 -translate-y-1/2",
    "top-end": "top-full right-0 -translate-x-full -translate-y-1/2",
    "top-start": "top-full left-0 translate-x-full -translate-y-1/2",
};

export interface ExternalPortalTrigger {
    setup: (handle: () => void) => void;
    clear: (handle: () => void) => void;
}
