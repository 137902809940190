export default function FormatNumber(valor) {
    const numero = parseFloat(valor);

    if (!isNaN(numero)) {
        if (Number.isInteger(numero)) {
            return numero.toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        } else {
            return numero.toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
    } else {
        return "";
    }
}
