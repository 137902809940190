import DangerIcon from "./linear";

export default DangerIcon;
export { default as DangerIcon, iconInfo as DangerIconInfo } from "./linear";

export {
    default as DangerBoldIcon,
    iconInfo as DangerBoldIconInfo,
} from "./bold";
export {
    default as DangerBrokenIcon,
    iconInfo as DangerBrokenIconInfo,
} from "./broken";
export {
    default as DangerBulkIcon,
    iconInfo as DangerBulkIconInfo,
} from "./bulk";
export {
    default as DangerLinearIcon,
    iconInfo as DangerLinearIconInfo,
} from "./linear";
export {
    default as DangerOutlineIcon,
    iconInfo as DangerOutlineIconInfo,
} from "./outline";
export {
    default as DangerTwotoneIcon,
    iconInfo as DangerTwotoneIconInfo,
} from "./twotone";
