import type { FC, ReactNode } from "react";

import { useDrawerContext } from "./helpers";

import { classes } from "@utils/styles/tailwind/v4";

export interface DrawerBodyProps {
    className?: string;
    children?: ReactNode;
}

const DrawerBody: FC<DrawerBodyProps> = ({ className, children }) => {
    const { aria } = useDrawerContext();
    return (
        <div id={aria.description} className="flex-[9999] relative">
            <nav
                role="menu"
                className={classes`absolute inset-0 overflow-x-hidden overflow-y-auto ${className}`}>
                {children}
            </nav>
        </div>
    );
};

export default DrawerBody;
