import type {
    SkuPlainSpecification,
    SkuSpecificationsRequestResponse,
} from "./interface";

import type { RequestOptions } from "@services/interface";

import franchise from "@configs/rest/franchise";

export default async function getSkuSpecifications(
    skuId: string | number,
    typeCompanyIntegrationId: string | number,
    { signal }: RequestOptions = {},
): Promise<SkuPlainSpecification[]> {
    try {
        const { data } = await franchise.get<SkuSpecificationsRequestResponse>(
            `/v1/skus/${skuId}/plain-specifications`,
            { signal, params: { typeCompanyIntegrationId } },
        );
        return data.sku.plainSpecifications;
    } catch (error) {
        console.log(error);
        return [];
    }
}
