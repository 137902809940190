import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowUpDownBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M13.5508 17.28L17.2708 21L20.9908 17.28"
                stroke="currentColor"
            />
            <path d="M17.2695 10.01V21" stroke="currentColor" />
            <path d="M17.2695 3V5.98999" stroke="currentColor" />
            <path
                d="M10.4498 6.71997L6.72974 3L3.00977 6.71997"
                stroke="currentColor"
            />
            <path d="M6.73047 3V14.09" stroke="currentColor" />
            <path d="M6.73047 21V18.03" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
