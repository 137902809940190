import type { TailwindPattern } from "../interface";

const scrollPattern: TailwindPattern = {
    base: /scroll/,
    variants: [
        { base: /scroll-m-/, arbitrary: "size" },
        { base: /scroll-mx-/, arbitrary: "size" },
        { base: /scroll-my-/, arbitrary: "size" },
        { base: /scroll-ms-/, arbitrary: "size" },
        { base: /scroll-me-/, arbitrary: "size" },
        { base: /scroll-mt-/, arbitrary: "size" },
        { base: /scroll-mb-/, arbitrary: "size" },
        { base: /scroll-ml-/, arbitrary: "size" },
        { base: /scroll-mr-/, arbitrary: "size" },
        { base: /scroll-p-/, arbitrary: "size" },
        { base: /scroll-px-/, arbitrary: "size" },
        { base: /scroll-py-/, arbitrary: "size" },
        { base: /scroll-ps-/, arbitrary: "size" },
        { base: /scroll-pe-/, arbitrary: "size" },
        { base: /scroll-pt-/, arbitrary: "size" },
        { base: /scroll-pb-/, arbitrary: "size" },
        { base: /scroll-pl-/, arbitrary: "size" },
        { base: /scroll-pr-/, arbitrary: "size" },
        /scroll/,
    ],
    arbitrary: "any",
};

export default scrollPattern;

export const scrollSample = [
    ["scroll-m-px", "scroll-m-8", "scroll-m-[16px]"],
    ["scroll-mx-px", "scroll-mx-8", "scroll-mx-[16px]"],
    ["scroll-my-px", "scroll-my-8", "scroll-my-[16px]"],
    ["scroll-ms-px", "scroll-ms-8", "scroll-ms-[16px]"],
    ["scroll-me-px", "scroll-me-8", "scroll-me-[16px]"],
    ["scroll-mt-px", "scroll-mt-8", "scroll-mt-[16px]"],
    ["scroll-mb-px", "scroll-mb-8", "scroll-mb-[16px]"],
    ["scroll-ml-px", "scroll-ml-8", "scroll-ml-[16px]"],
    ["scroll-mr-px", "scroll-mr-8", "scroll-mr-[16px]"],
    ["scroll-p-px", "scroll-p-8", "scroll-p-[16px]"],
    ["scroll-px-px", "scroll-px-8", "scroll-px-[16px]"],
    ["scroll-py-px", "scroll-py-8", "scroll-py-[16px]"],
    ["scroll-ps-px", "scroll-ps-8", "scroll-ps-[16px]"],
    ["scroll-pe-px", "scroll-pe-8", "scroll-pe-[16px]"],
    ["scroll-pt-px", "scroll-pt-8", "scroll-pt-[16px]"],
    ["scroll-pb-px", "scroll-pb-8", "scroll-pb-[16px]"],
    ["scroll-pl-px", "scroll-pl-8", "scroll-pl-[16px]"],
    ["scroll-pr-px", "scroll-pr-8", "scroll-pr-[16px]"],
    ["scroll-auto", "scroll-smooth"],
];
