import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DoubleChevronRightLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 12 4.0799 L 18.52 10.5999 C 19.29 11.3699 19.29 12.6299 18.52 13.3999 L 12 19.9199"
                stroke="currentColor"
            />
            <path
                d="M 6 4.0799 L 12.52 10.5999 C 13.29 11.3699 13.29 12.6299 12.52 13.3999 L 6 19.9199"
                stroke="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
