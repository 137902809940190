import DocumentPreviousIcon from "./linear";

export default DocumentPreviousIcon;
export {
    default as DocumentPreviousIcon,
    iconInfo as DocumentPreviousIconInfo,
} from "./linear";

export {
    default as DocumentPreviousBoldIcon,
    iconInfo as DocumentPreviousBoldIconInfo,
} from "./bold";
export {
    default as DocumentPreviousBrokenIcon,
    iconInfo as DocumentPreviousBrokenIconInfo,
} from "./broken";
export {
    default as DocumentPreviousBulkIcon,
    iconInfo as DocumentPreviousBulkIconInfo,
} from "./bulk";
export {
    default as DocumentPreviousLinearIcon,
    iconInfo as DocumentPreviousLinearIconInfo,
} from "./linear";
export {
    default as DocumentPreviousOutlineIcon,
    iconInfo as DocumentPreviousOutlineIconInfo,
} from "./outline";
export {
    default as DocumentPreviousTwotoneIcon,
    iconInfo as DocumentPreviousTwotoneIconInfo,
} from "./twotone";
