import type { ReducerCreators } from "@reduxjs/toolkit";

import type {
    MergeReducerRelations,
    ReducerBuilderActionCaller,
    ReducerBuilderFunction,
    ReducerRelation,
} from "./interface";

export function createReducers<
    State,
    Dependencies extends ReducerRelation<State>,
>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: State,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deps?: Dependencies,
) {
    return function <
        Relation extends ReducerRelation<
            State,
            Dependencies extends ReducerBuilderFunction
                ? ReturnType<Dependencies>
                : NonNullable<Dependencies>
        >,
    >(relation: Relation): Relation {
        return relation;
    };
}

export function combineReducers<State>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: State,
) {
    return function <Relations extends ReducerRelation<State, any>[]>(
        ...relations: [...Relations]
    ): MergeReducerRelations<State, Relations> {
        const plains: Record<string, any> = {};
        const builders: ReducerBuilderFunction<State>[] = [];
        for (const relation of relations) {
            if (typeof relation === "function") {
                builders.push(relation);
            } else {
                const keys = Object.getOwnPropertyNames(relation);
                for (const key of keys) {
                    if (typeof relation[key] === "function") {
                        plains[key] = relation[key];
                    } else {
                        plains[key] = Object.assign(
                            {},
                            plains[key],
                            relation[key],
                        );
                    }
                }
            }
        }

        if (builders.length) {
            const apply = (
                creator: ReducerCreators<State>,
                actions: ReducerBuilderActionCaller<State>,
            ) => builders.map((builder) => builder(creator, actions));
            return ((
                creator: ReducerCreators<State>,
                actions: ReducerBuilderActionCaller<State>,
            ) => {
                const rlts = apply(creator, actions);
                const reducers = { ...plains };
                for (const rlt of rlts) {
                    const keys = Object.getOwnPropertyNames(rlt);
                    for (const key of keys) {
                        if (typeof rlt[key] === "function") {
                            reducers[key] = rlt[key];
                        } else {
                            reducers[key] = Object.assign(
                                {},
                                reducers[key],
                                rlt[key],
                            );
                        }
                    }
                }
                return reducers;
            }) as unknown as MergeReducerRelations<State, Relations>;
        }

        return plains as MergeReducerRelations<State, Relations>;
    };
}
