import type { TailwindPattern } from "../interface";

const borderPattern: TailwindPattern = {
    base: /border(?!-(spacing|collapse|separate))|divide/,
    variants: [
        /divide-(solid|dashed|dotted|double|none)/,
        { base: /divide-x/, arbitrary: "size" },
        { base: /divide-y/, arbitrary: "size" },
        { base: /divide/, arbitrary: "any" },
        /border-(solid|dashed|dotted|double|hidden|none)/,
        {
            base: /border-x(?!\w)/,
            variants: [
                {
                    base: /border-x/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-x/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-y(?!\w)/,
            variants: [
                {
                    base: /border-y/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-y/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-s(?!\w)/,
            variants: [
                {
                    base: /border-s/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-s/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-e(?!\w)/,
            variants: [
                {
                    base: /border-e/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-e/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-t(?!\w)/,
            variants: [
                {
                    base: /border-t/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-t/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-b(?!\w)/,
            variants: [
                {
                    base: /border-b/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-b/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-l(?!\w)/,
            variants: [
                {
                    base: /border-l/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-l/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border-r(?!\w)/,
            variants: [
                {
                    base: /border-r/,
                    sufixes: [/(?= |$|-\d+)/],
                    arbitrary: "size",
                },
                {
                    base: /border-r/,
                    arbitrary: "any",
                },
            ],
            arbitrary: "any",
        },
        {
            base: /border/,
            sufixes: [/(?= |$|-\d+)/],
            arbitrary: "size",
        },
        {
            base: /border/,
            arbitrary: "any",
        },
    ],
    arbitrary: "any",
};

export default borderPattern;

export const borderSample = [
    [
        "divide-solid",
        "divide-dashed",
        "divide-dotted",
        "divide-double",
        "divide-none",
    ],
    ["divide-x", "divide-x-reverse", "divide-x-2", "divide-x-[3px]"],
    ["divide-y", "divide-y-reverse", "divide-y-2", "divide-y-[3px]"],
    [
        "divide-inherit",
        "divide-current",
        "divide-transparent",
        "divide-white",
        "divide-red-300",
        "divide-white/15",
        "divide-red-500/20",
        "divide-[#ffffff]",
        "divide-[#ffffff]/30",
    ],
    [
        "border-solid",
        "border-dashed",
        "border-dotted",
        "border-double",
        "border-hidden",
        "border-none",
    ],
    ["border-x", "border-x-2", "border-x-[6px]"],
    [
        "border-x-white",
        "border-x-red-500",
        "border-x-white/15",
        "border-x-red-500/25",
        "border-x-[#ffffff]",
        "border-x-[#ffffff]/30",
    ],
    ["border-y", "border-y-2", "border-y-[6px]"],
    [
        "border-y-white",
        "border-y-red-500",
        "border-y-white/15",
        "border-y-red-500/25",
        "border-y-[#ffffff]",
        "border-y-[#ffffff]/30",
    ],
    ["border-s", "border-s-2", "border-s-[6px]"],
    [
        "border-s-white",
        "border-s-red-500",
        "border-s-white/15",
        "border-s-red-500/25",
        "border-s-[#ffffff]",
        "border-s-[#ffffff]/30",
    ],
    ["border-e", "border-e-2", "border-e-[6px]"],
    [
        "border-e-white",
        "border-e-red-500",
        "border-e-white/15",
        "border-e-red-500/25",
        "border-e-[#ffffff]",
        "border-e-[#ffffff]/30",
    ],
    ["border-t", "border-t-2", "border-t-[6px]"],
    [
        "border-t-white",
        "border-t-red-500",
        "border-t-white/15",
        "border-t-red-500/25",
        "border-t-[#ffffff]",
        "border-t-[#ffffff]/30",
    ],
    ["border-b", "border-b-2", "border-b-[6px]"],
    [
        "border-b-white",
        "border-b-red-500",
        "border-b-white/15",
        "border-b-red-500/25",
        "border-b-[#ffffff]",
        "border-b-[#ffffff]/30",
    ],
    ["border-l", "border-l-2", "border-l-[6px]"],
    [
        "border-l-white",
        "border-l-red-500",
        "border-l-white/15",
        "border-l-red-500/25",
        "border-l-[#ffffff]",
        "border-l-[#ffffff]/30",
    ],
    ["border-r", "border-r-2", "border-r-[6px]"],
    [
        "border-r-white",
        "border-r-red-500",
        "border-r-white/15",
        "border-r-red-500/25",
        "border-r-[#ffffff]",
        "border-r-[#ffffff]/30",
    ],
    ["border", "border-2", "border-[6px]"],
    [
        "border-white",
        "border-red-500",
        "border-white/15",
        "border-red-500/25",
        "border-[#ffffff]",
        "border-[#ffffff]/30",
    ],
];
