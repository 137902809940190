export default function Domain() {
    let domain = location.hostname;
    if (location.hostname !== "localhost") {
        const parts = location.hostname.split(".");
        domain = parts.slice(1).join(".");
    }

    return domain;

    // var url = location.href;

    // var urlLength = url.substring(url.indexOf("/"));
    // urlLength = urlLength.length;

    // var localhost = url.substring(0, 16);

    // var URLFull = url.substring(0, url.length - urlLength);

    // var site_domain_name = URLFull;
    // var strsArray = site_domain_name.split(".");
    // var strsArrayLen = strsArray.length;

    // var response;

    // if (localhost === "http://localhost") {
    //     response = site_domain_name;
    // } else if (strsArrayLen === 3) {
    //     response =
    //         strsArray[strsArrayLen - 2] + "." + strsArray[strsArrayLen - 1];
    // } else {
    //     response =
    //         strsArray[strsArrayLen - 3] +
    //         "." +
    //         strsArray[strsArrayLen - 2] +
    //         "." +
    //         strsArray[strsArrayLen - 1];
    // }

    // return response;
}
