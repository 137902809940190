import ComponentIcon from "./linear";

export default ComponentIcon;
export {
    default as ComponentIcon,
    iconInfo as ComponentIconInfo,
} from "./linear";

export {
    default as ComponentBoldIcon,
    iconInfo as ComponentBoldIconInfo,
} from "./bold";
export {
    default as ComponentBrokenIcon,
    iconInfo as ComponentBrokenIconInfo,
} from "./broken";
export {
    default as ComponentBulkIcon,
    iconInfo as ComponentBulkIconInfo,
} from "./bulk";
export {
    default as ComponentLinearIcon,
    iconInfo as ComponentLinearIconInfo,
} from "./linear";
export {
    default as ComponentOutlineIcon,
    iconInfo as ComponentOutlineIconInfo,
} from "./outline";
export {
    default as ComponentTwotoneIcon,
    iconInfo as ComponentTwotoneIconInfo,
} from "./twotone";
