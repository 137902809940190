import type { TailwindPattern } from "../interface";

const textDecorationPattern: TailwindPattern = {
    base: /(no-)?underline|overline|line-through|decoration/,
    variants: [
        { base: /underline-offset/, arbitrary: "size" },
        /(no-)?underline|overline|line-through/,
        /decoration-(solid|double|dotted|dashed|wavy)/,
        {
            base: /decoration/,
            sufixes: [/-(auto|from-font|\d+)/],
            arbitrary: "size",
        },
        { base: /decoration/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default textDecorationPattern;

export const textDecorationSample = [
    ["underline-offset-auto", "underline-offset-1", "underline-offset-[3px]"],
    ["underline", "overline", "line-through", "no-underline"],
    [
        "decoration-solid",
        "decoration-double",
        "decoration-dotted",
        "decoration-dashed",
        "decoration-wavy",
    ],
    [
        "decoration-auto",
        "decoration-from-font",
        "decoration-2",
        "decoration-[3px]",
    ],
    [
        "decoration-inherit",
        "decoration-current",
        "decoration-transparent",
        "decoration-white",
        "decoration-red-300",
        "decoration-white/10",
        "decoration-red-500/25",
        "decoration-[#ffffff]",
        "decoration-[#ffffff]/30",
    ],
];
