export type ObjectObserverEventTypes = "onset" | "onget";

export interface ObjectObserverEventListeners<
    T extends Record<string | number | symbol, any>,
> {
    onset: ((key: keyof T, oldValue: any, newValue: any) => void)[];
    onget: ((key: keyof T, value: any) => void)[];
}

export default class ObjectObserver<
    T extends Record<string | number | symbol, any>,
> {
    public proxy: T;
    private eventListeners: ObjectObserverEventListeners<T> = {
        onset: [],
        onget: [],
    };

    constructor(obj: T) {
        this.proxy = new Proxy(obj as Record<string | number | symbol, any>, {
            set: (target, key, value) => {
                const oldValue = target[key];
                target[key] = value;
                this.triggerEventListeners("onset", key, oldValue, value);
                return true;
            },
            get: (target, key, receiver) => {
                this.triggerEventListeners("onget", key, target[key]);
                return Reflect.get(target, key, receiver);
            },
        });
    }

    public addEventListener<E extends ObjectObserverEventTypes>(
        eventType: E,
        listener: ObjectObserverEventListeners<T>[E][number],
    ) {
        if (!this.eventListeners[eventType].includes(listener as any)) {
            this.eventListeners[eventType].push(listener as any);
        }
    }

    public removeEventListener<E extends ObjectObserverEventTypes>(
        eventType: E,
        listener: ObjectObserverEventListeners<T>[E][number],
    ) {
        const idx = this.eventListeners[eventType].indexOf(listener as any);
        if (idx !== -1) {
            this.eventListeners[eventType].splice(idx, 1);
        }
    }

    private triggerEventListeners<E extends ObjectObserverEventTypes>(
        eventType: E,
        ...arg: Parameters<ObjectObserverEventListeners<T>[E][number]>
    ) {
        for (const listener of this.eventListeners[eventType]) {
            (listener as (...a: any[]) => void)(...arg);
        }
    }
}
