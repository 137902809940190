import type { TailwindPattern } from "../interface";

const fontPattern: TailwindPattern = {
    base: /font|(subpixel-)?antialiased|(not-)?italic|(normal|lining|oldstyle|proportional|tabular)-nums|ordinal|slashed-zero|diagonal-fractions|stacked-fractions/,
    variants: [
        /(subpixel-)?antialiased/,
        /(not-)?italic/,
        /(normal|lining|oldstyle|proportional|tabular)-nums|ordinal|slashed-zero|diagonal-fractions|stacked-fractions/,
        {
            base: /font/,
            sufixes: [
                /-(thin|extralight|light|normal|medium|semibold|bold|extrabold|black)/,
            ],
            arbitrary: "number",
        },
        { base: /font/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default fontPattern;

export const fontSample = [
    ["antialiased", "subpixel-antialiased"],
    ["italic", "not-italic"],
    [
        "normal-nums",
        "ordinal",
        "slashed-zero",
        "lining-nums",
        "oldstyle-nums",
        "proportional-nums",
        "tabular-nums",
        "diagonal-fractions",
        "stacked-fractions",
    ],
    [
        "font-thin",
        "font-extralight",
        "font-light",
        "font-normal",
        "font-medium",
        "font-semibold",
        "font-bold",
        "font-extrabold",
        "font-black",
        "font-[1000]",
    ],
    ["font-sans", "font-serif", "font-mono", "font-[inter]"],
];
