import EyeIcon from "./linear";

export default EyeIcon;
export { default as EyeIcon, iconInfo as EyeIconInfo } from "./linear";

export { default as EyeBoldIcon, iconInfo as EyeBoldIconInfo } from "./bold";
export {
    default as EyeBrokenIcon,
    iconInfo as EyeBrokenIconInfo,
} from "./broken";
export { default as EyeBulkIcon, iconInfo as EyeBulkIconInfo } from "./bulk";
export {
    default as EyeLinearIcon,
    iconInfo as EyeLinearIconInfo,
} from "./linear";
export {
    default as EyeOutlineIcon,
    iconInfo as EyeOutlineIconInfo,
} from "./outline";
export {
    default as EyeTwotoneIcon,
    iconInfo as EyeTwotoneIconInfo,
} from "./twotone";
