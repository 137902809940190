import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "WalletSideBarBulk",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 20 20",
    path: (
        <>
            <path
                d="M7.91666 11.4583C7.91666 12.2667 8.54167 12.9167 9.30833 12.9167H10.875C11.5416 12.9167 12.0833 12.35 12.0833 11.6417C12.0833 10.8833 11.75 10.6083 11.2583 10.4333L8.74999 9.55834C8.25832 9.38334 7.925 9.11668 7.925 8.35001C7.925 7.65001 8.46665 7.07501 9.13332 7.07501H10.7C11.4667 7.07501 12.0917 7.72501 12.0917 8.53335"
                stroke="currentColor"
            />
            <path d="M10 6.25V13.75" stroke="currentColor" />
            <path
                d="M9.99999 1.66666C5.39999 1.66666 1.66666 5.39999 1.66666 9.99999C1.66666 13.2833 3.56666 16.125 6.33333 17.4833"
                stroke="currentColor"
            />
            <path
                d="M18.3333 10C18.3333 14.6 14.6 18.3333 10 18.3333"
                stroke="currentColor"
            />
            <path d="M18.3333 4.99999V1.66666H15" stroke="currentColor" />
            <path d="M14.1667 5.83332L18.3333 1.66666" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
