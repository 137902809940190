import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "PrinterOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M16.75 7.75H7.25C6.84 7.75 6.5 7.41 6.5 7V5C6.5 2.44 7.69 1.25 10.25 1.25H13.75C16.31 1.25 17.5 2.44 17.5 5V7C17.5 7.41 17.16 7.75 16.75 7.75ZM8 6.25H16V5C16 3.3 15.45 2.75 13.75 2.75H10.25C8.55 2.75 8 3.3 8 5V6.25Z"
                fill="currentColor"
            />
            <path
                d="M13 22.75H11C8.58 22.75 7.25 21.42 7.25 19V15C7.25 14.59 7.59 14.25 8 14.25H16C16.41 14.25 16.75 14.59 16.75 15V19C16.75 21.42 15.42 22.75 13 22.75ZM8.75 15.75V19C8.75 20.58 9.42 21.25 11 21.25H13C14.58 21.25 15.25 20.58 15.25 19V15.75H8.75Z"
                fill="currentColor"
            />
            <path
                d="M18 18.75H16C15.59 18.75 15.25 18.41 15.25 18V15.75H8.75V18C8.75 18.41 8.41 18.75 8 18.75H6C3.58 18.75 2.25 17.42 2.25 15V10C2.25 7.58 3.58 6.25 6 6.25H18C20.42 6.25 21.75 7.58 21.75 10V15C21.75 17.42 20.42 18.75 18 18.75ZM16.75 17.25H18C19.58 17.25 20.25 16.58 20.25 15V10C20.25 8.42 19.58 7.75 18 7.75H6C4.42 7.75 3.75 8.42 3.75 10V15C3.75 16.58 4.42 17.25 6 17.25H7.25V15C7.25 14.59 7.59 14.25 8 14.25H16C16.41 14.25 16.75 14.59 16.75 15V17.25Z"
                fill="currentColor"
            />
            <path
                d="M17 15.75H7C6.59 15.75 6.25 15.41 6.25 15C6.25 14.59 6.59 14.25 7 14.25H17C17.41 14.25 17.75 14.59 17.75 15C17.75 15.41 17.41 15.75 17 15.75Z"
                fill="currentColor"
            />
            <path
                d="M10 11.75H7C6.59 11.75 6.25 11.41 6.25 11C6.25 10.59 6.59 10.25 7 10.25H10C10.41 10.25 10.75 10.59 10.75 11C10.75 11.41 10.41 11.75 10 11.75Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
