import { DocumentTypes, FlowSteps } from "./interface";
import type { SignUpData } from "./interface";

export const mapStepSpecs: Record<
    FlowSteps,
    {
        prev: FlowSteps | null;
        next: FlowSteps | null;
        validation: (data: SignUpData) => boolean;
    }
> = {
    [FlowSteps.ASAAS_ACCOUNT_INFO]: {
        prev: null,
        next: FlowSteps.ASAAS_PAYMENT,
        validation: ({
            accountName,
            birthdate,
            document,
            documentType,
            email,
            financialModel,
            phone,
            userName,
        }) =>
            !!(
                accountName &&
                document &&
                documentType &&
                email &&
                phone &&
                userName &&
                (birthdate || financialModel)
            ),
    },
    [FlowSteps.ASAAS_PAYMENT]: {
        prev: FlowSteps.ASAAS_ACCOUNT_INFO,
        next: FlowSteps.CONFIRMATION,
        validation: ({ paymentMethod }) => !!paymentMethod,
    },
    [FlowSteps.CONFIRMATION]: {
        prev: null,
        next: null,
        validation: () => false,
    },
    [FlowSteps.HOTMART_ACCOUNT_INFO]: {
        prev: FlowSteps.HOTMART_DOCUMENT_VALIDATION,
        next: FlowSteps.HOTMART_PAYMENT,
        validation: ({ accountName, email, phone, userName }) =>
            !!(accountName && email && phone && userName),
    },
    [FlowSteps.HOTMART_DOCUMENT_VALIDATION]: {
        prev: null,
        next: FlowSteps.HOTMART_ACCOUNT_INFO,
        validation: ({ document, documentType, financialModel }) =>
            !!(document && documentType) &&
            (documentType !== DocumentTypes.CNPJ ||
                (documentType === DocumentTypes.CNPJ && !!financialModel)),
    },
    [FlowSteps.HOTMART_PAYMENT]: {
        prev: null,
        next: FlowSteps.CONFIRMATION,
        validation: () => true,
    },
};
