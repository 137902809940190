import cyrb64Hash from "@utils/cryptography/cyrb64Hash";

import { mapSelectorRelations } from "./helpers";
import type {
    CreateSelectorSetupFunction,
    CreateSelectorsSetupFunction,
    ExtendedSelector,
    ExtractParams,
    MergeSelectorCreators,
    MergeSelectorRelations,
    Selector,
    SelectorParsedRelation,
    SelectorRelation,
    SelectorPlainRelation,
    SelectorBuilderFunction,
} from "./interface";

export function extendSelector<
    State,
    Result,
    Params extends any[],
    Ref extends "state" | "root",
>(
    selector:
        | Selector<State, Result, Params>
        | ExtendedSelector<State, Result, Params, "root" | "state">,
    ref: Ref,
): ExtendedSelector<State, Result, Params, Ref> {
    let base: Selector<State, Result, Params>;
    if ("ref" in selector) {
        base = selector.base;
    } else {
        base = selector;
    }
    const extended = Object.assign(base, {
        base,
        with:
            (...params: Params) =>
            (root: State) =>
                base(root, ...params),
        ref,
    }) satisfies ExtendedSelector<State, Result, Params, Ref>;
    return extended;
}

function generateHash(vl: unknown): string {
    if (Array.isArray(vl)) {
        return cyrb64Hash(vl.map(generateHash).join("/"));
    }
    if (typeof vl === "object" && vl !== null) {
        const keys = Object.getOwnPropertyNames(vl).sort((a, b) =>
            a.localeCompare(b),
        ) as (keyof typeof vl)[];
        const entries: string[] = [];
        for (const key of keys) {
            entries.push(`${key}:${generateHash(vl[key])}`);
        }
        return cyrb64Hash(entries.join(","));
    }
    return `${vl}`;
}

export function createSelector<
    RootState,
    State,
    Params extends any[],
    InputResults extends any[],
    Result,
>(
    rootSelector: (root: RootState) => State,
    inputs: (state: State, ...params: [...Params]) => [...InputResults],
    output: (...results: InputResults) => Result,
): ExtendedSelector<RootState, Result, Params, "root"> {
    const cache = new Map();
    const base = (root: RootState, ...params: Params) => {
        const state = rootSelector(root);
        const inputResults = inputs(state, ...params);
        const hash = generateHash(inputResults);
        if (cache.has(hash)) {
            return cache.get(hash);
        } else {
            const outputResult = output(
                ...(inputResults.map((vl) =>
                    Object.freeze(vl),
                ) as InputResults),
            );
            cache.set(hash, outputResult);
            return outputResult;
        }
    };
    return extendSelector(base, "root");
}

export function createSelectorCreator<RootState, State>(
    rootSelector: (root: RootState) => State,
): CreateSelectorSetupFunction<State, RootState> {
    return function <Params extends any[], InputResults extends any[], Result>(
        inputs: (state: State, ...params: [...Params]) => [...InputResults],
        output: (...results: InputResults) => Result,
    ): ExtendedSelector<RootState, Result, Params, "root"> {
        return createSelector<RootState, State, Params, InputResults, Result>(
            rootSelector,
            inputs,
            output,
        );
    };
}

export function createSelectors<
    RootState,
    State,
    Relation extends SelectorRelation<State>,
>(
    rootSelector: (root: RootState) => State,
    relation: Relation,
): SelectorParsedRelation<RootState, Relation> {
    const creator = createSelectorCreator(rootSelector);

    let temp = relation as unknown as SelectorRelation<State, RootState>;
    if (typeof temp === "function") {
        temp = temp(creator) as SelectorPlainRelation<State, RootState>;
    }

    const parseSelectors = (rl: SelectorPlainRelation<State, RootState>) => {
        const parsed: Record<string, any> = {};
        for (const key in rl) {
            const selector = rl[key];
            if ("ref" in selector) {
                if (selector.ref === "state") {
                    parsed[key] = extendSelector(
                        (
                            root: RootState,
                            ...params: ExtractParams<typeof selector>
                        ) => selector.base(rootSelector(root), ...params),
                        "root",
                    );
                } else {
                    parsed[key] = selector;
                }
            } else if (typeof selector === "function") {
                parsed[key] = extendSelector(
                    (
                        root: RootState,
                        ...params: ExtractParams<typeof selector>
                    ) => selector(rootSelector(root), ...params),
                    "root",
                );
            } else {
                parsed[key] = parseSelectors(selector);
            }
        }
        return parsed;
    };

    const selectors = parseSelectors(temp);

    return selectors as SelectorParsedRelation<RootState, Relation>;
}

export function createSelectorsCreator<State>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: State,
): CreateSelectorsSetupFunction<State> {
    return function <Relation extends SelectorRelation<State>>(
        relation: Relation,
    ) {
        return <RootState>(
            rootSelector: (root: RootState) => State,
        ): SelectorParsedRelation<RootState, Relation> =>
            createSelectors<RootState, State, Relation>(rootSelector, relation);
    };
}

export function combineSelectors<State>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: State,
) {
    return function <Relations extends SelectorRelation<State>[]>(
        ...relations: [...Relations]
    ): MergeSelectorRelations<State, Relations> {
        const plains: Record<string, any> = {};
        const builders: SelectorBuilderFunction<State>[] = [];
        for (const relation of relations) {
            if (typeof relation === "function") {
                builders.push(relation);
            } else {
                mapSelectorRelations(plains, relation);
            }
        }

        if (builders.length) {
            const apply = (creator: CreateSelectorSetupFunction<State>) =>
                builders.map((builder) => builder(creator));
            return ((creator: CreateSelectorSetupFunction<State>) => {
                const rlts = apply(creator);
                const selectors = { ...plains };
                mapSelectorRelations(selectors, ...rlts);
                return selectors;
            }) as unknown as MergeSelectorRelations<State, Relations>;
        }

        return plains as MergeSelectorRelations<State, Relations>;
    };
}

export function combineSelectorCreators<State>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    initState: State,
) {
    return function <
        Creators extends ReturnType<CreateSelectorsSetupFunction<State>>[],
    >(...creators: [...Creators]): MergeSelectorCreators<State, Creators> {
        const apply = <RootState>(selector: (root: RootState) => State) =>
            creators.map((creator) => creator(selector));
        return (<RootState>(rootSelector: (root: RootState) => State) => {
            const rlts = apply(rootSelector);
            const selectors: Record<string, any> = {};
            mapSelectorRelations(selectors, ...rlts);
            return selectors;
        }) as MergeSelectorCreators<State, Creators>;
    };
}

export function formatRootSelector<RootState, State>(
    rootSelector: (
        root: RootState,
        moduleParams: {
            moduleName: string;
            moduleId: string;
            moduleHash?: string;
        },
    ) => State,
) {
    return function <
        Relation extends SelectorParsedRelation<
            RootState,
            SelectorRelation<State>
        >,
    >(selectorsSetup: (rootSelector: (root: RootState) => State) => Relation) {
        return (moduleParams: {
            moduleName: string;
            moduleId: string;
            moduleHash?: string;
        }): Relation =>
            selectorsSetup((root) => rootSelector(root, moduleParams));
    };
}
