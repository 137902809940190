import LogoutIcon from "./linear";

export default LogoutIcon;
export { default as LogoutIcon, iconInfo as LogoutIconInfo } from "./linear";

export {
    default as LogoutBoldIcon,
    iconInfo as LogoutBoldIconInfo,
} from "./bold";
export {
    default as LogoutBrokenIcon,
    iconInfo as LogoutBrokenIconInfo,
} from "./broken";
export {
    default as LogoutBulkIcon,
    iconInfo as LogoutBulkIconInfo,
} from "./bulk";
export {
    default as LogoutLinearIcon,
    iconInfo as LogoutLinearIconInfo,
} from "./linear";
export {
    default as LogoutOutlineIcon,
    iconInfo as LogoutOutlineIconInfo,
} from "./outline";
export {
    default as LogoutTwotoneIcon,
    iconInfo as LogoutTwotoneIconInfo,
} from "./twotone";
