import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ShopAddBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M21.0505 11.71V17.7C21.0505 20.46 18.8105 22.7 16.0505 22.7H7.31055C7.08055 22.7 6.85055 22.68 6.63055 22.65C7.00055 22.49 7.33055 22.2699 7.63055 21.9999C7.67055 21.9699 7.70053 21.94 7.73053 21.9C8.00053 21.67 8.23053 21.3799 8.42053 21.0599C8.79053 20.4599 9.00055 19.7499 9.00055 18.9999C9.00055 17.9499 8.59054 17 7.93054 16.28C7.83054 16.17 7.72052 16.0599 7.60052 15.9699C6.90053 15.3599 6.00055 14.9999 5.00055 14.9999C4.29055 14.9999 3.62053 15.19 3.04053 15.52C2.85053 15.62 2.67055 15.7399 2.50055 15.8799C2.43055 15.9299 2.37055 15.9899 2.31055 16.0499V11.78C3.07055 12.6 4.15056 13.0699 5.32056 13.0699C6.58056 13.0699 7.79054 12.4399 8.55054 11.4299C9.23054 12.4399 10.3905 13.0699 11.6805 13.0699C12.9605 13.0699 14.1005 12.4699 14.7905 11.4699C15.5605 12.4599 16.7505 13.0699 17.9905 13.0699C19.2005 13.0699 20.3005 12.58 21.0505 11.71Z"
                fill="currentColor"
            />
            <path
                d="M14.669 1.57007H8.66904L7.92905 8.93005C7.86905 9.61005 7.96903 10.2501 8.21903 10.8301C8.79903 12.1901 10.1591 13.0701 11.6791 13.0701C13.2191 13.0701 14.5491 12.2101 15.1491 10.8401C15.3291 10.4101 15.4391 9.91009 15.4491 9.40009V9.21008L14.669 1.57007Z"
                fill="currentColor"
            />
            <path
                opacity="0.6"
                d="M22.0392 8.59009L21.7592 5.81006C21.3392 2.79006 19.9692 1.56006 17.0392 1.56006H13.1992L13.9392 9.06006C13.9492 9.16006 13.9592 9.27008 13.9592 9.46008C14.0192 9.98008 14.1792 10.4601 14.4192 10.8901C15.1392 12.2101 16.5392 13.0601 17.9992 13.0601C19.3292 13.0601 20.5292 12.4701 21.2792 11.4301C21.8692 10.6401 22.1392 9.63009 22.0392 8.59009Z"
                fill="currentColor"
            />
            <path
                opacity="0.6"
                d="M6.26934 1.57007C3.32934 1.57007 1.9693 2.80004 1.5393 5.85004L1.26934 8.60004C1.16934 9.67004 1.45934 10.7101 2.08934 11.5201C2.84934 12.5101 4.01933 13.0701 5.31932 13.0701C6.77933 13.0701 8.17933 12.22 8.88933 10.92C9.14933 10.47 9.31931 9.95003 9.36931 9.41003L10.1493 1.58008H6.26934V1.57007Z"
                fill="currentColor"
            />
            <path
                d="M7.92999 16.28C7.82999 16.17 7.71998 16.06 7.59998 15.97C6.89998 15.36 6 15 5 15C4.29 15 3.61998 15.19 3.03998 15.52C2.84998 15.62 2.67 15.74 2.5 15.88C1.58 16.61 1 17.74 1 19C1 19.75 1.21002 20.46 1.58002 21.06C1.67002 21.22 1.78002 21.37 1.90002 21.51C1.92002 21.54 1.94997 21.56 1.96997 21.59C2.68997 22.45 3.78 23 5 23C6.01 23 6.93001 22.63 7.63 22C7.67 21.97 7.69998 21.94 7.72998 21.9C7.99998 21.67 8.22998 21.38 8.41998 21.06C8.78998 20.46 9 19.75 9 19C9 17.95 8.58999 17 7.92999 16.28ZM6.5 19.73H5.75V20.51C5.75 20.92 5.41 21.26 5 21.26C4.59 21.26 4.25 20.92 4.25 20.51V19.73H3.5C3.09 19.73 2.75 19.39 2.75 18.98C2.75 18.57 3.09 18.23 3.5 18.23H4.25V17.52C4.25 17.11 4.59 16.77 5 16.77C5.41 16.77 5.75 17.11 5.75 17.52V18.23H6.5C6.91 18.23 7.25 18.57 7.25 18.98C7.25 19.39 6.91 19.73 6.5 19.73Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
