import type { HubOffer } from "./interface";

import type {
    SkuSpecificationDefinition,
    SkuSpecificationFieldValue,
} from "../../skus/specifications/interface";

import { mapErrorData } from "../../helpers";
import type { ErrorData, RequestOptions } from "../../interface";

import { MountException, MountResponse } from "@services/helpers";
import type { RequestResponse } from "@services/interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

interface HubProductSpecificationPayload
    extends Pick<
        SkuSpecificationDefinition,
        "FieldName" | "IDStockKeepingUnitSpecificationsFieldId" | "IsVariant"
    > {
    FieldValue: (Pick<SkuSpecificationFieldValue, "Value"> &
        Partial<
            Pick<
                SkuSpecificationFieldValue,
                "IDStockKeepingUnitSpecificationsFieldValues"
            >
        >)[];
}

export interface PostOfferPayload {
    AdvertisementStatus: 0 | 1;
    AnswerTitle: string; // Mensagem Automática
    CategoryId: string; // Categoria do Anúncio
    CategoryPath: string; // Categoria do Anúncio
    Comments: string;
    Condition: string; // Condição
    HubEcommerceDescription: string; // Descrição Personalizada
    HubProductName: string; // Título Personalizado
    HubProductSalesPolicy: [];
    HubProductSpecification: HubProductSpecificationPayload[]; // Especificações
    IDCompanyIntegration: string; // Dados da Integração
    IDHubProduct: string | number | null;
    IDHubProductQuestionAnswerTemplate: string | number | null; // Mensagem Automática
    IDProduct: number; // Dados do Produto
    IDProductHub: string; // Código do Anúncio
    LeadTimeDays: number | null; // Disponibilidade de Estoque
    ListingTypeDescription: string; // Tipo de Anúncio
    ListingTypeId: string; // Tipo de Anúncio
    OfficialStore: string; // Loja Oficial
    PathName: string; // Dados da Integração
    PricingSet: number; // R$ Preço de Venda
    ShippingFree: string; // Frete Grátis
    ShippingLocalPickUp: string; // Retirar Pessoalmente
    ShippingMode: string; // Forma de Entrega
    ShowWithoutStock: 0 | 1;
    Visible: 0 | 1;
    WarrantyTime: string; // Garantia
    WarrantyTimeType: string; // Garantia
    WarrantyType: string; // Garantia
}

export default async function postOffer(
    payload: PostOfferPayload,
    { accountName, signal }: RequestOptions = {},
): Promise<RequestResponse<HubOffer>> {
    try {
        const { data } = await lambdas.post<HubOffer[]>(
            "/hub/product",
            payload,
            { baseURL: mountBaseUrl(accountName), signal },
        );
        return MountResponse(data[0]);
    } catch (error) {
        console.log(error);
        return MountException<ErrorData>(error, mapErrorData);
    }
}
