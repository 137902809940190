import WarningIcon from "./linear";

export default WarningIcon;
export { default as WarningIcon, iconInfo as WarningIconInfo } from "./linear";

export {
    default as WarningBoldIcon,
    iconInfo as WarningBoldIconInfo,
} from "./bold";
export {
    default as WarningBrokenIcon,
    iconInfo as WarningBrokenIconInfo,
} from "./broken";
export {
    default as WarningBulkIcon,
    iconInfo as WarningBulkIconInfo,
} from "./bulk";
export {
    default as WarningLinearIcon,
    iconInfo as WarningLinearIconInfo,
} from "./linear";
export {
    default as WarningOutlineIcon,
    iconInfo as WarningOutlineIconInfo,
} from "./outline";
export {
    default as WarningTwotoneIcon,
    iconInfo as WarningTwotoneIconInfo,
} from "./twotone";
