import InfoIcon from "./linear";

export default InfoIcon;
export { default as InfoIcon, iconInfo as InfoIconInfo } from "./linear";

export { default as InfoBoldIcon, iconInfo as InfoBoldIconInfo } from "./bold";
export {
    default as InfoBrokenIcon,
    iconInfo as InfoBrokenIconInfo,
} from "./broken";
export { default as InfoBulkIcon, iconInfo as InfoBulkIconInfo } from "./bulk";
export {
    default as InfoLinearIcon,
    iconInfo as InfoLinearIconInfo,
} from "./linear";
export {
    default as InfoOutlineIcon,
    iconInfo as InfoOutlineIconInfo,
} from "./outline";
export {
    default as InfoTwotoneIcon,
    iconInfo as InfoTwotoneIconInfo,
} from "./twotone";
