export function padEnd<T, F = undefined>(
    arr: T[],
    length: number,
    filler?: F,
): (T | F)[] {
    return Object.assign(new Array(length).fill(filler), arr);
}

export function padStart<T, F = undefined>(
    arr: T[],
    length: number,
    filler?: F,
): (T | F)[] {
    let temp = [];
    if (length - arr.length >= 0) {
        temp = new Array(length - arr.length).fill(filler);
    }
    return temp.concat(arr);
}
