import { createContext } from "react";

import type { SortingInfo, TabInfo } from "./interface";

type TabLoadingStatus = "idle" | "loading" | "blocking";
export interface TabLoadingEntry {
    key: string;
    status: TabLoadingStatus;
}

export interface TabsContext {
    current: string | null;
    loading: string[];
    onClose: (key: string) => void;
    onCloseAll: () => void;
    onChange: (key: string) => void;
    onSort: (sorting: (current: TabInfo[]) => SortingInfo | null) => void;
    setLoading: (current: string, tabs: TabLoadingEntry[]) => void;
    tabs: TabInfo[];
}

export const TabsContext = createContext<TabsContext>({
    current: null,
    loading: [],
    onChange: () => null,
    onClose: () => null,
    onCloseAll: () => null,
    onSort: () => null,
    setLoading: () => null,
    tabs: [],
});

export function formatTabId(slug: string): string {
    return `tab-${slug}`;
}

export function parseTabId(id: string): string {
    return id.replace(/^tab-/, "");
}

export function formatPanelId(slug: string): string {
    return `panel-${slug}`;
}
