import {
    FLUSH,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";

import type { DynamicMiddleware } from "@redux/utils/middlewares/interface";
import { fetchModules } from "@redux/utils/store/helpers";
import type { ModuleFetcher } from "@redux/utils/interface";

export const blacklist = [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE];

export default function moduleHotLauncherMiddleware(
    fetcher: ModuleFetcher,
): DynamicMiddleware<any> {
    return ({ modules, addModules }) =>
        (next) =>
        async (action) => {
            if (!blacklist.includes(action.type)) {
                const [name] = action.type.split("/");
                const module = modules.find((md) => md.name == name);

                if (!module) {
                    const response = await fetchModules(fetcher, [
                        { name, updatedAt: null },
                    ]);
                    addModules(response);
                }
            }
            return next(action);
        };
}
