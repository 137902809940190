import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "EyeSlashBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M14.5299 9.47004L9.46992 14.53C8.81992 13.88 8.41992 12.99 8.41992 12C8.41992 10.02 10.0199 8.42004 11.9999 8.42004C12.9899 8.42004 13.8799 8.82004 14.5299 9.47004Z"
                stroke="currentColor"
            />
            <path
                d="M5.59984 17.76C4.59984 16.9 3.68984 15.84 2.88984 14.59C1.98984 13.18 1.98984 10.81 2.88984 9.4C4.06984 7.55 5.50984 6.1 7.11984 5.13"
                stroke="currentColor"
            />
            <path
                d="M17.82 5.76998C16.07 4.44998 14.07 3.72998 12 3.72998"
                stroke="currentColor"
            />
            <path
                d="M8.41992 19.5301C9.55992 20.0101 10.7699 20.2701 11.9999 20.2701C15.5299 20.2701 18.8199 18.1901 21.1099 14.5901C22.0099 13.1801 22.0099 10.8101 21.1099 9.40005C20.7799 8.88005 20.4199 8.39005 20.0499 7.93005"
                stroke="currentColor"
            />
            <path
                d="M15.5095 12.7C15.2495 14.11 14.0995 15.26 12.6895 15.52"
                stroke="currentColor"
            />
            <path d="M9.47 14.53L2 22" stroke="currentColor" />
            <path d="M21.9993 2L14.5293 9.47" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
