import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ComponentBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M6.73976 18.24L3.05977 14.56C1.65977 13.16 1.65977 10.86 3.05977 9.45002L9.44978 3.06001C10.8498 1.66001 13.1498 1.66001 14.5598 3.06001L20.9498 9.45002C22.3498 10.85 22.3498 13.15 20.9498 14.56L14.5598 20.95C13.1598 22.35 10.8598 22.35 9.44978 20.95"
                stroke="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
