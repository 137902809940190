import DoubleChevronLeftIcon from "./linear";

export default DoubleChevronLeftIcon;
export {
    default as DoubleChevronLeftIcon,
    iconInfo as DoubleChevronLeftIconInfo,
} from "./linear";

export {
    default as DoubleChevronLeftBoldIcon,
    iconInfo as DoubleChevronLeftBoldIconInfo,
} from "./bold";
export {
    default as DoubleChevronLeftBrokenIcon,
    iconInfo as DoubleChevronLeftBrokenIconInfo,
} from "./broken";
export {
    default as DoubleChevronLeftBulkIcon,
    iconInfo as DoubleChevronLeftBulkIconInfo,
} from "./bulk";
export {
    default as DoubleChevronLeftLinearIcon,
    iconInfo as DoubleChevronLeftLinearIconInfo,
} from "./linear";
export {
    default as DoubleChevronLeftOutlineIcon,
    iconInfo as DoubleChevronLeftOutlineIconInfo,
} from "./outline";
export {
    default as DoubleChevronLeftTwotoneIcon,
    iconInfo as DoubleChevronLeftTwotoneIconInfo,
} from "./twotone";
