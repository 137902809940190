import type { TailwindPattern } from "../interface";

const overscrollPattern: TailwindPattern = {
    base: /overscroll/,
    variants: [/overscroll-x/, /overscroll-y/, /overscroll/],
};

export default overscrollPattern;

export const overscrollSample = [
    [
        "overscroll-x-auto",
        "overscroll-x-hidden",
        "overscroll-x-clip",
        "overscroll-x-visible",
        "overscroll-x-scroll",
    ],
    [
        "overscroll-y-auto",
        "overscroll-y-hidden",
        "overscroll-y-clip",
        "overscroll-y-visible",
        "overscroll-y-scroll",
    ],
    [
        "overscroll-auto",
        "overscroll-hidden",
        "overscroll-clip",
        "overscroll-visible",
        "overscroll-scroll",
    ],
];
