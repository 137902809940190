import type { TailwindPattern } from "../interface";

const textPattern: TailwindPattern = {
    base: /text|leading|tracking|align|hyphens|whitespace|line-clamp|indent|content-(?=none|\[)|break(?!-(after|before|inside))|truncate|uppercase|lowercase|capitalize|normal-case/,
    variants: [
        /truncate|text-(ellipsis|clip)/,
        /uppercase|lowercase|capitalize|normal-case/,
        /hyphens/,
        /whitespace/,
        /break/,
        /text-(left|center|right|justify|start|end)/,
        /text-((no)?wrap|balance|pretty)/,
        { base: /tracking/, arbitrary: "size" },
        { base: /leading/, arbitrary: "size" },
        { base: /align/, arbitrary: "position" },
        { base: /line-clamp/, arbitrary: "number" },
        { base: /indent/, arbitrary: "size" },
        { base: /content/, arbitrary: "any" },
        {
            base: /text/,
            sufixes: [/-(xs|sm|base|lg|[2-9]?xl)/],
            arbitrary: "size",
        },
        { base: /text/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default textPattern;

export const textSample = [
    ["truncate", "text-ellipsis", "text-clip"],
    ["uppercase", "lowercase", "capitalize", "normal-case"],
    ["hyphens-none", "hyphens-manual", "hyphens-auto"],
    [
        "whitespace-normal",
        "whitespace-nowrap",
        "whitespace-pre",
        "whitespace-pre-line",
        "whitespace-pre-wrap",
        "whitespace-break-spaces",
    ],
    ["break-normal", "break-words", "break-all", "break-keep"],
    [
        "text-left",
        "text-center",
        "text-right",
        "text-justify",
        "text-start",
        "text-end",
    ],
    ["text-wrap", "text-nowrap", "text-balance", "text-pretty"],
    [
        "tracking-tighter",
        "tracking-tight",
        "tracking-normal",
        "tracking-wide",
        "tracking-wider",
        "tracking-widest",
        "tracking-[0.25em]",
    ],
    [
        "leading-none",
        "leading-tight",
        "leading-snug",
        "leading-normal",
        "leading-relaxed",
        "leading-loose",
        "leading-1",
        "leading-[3rem]",
    ],
    [
        "align-baseline",
        "align-top",
        "align-middle",
        "align-bottom",
        "align-text-top",
        "align-text-bottom",
        "align-sub",
        "align-super",
        "align-[4px]",
    ],
    ["line-clamp-none", "line-clamp-1", "line-clamp-[7]"],
    ["indent-px", "indent-1", "indent-[50%]"],
    ["content-none", "content-['Hello_World']"],
    [
        "text-xs",
        "text-sm",
        "text-base",
        "text-lg",
        "text-xl",
        "text-2xl",
        "text-3xl",
        "text-4xl",
        "text-5xl",
        "text-6xl",
        "text-7xl",
        "text-8xl",
        "text-9xl",
        "text-xs/4",
        "text-[14px]",
        "text-[1.5em]",
        "text-[1vw]",
        "text-[2vh]",
        "text-[1%]",
        "text-[16px]/4",
    ],
    [
        "text-inherit",
        "text-current",
        "text-transparent",
        "text-white",
        "text-red-300",
        "text-red-500/20",
        "text-[white]",
        "text-[#ffffff]",
        "text-[rgb(150,150,150)]",
        "text-[white]/30",
    ],
];
