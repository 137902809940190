import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DocumentForwardBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M20.5 10.19H17.61C15.24 10.19 13.31 8.26 13.31 5.89V3C13.31 2.45 12.86 2 12.31 2H8.07C4.99 2 2.5 4 2.5 7.57V16.43C2.5 20 4.99 22 8.07 22H15.93C19.01 22 21.5 20 21.5 16.43V11.19C21.5 10.64 21.05 10.19 20.5 10.19ZM13.75 14.94C13.75 14.99 13.75 15.05 13.75 15.1C13.73 15.24 13.67 15.37 13.57 15.49C13.56 15.5 13.54 15.52 13.53 15.53L11.53 17.53C11.38 17.68 11.19 17.75 11 17.75C10.81 17.75 10.62 17.68 10.47 17.53C10.18 17.24 10.18 16.76 10.47 16.47L11.19 15.75H7C6.59 15.75 6.25 15.41 6.25 15C6.25 14.59 6.59 14.25 7 14.25H11.19L10.47 13.53C10.32 13.38 10.25 13.19 10.25 13C10.25 12.81 10.32 12.62 10.47 12.47C10.76 12.18 11.24 12.18 11.53 12.47L13.53 14.47C13.66 14.61 13.73 14.77 13.75 14.94Z"
                fill="currentColor"
            />
            <path
                d="M17.4297 8.81048C18.3797 8.82048 19.6997 8.82048 20.8297 8.82048C21.3997 8.82048 21.6997 8.15048 21.2997 7.75048C19.8597 6.30048 17.2797 3.69048 15.7997 2.21048C15.3897 1.80048 14.6797 2.08048 14.6797 2.65048V6.14048C14.6797 7.60048 15.9197 8.81048 17.4297 8.81048Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
