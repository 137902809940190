import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "MoreVerticalBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 16.19 2 H 7.81 C 4.17 2 2 4.17 2 7.81 V 16.18 C 2 19.83 4.17 22 7.81 22 H 16.18 C 19.82 22 21.99 19.83 21.99 16.19 V 7.81 C 22 4.17 19.83 2 16.19 2 Z M 12 18.31 C 11.28 18.31 10.69 17.72 10.69 17 C 10.69 16.28 11.28 15.69 12 15.69 C 12.72 15.69 13.31 16.28 13.31 17 C 13.31 17.72 12.72 18.31 12 18.31 Z M 12 13.31 C 11.28 13.31 10.69 12.72 10.69 12 C 10.69 11.28 11.28 10.69 12 10.69 C 12.72 10.69 13.31 11.28 13.31 12 C 13.31 12.72 12.72 13.31 12 13.31 Z M 12 8.31 C 11.28 8.31 10.69 7.72 10.69 7 C 10.69 6.28 11.28 5.69 12 5.69 C 12.72 5.69 13.31 6.28 13.31 7 C 13.31 7.72 12.72 8.31 12 8.31 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
