import type { FC, ReactNode } from "react";

import { classes } from "@utils/styles/tailwind/v4";

interface PopoverFooterProps {
    className?: string;
    children?: ReactNode;
}

const PopoverFooter: FC<PopoverFooterProps> = ({ className, children }) => {
    return (
        <footer
            className={classes`flex justify-end items-center p-16 ${className}`}>
            {children}
        </footer>
    );
};

export default PopoverFooter;
