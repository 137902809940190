import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "FilterOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M10.9404 22.6501C10.4604 22.6501 9.99039 22.5301 9.55039 22.2901C8.67039 21.8001 8.14039 20.9101 8.14039 19.9101V14.6101C8.14039 14.1101 7.81039 13.3601 7.50039 12.9801L3.76039 9.0201C3.13039 8.3901 2.65039 7.3101 2.65039 6.5001V4.2001C2.65039 2.6001 3.86039 1.3501 5.40039 1.3501H18.6004C20.1204 1.3501 21.3504 2.5801 21.3504 4.1001V6.3001C21.3504 7.3501 20.7204 8.5401 20.1304 9.1301L15.8004 12.9601C15.3804 13.3101 15.0504 14.0801 15.0504 14.7001V19.0001C15.0504 19.8901 14.4904 20.9201 13.7904 21.3401L12.4104 22.2301C11.9604 22.5101 11.4504 22.6501 10.9404 22.6501ZM5.40039 2.8501C4.70039 2.8501 4.15039 3.4401 4.15039 4.2001V6.5001C4.15039 6.8701 4.45039 7.5901 4.83039 7.9701L8.64039 11.9801C9.15039 12.6101 9.65039 13.6601 9.65039 14.6001V19.9001C9.65039 20.5501 10.1004 20.8701 10.2904 20.9701C10.7104 21.2001 11.2204 21.2001 11.6104 20.9601L13.0004 20.0701C13.2804 19.9001 13.5604 19.3601 13.5604 19.0001V14.7001C13.5604 13.6301 14.0804 12.4501 14.8304 11.8201L19.1104 8.0301C19.4504 7.6901 19.8604 6.8801 19.8604 6.2901V4.1001C19.8604 3.4101 19.3004 2.8501 18.6104 2.8501H5.40039Z"
                fill="currentColor"
            />
            <path
                d="M5.99968 10.75C5.85968 10.75 5.72968 10.71 5.59968 10.64C5.24968 10.42 5.13968 9.95002 5.35968 9.60002L10.2897 1.70002C10.5097 1.35002 10.9697 1.24002 11.3197 1.46002C11.6697 1.68002 11.7797 2.14002 11.5597 2.49002L6.62968 10.39C6.48968 10.62 6.24968 10.75 5.99968 10.75Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
