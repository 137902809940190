export interface ErrorTranslation {
    message: string | RegExp;
    messageTranslated: string;
}

export const translate: Record<string, string> = {
    ANATEL_HOMOLOGATION_NUMBER: "HOMOLOGAÇÃO ANATEL",
    COLOR: "COR",
    CARRIER: "OPERADORA",
    BRAND: "Brand",
    MODEL: "MODELO",
    DATA_STORAGE_CAPACITY: "CAPACIDADE DE ARMAZENAMENTO DE DADOS",
    LENGTH: "COMPRIMENTO",
    HEIGHT: "ALTURA",
};

export const regexTranslations = [
    {
        message:
            /^(.*?) is a required attribute for channel (.*?) and cannot be not applicable:$/,
        messageTranslated:
            "$1 é um atributo obrigatório para o canal $2 e não pode ser não aplicável.",
    },

    {
        message:
            /^Attribute (.*?) with value (\S+?) is required and was omitted. You can use a number followed by one of these valid units: \[([^\]]+)]\.$/,
        messageTranslated:
            "O atributo $1 é obrigatório. Você pode preencher com um número seguido das seguintes unidades de valor: [$3]",
    },

    {
        message: /^The category (.*?) requires a minimum price of (.+)$/,
        messageTranslated: "A categoria $1 exige um preço mínimo de $2",
    },

    {
        message:
            /^Invalid value length for attribute (.+). Maximum length is (.+).$/,
        messageTranslated: "O atributo $1 deve ter, no máximo, $2 caracteres.",
    },
    {
        message: /^Method (.*?) not allowed.$/,
        messageTranslated: "Erro inesperado, tente novamente mais tarde",
    },
    {
        message:
            /^Attribute (.+) has an invalid value \((.+)\). Allowed values are \[(.+)\]\. - Validation error:$/,
        messageTranslated:
            "O atributo $1 um valor inválido. Os valores permitidos são [$3]]",
    },

    {
        message:
            /^The attributes \[([^\]]+)] is required for category (.+?) and channel marketplace\. Check the attribute is present in the attributes list or in all variation's attributes_combination or attributes\.$/,
        messageTranslated:
            "$1 é um atributo obrigatório e não pode estar em branco. Verifique na aba Especificações | Atributos se o campo foi preenchido..",
    },

    {
        message:
            /^The attributes \[([^\]]+)] are required for category (.+?) and channel marketplace\. Check the attribute is present in the attributes list or in all variation's attributes_combination or attributes\.$/,
        messageTranslated:
            "$1 são atributos obrigatórios e não podem estar em branco. Verifique na aba Especificações | Atributos se os campos foram preenchidos.",
    },
];

export const apiErrorTranslations: ErrorTranslation[] = [
    {
        message: "Invalid OAuth access token.",
        messageTranslated: "Precisa ativar a loja de marketplaces",
    },
    {
        message: "error_auth - Invalid access_token.",
        messageTranslated: "Token de acesso inválido",
    },
    {
        message: "Descrição esta vazia. Verificar conteúdo e HTML",
        messageTranslated: "Descrição está vazia. Verificar conteúdo",
    },
    {
        message: "variations is not modifiable.:",
        messageTranslated: "Não é possível modificar variações.",
    },
    {
        message: "Product is duplicate with another product in the same shop",
        messageTranslated:
            "O produto é duplicado com outro produto na mesma loja",
    },
    {
        message:
            '["N\u00e3o foi localizado o sku:  | Azul. Inserir na DOLADO ou remover do Meli a varia\u00e7\u00e3o"] :',
        messageTranslated:
            "Não foi localizado o sku: | Azul. Inserir na DOLADO ou remover do Meli a variação",
    },
    {
        message: "| address_pending,address_empty_state,address_empty_city :",
        messageTranslated:
            "Não foi possível criar o anúncio. Verifique se o endereço de coleta foi cadastrado no marketplace.",
    },
    {
        message: "You must provide a client.id",
        messageTranslated: "Erro inesperado, tente novamente mais tarde",
    },
    ...regexTranslations,
];
