import SettingIcon from "./linear";

export default SettingIcon;
export { default as SettingIcon, iconInfo as SettingIconInfo } from "./linear";

export {
    default as SettingBoldIcon,
    iconInfo as SettingBoldIconInfo,
} from "./bold";
export {
    default as SettingBrokenIcon,
    iconInfo as SettingBrokenIconInfo,
} from "./broken";
export {
    default as SettingBulkIcon,
    iconInfo as SettingBulkIconInfo,
} from "./bulk";
export {
    default as SettingLinearIcon,
    iconInfo as SettingLinearIconInfo,
} from "./linear";
export {
    default as SettingOutlineIcon,
    iconInfo as SettingOutlineIconInfo,
} from "./outline";
export {
    default as SettingTwotoneIcon,
    iconInfo as SettingTwotoneIconInfo,
} from "./twotone";
