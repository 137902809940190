import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CalculatorLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z"
                stroke="currentColor"
            />
            <path
                d="M16.5 7.57996V8.57996C16.5 9.39996 15.83 10.08 15 10.08H9C8.18 10.08 7.5 9.40996 7.5 8.57996V7.57996C7.5 6.75996 8.17 6.07996 9 6.07996H15C15.83 6.07996 16.5 6.74996 16.5 7.57996Z"
                stroke="currentColor"
            />
            <path
                d="M8.13612 14H8.14767"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.9952 14H12.0068"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M15.8544 14H15.8659"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M8.13612 17.5H8.14767"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M11.9952 17.5H12.0068"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M15.8544 17.5H15.8659"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
