import type { RequestOptions } from "../interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

import { MountException, MountResponse } from "@services/helpers";
import type { RequestResponse } from "@services/interface";

export default async function postSkuMatch(
    skuId: string | number,
    { accountName, signal }: RequestOptions = {},
): Promise<RequestResponse> {
    try {
        await lambdas.post(`/sku/${skuId}/match`, undefined, {
            baseURL: mountBaseUrl(accountName),
            signal,
        });
        return MountResponse(null);
    } catch (error) {
        console.log(error);
        return MountException(error);
    }
}
