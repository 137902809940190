export enum DEFAULT_STATUS {
    FAILURE = "failure",
    HYDRATING = "hydrating",
    IDLE = "idle",
    LOADING = "loading",
    PENDING = "pending",
    PROCESSING = "processing",
    SUCCESS = "success",
}

export interface PageInfo {
    page: number;
    pageSize: number;
    pages: number;
    totalCount: number;
}
