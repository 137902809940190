import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CalendarTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M8 2V5" stroke="currentColor" />
            <path d="M16 2V5" stroke="currentColor" />
            <path opacity="0.4" d="M3.5 9.08984H20.5" stroke="currentColor" />
            <path
                d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                stroke="currentColor"
            />
            <path
                opacity="0.4"
                d="M15.6947 13.7002H15.7037"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M15.6947 16.7002H15.7037"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M11.9955 13.7002H12.0045"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M11.9955 16.7002H12.0045"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M8.29431 13.7002H8.30329"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M8.29395 16.7002H8.30293"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
