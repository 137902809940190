import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowDownBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.0697 14.4299L11.9997 20.4999L5.92969 14.4299"
                stroke="currentColor"
            />
            <path d="M12 12V20.33" stroke="currentColor" />
            <path d="M12 3.5V8.03" stroke="currentColor" />
        </svg>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
