import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "GlobalTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="currentColor"
            />
            <g opacity="0.4">
                <path
                    d="M7.99961 3H8.99961C7.04961 8.84 7.04961 15.16 8.99961 21H7.99961"
                    stroke="currentColor"
                />
                <path
                    d="M15 3C16.95 8.84 16.95 15.16 15 21"
                    stroke="currentColor"
                />
                <path
                    d="M3 16V15C8.84 16.95 15.16 16.95 21 15V16"
                    stroke="currentColor"
                />
                <path
                    d="M3 9.0001C8.84 7.0501 15.16 7.0501 21 9.0001"
                    stroke="currentColor"
                />
            </g>
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
