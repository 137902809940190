import MinusIcon from "./linear";

export default MinusIcon;
export { default as MinusIcon, iconInfo as MinusIconInfo } from "./linear";

export {
    default as MinusBoldIcon,
    iconInfo as MinusBoldIconInfo,
} from "./bold";
export {
    default as MinusBrokenIcon,
    iconInfo as MinusBrokenIconInfo,
} from "./broken";
export {
    default as MinusBulkIcon,
    iconInfo as MinusBulkIconInfo,
} from "./bulk";
export {
    default as MinusLinearIcon,
    iconInfo as MinusLinearIconInfo,
} from "./linear";
export {
    default as MinusOutlineIcon,
    iconInfo as MinusOutlineIconInfo,
} from "./outline";
export {
    default as MinusTwotoneIcon,
    iconInfo as MinusTwotoneIconInfo,
} from "./twotone";
