import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "BoxTickBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M19.3504 5.65995L13.0604 2.27C12.4004 1.91 11.6004 1.91 10.9304 2.27L4.64041 5.65995C4.18041 5.90995 3.90039 6.39998 3.90039 6.93998C3.90039 7.47998 4.18041 7.96995 4.64041 8.21995L10.9304 11.61C11.2604 11.79 11.6304 11.88 11.9904 11.88C12.3504 11.88 12.7204 11.79 13.0504 11.61L19.3404 8.21995C19.8004 7.96995 20.0804 7.47998 20.0804 6.93998C20.1004 6.39998 19.8104 5.90995 19.3504 5.65995Z"
                fill="currentColor"
            />
            <path
                opacity="0.4"
                d="M9.9007 12.7899L4.05069 9.85989C3.60069 9.62989 3.0807 9.65989 2.6507 9.91989C2.2207 10.1799 1.9707 10.6399 1.9707 11.1399V16.6699C1.9707 17.6299 2.50069 18.4899 3.36069 18.9199L9.21069 21.8399C9.41069 21.9399 9.63071 21.9899 9.85071 21.9899C10.1107 21.9899 10.3707 21.9199 10.6007 21.7699C11.0307 21.5099 11.2807 21.0499 11.2807 20.5499V15.0199C11.2907 14.0799 10.7607 13.2199 9.9007 12.7899Z"
                fill="currentColor"
            />
            <path
                opacity="0.4"
                d="M22.0309 11.1502V15.7401C22.0209 15.7301 22.0109 15.7101 22.0009 15.7001C22.0009 15.6901 21.991 15.6801 21.981 15.6701C21.941 15.6101 21.8909 15.5501 21.8409 15.5001C21.8309 15.4901 21.8209 15.4702 21.8109 15.4602C21.0009 14.5602 19.8109 14.0001 18.5009 14.0001C17.2409 14.0001 16.0909 14.5201 15.2709 15.3601C14.4809 16.1701 14.0009 17.2801 14.0009 18.5001C14.0009 19.3401 14.2409 20.1401 14.6509 20.8201C14.8209 21.1101 15.031 21.3701 15.261 21.6101L14.791 21.8501C14.591 21.9501 14.3709 22.0001 14.1509 22.0001C13.8909 22.0001 13.631 21.9302 13.391 21.7802C12.971 21.5202 12.7109 21.0601 12.7109 20.5601V15.0401C12.7109 14.0801 13.241 13.2201 14.101 12.7901L19.951 9.87013C20.401 9.64013 20.921 9.66012 21.351 9.93012C21.771 10.1901 22.0309 10.6502 22.0309 11.1502Z"
                fill="currentColor"
            />
            <path
                d="M21.98 15.67C21.16 14.66 19.91 14.02 18.5 14.02C17.44 14.02 16.46 14.39 15.69 15.01C14.65 15.83 14 17.1 14 18.52C14 19.36 14.24 20.16 14.65 20.84C14.92 21.29 15.26 21.68 15.66 22H15.67C16.44 22.64 17.43 23.02 18.5 23.02C19.64 23.02 20.67 22.6 21.46 21.9C21.81 21.6 22.11 21.24 22.35 20.84C22.76 20.16 23 19.36 23 18.52C23 17.44 22.62 16.44 21.98 15.67ZM20.76 17.96L18.36 20.18C18.22 20.31 18.03 20.38 17.85 20.38C17.66 20.38 17.47 20.31 17.32 20.16L16.21 19.05C15.92 18.76 15.92 18.28 16.21 17.99C16.5 17.7 16.98 17.7 17.27 17.99L17.87 18.59L19.74 16.86C20.04 16.58 20.52 16.6 20.8 16.9C21.09 17.21 21.07 17.68 20.76 17.96Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
