import type { FC } from "react";

import { Suspense, useMemo, useState } from "react";

import { loadComponent } from "./helpers";
import { moduleRelation } from "./relations";
import type { ModuleInjectedProps, ModuleSpecs } from "./interface";

import Button from "@components/@basic/Button";
import ButtonIcon from "@components/@basic/ButtonIcon";
import Loading from "@components/@basic/Loading";
import Modal, {
    ModalBody,
    ModalHeader,
    ModalFooter,
} from "@components/@basic/Modal";

import { useModalManager } from "@contexts/modal-manager-v2";
import { ModalCommonTags } from "@contexts/modal-manager-v2/relations";
import type { ModalManagerComponent } from "@contexts/modal-manager-v2/interface";

export interface ModulesProps {
    mainKey: string;
    params?: Record<string, any>;
    tag: keyof typeof moduleRelation;
    oldKey?: string;
    URLSearchParams?: string;
    oldParams?: {
        paramsI?: any;
        paramsII?: any;
        paramsIII?: any;
        paramsIV?: any;
        paramsV?: any;
    };
}

export const Modules: FC<ModulesProps> = ({
    tag,
    mainKey,
    params,
    oldKey,
    URLSearchParams,
    oldParams: _oldParams,
}) => {
    const module = useMemo<ModuleSpecs>(
        () => moduleRelation[tag as keyof typeof moduleRelation],
        [tag],
    );

    const Component = useMemo(() => module && loadComponent(module), [module]);

    if (!Component) return null;

    const props: ModuleInjectedProps["_moduleManager"] = {
        tag,
        key: mainKey,
        oldKey,
        params: params ?? {},
    };

    const search = _oldParams?.paramsI?.URLSearchParams || URLSearchParams;
    const oldParams = module.oldParams?.(_oldParams) || _oldParams;

    return (
        <Suspense
            fallback={
                <div className="flex flex-col justify-center items-center w-full h-full">
                    <Loading />
                </div>
            }>
            <Component
                _moduleManager={props}
                URLSearchParams={search}
                {...oldParams}
            />
        </Suspense>
    );
};

export const moduleModalKey = "moduleModalView";

interface ModulesModalProps extends Omit<ModulesProps, "mainKey"> {
    title?: string;
}

export const ModulesModal: ModalManagerComponent<ModulesModalProps> = ({
    _modalManager: { close, itemKey },
    title,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(true);
    const onClose = () => setIsOpen(false);

    return (
        <Modal
            size="full"
            className="max-w-1600 h-full-minus-0 divide-y divide-gray-200"
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            onCloseEnd={close}>
            <ModalHeader className="min-h-52" buttonStyle="top-10 right-10">
                <h4 className="mb-0 text-base/5 text-gray-700 font-medium">
                    {title}
                </h4>
            </ModalHeader>
            <ModalBody className="flex-1 p-16 md:px-24">
                <Modules mainKey={`${moduleModalKey}-${itemKey}`} {...props} />
            </ModalBody>
            <ModalFooter className="flex-[0] pb-10 pt-10 px-16 md:px-24">
                <Button
                    variant="outlined"
                    className="w-max self-end"
                    onClick={onClose}>
                    Fechar
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export const ModulesModalButton: FC<ModulesModalProps> = (props) => {
    const { set } = useModalManager();

    const openModal = () => set([{ tag: ModalCommonTags.MODULE_VIEW, props }]);

    return (
        <ButtonIcon
            variant="outlined"
            size="sm"
            className="border-dashed rounded-full"
            icon="eye"
            label="view"
            onClick={openModal}
        />
    );
};
