import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ShopAddOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M14.7205 22.75H9.33056C8.69056 22.75 8.13054 22.71 7.62054 22.64C7.21054 22.58 6.92052 22.2 6.98052 21.79C7.04052 21.38 7.41056 21.08 7.83056 21.15C8.27056 21.21 8.76055 21.24 9.32055 21.24H14.7106C18.8006 21.24 20.2505 19.79 20.2505 15.7V11.21C20.2505 10.8 20.5905 10.46 21.0005 10.46C21.4105 10.46 21.7505 10.8 21.7505 11.21V15.7C21.7605 20.64 19.6505 22.75 14.7205 22.75Z"
                fill="currentColor"
            />
            <path
                d="M3.03906 16.27C2.62906 16.27 2.28906 15.93 2.28906 15.52V11.22C2.28906 10.81 2.62906 10.47 3.03906 10.47C3.44906 10.47 3.78906 10.81 3.78906 11.22V15.52C3.78906 15.93 3.44906 16.27 3.03906 16.27Z"
                fill="currentColor"
            />
            <path
                d="M12.0303 12.75C10.9303 12.75 9.93027 12.32 9.22027 11.53C8.51027 10.74 8.18028 9.70999 8.29028 8.60999L8.96032 1.93005C9.00032 1.55005 9.32032 1.25 9.71032 1.25H14.3803C14.7703 1.25 15.0903 1.54005 15.1303 1.93005L15.8003 8.60999C15.9103 9.70999 15.5803 10.74 14.8703 11.53C14.1303 12.32 13.1303 12.75 12.0303 12.75ZM10.3703 2.75L9.77032 8.76001C9.70032 9.43001 9.9003 10.06 10.3203 10.52C11.1703 11.46 12.8703 11.46 13.7203 10.52C14.1403 10.05 14.3403 9.42001 14.2703 8.76001L13.6703 2.75H10.3703Z"
                fill="currentColor"
            />
            <path
                d="M18.3295 12.75C16.2995 12.75 14.4895 11.11 14.2795 9.08997L13.5795 2.07996C13.5595 1.86996 13.6295 1.66 13.7695 1.5C13.9095 1.34 14.1095 1.25 14.3295 1.25H17.3795C20.3195 1.25 21.6895 2.48 22.0995 5.5L22.3795 8.28003C22.4995 9.46003 22.1395 10.5801 21.3695 11.4301C20.5995 12.2801 19.5195 12.75 18.3295 12.75ZM15.1595 2.75L15.7795 8.93994C15.9095 10.1899 17.0795 11.25 18.3295 11.25C19.0895 11.25 19.7695 10.9601 20.2595 10.4301C20.7395 9.90005 20.9595 9.19005 20.8895 8.43005L20.6095 5.68005C20.2995 3.42005 19.5695 2.75 17.3795 2.75H15.1595Z"
                fill="currentColor"
            />
            <path
                d="M5.67028 12.75C4.48028 12.75 3.4003 12.2801 2.6303 11.4301C1.8603 10.5801 1.50029 9.46003 1.62029 8.28003L1.90032 5.53003C2.32032 2.48003 3.6903 1.25 6.6303 1.25H9.68029C9.89029 1.25 10.0903 1.34 10.2403 1.5C10.3803 1.66 10.4503 1.86996 10.4303 2.07996L9.73028 9.08997C9.51028 11.11 7.69028 12.75 5.67028 12.75ZM6.62029 2.75C4.43029 2.75 3.7003 3.40995 3.3803 5.69995L3.11028 8.42004C3.03028 9.18004 3.26029 9.89004 3.74029 10.42C4.22029 10.95 4.91028 11.24 5.67028 11.24C6.93028 11.24 8.10027 10.1801 8.22027 8.93005L8.84033 2.73999H6.62029V2.75Z"
                fill="currentColor"
            />
            <path
                d="M5 23.75C3.6 23.75 2.29001 23.1399 1.39001 22.0699C1.38001 22.0599 1.34 22.02 1.31 21.97C1.16 21.79 1.02999 21.61 0.929993 21.42C0.489993 20.71 0.25 19.87 0.25 18.99C0.25 17.53 0.900029 16.18 2.03003 15.28C2.23003 15.12 2.45 14.97 2.69 14.84C3.38 14.45 4.19 14.23 5 14.23C6.16 14.23 7.23003 14.63 8.09003 15.38C8.20003 15.46 8.34998 15.61 8.47998 15.75C9.28998 16.64 9.73999 17.78 9.73999 18.97C9.73999 19.84 9.49999 20.69 9.04999 21.42C8.82999 21.8 8.56001 22.13 8.26001 22.39C8.20001 22.45 8.14001 22.5101 8.07001 22.5601C7.28001 23.3301 6.17 23.75 5 23.75ZM2.47998 21.04C2.50998 21.07 2.55998 21.1199 2.59998 21.1899C3.14998 21.8399 4.05 22.26 5 22.26C5.79 22.26 6.55 21.97 7.13 21.45C7.16001 21.41 7.2 21.37 7.25 21.34C7.45 21.17 7.62003 20.9499 7.78003 20.6899C8.09003 20.1799 8.26001 19.61 8.26001 19.01C8.26001 18.2 7.95001 17.41 7.39001 16.8C7.32001 16.72 7.24997 16.64 7.15997 16.58C6.07997 15.65 4.59998 15.5101 3.41998 16.1801C3.25998 16.2701 3.11999 16.36 2.98999 16.47C2.20999 17.09 1.76001 18.01 1.76001 19.01C1.76001 19.6 1.91998 20.1801 2.22998 20.6801C2.30998 20.8201 2.38998 20.93 2.47998 21.03C2.46998 21.03 2.47998 21.03 2.47998 21.04Z"
                fill="currentColor"
            />
            <path
                d="M6.49976 19.73H3.50977C3.09977 19.73 2.75977 19.39 2.75977 18.98C2.75977 18.57 3.09977 18.23 3.50977 18.23H6.49976C6.90976 18.23 7.24976 18.57 7.24976 18.98C7.24976 19.39 6.90976 19.73 6.49976 19.73Z"
                fill="currentColor"
            />
            <path
                d="M5 21.26C4.59 21.26 4.25 20.92 4.25 20.51V17.52C4.25 17.11 4.59 16.77 5 16.77C5.41 16.77 5.75 17.11 5.75 17.52V20.51C5.75 20.93 5.41 21.26 5 21.26Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
