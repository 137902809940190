import type { TailwindPattern } from "../interface";

const ringPattern: TailwindPattern = {
    base: /ring/,
    variants: [
        {
            base: /ring-offset/,
            sufixes: [/-\d+/],
            arbitrary: "size",
        },
        { base: /ring-offset/, arbitrary: "any" },
        {
            base: /ring/,
            sufixes: [/(-(inset|\d+)|((?= |$)))/],
            arbitrary: "size",
        },
        { base: /ring/, arbitrary: "any" },
    ],
    arbitrary: "any",
};

export default ringPattern;

export const ringSample = [
    ["ring-offset-4", "ring-offset-[3px]"],
    [
        "ring-offset-inherit",
        "ring-offset-current",
        "ring-offset-transparent",
        "ring-offset-white",
        "ring-offset-red-300",
        "ring-offset-white/15",
        "ring-offset-red-500/20",
        "ring-offset-[#ffffff]",
        "ring-offset-[#ffffff]/30",
    ],
    ["ring", "ring-inset", "ring-3", "ring-[5px]"],
    [
        "ring-inherit",
        "ring-current",
        "ring-transparent",
        "ring-white",
        "ring-red-300",
        "ring-white/15",
        "ring-red-500/20",
        "ring-[#ffffff]",
        "ring-[#ffffff]/30",
    ],
];
