import { Button, Image, Popover, Tag, Tooltip } from "antd";
import moment from "moment";
import ComponentView from "@old/components/App/ComponentView";
import { Icon } from "@old/components/App/Icon";
import ModalView from "@old/components/App/ModalView";
import FormatCPFCNPJ from "./FormatCPFCNPJ";
import FormatNumberToBRL from "./FormatNumberToBRL";
import FormatNumberToUSA from "./FormatNumberToUSA";

import config from "./configApp";
import FormatNumberToWeight from "./FormatNumberToWeight";
import { isMobile } from "react-device-detect";
import Notification from "@old/components/Notification";
import FormatNumber from "./FormatNumber";

export function ContactColumns(
    columns,
    getColumnSearchProps,
    AppendPrependTabs,
    ToggleModalViewFile,
) {
    var dataColumns = [];
    var json;

    for (var item of columns) {
        const dataIndex = item.dataIndex;
        const dataRender = item.render;
        const dataType = item.type;
        const dataDateFormat = item.dateFormat;
        const dataIconComponent = item.iconComponent;
        const dataWidthImg = item.widthImg === undefined ? "" : item.widthImg;
        const dataHeightImg = item.height === undefined ? "" : item.height;
        const dataPreviewImg = item.preview === undefined ? true : item.preview;
        const dataEllipsis =
            item.ellipsis === undefined ? false : item.ellipsis;
        const dataConditional =
            item.conditional === undefined ? [] : item.conditional;
        const refComponent = item.refComponent;
        const dataComponent = item.component;
        const dataDescription = item.description;
        var dataSearch = "";
        var dataSorter = item.sorter === undefined ? "" : item.sorter;
        const dataTitle = item.title;
        const dataTooltip = item.tooltip;
        const dataAlign = item?.align
            ? item?.align
            : dataType === "decimal" ||
              dataType === "date" ||
              dataType === "time" ||
              dataType === "number" ||
              dataType === "percenter" ||
              dataType === "document" ||
              dataType === "weight"
            ? "right"
            : "left";
        const dataFormat = item?.format
            ? item?.format
            : dataDateFormat === "complete"
            ? "DD/MM/YYYY HH:mm:ss"
            : dataDateFormat === "month_year"
            ? "MM/YYYY"
            : "DD/MM/YYYY";

        if (item.search === true) {
            dataSearch = getColumnSearchProps(dataIndex);
        }

        if (dataSorter === false) {
            dataSorter = false;
        } else if (dataType === "date") {
            dataSorter = {
                sorter: (a, b) =>
                    new Date(a[dataIndex]) - new Date(b[dataIndex]),
            };
        } else if (
            dataType === "decimal" ||
            dataType === "number" ||
            dataType === "check" ||
            dataType === "render_installment" ||
            dataType === "conditional_number" ||
            dataType === "conditional_decimal" ||
            dataType === "component_number" ||
            dataType === "percenter" ||
            dataType === "weight"
        ) {
            dataSorter = {
                sorter: (a, b) => Number(a[dataIndex]) - Number(b[dataIndex]),
            };
        } else if (dataType === "render") {
            dataSorter = {
                sorter: (a, b) => {
                    a = a[dataIndex]?.toString() || "";
                    b = b[dataIndex]?.toString() || "";
                    return a.localeCompare(b);
                },
            };
        } else if (dataType === "tags") {
            dataSorter = {
                sorter: (a, b) => {
                    a = a[dataIndex][0]?.toString() || "";
                    b = b[dataIndex][0]?.toString() || "";
                    return a.localeCompare(b);
                },
            };
        } else {
            dataSorter = {
                sorter: (a, b) => {
                    a = a[dataIndex] || "";
                    b = b[dataIndex] || "";
                    return a.localeCompare(b);
                },
            };
        }

        json = {
            // title: item.title,
            title: () => {
                if (dataTooltip)
                    return (
                        <>
                            <Tooltip
                                placement="bottom"
                                color={"gray"}
                                title={dataTooltip}>
                                <Icon
                                    name="QuestionCircleOutlined"
                                    style={{ marginRight: "5px" }}
                                />
                            </Tooltip>
                            <span>
                                {dataTitle?.includes("renderTooltip")
                                    ? dataTitle
                                          .replace(/renderTooltip\s*$/, "")
                                          .trim()
                                    : dataTitle}
                            </span>
                        </>
                    );
                else
                    return dataTitle?.includes("renderTooltip")
                        ? dataTitle.replace(/renderTooltip\s*$/, "").trim()
                        : dataTitle;
            },
            width: item.width,
            dataIndex: dataIndex,
            ...dataSorter,
            ...dataSearch,
            type: dataType,
            fixed: isMobile
                ? false
                : item.fixed === undefined
                ? false
                : item.fixed,
            align: dataAlign,
            ellipsis: dataEllipsis,
            render: (dataIndex, row) => {
                return (
                    <span>
                        {(() => {
                            var accountName;

                            if (dataType === "conditional") {
                                return (
                                    <>
                                        {(() => {
                                            for (let itemData of dataConditional) {
                                                var operatorLabel =
                                                    itemData.operator[0] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[0];
                                                var operator =
                                                    itemData.operator[1] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[1];
                                                var operatorValue =
                                                    itemData.operator[2] ===
                                                    undefined
                                                        ? ""
                                                        : itemData
                                                              .operator[2] === 1
                                                        ? "1"
                                                        : itemData.operator[2];

                                                var label =
                                                    row[operatorLabel] ===
                                                        null ||
                                                    row[operatorLabel] ===
                                                        undefined ||
                                                    row[operatorLabel] === ""
                                                        ? ""
                                                        : row[
                                                              operatorLabel
                                                          ].toString();

                                                var value =
                                                    operatorValue === null ||
                                                    operatorValue ===
                                                        undefined ||
                                                    operatorValue === ""
                                                        ? ""
                                                        : operatorValue.toString();

                                                switch (operator) {
                                                    case "===":
                                                        if (label === value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        dataIndex
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {dataIndex}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case ">":
                                                        if (label > value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        dataIndex
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {dataIndex}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "<":
                                                        if (label < value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        dataIndex
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {dataIndex}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "!==":
                                                        if (label !== value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        dataIndex
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {dataIndex}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "default":
                                                        return dataTitle?.includes(
                                                            "renderTooltip",
                                                        ) ? (
                                                            <Tooltip
                                                                title={
                                                                    dataIndex
                                                                }>
                                                                <Tag
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tag
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                                color={
                                                                    itemData
                                                                        .value[0]
                                                                }>
                                                                {dataIndex}
                                                            </Tag>
                                                        );

                                                    default:
                                                        return dataTitle?.includes(
                                                            "renderTooltip",
                                                        ) ? (
                                                            <Tooltip
                                                                title={
                                                                    dataIndex
                                                                }>
                                                                <Tag
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        borderRadius:
                                                                            "50%",
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        "#B2BBC0"
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tag
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                                color="#B2BBC0">
                                                                {dataIndex}
                                                            </Tag>
                                                        );
                                                }
                                            }
                                        })()}
                                    </>
                                );
                            } else if (dataType === "conditional_number") {
                                return (
                                    <>
                                        {(() => {
                                            for (let itemData of dataConditional) {
                                                var operatorLabel =
                                                    itemData.operator[0] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[0];
                                                var operator =
                                                    itemData.operator[1] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[1];
                                                var operatorValue =
                                                    itemData.operator[2] ===
                                                    undefined
                                                        ? ""
                                                        : itemData
                                                              .operator[2] === 1
                                                        ? "1"
                                                        : itemData.operator[2];

                                                var label =
                                                    row[operatorLabel] ===
                                                        null ||
                                                    row[operatorLabel] ===
                                                        undefined ||
                                                    row[operatorLabel] === "" ||
                                                    dataIndex === "-"
                                                        ? "0"
                                                        : Number(
                                                              row[
                                                                  operatorLabel
                                                              ],
                                                          )?.toString();

                                                var value =
                                                    operatorValue === null ||
                                                    operatorValue ===
                                                        undefined ||
                                                    operatorValue === ""
                                                        ? ""
                                                        : operatorValue.toString();

                                                var quantity =
                                                    !dataIndex ||
                                                    dataIndex === "-"
                                                        ? 0
                                                        : FormatNumber(
                                                              dataIndex,
                                                          );

                                                switch (operator) {
                                                    case "===":
                                                        if (label === value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        quantity
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {quantity}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case ">":
                                                        if (label > value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        quantity
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {quantity}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "<":
                                                        if (label < value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        quantity
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {quantity}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "!==":
                                                        if (label !== value) {
                                                            return dataTitle?.includes(
                                                                "renderTooltip",
                                                            ) ? (
                                                                <Tooltip
                                                                    title={
                                                                        quantity
                                                                    }>
                                                                    <Tag
                                                                        style={{
                                                                            width: "15px",
                                                                            height: "15px",
                                                                            borderRadius:
                                                                                "50%",
                                                                            marginBottom:
                                                                                "0px",
                                                                        }}
                                                                        color={
                                                                            itemData
                                                                                .value[0]
                                                                        }
                                                                    />
                                                                </Tooltip>
                                                            ) : (
                                                                <Tag
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }>
                                                                    {quantity}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "default":
                                                        return dataTitle?.includes(
                                                            "renderTooltip",
                                                        ) ? (
                                                            <Tooltip
                                                                title={
                                                                    quantity
                                                                }>
                                                                <Tag
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        borderRadius:
                                                                            "50%",
                                                                    }}
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tag
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                                color={
                                                                    itemData
                                                                        .value[0]
                                                                }>
                                                                {quantity}
                                                            </Tag>
                                                        );

                                                    default:
                                                        return dataTitle?.includes(
                                                            "renderTooltip",
                                                        ) ? (
                                                            <Tooltip
                                                                title={
                                                                    quantity
                                                                }>
                                                                <Tag
                                                                    style={{
                                                                        width: "15px",
                                                                        height: "15px",
                                                                        borderRadius:
                                                                            "50%",
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}
                                                                    color={
                                                                        "#B2BBC0"
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tag
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}
                                                                color="#B2BBC0">
                                                                {quantity}
                                                            </Tag>
                                                        );
                                                }
                                            }
                                        })()}
                                    </>
                                );
                            } else if (dataType === "conditional_decimal") {
                                return (
                                    <>
                                        {(() => {
                                            for (let itemData of dataConditional) {
                                                var operatorLabel =
                                                    itemData.operator[0] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[0];

                                                var operator =
                                                    itemData.operator[1] ===
                                                    undefined
                                                        ? ""
                                                        : itemData.operator[1];

                                                var operatorValue =
                                                    itemData.operator[2] ===
                                                    undefined
                                                        ? ""
                                                        : itemData
                                                              .operator[2] === 1
                                                        ? "1"
                                                        : itemData.operator[2];

                                                var label =
                                                    row[operatorLabel] ===
                                                        null ||
                                                    row[operatorLabel] ===
                                                        undefined ||
                                                    row[operatorLabel] === ""
                                                        ? ""
                                                        : row[
                                                              operatorLabel
                                                          ].toString();

                                                var value =
                                                    operatorValue === null ||
                                                    operatorValue ===
                                                        undefined ||
                                                    operatorValue === ""
                                                        ? ""
                                                        : operatorValue.toString();

                                                switch (operator) {
                                                    case "===":
                                                        if (label === value) {
                                                            return (
                                                                <Tag
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}>
                                                                    {dataIndex ===
                                                                    "-"
                                                                        ? "-"
                                                                        : FormatNumberToBRL(
                                                                              dataIndex,
                                                                          )}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case ">":
                                                        if (label > value) {
                                                            return (
                                                                <Tag
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}>
                                                                    {dataIndex ===
                                                                    "-"
                                                                        ? "-"
                                                                        : FormatNumberToBRL(
                                                                              dataIndex,
                                                                          )}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "<":
                                                        if (label < value) {
                                                            return (
                                                                <Tag
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}>
                                                                    {dataIndex ===
                                                                    "-"
                                                                        ? "-"
                                                                        : FormatNumberToBRL(
                                                                              dataIndex,
                                                                          )}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "!==":
                                                        if (label !== value) {
                                                            return (
                                                                <Tag
                                                                    color={
                                                                        itemData
                                                                            .value[0]
                                                                    }
                                                                    style={{
                                                                        marginBottom:
                                                                            "0px",
                                                                    }}>
                                                                    {dataIndex ===
                                                                    "-"
                                                                        ? "-"
                                                                        : FormatNumberToBRL(
                                                                              dataIndex,
                                                                          )}
                                                                </Tag>
                                                            );
                                                        }
                                                        break;

                                                    case "default":
                                                        return (
                                                            <Tag
                                                                color={
                                                                    itemData
                                                                        .value[0]
                                                                }
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}>
                                                                {dataIndex ===
                                                                "-"
                                                                    ? "-"
                                                                    : FormatNumberToBRL(
                                                                          dataIndex,
                                                                      )}
                                                            </Tag>
                                                        );

                                                    default:
                                                        return (
                                                            <Tag
                                                                color="#B2BBC0"
                                                                style={{
                                                                    marginBottom:
                                                                        "0px",
                                                                }}>
                                                                {dataIndex ===
                                                                "-"
                                                                    ? "-"
                                                                    : FormatNumberToBRL(
                                                                          dataIndex,
                                                                      )}
                                                            </Tag>
                                                        );
                                                }
                                            }
                                        })()}
                                    </>
                                );
                            } else if (dataType === "number") {
                                return (() => {
                                    if (
                                        dataIndex === "null" ||
                                        dataIndex === null ||
                                        dataIndex === undefined ||
                                        dataIndex === "-"
                                    )
                                        return "";
                                    else return Number(dataIndex);
                                })();
                            } else if (dataType === "decimal") {
                                return (
                                    <>
                                        {dataIndex === "-"
                                            ? "-"
                                            : FormatNumberToBRL(dataIndex)}
                                    </>
                                );
                            } else if (dataType === "document") {
                                return <>{FormatCPFCNPJ(dataIndex)}</>;
                            } else if (dataType === "percenter") {
                                return (
                                    <>
                                        {dataIndex === "-"
                                            ? "-"
                                            : `${FormatNumberToBRL(
                                                  dataIndex * 100,
                                              )} %`}
                                    </>
                                );
                            } else if (dataType === "date") {
                                return (
                                    <>
                                        {(() => {
                                            if (
                                                dataIndex === "null" ||
                                                dataIndex === null ||
                                                dataIndex === undefined ||
                                                dataIndex ===
                                                    "4000-01-01T00:00:00.000Z" ||
                                                dataIndex ===
                                                    "1900-01-01T00:00:00.000Z" ||
                                                dataIndex ===
                                                    "4000-12-31T00:00:00.000Z" ||
                                                dataIndex ===
                                                    "0000-00-00T00:00:00.000Z" ||
                                                dataIndex === "0000-00-00" ||
                                                dataIndex === "-"
                                            )
                                                return "-";
                                            else if (
                                                dataDateFormat === "complete"
                                            )
                                                return moment(dataIndex)
                                                    .utc()
                                                    .format(dataFormat);
                                            else if (
                                                dataDateFormat === "month_year"
                                            )
                                                return moment(dataIndex)
                                                    .utc()
                                                    .format(dataFormat);
                                            else
                                                return moment(dataIndex)
                                                    .utc()
                                                    .format(dataFormat);
                                        })()}
                                    </>
                                );
                            } else if (dataType === "time") {
                                return (() => {
                                    if (
                                        dataIndex === "null" ||
                                        dataIndex === null ||
                                        dataIndex === undefined ||
                                        dataIndex === "-"
                                    )
                                        return "-";
                                    else
                                        return moment(
                                            dataIndex,
                                            "HH:mm:ss",
                                        ).format("HH:mm");
                                })();
                            } else if (dataType === "img") {
                                return (
                                    <>
                                        {dataIndex === null ||
                                        dataIndex === undefined ||
                                        dataIndex === "" ||
                                        dataIndex === "-" ? (
                                            "-"
                                        ) : (
                                            <>
                                                <Image
                                                    width={dataWidthImg}
                                                    height={dataHeightImg}
                                                    preview={
                                                        dataPreviewImg && {
                                                            src: row?.MainImageURL,
                                                        }
                                                    }
                                                    placeholder=""
                                                    src={dataIndex}
                                                />
                                            </>
                                        )}
                                    </>
                                );
                            } else if (dataType === "component_string") {
                                accountName = !row.AccountName
                                    ? config.Subdomain
                                    : row.AccountName;

                                return (
                                    <>
                                        {dataIndex === null ||
                                        dataIndex === undefined ||
                                        dataIndex === "" ||
                                        dataIndex === "-" ? (
                                            "-"
                                        ) : (
                                            <span
                                                className={
                                                    dataTitle?.includes(
                                                        "renderTooltip",
                                                    )
                                                        ? "renderTooltip"
                                                        : ""
                                                }
                                                style={{ display: "flex" }}>
                                                {(() => {
                                                    if (row[refComponent])
                                                        return (
                                                            <Popover
                                                                zIndex={5}
                                                                content={
                                                                    <>
                                                                        <ModalView
                                                                            disabled={
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    null ||
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    undefined ||
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    ""
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            size=""
                                                                            title={
                                                                                dataIndex
                                                                            }
                                                                            view={
                                                                                dataComponent
                                                                            }
                                                                            paramsI={{
                                                                                URLSearchParams: `component=${dataComponent}&title=${dataIndex}&key=${row[refComponent]}&account=${accountName}`,
                                                                            }}
                                                                            paramsII=""
                                                                            paramsIII=""
                                                                            paramsIV=""
                                                                            paramsV=""></ModalView>
                                                                        <Button
                                                                            disabled={
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    null ||
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    undefined ||
                                                                                row[
                                                                                    refComponent
                                                                                ] ===
                                                                                    ""
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onClick={() =>
                                                                                AppendPrependTabs(
                                                                                    dataComponent,
                                                                                    dataIndex,
                                                                                    row[
                                                                                        refComponent
                                                                                    ],
                                                                                    accountName,
                                                                                )
                                                                            }
                                                                            size="small"
                                                                            type="dashed"
                                                                            shape="circle"
                                                                            title="Visualizar em uma nova guia"
                                                                            icon={
                                                                                <Icon name="VerticalAlignTopOutlined" />
                                                                            }
                                                                        />
                                                                    </>
                                                                }>
                                                                <Button
                                                                    style={{
                                                                        display:
                                                                            "flex",
                                                                        marginBottom:
                                                                            "0px",
                                                                        alignItems:
                                                                            "center",
                                                                    }}
                                                                    size="small"
                                                                    type="dashed"
                                                                    shape="circle"
                                                                    icon={
                                                                        <Icon
                                                                            name="EyeOutlined"
                                                                            style={{
                                                                                margin: "4px",
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Popover>
                                                        );
                                                    else
                                                        return (
                                                            <Button
                                                                style={{
                                                                    display:
                                                                        "flex",
                                                                    marginBottom:
                                                                        "0px",
                                                                    alignItems:
                                                                        "center",
                                                                }}
                                                                size="small"
                                                                type="dashed"
                                                                shape="circle"
                                                                disabled
                                                                icon={
                                                                    <Icon
                                                                        name="EyeOutlined"
                                                                        style={{
                                                                            margin: "4px",
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        );
                                                })()}

                                                <span
                                                    style={{
                                                        margin: "5px 0 0 -10px",
                                                    }}>
                                                    {dataIndex}
                                                </span>
                                            </span>
                                        )}
                                    </>
                                );
                            } else if (dataType === "component_number") {
                                accountName = !row.AccountName
                                    ? config.Subdomain
                                    : row.AccountName;

                                return (
                                    <>
                                        {dataIndex === null ||
                                        dataIndex === undefined ||
                                        dataIndex === "" ||
                                        dataIndex === "-" ? (
                                            "-"
                                        ) : (
                                            <span
                                                className={
                                                    dataTitle?.includes(
                                                        "renderTooltip",
                                                    )
                                                        ? "renderTooltip"
                                                        : ""
                                                }
                                                style={{ display: "flex" }}>
                                                <Popover
                                                    zIndex={5}
                                                    content={
                                                        <>
                                                            <ModalView
                                                                disabled={
                                                                    row[
                                                                        refComponent
                                                                    ] ===
                                                                        null ||
                                                                    row[
                                                                        refComponent
                                                                    ] ===
                                                                        undefined ||
                                                                    row[
                                                                        refComponent
                                                                    ] === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                size=""
                                                                title={
                                                                    dataIndex
                                                                }
                                                                view={
                                                                    dataComponent
                                                                }
                                                                paramsI={{
                                                                    URLSearchParams: `component=${dataComponent}&title=${dataIndex}&key=${row[refComponent]}&account=${accountName}`,
                                                                }}
                                                                paramsII=""
                                                                paramsIII=""
                                                                paramsIV=""
                                                                paramsV=""></ModalView>
                                                            <Button
                                                                disabled={
                                                                    row[
                                                                        refComponent
                                                                    ] ===
                                                                        null ||
                                                                    row[
                                                                        refComponent
                                                                    ] ===
                                                                        undefined ||
                                                                    row[
                                                                        refComponent
                                                                    ] === ""
                                                                        ? true
                                                                        : false
                                                                }
                                                                onClick={() =>
                                                                    AppendPrependTabs(
                                                                        dataComponent,
                                                                        dataIndex,
                                                                        row[
                                                                            refComponent
                                                                        ],
                                                                        accountName,
                                                                    )
                                                                }
                                                                size="small"
                                                                type="dashed"
                                                                shape="circle"
                                                                title="Visualizar em uma nova guia"
                                                                icon={
                                                                    <Icon name="VerticalAlignTopOutlined" />
                                                                }
                                                            />
                                                        </>
                                                    }>
                                                    <Button
                                                        style={{
                                                            display: "flex",
                                                            marginBottom: "0px",
                                                            alignItems:
                                                                "center",
                                                        }}
                                                        size="small"
                                                        type="dashed"
                                                        shape="circle"
                                                        icon={
                                                            <Icon
                                                                name="EyeOutlined"
                                                                style={{
                                                                    margin: "4px",
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </Popover>

                                                <span
                                                    style={{
                                                        margin: "5px 0 0 -10px",
                                                    }}>
                                                    {dataIndex}
                                                </span>
                                            </span>
                                        )}
                                    </>
                                );
                            } else if (dataType === "component_view") {
                                accountName = !row.AccountName
                                    ? config.Subdomain
                                    : row.AccountName;

                                var dataRow =
                                    row[refComponent] === null ||
                                    row[refComponent] === undefined ||
                                    row[refComponent] === ""
                                        ? ""
                                        : row[refComponent];

                                return (
                                    <>
                                        {dataRow === "" ? (
                                            <></>
                                        ) : (
                                            <ComponentView
                                                view={dataComponent}
                                                paramsI={dataIndex}
                                                paramsII={dataRow}
                                                paramsIII={accountName}
                                                paramsIV=""
                                                paramsV=""
                                            />
                                        )}
                                    </>
                                );
                            } else if (dataType === "check") {
                                return (
                                    <>
                                        {dataIndex === 1 ? (
                                            <Icon name="CheckSquareOutlined"></Icon>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                );
                            } else if (dataType === "icon") {
                                return (
                                    <>
                                        {dataIndex ? (
                                            <Tag color="blue">
                                                <Icon name="ArrowRightOutlined" />
                                            </Tag>
                                        ) : (
                                            "=>"
                                        )}
                                    </>
                                );
                            } else if (dataType === "link") {
                                return (
                                    <div>
                                        <a
                                            href={
                                                dataIndex === "" ||
                                                dataIndex === null
                                                    ? "#"
                                                    : dataIndex
                                            }>
                                            <Button
                                                title={dataDescription}
                                                icon={
                                                    <Icon
                                                        name={dataIconComponent}
                                                    />
                                                }
                                                size="small"
                                            />
                                        </a>
                                    </div>
                                );
                            } else if (dataType === "modal_view") {
                                return (
                                    <Button
                                        title={dataDescription}
                                        icon={<Icon name={dataIconComponent} />}
                                        size="small"
                                        onClick={() =>
                                            ToggleModalViewFile(dataIndex)
                                        }
                                    />
                                );
                            } else if (dataType === "render") {
                                return (
                                    <>
                                        {row[dataRender[0]] !== undefined &&
                                        row[dataRender[0]] !== null &&
                                        row[dataRender[0]] !== ""
                                            ? row[dataRender[0]]
                                            : " - "}
                                        {row[dataRender[1]] !== undefined &&
                                        row[dataRender[1]] !== null &&
                                        row[dataRender[1]] !== ""
                                            ? " - " + row[dataRender[1]]
                                            : ""}{" "}
                                        {row[dataRender[2]] !== undefined &&
                                        row[dataRender[2]] !== null &&
                                        row[dataRender[2]] !== ""
                                            ? " - " + row[dataRender[2]]
                                            : ""}
                                    </>
                                );
                            } else if (dataType === "weight") {
                                return (
                                    <>
                                        {dataIndex === "-"
                                            ? "-"
                                            : FormatNumberToWeight(dataIndex)}
                                    </>
                                );
                            } else if (dataType === "tags") {
                                return dataIndex?.map((tag) => (
                                    <Tag style={{ marginBottom: "5px" }}>
                                        {tag}
                                    </Tag>
                                ));
                            } else {
                                return (
                                    <>
                                        {dataIndex?.length > 65
                                            ? dataIndex?.substring(0, 65) +
                                              "..."
                                            : dataIndex}
                                    </>
                                );
                            }
                        })()}
                    </span>
                );
            },
        };

        dataColumns = [...dataColumns, json];
    }

    return dataColumns;
}

export function Disabled(operator, value, label) {
    var disabled;

    switch (operator) {
        case "===":
            disabled = label.toString() === value.toString() ? false : true;
            break;

        case "!==":
            disabled = label.toString() !== value.toString() ? false : true;
            break;

        case ">":
            disabled = Number(label) > Number(value) ? false : true;
            break;

        case "<":
            disabled = Number(label) < Number(value) ? false : true;
            break;

        default:
            disabled = true;
    }

    return disabled;
}

export function ControllerJSON(field, method, state) {
    var json = {};
    var jsonData = {};

    for (const item of field) {
        if (item[method]) {
            if (item.required && !state[item.name]) {
                return Notification("warning", item.message, "Atenção");
            }
        }
    }

    //JSON
    for (const item of field) {
        if (item[method]) {
            switch (item.type) {
                case "integer":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            Number(state[item.name]),
                        ),
                    };
                    break;
                case "decimal":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            Number(
                                FormatNumberToUSA(state[item.name])?.toFixed(2),
                            ),
                        ),
                    };
                    break;
                case "date_simple":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            moment(state[item.name]).format("YYYY-MM-DD"),
                        ),
                    };
                    break;
                case "date_complete":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            moment(state[item.name]).format(
                                "YYYY-MM-DD HH:mm:ss",
                            ),
                        ),
                    };
                    break;
                case "time":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            moment(state[item.name], "HH:mm").format(
                                "HH:mm:ss",
                            ),
                        ),
                    };
                    break;
                case "document":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            state[item.name]?.replace(/\D/g, ""),
                        ),
                    };
                    break;
                case "switch":
                    jsonData = {
                        [item.nameApi]:
                            state[item.name] || state[item.name] === "1"
                                ? 1
                                : 0,
                    };
                    break;
                case "percenter":
                    var percenterValue =
                        typeof state[item.name] === "string"
                            ? Number(
                                  parseFloat(
                                      state[item.name]?.replace(",", ".") / 100,
                                  ).toFixed(4),
                              )
                            : Number(
                                  parseFloat(state[item.name] / 100).toFixed(4),
                              );
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            null,
                            percenterValue,
                        ),
                    };
                    break;
                case "weight":
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            0,
                            Number(
                                parseFloat(
                                    state[item.name]
                                        ?.replaceAll(".", "")
                                        ?.replace(",", "."),
                                ).toFixed(3),
                            ),
                        ),
                    };
                    break;
                default:
                    jsonData = {
                        [item.nameApi]: functionConditional(
                            state[item.name],
                            "",
                            state[item.name],
                        ),
                    };
            }
        }
        json = Object.assign(json, jsonData);
    }

    return json;
}

export function functionConditional(item, firstResult, secondResult) {
    return item === false ||
        item === undefined ||
        item === "undefined" ||
        item === null ||
        item === "null" ||
        item === "" ||
        item === "4000-01-01T00:00:00.000Z" ||
        item === "4000-01-01T00:00:00.000Z"
        ? firstResult
        : secondResult;
}

export function OpenUrl(URL, prefix) {
    if (prefix) {
        window.open(`https://${URL}`, "_blank");
    } else {
        window.open(URL, "_blank");
    }
}

export function Logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("companyNameCorporateName");
    localStorage.removeItem("companyLogoUrl");
    localStorage.removeItem("privileges");
    localStorage.removeItem("ListAccounts");
    localStorage.removeItem("Notification");
    localStorage.removeItem("ConfigApp");

    if (config.Domain === "http://localhost") {
        var url = window.location;
        window.location = url + "/signin";
        window.location.reload();
    } else {
        window.location.href = config.UrlFront + "/signin";
        window.location.reload();
    }
}

function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
        result.push(i);
    }
    return result;
}

export function disabledDate(current, time = "before") {
    if (time === "before") {
        // Can not select days before today and today
        return current && current <= moment().endOf("day");
    } else {
        return current && current >= moment().endOf("day");
    }
}

export function disabledDateTime() {
    return {
        disabledHours: () => range(moment().hour(), 24),
        disabledMinutes: () => range(moment().minute(), 60),
        disabledSeconds: () => range(moment().second(), 60),
    };
}
