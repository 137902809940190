import TrendUpIcon from "./linear";

export default TrendUpIcon;
export { default as TrendUpIcon, iconInfo as TrendUpIconInfo } from "./linear";

export {
    default as TrendUpBoldIcon,
    iconInfo as TrendUpBoldIconInfo,
} from "./bold";
export {
    default as TrendUpBrokenIcon,
    iconInfo as TrendUpBrokenIconInfo,
} from "./broken";
export {
    default as TrendUpBulkIcon,
    iconInfo as TrendUpBulkIconInfo,
} from "./bulk";
export {
    default as TrendUpLinearIcon,
    iconInfo as TrendUpLinearIconInfo,
} from "./linear";
export {
    default as TrendUpOutlineIcon,
    iconInfo as TrendUpOutlineIconInfo,
} from "./outline";
export {
    default as TrendUpTwotoneIcon,
    iconInfo as TrendUpTwotoneIconInfo,
} from "./twotone";
