import ChevronCircleLeftIcon from "./linear";

export default ChevronCircleLeftIcon;
export {
    default as ChevronCircleLeftIcon,
    iconInfo as ChevronCircleLeftIconInfo,
} from "./linear";

export {
    default as ChevronCircleLeftBoldIcon,
    iconInfo as ChevronCircleLeftBoldIconInfo,
} from "./bold";
export {
    default as ChevronCircleLeftBrokenIcon,
    iconInfo as ChevronCircleLeftBrokenIconInfo,
} from "./broken";
export {
    default as ChevronCircleLeftBulkIcon,
    iconInfo as ChevronCircleLeftBulkIconInfo,
} from "./bulk";
export {
    default as ChevronCircleLeftLinearIcon,
    iconInfo as ChevronCircleLeftLinearIconInfo,
} from "./linear";
export {
    default as ChevronCircleLeftOutlineIcon,
    iconInfo as ChevronCircleLeftOutlineIconInfo,
} from "./outline";
export {
    default as ChevronCircleLeftTwotoneIcon,
    iconInfo as ChevronCircleLeftTwotoneIconInfo,
} from "./twotone";
