import type { FC, ReactNode } from "react";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { TabsContext } from "./helpers";
import type { TabLoadingEntry } from "./helpers";
import type { SortingInfo, TabInfo } from "./interface";

import deepEqual from "@utils/objects/deepEqual";

interface TabsProps
    extends Pick<TabsContext, "tabs">,
        Partial<Pick<TabsContext, "onChange" | "onClose" | "onCloseAll">> {
    children?: ReactNode;
    current?: string | null;
    loading?: TabLoadingEntry[];
    onSort?: (sorting: SortingInfo | null) => void;
}

const Tabs: FC<TabsProps> = ({
    children,
    current: _current,
    loading: _loading,
    onChange: _onChange,
    onClose: _onClose,
    onCloseAll: _onCloseAll,
    onSort: _onSort,
    tabs: _tabs,
}) => {
    const [tabs, setTabs] = useState<TabInfo[]>(_tabs);
    const tabsRef = useRef(tabs);
    const [current, setCurrent] = useState(_current ?? null);
    const currentRef = useRef(current);
    const [loading, setLoading] = useState(_loading ?? []);
    const loadingRef = useRef(loading);

    useEffect(() => {
        if (_current !== currentRef.current) {
            currentRef.current = _current ?? null;
            setCurrent(_current ?? null);
        }
    }, [_current]);

    useEffect(() => {
        if (!deepEqual(_tabs, tabsRef.current)) {
            tabsRef.current = _tabs;
            setTabs(tabsRef.current);
        }
    }, [_tabs]);

    useEffect(() => {
        if (!deepEqual(_loading, loadingRef.current)) {
            loadingRef.current = _loading ?? [];
            setLoading(loadingRef.current);
        }
    }, [_loading]);

    const handleChangeLoading = useCallback<TabsContext["setLoading"]>(
        (current, entries) => {
            loadingRef.current = [...loadingRef.current];
            for (const entry of entries) {
                const idx = loadingRef.current.findIndex(
                    ({ key }) => key === entry.key,
                );
                if (idx !== -1) {
                    const curr = loadingRef.current[idx];
                    if (entry.status === "blocking") {
                        loadingRef.current[idx] = {
                            key: curr.key,
                            status: "blocking",
                        };
                    } else if (
                        curr.status !== "blocking" ||
                        (curr.status === "blocking" && curr.key === current)
                    ) {
                        loadingRef.current[idx] = {
                            key: curr.key,
                            status: entry.status,
                        };
                    }
                } else {
                    loadingRef.current.push(entry);
                }
            }
            setLoading(loadingRef.current);
        },
        [],
    );

    const onChange = useCallback<TabsContext["onChange"]>(
        (key) => {
            currentRef.current = key;
            setCurrent(key);
            _onChange?.(key);
        },
        [_onChange],
    );

    const onClose = useCallback<TabsContext["onClose"]>(
        (key) => {
            _onClose?.(key);
        },
        [_onClose],
    );

    const onCloseAll = useCallback<TabsContext["onCloseAll"]>(() => {
        _onCloseAll?.();
    }, [_onCloseAll]);

    const onSort = useCallback<TabsContext["onSort"]>(
        (sorting) => {
            const temp =
                typeof sorting === "function"
                    ? sorting(tabsRef.current)
                    : sorting;
            _onSort?.(temp);
        },
        [_onSort],
    );

    const state = useMemo<TabsContext>(
        () => ({
            current,
            loading: loading
                .filter(({ status }) => status !== "idle")
                .map(({ key }) => key),
            onChange,
            onClose,
            onCloseAll,
            onSort,
            setLoading: handleChangeLoading,
            tabs,
        }),
        [
            current,
            loading,
            onChange,
            onClose,
            onCloseAll,
            onSort,
            handleChangeLoading,
            tabs,
        ],
    );

    return (
        <TabsContext.Provider value={state}>{children}</TabsContext.Provider>
    );
};

export default Tabs;

export { default as TabList } from "./List";
export { default as TabPanels } from "./Panels";
