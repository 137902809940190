import type { CompanyBranch } from "./interface";

import type { RequestOptions } from "../interface";

import lambdas, { mountBaseUrl } from "@old/configs/rest/lambdas";

export default async function getCompanyBranches({
    accountName,
    signal,
}: RequestOptions = {}): Promise<CompanyBranch | null> {
    try {
        const { data } = await lambdas.get<CompanyBranch[]>("/company/branch", {
            baseURL: mountBaseUrl(accountName),
            signal,
        });

        if (data[0]?.IDCompany) return data[0];
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}
