import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ArrowCircleLeftOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
                fill="currentColor"
            />
            <path
                d="M15.5 12.75H9.5C9.09 12.75 8.75 12.41 8.75 12C8.75 11.59 9.09 11.25 9.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
                fill="currentColor"
            />
            <path
                d="M11.4995 15.7499C11.3095 15.7499 11.1195 15.6799 10.9695 15.5299L7.96945 12.5299C7.67945 12.2399 7.67945 11.7599 7.96945 11.4699L10.9695 8.46994C11.2595 8.17994 11.7395 8.17994 12.0295 8.46994C12.3195 8.75994 12.3195 9.23994 12.0295 9.52994L9.55945 11.9999L12.0295 14.4699C12.3195 14.7599 12.3195 15.2399 12.0295 15.5299C11.8795 15.6799 11.6895 15.7499 11.4995 15.7499Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
