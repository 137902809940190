import { useCallback, useEffect, useRef } from "react";

export default function useThrottle<Args extends any[]>(
    callback: (...args: [...Args]) => void,
    delay: number,
): (...args: Args) => void {
    const func = useRef(callback);
    useEffect(() => {
        func.current = callback;
    }, [callback]);

    const shouldCall = useRef(true);
    const throttle = useCallback(
        function (this: any, ...args: Args) {
            if (!shouldCall.current) return;
            func.current.apply(this, args);
            shouldCall.current = false;
            setTimeout(() => {
                shouldCall.current = true;
            }, delay);
        },
        [delay],
    );

    return throttle;
}
