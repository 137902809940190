import BoxTickIcon from "./linear";

export default BoxTickIcon;
export { default as BoxTickIcon, iconInfo as BoxTickIconInfo } from "./linear";

export {
    default as BoxTickBoldIcon,
    iconInfo as BoxTickBoldIconInfo,
} from "./bold";
export {
    default as BoxTickBrokenIcon,
    iconInfo as BoxTickBrokenIconInfo,
} from "./broken";
export {
    default as BoxTickBulkIcon,
    iconInfo as BoxTickBulkIconInfo,
} from "./bulk";
export {
    default as BoxTickLinearIcon,
    iconInfo as BoxTickLinearIconInfo,
} from "./linear";
export {
    default as BoxTickOutlineIcon,
    iconInfo as BoxTickOutlineIconInfo,
} from "./outline";
export {
    default as BoxTickTwotoneIcon,
    iconInfo as BoxTickTwotoneIconInfo,
} from "./twotone";
