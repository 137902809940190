import React from "react";
import { Spin } from "antd";

const CircularProgress = ({ className }) => {
    return (
        <div className={`loader ${className}`}>
            <Spin size="small" />
        </div>
    );
};

export default CircularProgress;
