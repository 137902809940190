import type { FC, ReactNode } from "react";

import { Children, cloneElement, isValidElement } from "react";

import { usePopoverContext } from "./helpers";

interface PopoverTriggerProps {
    children?: ReactNode;
}

const PopoverTrigger: FC<PopoverTriggerProps> = ({ children }) => {
    const { triggerRef } = usePopoverContext();
    return (
        <>
            {Children.map(
                Children.only(children),
                (child) =>
                    isValidElement(child) &&
                    cloneElement(child as JSX.Element, {
                        ref: (ref: HTMLElement) => (triggerRef.current = ref),
                        style: { cursor: "pointer" },
                    }),
            )}
        </>
    );
};

export default PopoverTrigger;
