import ArrowCircleUpIcon from "./linear";

export default ArrowCircleUpIcon;
export {
    default as ArrowCircleUpIcon,
    iconInfo as ArrowCircleUpIconInfo,
} from "./linear";

export {
    default as ArrowCircleUpBoldIcon,
    iconInfo as ArrowCircleUpBoldIconInfo,
} from "./bold";
export {
    default as ArrowCircleUpBrokenIcon,
    iconInfo as ArrowCircleUpBrokenIconInfo,
} from "./broken";
export {
    default as ArrowCircleUpBulkIcon,
    iconInfo as ArrowCircleUpBulkIconInfo,
} from "./bulk";
export {
    default as ArrowCircleUpLinearIcon,
    iconInfo as ArrowCircleUpLinearIconInfo,
} from "./linear";
export {
    default as ArrowCircleUpOutlineIcon,
    iconInfo as ArrowCircleUpOutlineIconInfo,
} from "./outline";
export {
    default as ArrowCircleUpTwotoneIcon,
    iconInfo as ArrowCircleUpTwotoneIconInfo,
} from "./twotone";
