import SlashIcon from "./linear";

export default SlashIcon;
export { default as SlashIcon, iconInfo as SlashIconInfo } from "./linear";

export {
    default as SlashBoldIcon,
    iconInfo as SlashBoldIconInfo,
} from "./bold";
export {
    default as SlashBrokenIcon,
    iconInfo as SlashBrokenIconInfo,
} from "./broken";
export {
    default as SlashBulkIcon,
    iconInfo as SlashBulkIconInfo,
} from "./bulk";
export {
    default as SlashLinearIcon,
    iconInfo as SlashLinearIconInfo,
} from "./linear";
export {
    default as SlashOutlineIcon,
    iconInfo as SlashOutlineIconInfo,
} from "./outline";
export {
    default as SlashTwotoneIcon,
    iconInfo as SlashTwotoneIconInfo,
} from "./twotone";
