import type {
    ActionReducerMapBuilder,
    ReducerCreators,
} from "@reduxjs/toolkit";

import {
    extendActions,
    formatModuleName,
    flatReducers,
    remapActions,
} from "./helpers";

import type {
    ModuleBuilderFunction,
    ModuleConfigs,
    ModuleData,
    ModuleParams,
} from "./interface";

import type {
    ReducerBuilderActionCaller,
    ReducerParsedRelation,
    ReducerRelation,
} from "../reducers/interface";
import { createSlice } from "../slice";
import { createSelectors } from "../selectors";
import { mapSelectorRelations } from "../selectors/helpers";
import type {
    SelectorParsedRelation,
    SelectorRelation,
} from "../selectors/interface";

interface CreateModuleOptions<
    State,
    ID extends string,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<
        Record<string, any>,
        SelectorRelation<State | undefined>
    >,
    RootSelectors extends (
        options: ModuleParams<ID>,
    ) => SelectorParsedRelation<Record<string, any>, Record<string, any>>,
> {
    initialState: State | (() => State);
    reducers: Reducers;
    extraReducers?: (builder: ActionReducerMapBuilder<State>) => void;
    selectors: Selectors;
    extraSelectors?: ExtraSelectors;
    rootSelectors?: RootSelectors;
}

export function createModule<
    State,
    ID extends string,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<Record<string, any>, NonNullable<unknown>>,
    RootSelectors extends (
        options: ModuleParams,
    ) => SelectorParsedRelation<Record<string, any>, Record<string, any>>,
>(
    {
        reducers,
        selectors,
        extraSelectors,
        rootSelectors,
        ...options
    }: CreateModuleOptions<
        State,
        ID,
        Reducers,
        Selectors,
        ExtraSelectors,
        RootSelectors
    >,
    configs: ModuleConfigs<State, ID>,
): ModuleData<
    State,
    ID,
    ReducerParsedRelation<Reducers>,
    Selectors,
    ExtraSelectors,
    RootSelectors
> {
    const name = formatModuleName(configs);
    const moduleParams: ModuleParams<ID> = {
        moduleName: name,
        moduleId: configs.id,
        moduleHash: configs.hash,
    };

    let actions: Record<string, any> = {};
    const actionCaller = ((actionTag) => {
        return actions[actionTag] || (() => ({ type: "", payload: null }));
    }) as ReducerBuilderActionCaller<State, ReducerParsedRelation<Reducers>>;

    let temp: any = reducers;
    if (typeof reducers === "function") {
        temp = (creator: ReducerCreators<State>) => {
            const plain = reducers(
                { ...creator, ...moduleParams },
                actionCaller,
            );
            return flatReducers(plain);
        };
    } else {
        temp = flatReducers(temp);
    }

    const slice = createSlice({ ...options, name, reducers: temp });

    actions = slice.actions;

    const selectSlice = (root: Record<string, any>) =>
        root[name] as State | undefined;

    const mainSelectors = createSelectors(selectSlice, selectors);

    let extra = {} as ExtraSelectors extends (...args: any[]) => infer RL
        ? RL
        : NonNullable<unknown>;
    if (extraSelectors) {
        extra = extraSelectors(selectSlice) as ExtraSelectors extends (
            ...args: any[]
        ) => infer RL
            ? RL
            : NonNullable<unknown>;
    }

    let root = {} as RootSelectors extends (...args: any[]) => infer RL
        ? RL
        : NonNullable<unknown>;
    if (rootSelectors) {
        root = rootSelectors(moduleParams) as RootSelectors extends (
            ...args: any[]
        ) => infer RL
            ? RL
            : NonNullable<unknown>;
    }

    type Module = ModuleData<
        State,
        ID,
        ReducerParsedRelation<Reducers>,
        Selectors,
        ExtraSelectors,
        RootSelectors
    >;

    const parsedSelectors = {} as Module["selectors"];
    mapSelectorRelations(parsedSelectors, mainSelectors, extra, root as any);

    return {
        actions: remapActions(extendActions(configs.id, slice.actions)),
        fixed: configs.fixed,
        hash: configs.hash,
        id: configs.id,
        name,
        persist: configs.persist,
        reducer: slice.reducer,
        selectSlice,
        selectors: parsedSelectors,
        updatedAt: null,
    } as Module;
}

export function createModuleCreator<
    State,
    ID extends string,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<
        Record<string, any>,
        SelectorRelation<State | undefined>
    >,
    RootSelectors extends (
        options: ModuleParams,
    ) => SelectorParsedRelation<Record<string, any>, Record<string, any>>,
>(
    options: CreateModuleOptions<
        State,
        ID,
        Reducers,
        Selectors,
        ExtraSelectors,
        RootSelectors
    >,
    defaultConfigs: ModuleConfigs<State, ID>,
): ModuleBuilderFunction<
    State,
    ID,
    ReducerParsedRelation<Reducers>,
    Selectors,
    ExtraSelectors,
    RootSelectors
> {
    return (configs) =>
        createModule(options, { ...defaultConfigs, ...configs }) as ModuleData<
            State,
            ID,
            ReducerParsedRelation<Reducers>,
            Selectors,
            ExtraSelectors,
            RootSelectors
        >;
}
