import { AxiosError } from "axios";

import type {
    ErrorData,
    RequestResponse,
    RequestFailureResponse,
    RequestSuccessResponse,
} from "./interface";

export function MountResponse<Data = void>(
    data: Data,
): RequestSuccessResponse<Data> {
    return {
        __success: true,
        data,
    };
}

type ErrorMapFunction<Data, ResponseData> = (
    data?: Data,
) => Partial<ErrorData> & { data?: ResponseData };

export function MountException<Data = ErrorData, ResponseData = void>(
    error: unknown,
    map: ErrorMapFunction<Data, ResponseData> = (data) => ({ ...data }),
): RequestFailureResponse<ResponseData> {
    const temp = error as AxiosError;
    const data = temp.response?.data as Data | undefined;
    const errorInfo = map(data);
    return {
        __success: false,
        data: errorInfo?.data ?? (data as unknown as ResponseData),
        message: errorInfo?.message ?? temp.message ?? "Internal Error",
        statusCode: errorInfo?.statusCode ?? temp.response?.status ?? 500,
    };
}

export function formatQuery<
    P extends Record<
        string,
        | string
        | boolean
        | number
        | null
        | (string | number | boolean)[]
        | undefined
    >,
>(params: P): string {
    const temp = new URLSearchParams();
    let key: keyof P;
    for (key in params) {
        if (typeof params[key] !== "undefined") {
            temp.append(key, `${params[key]}`);
        }
    }
    return temp.toString();
}

export function hasSuccessed<Data>(
    res: RequestResponse<Data>,
): res is RequestSuccessResponse<Data> {
    return res.__success;
}

export function hasFailed<Data>(
    res: RequestResponse<any, Data>,
): res is RequestFailureResponse<Data> {
    return !res.__success;
}
