import type { TailwindPattern } from "../../interface";

const filterColorsPattern: TailwindPattern = {
    base: /filter-(?!none)/,
    arbitrary: "any",
};

export default filterColorsPattern;

export const filterColorsSample = [
    [
        "filter-white",
        "filter-red-300",
        "filter-red-500/20",
        "filter-[white]",
        "filter-[#ffffff]",
        "filter-[rgb(150,150,150)]",
        "filter-[white]/30",
    ],
];
