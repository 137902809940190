import type { FilterState } from "@redux/plugins/filter-manager/interface";
import type { PaginationState } from "@redux/plugins/pagination-manager/interface";

import type {
    FilterDrawerExtraData,
    FilterDrawerGroupExtraData,
} from "@components/FilterDrawer/interface";

import type { GetAccountPayloadsParams } from "@services/accounts/payloads/getAll";
import type { AccountPayloadWithAudits } from "@services/accounts/payloads/interface";

export type PaginationParams = Omit<GetAccountPayloadsParams, "limit" | "page">;

export type State = {
    payloads: PaginationState<AccountPayloadWithAudits>;
    filters: FilterState<FilterDrawerExtraData, FilterDrawerGroupExtraData>;
};

export const moduleId = "accountPayloads" as const;
