import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CalculatorTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M10 22H14C19 22 21 20 21 15V9C21 4 19 2 14 2H10C5 2 3 4 3 9V15C3 20 5 22 10 22Z"
                stroke="currentColor"
            />
            <path
                opacity="0.4"
                d="M16.5 7.58008V8.58008C16.5 9.40008 15.83 10.0801 15 10.0801H9C8.18 10.0801 7.5 9.41008 7.5 8.58008V7.58008C7.5 6.76008 8.17 6.08008 9 6.08008H15C15.83 6.08008 16.5 6.75008 16.5 7.58008Z"
                stroke="currentColor"
            />
            <path
                opacity="0.4"
                d="M8.13612 14H8.14767"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M11.9955 14H12.007"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M15.8549 14H15.8664"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M8.13612 17.5H8.14767"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M11.9955 17.5H12.007"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                opacity="0.4"
                d="M15.8549 17.5H15.8664"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
