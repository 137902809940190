import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "LoginTwotone",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M8.90039 7.56023C9.21039 3.96023 11.0604 2.49023 15.1104 2.49023H15.2404C19.7104 2.49023 21.5004 4.28023 21.5004 8.75023V15.2702C21.5004 19.7402 19.7104 21.5302 15.2404 21.5302H15.1104C11.0904 21.5302 9.24039 20.0802 8.91039 16.5402"
                stroke="currentColor"
            />
            <g opacity="0.4">
                <path d="M2 12H14.88" stroke="currentColor" />
                <path
                    d="M12.6504 8.65039L16.0004 12.0004L12.6504 15.3504"
                    stroke="currentColor"
                />
            </g>
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
