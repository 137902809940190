import { ErrorTranslation, translate } from "./data/apiErrorTranslations";

export const translateErrorMessage = (
    originalMessage: string,
    errorTranslations: ErrorTranslation[],
): string => {
    const error = findError(originalMessage, errorTranslations);

    if (error) {
        const match = originalMessage.match(error.message as RegExp);

        if (match) {
            let translatedMessage = originalMessage;

            Object.keys(translate).forEach((term) => {
                translatedMessage = translatedMessage.replace(
                    new RegExp(term, "g"),
                    translate[term as keyof typeof translate] || term,
                );
            });

            return translatedMessage.replace(
                error.message,
                error.messageTranslated,
            );
        }
    }
    return originalMessage;
};

const findError = (
    originalMessage: string,
    errorTranslations: ErrorTranslation[],
) => {
    return errorTranslations.find((error) =>
        typeof error.message === "string"
            ? originalMessage.includes(error.message)
            : error.message instanceof RegExp &&
              error.message.test(originalMessage),
    );
};
