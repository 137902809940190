import type { TailwindPattern } from "../interface";

const displayPattern: TailwindPattern = {
    base: /block|flex(?!-)|flow-root|grid(?!-)|contents|list-item|hidden|inline(-(block|flex|table|grid))?|(?<!inline-)table(?!-(auto|fixed))(-(caption|cell|column(-group)?|footer-group|header-group|row(-group)?))?/,
};

export default displayPattern;

export const displaySample = [
    [
        "block",
        "inline-block",
        "inline",
        "flex",
        "inline-flex",
        "table",
        "inline-table",
        "table-caption",
        "table-cell",
        "table-column",
        "table-column-group",
        "table-footer-group",
        "table-header-group",
        "table-row-group",
        "table-row",
        "flow-root",
        "grid",
        "inline-grid",
        "contents",
        "list-item",
        "hidden",
    ],
];
