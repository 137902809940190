import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ChevronLeftBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M11.1897 7.93994L8.56969 10.5599C7.79969 11.3299 7.79969 12.5899 8.56969 13.3599L15.0897 19.8799"
                stroke="currentColor"
            />
            <path d="M15.0908 4.04004L14.0508 5.08004" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
