import type { TailwindPattern } from "../interface";

const backgroundPattern: TailwindPattern = {
    base: /(bg|from|via|to)-(?!pos|size)/,
    variants: [
        {
            base: /bg/,
            variants: [
                /bg-blend/,
                /bg-clip/,
                /bg-origin/,
                /bg-(fixed|local|scroll)/,
                /bg-((no-)?repeat(-(x|y|round|space))?)/,
                {
                    base: /bg/,
                    sufixes: [
                        /-(bottom|top|center|left(-(bottom|top))?|right(-(bottom|top))?)/,
                    ],
                    arbitrary: "position",
                },
                {
                    base: /bg/,
                    sufixes: [/-(none|gradient-to-(r|l|t(r|l)?|b(r|l)?))/],
                    arbitrary: "image",
                },
                {
                    base: /bg/,
                    sufixes: [/-(auto|cover|contain)/],
                    arbitrary: "size",
                },
                { base: /bg/, arbitrary: "any" },
            ],
            arbitrary: "any",
        },
        {
            base: /from/,
            variants: [
                { base: /from/, sufixes: [/-\d+/], arbitrary: "size" },
                { base: /from/, arbitrary: "any" },
            ],
            arbitrary: "any",
        },
        {
            base: /via/,
            variants: [
                { base: /via/, sufixes: [/-\d+/], arbitrary: "size" },
                { base: /via/, arbitrary: "any" },
            ],
            arbitrary: "any",
        },
        {
            base: /to/,
            variants: [
                { base: /to/, sufixes: [/-\d+/], arbitrary: "size" },
                { base: /to/, arbitrary: "any" },
            ],
            arbitrary: "any",
        },
    ],
    arbitrary: "any",
};

export default backgroundPattern;

export const backgroundSample = [
    [
        "bg-blend-normal",
        "bg-blend-multiply",
        "bg-blend-screen",
        "bg-blend-overlay",
        "bg-blend-darken",
        "bg-blend-lighten",
        "bg-blend-color-dodge",
        "bg-blend-color-burn",
        "bg-blend-hard-light",
        "bg-blend-soft-light",
        "bg-blend-difference",
        "bg-blend-exclusion",
        "bg-blend-hue",
        "bg-blend-saturation",
        "bg-blend-color",
        "bg-blend-luminosity",
    ],
    ["bg-clip-border", "bg-clip-padding", "bg-clip-content", "bg-clip-text"],
    ["bg-origin-border", "bg-origin-padding", "bg-origin-content"],
    ["bg-fixed", "bg-local", "bg-scroll"],
    [
        "bg-repeat",
        "bg-no-repeat",
        "bg-repeat-x",
        "bg-repeat-y",
        "bg-repeat-round",
        "bg-repeat-space",
    ],
    [
        "bg-bottom",
        "bg-center",
        "bg-left",
        "bg-left-bottom",
        "bg-left-top",
        "bg-right",
        "bg-right-bottom",
        "bg-right-top",
        "bg-top",
        "bg-[top]",
        "bg-[center_10%]",
        "bg-[center_top_1rem]",
    ],
    [
        "bg-none",
        "bg-gradient-to-t",
        "bg-gradient-to-tr",
        "bg-gradient-to-r",
        "bg-gradient-to-br",
        "bg-gradient-to-b",
        "bg-gradient-to-bl",
        "bg-gradient-to-l",
        "bg-gradient-to-tl",
        "bg-[url('/path/image')]",
    ],
    ["bg-auto", "bg-cover", "bg-contain", "bg-[length:200px_100px]"],
    [
        "bg-white",
        "bg-red-500",
        "bg-white/15",
        "bg-red-500/25",
        "bg-[#ffffff]",
        "bg-[#ffffff]/30",
    ],
    ["from-10%", "from-100%", "from-[12%]"],
    [
        "from-inherit",
        "from-current",
        "from-transparent",
        "from-white",
        "from-red-300",
        "from-[#ffffff]",
    ],
    ["via-10%", "via-100%", "via-[12%]"],
    [
        "via-inherit",
        "via-current",
        "via-transparent",
        "via-white",
        "via-red-300",
        "via-[#ffffff]",
    ],
    ["to-10%", "to-100%", "to-[12%]"],
    [
        "to-inherit",
        "to-current",
        "to-transparent",
        "to-white",
        "to-red-300",
        "to-[#ffffff]",
    ],
];
