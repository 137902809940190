export default function FormatNumberToBRL(numero, digitos) {
    if (numero === null || numero === 0 || numero === "0") {
        return "0,00";
    }

    if (typeof numero !== "undefined") {
        let formatted = numero;
        if (typeof formatted === "string") {
            formatted = formatted.replace(",", ".");
        }
        formatted = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(formatted);
        formatted = formatted.replace("R$ ", "");
        return formatted;
    }

    return numero;
}
