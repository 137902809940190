export type PromiseEntry<ResponseType> = [
    id: string | number,
    starter: () => Promise<ResponseType>,
];

export default class PromisePool<ResponseType> {
    private pool: Record<
        string,
        Promise<readonly [id: string | number, result: ResponseType | null]>
    >;
    private poolSize: number;
    private entries: PromiseEntry<ResponseType>[];

    constructor(poolSize: number, entries?: PromiseEntry<ResponseType>[]) {
        this.entries = entries || [];
        this.pool = {};
        this.poolSize = poolSize;
    }

    append(entries: PromiseEntry<ResponseType>[]): void {
        this.entries = this.entries.concat(entries);
    }

    setPoolSize(size: number): void {
        this.poolSize = size;
    }

    async start(): Promise<Record<string | number, ResponseType | null>> {
        const results: Record<string | number, ResponseType | null> = {};

        while (this.entries.length > 0) {
            const [id, starter] =
                this.entries.shift() as PromiseEntry<ResponseType>;
            this.pool[id] = starter()
                .then((res) => [id, res] as const)
                .catch(() => [id, null] as const);

            if (Object.keys(this.pool).length >= this.poolSize) {
                const promises = Object.values(this.pool);
                const [id, result] = await Promise.race(promises);
                results[id] = result;
                delete this.pool[id];
            }
        }

        const temp = await Promise.all(Object.values(this.pool));
        for (const entry of temp) {
            results[entry[0]] = entry[1];
        }

        return results;
    }
}
