import MoneySendIcon from "./linear";

export default MoneySendIcon;
export {
    default as MoneySendIcon,
    iconInfo as MoneySendIconInfo,
} from "./linear";

export {
    default as MoneySendBoldIcon,
    iconInfo as MoneySendBoldIconInfo,
} from "./bold";
export {
    default as MoneySendBrokenIcon,
    iconInfo as MoneySendBrokenIconInfo,
} from "./broken";
export {
    default as MoneySendBulkIcon,
    iconInfo as MoneySendBulkIconInfo,
} from "./bulk";
export {
    default as MoneySendLinearIcon,
    iconInfo as MoneySendLinearIconInfo,
} from "./linear";
export {
    default as MoneySendOutlineIcon,
    iconInfo as MoneySendOutlineIconInfo,
} from "./outline";
export {
    default as MoneySendTwotoneIcon,
    iconInfo as MoneySendTwotoneIconInfo,
} from "./twotone";
