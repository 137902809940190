import type { TailwindPattern } from "../interface";

const spacingPattern: TailwindPattern = {
    base: /(((m|p)(x|y|s|e|t|b|l|r)?)|space|gap)-/,
    variants: [
        { base: /gap-x/, arbitrary: "size" },
        { base: /gap-y/, arbitrary: "size" },
        { base: /gap/, arbitrary: "size" },
        { base: /space-x/, arbitrary: "size" },
        { base: /space-y/, arbitrary: "size" },
        { base: /mx/, arbitrary: "size" },
        { base: /my/, arbitrary: "size" },
        { base: /ms/, arbitrary: "size" },
        { base: /me/, arbitrary: "size" },
        { base: /mt/, arbitrary: "size" },
        { base: /mb/, arbitrary: "size" },
        { base: /ml/, arbitrary: "size" },
        { base: /mr/, arbitrary: "size" },
        { base: /m/, arbitrary: "size" },
        { base: /px/, arbitrary: "size" },
        { base: /py/, arbitrary: "size" },
        { base: /ps/, arbitrary: "size" },
        { base: /pe/, arbitrary: "size" },
        { base: /pt/, arbitrary: "size" },
        { base: /pb/, arbitrary: "size" },
        { base: /pl/, arbitrary: "size" },
        { base: /pr/, arbitrary: "size" },
        { base: /p/, arbitrary: "size" },
    ],
    arbitrary: "any",
};

export default spacingPattern;

export const spacingSample = [
    ["gap-x-px", "gap-x-4", "gap-x-[16px]"],
    ["gap-y-px", "gap-y-4", "gap-y-[16px]"],
    ["gap-px", "gap-4", "gap-[16px]"],
    ["space-x-px", "space-x-reverse", "space-x-4", "space-x-[16px]"],
    ["space-y-px", "space-y-reverse", "space-y-4", "space-y-[16px]"],
    ["mx-auto", "mx-px", "mx-8", "mx-1.5", "-mx-4", "mx-[15px]", "-mx-[15px]"],
    ["my-auto", "my-px", "my-8", "my-1.5", "-my-4", "my-[15px]", "-my-[15px]"],
    ["ms-auto", "ms-px", "ms-8", "ms-1.5", "-ms-4", "ms-[15px]", "-ms-[15px]"],
    ["me-auto", "me-px", "me-8", "me-1.5", "-me-4", "me-[15px]", "-me-[15px]"],
    ["mt-auto", "mt-px", "mt-8", "mt-1.5", "-mt-4", "mt-[15px]", "-mt-[15px]"],
    ["mb-auto", "mb-px", "mb-8", "mb-1.5", "-mb-4", "mb-[15px]", "-mb-[15px]"],
    ["ml-auto", "ml-px", "ml-8", "ml-1.5", "-ml-4", "ml-[15px]", "-ml-[15px]"],
    ["mr-auto", "mr-px", "mr-8", "mr-1.5", "-mr-4", "mr-[15px]", "-mr-[15px]"],
    ["m-auto", "m-px", "m-8", "m-1.5", "-m-4", "m-[15px]", "-m-[15px]"],
    ["px-auto", "px-px", "px-8", "px-1.5", "px-[15px]"],
    ["py-auto", "py-px", "py-8", "py-1.5", "py-[15px]"],
    ["ps-auto", "ps-px", "ps-8", "ps-1.5", "ps-[15px]"],
    ["pe-auto", "pe-px", "pe-8", "pe-1.5", "pe-[15px]"],
    ["pt-auto", "pt-px", "pt-8", "pt-1.5", "pt-[15px]"],
    ["pb-auto", "pb-px", "pb-8", "pb-1.5", "pb-[15px]"],
    ["pl-auto", "pl-px", "pl-8", "pl-1.5", "pl-[15px]"],
    ["pr-auto", "pr-px", "pr-8", "pr-1.5", "pr-[15px]"],
    ["p-auto", "p-px", "p-8", "p-1.5", "p-[15px]"],
];
