import ChevronLeftIcon from "./linear";

export default ChevronLeftIcon;
export {
    default as ChevronLeftIcon,
    iconInfo as ChevronLeftIconInfo,
} from "./linear";

export {
    default as ChevronLeftBoldIcon,
    iconInfo as ChevronLeftBoldIconInfo,
} from "./bold";
export {
    default as ChevronLeftBrokenIcon,
    iconInfo as ChevronLeftBrokenIconInfo,
} from "./broken";
export {
    default as ChevronLeftBulkIcon,
    iconInfo as ChevronLeftBulkIconInfo,
} from "./bulk";
export {
    default as ChevronLeftLinearIcon,
    iconInfo as ChevronLeftLinearIconInfo,
} from "./linear";
export {
    default as ChevronLeftOutlineIcon,
    iconInfo as ChevronLeftOutlineIconInfo,
} from "./outline";
export {
    default as ChevronLeftTwotoneIcon,
    iconInfo as ChevronLeftTwotoneIconInfo,
} from "./twotone";
