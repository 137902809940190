import type { TailwindPattern } from "../interface";

const effectsPattern: TailwindPattern = {
    base: /shadow|opacity|mix-blend/,
    variants: [
        /mix-blend/,
        {
            base: /shadow/,
            sufixes: [/(?= |$|(-(inner|none|sm|md|lg|[2]?xl)))/],
            arbitrary: "shadow",
        },
        { base: /shadow/, arbitrary: "any" },
        { base: /opacity/, arbitrary: "number" },
    ],
    arbitrary: "any",
};

export default effectsPattern;

export const effectsSample = [
    [
        "mix-blend-normal",
        "mix-blend-multiply",
        "mix-blend-screen",
        "mix-blend-overlay",
        "mix-blend-darken",
        "mix-blend-lighten",
        "mix-blend-color-dodge",
        "mix-blend-color-burn",
        "mix-blend-hard-light",
        "mix-blend-soft-light",
        "mix-blend-difference",
        "mix-blend-exclusion",
        "mix-blend-hue",
        "mix-blend-saturation",
        "mix-blend-color",
        "mix-blend-luminosity",
        "mix-blend-plus-lighter",
    ],
    [
        "shadow-none",
        "shadow-inner",
        "shadow",
        "shadow-sm",
        "shadow-md",
        "shadow-lg",
        "shadow-xl",
        "shadow-2xl",
        "shadow-[0_35px_60px_-15px_#ffffff]",
        "shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]",
    ],
    [
        "shadow-inherit",
        "shadow-current",
        "shadow-transparent",
        "shadow-white",
        "shadow-red-300",
        "shadow-white/10",
        "shadow-red-500/20",
        "shadow-[#ffffff]",
        "shadow-[#ffffff]/30",
    ],
    ["opacity-10", "opacity-[0.25]"],
];
