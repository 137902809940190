import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "ListLinear",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path d="M7 5.5H21" stroke="currentColor" />
            <path d="M7 12.5H21" stroke="currentColor" />
            <path d="M7 19.5H21" stroke="currentColor" />
            <path d="M3 5.5L3 5.5" stroke="currentColor" />
            <path d="M3 12.5L3 12.5" stroke="currentColor" />
            <path d="M3 19.5L3 19.5" stroke="currentColor" />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
