import { Button, Space } from "antd";
import { NotificationManager } from "react-notifications";
import { Icon } from "../App/Icon";

export default function Notification(status, message, title, errorHelp) {
    let codeHelp = null;
    if (errorHelp) {
        codeHelp = errorHelp.match(/\/(\d+)-/);
        codeHelp = codeHelp[1];
    }

    const description = (
        <Space direction="vertical">
            <p>{message}</p>

            {codeHelp && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        type="default"
                        style={{ marginBottom: 0 }}
                        onClick={() =>
                            window.FreshworksWidget("open", "article", {
                                id: codeHelp,
                            })
                        }
                        icon={<Icon name="QuestionCircleOutlined" />}>
                        Ajuda
                    </Button>
                </div>
            )}
        </Space>
    );
    const time = codeHelp ? 8000 : 5000;

    return NotificationManager[status](description, title, time);
}
