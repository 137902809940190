import TrendDownIcon from "./linear";

export default TrendDownIcon;
export {
    default as TrendDownIcon,
    iconInfo as TrendDownIconInfo,
} from "./linear";

export {
    default as TrendDownBoldIcon,
    iconInfo as TrendDownBoldIconInfo,
} from "./bold";
export {
    default as TrendDownBrokenIcon,
    iconInfo as TrendDownBrokenIconInfo,
} from "./broken";
export {
    default as TrendDownBulkIcon,
    iconInfo as TrendDownBulkIconInfo,
} from "./bulk";
export {
    default as TrendDownLinearIcon,
    iconInfo as TrendDownLinearIconInfo,
} from "./linear";
export {
    default as TrendDownOutlineIcon,
    iconInfo as TrendDownOutlineIconInfo,
} from "./outline";
export {
    default as TrendDownTwotoneIcon,
    iconInfo as TrendDownTwotoneIconInfo,
} from "./twotone";
