import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "MessageBroken",
    fill: "none",
    stroke: "currentColor",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: "1.5",
    strokeMiterlimit: "10",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M2 10.79C2 7.86001 3.35001 6.27001 6.04001 6.04001C6.28001 6.01001 6.53001 6 6.79001 6H13.19C16.38 6 17.98 7.60001 17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.82999 19.9C7.69999 19.73 7.41 19.58 7.19 19.58H6.79001C3.60001 19.58 2 18.79 2 14.79"
                stroke="currentColor"
            />
            <path
                d="M21.9791 6.79001C21.9791 3.60001 20.3791 2 17.1891 2H10.7891C7.85906 2 6.26906 3.35001 6.03906 6.04001C6.27906 6.01001 6.52906 6 6.78906 6H13.1891C16.3791 6 17.9791 7.60001 17.9791 10.79V14.79C17.9791 15.05 17.9691 15.3 17.9391 15.54C20.6291 15.31 21.9791 13.73 21.9791 10.79"
                stroke="currentColor"
            />
            <path
                d="M13.4955 13.25H13.5045"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M9.9955 13.25H10.0045"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path
                d="M6.4955 13.25H6.5045"
                stroke="currentColor"
                strokeWidth="2"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
