import type { TailwindPattern } from "../interface";

const filtersPattern: TailwindPattern = {
    base: /blur|brightness|contrast|drop-shadow|grayscale|hue-rotate|invert|saturate|sepia|backdrop/,
    variants: [
        /blur/,
        /brightness/,
        /contrast/,
        /drop-shadow/,
        /grayscale/,
        /hue-rotate/,
        /invert/,
        /saturate/,
        /sepia/,
        {
            base: /backdrop/,
            variants: [
                /backdrop-blur/,
                /backdrop-brightness/,
                /backdrop-contrast/,
                /backdrop-drop-shadow/,
                /backdrop-grayscale/,
                /backdrop-hue-rotate/,
                /backdrop-invert/,
                /backdrop-opacity/,
                /backdrop-saturate/,
                /backdrop-sepia/,
            ],
        },
    ],
};

export default filtersPattern;

export const filtersSample = [
    [
        "blur",
        "blur-none",
        "blur-sm",
        "blur-md",
        "blur-lg",
        "blur-xl",
        "blur-2xl",
        "blur-3xl",
        "blur-[3px]",
    ],
    ["brightness-50", "brightness-[1.75]"],
    ["contrast-50", "contrast-[1.75]"],
    [
        "drop-shadow",
        "drop-shadow-none",
        "drop-shadow-sm",
        "drop-shadow-md",
        "drop-shadow-lg",
        "drop-shadow-xl",
        "drop-shadow-2xl",
        "drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]",
    ],
    ["grayscale", "grayscale-0", "grayscale-[50%]"],
    ["hue-rotate-50", "hue-rotate-[120deg]"],
    ["invert", "invert-0", "invert-[.25]"],
    ["saturate-50", "saturate-[1.5]"],
    ["sepia", "sepia-0", "sepia-[.25]"],
    [
        "backdrop-blur",
        "backdrop-blur-none",
        "backdrop-blur-sm",
        "backdrop-blur-md",
        "backdrop-blur-lg",
        "backdrop-blur-xl",
        "backdrop-blur-2xl",
        "backdrop-blur-3xl",
        "backdrop-blur-[3px]",
    ],
    ["backdrop-brightness-50", "backdrop-brightness-[1.75]"],
    ["backdrop-contrast-50", "backdrop-contrast-[1.75]"],
    [
        "backdrop-drop-shadow",
        "backdrop-drop-shadow-none",
        "backdrop-drop-shadow-sm",
        "backdrop-drop-shadow-md",
        "backdrop-drop-shadow-lg",
        "backdrop-drop-shadow-xl",
        "backdrop-drop-shadow-2xl",
        "backdrop-drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]",
    ],
    ["backdrop-grayscale", "backdrop-rayscale-0", "backdrop-grayscale-[50%]"],
    ["backdrop-hue-rotate-50", "backdrop-hue-rotate-[120deg]"],
    ["backdrop-invert", "backdrop-invert-0", "backdrop-invert-[.25]"],
    ["backdrop-opacity-50", "backdrop-opacity-[1.5]"],
    ["backdrop-saturate-50", "backdrop-saturate-[1.5]"],
    ["backdrop-sepia", "backdrop-sepia-0", "sbackdrop-epia-[.25]"],
];
