import { Modules } from "../../new/modules";

export default function ComponentView(props) {
    const view = props.view;
    const paramsI = props.paramsI;
    const paramsII = props.paramsII;
    const paramsIII = props.paramsIII;
    const paramsIV = props.paramsIV;
    const paramsV = props.paramsV;

    return (
        <Modules
            tag={view}
            oldKey={view}
            oldParams={{
                paramsI,
                paramsII,
                paramsIII,
                paramsIV,
                paramsV,
            }}
        />
    );
}
