import type { FC, ReactNode } from "react";

import { classes } from "@utils/styles/tailwind/v4";

export interface ModalFooterProps {
    className?: string;
    children?: ReactNode;
}

const ModalFooter: FC<ModalFooterProps> = ({ className, children }) => {
    return (
        <div
            className={classes`flex-1 flex flex-col pt-16 before:flex-1 ${className}`}>
            {children}
        </div>
    );
};

export default ModalFooter;
