import StatusUpIcon from "./linear";

export default StatusUpIcon;
export {
    default as StatusUpIcon,
    iconInfo as StatusUpIconInfo,
} from "./linear";

export {
    default as StatusUpBoldIcon,
    iconInfo as StatusUpBoldIconInfo,
} from "./bold";
export {
    default as StatusUpBrokenIcon,
    iconInfo as StatusUpBrokenIconInfo,
} from "./broken";
export {
    default as StatusUpBulkIcon,
    iconInfo as StatusUpBulkIconInfo,
} from "./bulk";
export {
    default as StatusUpLinearIcon,
    iconInfo as StatusUpLinearIconInfo,
} from "./linear";
export {
    default as StatusUpOutlineIcon,
    iconInfo as StatusUpOutlineIconInfo,
} from "./outline";
export {
    default as StatusUpTwotoneIcon,
    iconInfo as StatusUpTwotoneIconInfo,
} from "./twotone";
