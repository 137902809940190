import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CameraOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M17.2397 22.75H6.75969C3.95969 22.75 2.17969 21.08 2.01969 18.29L1.49969 10.04C1.41969 8.79 1.84969 7.59 2.70969 6.68C3.55969 5.77 4.75969 5.25 5.99969 5.25C6.31969 5.25 6.62969 5.06 6.77969 4.76L7.49969 3.33C8.08969 2.16 9.56968 1.25 10.8597 1.25H13.1497C14.4397 1.25 15.9097 2.16 16.4997 3.32L17.2197 4.78C17.3697 5.06 17.6697 5.25 17.9997 5.25C19.2397 5.25 20.4397 5.77 21.2897 6.68C22.1497 7.6 22.5797 8.79 22.4997 10.04L21.9797 18.3C21.7997 21.13 20.0697 22.75 17.2397 22.75ZM10.8597 2.75C10.1197 2.75 9.17969 3.33 8.83969 4L8.11969 5.44C7.69969 6.25 6.88969 6.75 5.99969 6.75C5.15969 6.75 4.37968 7.09 3.79969 7.7C3.22969 8.31 2.93969 9.11 2.99969 9.94L3.51969 18.2C3.63968 20.22 4.72969 21.25 6.75969 21.25H17.2397C19.2597 21.25 20.3497 20.22 20.4797 18.2L20.9997 9.94C21.0497 9.11 20.7697 8.31 20.1997 7.7C19.6197 7.09 18.8397 6.75 17.9997 6.75C17.1097 6.75 16.2997 6.25 15.8797 5.46L15.1497 4C14.8197 3.34 13.8797 2.76 13.1397 2.76H10.8597V2.75Z"
                fill="currentColor"
            />
            <path
                d="M13.5 8.75H10.5C10.09 8.75 9.75 8.41 9.75 8C9.75 7.59 10.09 7.25 10.5 7.25H13.5C13.91 7.25 14.25 7.59 14.25 8C14.25 8.41 13.91 8.75 13.5 8.75Z"
                fill="currentColor"
            />
            <path
                d="M12 18.75C9.79 18.75 8 16.96 8 14.75C8 12.54 9.79 10.75 12 10.75C14.21 10.75 16 12.54 16 14.75C16 16.96 14.21 18.75 12 18.75ZM12 12.25C10.62 12.25 9.5 13.37 9.5 14.75C9.5 16.13 10.62 17.25 12 17.25C13.38 17.25 14.5 16.13 14.5 14.75C14.5 13.37 13.38 12.25 12 12.25Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
