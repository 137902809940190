import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "DocumentPreviousOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M9.00048 17.75C8.81048 17.75 8.62048 17.68 8.47048 17.53L6.47048 15.53C6.26048 15.32 6.19048 14.99 6.31048 14.71C6.43048 14.43 6.70048 14.25 7.00048 14.25H13.0005C13.4105 14.25 13.7505 14.59 13.7505 15C13.7505 15.41 13.4105 15.75 13.0005 15.75H8.81048L9.53048 16.47C9.82048 16.76 9.82048 17.24 9.53048 17.53C9.38048 17.68 9.19048 17.75 9.00048 17.75Z"
                fill="currentColor"
            />
            <path
                d="M6.99945 15.7499C6.80945 15.7499 6.61945 15.6799 6.46945 15.5299C6.17945 15.2399 6.17945 14.7599 6.46945 14.4699L8.46945 12.4699C8.75945 12.1799 9.23945 12.1799 9.52945 12.4699C9.81945 12.7599 9.81945 13.2399 9.52945 13.5299L7.52945 15.5299C7.37945 15.6799 7.18945 15.7499 6.99945 15.7499Z"
                fill="currentColor"
            />
            <path
                d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H14C14.41 1.25 14.75 1.59 14.75 2C14.75 2.41 14.41 2.75 14 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
                fill="currentColor"
            />
            <path
                d="M22 10.75H18C14.58 10.75 13.25 9.41999 13.25 5.99999V1.99999C13.25 1.69999 13.43 1.41999 13.71 1.30999C13.99 1.18999 14.31 1.25999 14.53 1.46999L22.53 9.46999C22.74 9.67999 22.81 10.01 22.69 10.29C22.57 10.57 22.3 10.75 22 10.75ZM14.75 3.80999V5.99999C14.75 8.57999 15.42 9.24999 18 9.24999H20.19L14.75 3.80999Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
