import TeacherIcon from "./linear";

export default TeacherIcon;
export { default as TeacherIcon, iconInfo as TeacherIconInfo } from "./linear";

export {
    default as TeacherBoldIcon,
    iconInfo as TeacherBoldIconInfo,
} from "./bold";
export {
    default as TeacherBrokenIcon,
    iconInfo as TeacherBrokenIconInfo,
} from "./broken";
export {
    default as TeacherBulkIcon,
    iconInfo as TeacherBulkIconInfo,
} from "./bulk";
export {
    default as TeacherLinearIcon,
    iconInfo as TeacherLinearIconInfo,
} from "./linear";
export {
    default as TeacherOutlineIcon,
    iconInfo as TeacherOutlineIconInfo,
} from "./outline";
export {
    default as TeacherTwotoneIcon,
    iconInfo as TeacherTwotoneIconInfo,
} from "./twotone";
