import { OnboardingChecks } from "./interface";

import getOnboardingStatus from "@services/companies/company/onboarding/getStatus";

export async function fetchChecks(
    accountId: number,
): Promise<OnboardingChecks[]> {
    const data = await getOnboardingStatus(accountId);
    if (!data) return [];
    return [
        data.acceptTerm && OnboardingChecks.ACCEPT_TERMS,
        data.nfeCertificate && OnboardingChecks.CERTIFICATE,
        data.nfeNumbers && OnboardingChecks.NF,
        data.oneHubProductCreated && OnboardingChecks.OFFERS,
        data.oneHubProductPublished && OnboardingChecks.OFFER_PUBLISHING,
        data.oneMarketplaceActivated && OnboardingChecks.INTEGRATIONS,
        data.oneProductCreated && OnboardingChecks.PRODUCTS,
    ].filter((value): value is OnboardingChecks => !!value);
}
