import axios from "axios";

import { LOCAL_STORAGE_NAMES } from "@constants/localStorage";

import { LSInstance } from "@hooks/storages/useLocalStorage";

import getSubdomain from "@utils/navigation/getSubdomain";

const api = process.env.REACT_APP_API_DOMAIN || "";

const lambdas = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

lambdas.interceptors.request.use((req) => {
    if (!req.baseURL) {
        const baseUrl = mountBaseUrl();
        req.baseURL = baseUrl;
    }
    if (req.headers.Authorization) return req;
    const token = LSInstance.getItem("token" as LOCAL_STORAGE_NAMES);
    const newToken = LSInstance.getItem(LOCAL_STORAGE_NAMES.ACCESS_TOKEN);
    if (newToken || token) {
        req.headers.Authorization = `Bearer ${newToken || token}`;
    }
    return req;
});

export default lambdas;

export function mountBaseUrl(accountName?: string): string {
    const subdomain = accountName || getSubdomain();
    return `https://${subdomain}.${api}/1.0`;
}
