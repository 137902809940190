import type { FC, ReactNode } from "react";

import { Children, cloneElement, isValidElement } from "react";

import { usePopoverContext } from "./helpers";

interface PopoverAnchorProps {
    children?: ReactNode;
}

const PopoverAnchor: FC<PopoverAnchorProps> = ({ children }) => {
    const { anchorRef, isOpen } = usePopoverContext();
    return (
        <>
            {Children.map(
                Children.only(children),
                (child) =>
                    isValidElement(child) &&
                    cloneElement(child as JSX.Element, {
                        ref: (ref: HTMLElement) => (anchorRef.current = ref),
                        disabled: !isOpen,
                    }),
            )}
        </>
    );
};

export default PopoverAnchor;
