import type { TailwindPattern } from "../interface";

const tablePattern: TailwindPattern = {
    base: /table(?=-(auto|fixed))|caption|border(?=-(spacing|collapse|separate))/,
    variants: [
        /table/,
        /caption/,
        /border-(collapse|separate)/,
        { base: /border-spacing-x/, arbitrary: "size" },
        { base: /border-spacing-y/, arbitrary: "size" },
        { base: /border-spacing/, arbitrary: "size" },
    ],
    arbitrary: "any",
};

export default tablePattern;

export const tableSample = [
    ["table-auto", "table-fixed"],
    ["caption-top", "caption-bottom"],
    ["border-collapse", "border-separate"],
    ["border-spacing-x-px", "border-spacing-x-8", "border-spacing-x-[16px]"],
    ["border-spacing-y-px", "border-spacing-y-8", "border-spacing-y-[16px]"],
    ["border-spacing-px", "border-spacing-8", "border-spacing-[16px]"],
];
