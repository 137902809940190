import type { FC } from "react";

import type { IconInfo } from "./interface";

import { classes } from "@utils/styles/tailwind/v4";

export interface BaseIconProps {
    className?: string;
    info?: IconInfo;
    title?: string;
}

const BaseIcon: FC<BaseIconProps> = ({
    info: { name, path, viewbox, ...rest } = {},
    title,
    className,
}) => (
    <svg
        viewBox={viewbox}
        {...rest}
        xmlns="http://www.w3.org/2000/svg"
        className={classes`w-24 h-24 ${className}`}>
        <title>{title ?? (name || "empty")}</title>
        {path}
    </svg>
);

export default BaseIcon;

export type MockedIcon = FC<Omit<BaseIconProps, "info">>;

export function MountMockedIcon(info: IconInfo): MockedIcon {
    const MockedIcon: MockedIcon = (props) => (
        <BaseIcon info={info} {...props} />
    );
    MockedIcon.displayName = `MockedIcon(${info.name.replace(/\s/g, "")})`;
    return MockedIcon;
}
