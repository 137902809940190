import InfoCircleIcon from "./linear";

export default InfoCircleIcon;
export {
    default as InfoCircleIcon,
    iconInfo as InfoCircleIconInfo,
} from "./linear";

export {
    default as InfoCircleBoldIcon,
    iconInfo as InfoCircleBoldIconInfo,
} from "./bold";
export {
    default as InfoCircleBrokenIcon,
    iconInfo as InfoCircleBrokenIconInfo,
} from "./broken";
export {
    default as InfoCircleBulkIcon,
    iconInfo as InfoCircleBulkIconInfo,
} from "./bulk";
export {
    default as InfoCircleLinearIcon,
    iconInfo as InfoCircleLinearIconInfo,
} from "./linear";
export {
    default as InfoCircleOutlineIcon,
    iconInfo as InfoCircleOutlineIconInfo,
} from "./outline";
export {
    default as InfoCircleTwotoneIcon,
    iconInfo as InfoCircleTwotoneIconInfo,
} from "./twotone";
