import supabase from "@configs/supabase";

export enum ConsultantGatewayPgtoType {
    ASAAS = "ASAAS",
    HOTMART = "HOTMART",
}

export interface ConsultantData {
    bypass_cnpja: boolean;
    id: string;
    id_hotmart_cnpj: string | null;
    id_hotmart_cpf: string | null;
    gateway_pgto: ConsultantGatewayPgtoType;
    name: string;
    subscription_value: number | null;
}

export async function getConsultants(): Promise<ConsultantData[]> {
    const { data } = await supabase
        .from("consultants")
        .select(
            "id, name, subscription_value, gateway_pgto, id_hotmart_cnpj, id_hotmart_cpf, bypass_cnpja",
        )
        .returns<ConsultantData[]>();
    return data || [];
}

export async function getConsultant(
    uuid: string,
): Promise<ConsultantData | null> {
    const { data } = await supabase
        .from("consultants")
        .select(
            "id, name, subscription_value, gateway_pgto, id_hotmart_cnpj, id_hotmart_cpf, bypass_cnpja",
        )
        .eq("id", uuid)
        .range(0, 0)
        .returns<ConsultantData[]>();
    return data?.[0] || null;
}
