import type { FC } from "react";

import { useMemo } from "react";

import { Modules } from "@modules";
import type { ModulesProps } from "@modules";
import { useAppModuleManager } from "@modules/utils";

const PageModule: FC<Omit<ModulesProps, "params" | "URLSearchParams">> = ({
    mainKey,
    ...props
}) => {
    const { modules } = useAppModuleManager();

    const currentParams = useMemo<
        Pick<ModulesProps, "params" | "URLSearchParams">
    >(() => {
        const md = modules.find(({ key }) => key === mainKey);
        if (!md) return {};
        return { params: md.params, URLSearchParams: md.search };
    }, [mainKey, modules]);

    return <Modules mainKey={mainKey} {...currentParams} {...props} />;
};

export default PageModule;
