import type { FC, ReactNode } from "react";

import { useCallback, useEffect, useRef, useState } from "react";

import { TabsContext } from "./helpers";
import type { SortingInfo, TabInfo } from "./interface";

import deepEqual from "@utils/objects/deepEqual";

interface TabsProps
    extends Pick<TabsContext, "tabs">,
        Partial<Pick<TabsContext, "onChange" | "onClose" | "onCloseAll">> {
    current?: string | null;
    children?: ReactNode;
    onSort?: (sorting: SortingInfo | null) => void;
}

const Tabs: FC<TabsProps> = ({
    tabs: _tabs,
    current: _current,
    onChange: _onChange,
    onClose: _onClose,
    onCloseAll: _onCloseAll,
    onSort: _onSort,
    children,
}) => {
    const [tabs, setTabs] = useState<TabInfo[]>(_tabs);
    const tabsRef = useRef(tabs);
    const [current, setCurrent] = useState(_current ?? null);
    const currentRef = useRef(current);

    useEffect(() => {
        if (_current !== currentRef.current) {
            currentRef.current = _current ?? null;
            setCurrent(_current ?? null);
        }
    }, [_current]);

    useEffect(() => {
        if (!deepEqual(_tabs, tabsRef.current)) {
            tabsRef.current = _tabs;
            setTabs(tabsRef.current);
        }
    }, [_tabs]);

    const onChange = useCallback<TabsContext["onChange"]>(
        (key) => {
            currentRef.current = key;
            setCurrent(key);
            _onChange?.(key);
        },
        [_onChange],
    );

    const onClose = useCallback<TabsContext["onClose"]>(
        (key) => {
            _onClose?.(key);
        },
        [_onClose],
    );

    const onCloseAll = useCallback<TabsContext["onCloseAll"]>(() => {
        _onCloseAll?.();
    }, [_onCloseAll]);

    const onSort = useCallback<TabsContext["onSort"]>(
        (sorting) => {
            const temp =
                typeof sorting === "function"
                    ? sorting(tabsRef.current)
                    : sorting;
            _onSort?.(temp);
        },
        [_onSort],
    );

    return (
        <TabsContext.Provider
            value={{
                tabs,
                current,
                onChange,
                onClose,
                onCloseAll,
                onSort,
            }}>
            {children}
        </TabsContext.Provider>
    );
};

export default Tabs;

export { default as TabList } from "./List";
export { default as TabPanels } from "./Panels";
