import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "TrendDownOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M 7.5004 8.7501 C 7.3104 8.7501 7.1204 8.8201 6.9704 8.9701 C 6.6804 9.2601 6.6804 9.7401 6.9704 10.0301 L 10.1704 13.2301 C 10.3304 13.3901 10.5404 13.4701 10.7704 13.4501 C 10.9904 13.4301 11.1904 13.3101 11.3204 13.1201 L 12.4104 11.4801 L 15.9604 15.0301 C 16.2504 15.3201 16.7304 15.3201 17.0204 15.0301 C 17.3104 14.7401 17.3104 14.2601 17.0204 13.9701 L 12.8204 9.7701 C 12.6604 9.6101 12.4504 9.5301 12.2204 9.5501 C 12.0004 9.5701 11.8004 9.6901 11.6704 9.8801 L 10.5804 11.5201 L 8.0304 8.9701 C 7.8804 8.8201 7.6904 8.7501 7.5004 8.7501 Z "
                fill="currentColor"
            />
            <path
                d="M 16.5 11.75 C 16.09 11.75 15.75 12.09 15.75 12.5 V 13.75 H 14.5 C 14.09 13.75 13.75 14.09 13.75 14.5 C 13.75 14.91 14.09 15.25 14.5 15.25 H 16.5 C 16.91 15.25 17.25 14.91 17.25 14.5 V 12.5 C 17.25 12.09 16.91 11.75 16.5 11.75 Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
