import MoreVerticalIcon from "./linear";

export default MoreVerticalIcon;
export {
    default as MoreVerticalIcon,
    iconInfo as MoreVerticalIconInfo,
} from "./linear";

export {
    default as MoreVerticalBoldIcon,
    iconInfo as MoreVerticalBoldIconInfo,
} from "./bold";
export {
    default as MoreVerticalBrokenIcon,
    iconInfo as MoreVerticalBrokenIconInfo,
} from "./broken";
export {
    default as MoreVerticalBulkIcon,
    iconInfo as MoreVerticalBulkIconInfo,
} from "./bulk";
export {
    default as MoreVerticalLinearIcon,
    iconInfo as MoreVerticalLinearIconInfo,
} from "./linear";
export {
    default as MoreVerticalOutlineIcon,
    iconInfo as MoreVerticalOutlineIconInfo,
} from "./outline";
export {
    default as MoreVerticalTwotoneIcon,
    iconInfo as MoreVerticalTwotoneIconInfo,
} from "./twotone";
