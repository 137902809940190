import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CloseBulk",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                opacity="0.4"
                d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
                fill="currentColor"
            />
            <path
                d="M16.773 15.7124L13.0607 12L16.773 8.28773C17.0629 7.99782 17.0629 7.51699 16.773 7.22707C16.4831 6.93716 16.0022 6.93716 15.7123 7.22707L12 10.9394L8.28769 7.22707C7.99778 6.93716 7.51694 6.93716 7.22703 7.22707C6.93712 7.51699 6.93712 7.99782 7.22703 8.28773L10.9393 12L7.22703 15.7124C6.93712 16.0023 6.93712 16.4831 7.22703 16.773C7.51694 17.0629 7.99778 17.0629 8.28769 16.773L12 13.0607L15.7123 16.773C16.0022 17.0629 16.4831 17.0629 16.773 16.773C17.0629 16.4831 17.0629 16.0023 16.773 15.7124Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
