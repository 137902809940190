import type { DataStructure } from "@redux/plugins/data-fetcher/interface";

import type { CompanyBranch } from "@old/services/companies/interface";

export type CompanyItem = Pick<
    CompanyBranch,
    | "AccountName"
    | "CompanyCpfCnpj"
    | "CompanyLogoUrl"
    | "CompanyNameCorporateName"
    | "CompanyTradeName"
    | "IDCompany"
>;

export type State = DataStructure<CompanyItem[]>;

export const moduleId = "companies" as const;
