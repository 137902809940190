import ArrowUpDownIcon from "./linear";

export default ArrowUpDownIcon;
export {
    default as ArrowUpDownIcon,
    iconInfo as ArrowUpDownIconInfo,
} from "./linear";

export {
    default as ArrowUpDownBoldIcon,
    iconInfo as ArrowUpDownBoldIconInfo,
} from "./bold";
export {
    default as ArrowUpDownBrokenIcon,
    iconInfo as ArrowUpDownBrokenIconInfo,
} from "./broken";
export {
    default as ArrowUpDownBulkIcon,
    iconInfo as ArrowUpDownBulkIconInfo,
} from "./bulk";
export {
    default as ArrowUpDownLinearIcon,
    iconInfo as ArrowUpDownLinearIconInfo,
} from "./linear";
export {
    default as ArrowUpDownOutlineIcon,
    iconInfo as ArrowUpDownOutlineIconInfo,
} from "./outline";
export {
    default as ArrowUpDownTwotoneIcon,
    iconInfo as ArrowUpDownTwotoneIconInfo,
} from "./twotone";
