export type ButtonIconVariants = "solid" | "outlined" | "ghost" | "unstyled";
export type ButtonIconSizes = "sm" | "md" | "lg" | "unset";

export const buttonIconVariants: Record<ButtonIconVariants, string> = {
    solid: "bg-doladoBlue rounded-4 text-sm font-normal text-lightGrey hover:invert-20",
    outlined:
        "border border-grey-100 rounded-4 text-sm font-normal text-grey hover:invert-40",
    ghost: "bg-black/0 hover:bg-black/10 rounded-4 font-normal text-grey",
    unstyled: "",
};

export const buttonIconSizes: Record<ButtonIconSizes, string> = {
    lg: "w-40 h-40 p-8",
    md: "w-32 h-32 p-6",
    sm: "w-24 h-24 p-4",
    unset: "",
};

export const buttonIconCommonStyle =
    "flex justify-center items-center disabled:bg-gray-200 disabled:text-gray-400 disabled:hover:bg-gray-200 disabled:hover:invert-0 disabled:hover:text-gray-400";
