import type { DetailsPageLayoutRouteParams } from "@layouts/DetailsPage/interface";

export interface Route {
    params?: Record<string, string>;
    queries?: Record<string, string | string[]>;
    body?: Record<string, any>;
    response?: Record<"success" | "failure", any>;
}

export type UseRoute<R extends Route> = R;
export type UseRouteAPI<R extends Partial<Record<"GET" | "POST", Route>>> = R;

export type Params<R extends keyof Routes> = "params" extends keyof Routes[R]
    ? NonNullable<Routes[R]["params"]>
    : Record<string, never>;
export type SearchParams<R extends keyof Routes> =
    "queries" extends keyof Routes[R]
        ? NonNullable<Routes[R]["queries"]>
        : Record<string, never>;

export enum routeNames {
    APP_ACCOUNTS_PAYLOADS = "/app/accounts/payloads",
    APP_ANALYTICS = "/app/analytics",
    APP_COMPANIES = "/app/companies",
    APP_COMPANIES_DETAILS = "/app/companies/:key",
    APP_CONSUMERS = "/app/consumers",
    APP_CONSUMERS_DETAILS = "/app/consumers/:key",
    APP_HOME = "/app/main",
    APP_OFFERS = "/app/offers",
    APP_OFFERS_DETAILS = "/app/offers/:key",
    APP_ORDERS = "/app/orders",
    APP_ORDERS_HUB = "/app/orders/hub",
    APP_ORDERS_DETAILS = "/app/orders/:key",
    APP_POLICIES_TERMS_OF_USE = "/app/terms-of-use",
    APP_PRODUCT_DETAILS = "/app/catalog/:key",
    APP_ROOT = "/app",
    APP_SKUS = "/app/catalog",
    APP_SKUS_PURCHASE_DETAILS = "/app/purchases/:key",
    APP_TAXES_MODELS = "/app/taxes/models",
    APP_TAXES_MODELS_DETAILS = "/app/taxes/models/:key",
    APP_WALLET = "/app/wallets",

    LOGIN = "/signin",
    LOGIN_REDIRECT = "/signin-redirect",
    PASSWORD_FORGOT = "/password-forgot",
    PASSWORD_RESET = "/password-reset",
    SIGNUP = "/signup",
}

export interface Routes {
    [routeNames.APP_ACCOUNTS_PAYLOADS]: UseRoute<Record<string, never>>;
    [routeNames.APP_ANALYTICS]: UseRoute<Record<string, never>>;
    [routeNames.APP_COMPANIES]: UseRoute<Record<string, never>>;
    [routeNames.APP_COMPANIES_DETAILS]: DetailsPageLayoutRouteParams &
        UseRoute<{
            queries: { account?: string };
        }>;
    [routeNames.APP_CONSUMERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_CONSUMERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_HOME]: UseRoute<Record<string, never>>;
    [routeNames.APP_OFFERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_OFFERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_ORDERS]: UseRoute<Record<string, never>>;
    [routeNames.APP_ORDERS_HUB]: UseRoute<Record<string, never>>;
    [routeNames.APP_ORDERS_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_POLICIES_TERMS_OF_USE]: UseRoute<Record<string, never>>;
    [routeNames.APP_PRODUCT_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_ROOT]: UseRoute<Record<string, never>>;
    [routeNames.APP_SKUS]: UseRoute<Record<string, never>>;
    [routeNames.APP_SKUS_PURCHASE_DETAILS]: UseRoute<{
        params: { key: string };
    }>;
    [routeNames.APP_TAXES_MODELS]: UseRoute<Record<string, never>>;
    [routeNames.APP_TAXES_MODELS_DETAILS]: DetailsPageLayoutRouteParams;
    [routeNames.APP_WALLET]: UseRoute<Record<string, never>>;

    [routeNames.LOGIN]: UseRoute<Record<string, never>>;
    [routeNames.LOGIN_REDIRECT]: UseRoute<Record<string, never>>;
    [routeNames.PASSWORD_FORGOT]: UseRoute<Record<string, never>>;
    [routeNames.PASSWORD_RESET]: UseRoute<Record<string, never>>;
    [routeNames.SIGNUP]: UseRoute<{
        queries: { id?: string };
    }>;
}
