import type { TailwindPattern } from "../interface";

const snapPattern: TailwindPattern = {
    base: /snap/,
    variants: [
        /snap-(start|end|center|align-none)/,
        /snap-(normal|always)/,
        /snap-(none|x|y|both|mandatory|proximity)/,
    ],
};

export default snapPattern;

export const snapSample = [
    ["snap-start", "snap-end", "snap-center", "snap-align-none"],
    ["snap-normal", "snap-always"],
    [
        "snap-none",
        "snap-x",
        "snap-y",
        "snap-both",
        "snap-mandatory",
        "snap-proximity",
    ],
];
