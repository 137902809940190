import type { OnboardingFlowBlock } from "./interface";

import { ModalCommonTags } from "@contexts/modal-manager-v2/relations";
import { OnboardingChecks } from "@contexts/onboarding/interface";

import type { AppModuleManagerContext } from "@modules/utils";

function goToTab(...args: Parameters<AppModuleManagerContext["addModules"]>) {
    return (add: AppModuleManagerContext["addModules"]) => () => add(...args);
}

function openModal(tag: ModalCommonTags) {
    return (set: (arg: any) => void) => () => set([{ tag }]);
}

export const accountConfigBlock: OnboardingFlowBlock = {
    index: 1,
    title: "Configurar conta",
    description:
        "Finalize os primeiros passos para aproveitar ao máximo todos os benefícios da dolado.",
    resume: "Finalize as etapas para começar a vender!",
    checks: [
        // OnboardingChecks.VIDEO_WATCHED,
        OnboardingChecks.NF,
        OnboardingChecks.CERTIFICATE,
    ],
    steps: [
        {
            index: 1,
            label: "Sua jornada começa aqui! Clique para assistir o vídeo",
            openModal: openModal(ModalCommonTags.ONBOARDING_VIDEO),
        },
        {
            index: 2,
            label: "Fornecer número e série de nota fiscal",
            check: OnboardingChecks.NF,
            openModal: openModal(ModalCommonTags.ONBOARDING_CONFIGS),
        },
        {
            index: 3,
            label: "Enviar Certificado Digital",
            check: OnboardingChecks.CERTIFICATE,
            openModal: openModal(ModalCommonTags.ONBOARDING_CONFIGS),
        },
    ],
};

export const storeConfigBlock: OnboardingFlowBlock = {
    index: 2,
    title: "Configurar loja",
    description: "Ative uma loja e publique anúncios para começar a vender.",
    resume: "Finalize as etapas para começar a vender!",
    checks: [
        OnboardingChecks.INTEGRATIONS,
        // OnboardingChecks.PRODUCTS,
        // OnboardingChecks.OFFERS,
        // OnboardingChecks.OFFER_PUBLISHING,
    ],
    steps: [
        {
            index: 1,
            label: "Ativar loja de marketplace",
            check: OnboardingChecks.INTEGRATIONS,
            redirect: goToTab([
                {
                    tag: "companyparameter",
                    title: "Integrações",
                    search: "/app/#/companyparameter?component=companyparameter&title=Integrações",
                },
            ]),
        },
        // {
        //     index: 2,
        //     label: "Adicionar produtos",
        //     check: OnboardingChecks.PRODUCTS,
        //     redirect: goToTab({
        //         key: "Catálogo Dolado",
        //         component: "skus",
        //         title: "Catálogo Dolado",
        //         URLSearchParams:
        //             "?IDCompany=&AccountName=&HasIDTypeSkuFamily=&HasIDTypeSkuFamilyDescription=&IDSku=&SkuName=&IDSkuCompany=&BarCode=&SupplierCode=&IDTypeSku=&TypeProduct=&IDBrand=&Brand=&IDCategory=&Category=&IDSupplier=&SupplierNameCorporateName=&IDTypeCompanyIntegration=&IntegrationTypeName=&IDTypeCompanyIntegrationNot=&IntegrationTypeNameNot=&IDCompanyIntegration=&IntegrationName=&IDCompanyIntegrationNot=&IntegrationNameNot=&IDTypeStatusSkuFulfillment=&TypeStatusSkuFulfillment=&IDCompanyInvoice=&CompanyNameCorporateNameInvoice=&IDStatusSku=&TypeStatusSku=&AbcCurve=&AbcCurveDesc=&Tags=&TagsDescription=&Query=&Search=&HealthType=&HealthTypeDescription=&QtyAvailable=&component=skus&title=Catálogo Dolado",
        //     }),
        // },
        // {
        //     index: 3,
        //     label: "Criar anúncio",
        //     check: OnboardingChecks.OFFERS,
        //     redirect: goToTab({
        //         key: "Meus Produtos",
        //         URLSearchParams:
        //             "/app/#/myskus?component=myskus&title=Meus Produtos",
        //         title: "Meus Produtos",
        //         component: "myskus",
        //     }),
        // },
        // {
        //     index: 4,
        //     label: "Publicar anúncio",
        //     check: OnboardingChecks.OFFER_PUBLISHING,
        //     redirect: goToTab({
        //         key: "Anúncios",
        //         component: "hub/skuoffer",
        //         title: "Anúncios",
        //         URLSearchParams:
        //             "?IDCompany=&AccountName=&IDProductHub=&IDSkuHub=&IDHubProduct=&IDSkuCompany=&SkuName=&IDSku=&BarCode=&IDTypeSku=&SkuType=&IDTypeCompanyIntegration=&IntegrationName=&Published=&PublishedDescription=&HealthType=&HealthTypeDescription=&Health=&IDCompanyIntegration=&CompanyIntegration=&IDBrand=&Brand=&Search=&IDStatus=&StatusName=&HasProduct=&DescriptionHasProduct=&component=hub/skuoffer&title=Anúncios",
        //     }),
        // },
    ],
};

export function parseOnboardingFlow(
    checks: OnboardingChecks[] | null,
    isPF = false,
):
    | {
          block: OnboardingFlowBlock;
          steps: number;
          progress: number;
          highlight: number;
      }
    | undefined {
    if (!checks) return;

    const flow: OnboardingFlowBlock[] = [];
    if (!isPF) flow.push(accountConfigBlock);
    flow.push(storeConfigBlock);

    const block = flow
        .filter((block) =>
            block.checks.some((check) => !checks.includes(check)),
        )
        .sort((a, b) => a.index - b.index)[0];

    if (!block) return;

    const stepsWithChecks = block.steps.filter(
        (step) => step.check !== undefined,
    );

    return {
        block,
        steps: stepsWithChecks.length,
        progress: stepsWithChecks.reduce(
            (tol, step) =>
                step.check && checks.includes(step.check) ? tol + 1 : tol,
            0,
        ),
        highlight:
            stepsWithChecks.find(
                (step) => step.check && !checks.includes(step.check),
            )?.index ?? -1,
    };
}
