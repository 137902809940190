import type { FC, ReactNode } from "react";

import { useMemo } from "react";
import { createPortal } from "react-dom";

import { usePopoverContext } from "./helpers";

import {
    popoverArrowPosition,
    popoverPosition,
    popoverSizes,
} from "./interface";

import { classes } from "@utils/styles/tailwind/v4";

interface PopoverContentProps {
    arrowStyle?: string;
    children?: ReactNode;
    className?: string;
    hideArrow?: boolean;
}

const PopoverContent: FC<PopoverContentProps> = ({
    arrowStyle: _arrowStyle,
    className,
    hideArrow,
    children,
}) => {
    const { contentRef, position, size, isOpen, usePortal } =
        usePopoverContext();

    const arrowStyle = useMemo(
        () =>
            classes`absolute -z-10 w-8 h-8 bg-inherit rotate-45 ${popoverArrowPosition[position]} ${_arrowStyle}`,
        [position, _arrowStyle],
    );

    const currentStyle = useMemo(
        () =>
            classes`absolute z-popover bg-white shadow-[0px_0px_32px_-10px_rgba(0,0,0,0.6)] rounded-4 transition-transform duration-100 ${
                popoverSizes[size]
            } ${popoverPosition[position]} ${
                isOpen
                    ? "scale-100 opacity-100 pointer-events-auto touch-auto"
                    : "scale-0 opacity-0 pointer-events-none touch-none"
            } ${className}`,
        [isOpen, size, position, className],
    );

    if (usePortal) {
        return (
            <>
                {createPortal(
                    <div className="fixed z-popover top-0 left-0 pointer-events-none touch-none">
                        <div
                            ref={contentRef}
                            role="dialog"
                            className={currentStyle}>
                            {children}
                            {!hideArrow && <div className={arrowStyle} />}
                        </div>
                    </div>,
                    document.body,
                )}
            </>
        );
    }

    return (
        <div ref={contentRef} role="dialog" className={currentStyle}>
            {children}
            {!hideArrow && <div className={arrowStyle} />}
        </div>
    );
};

export default PopoverContent;
