import type { ComponentType, FC, ReactNode } from "react";
import { createContext, useContext, useEffect, useRef } from "react";

import { useStore } from "./hooks";

import type {
    ModuleBuilderFunction,
    ModuleConfigs,
    ModuleData,
} from "./utils/modules/interface";

type ReduxDynamicModuleContext<Module extends ModuleData = ModuleData> = Module;

const ReduxDynamicModuleContext = createContext<ReduxDynamicModuleContext>({
    actions: {},
    id: "",
    reducer: () => null,
    selectors: {},
    selectSlice: () => null,
});

interface ReduxDynamicModuleProps {
    builder?: ModuleBuilderFunction;
    children?: ReactNode;
    hash?: ModuleConfigs["hash"];
    module?: ModuleData;
}

export const ReduxDynamicModule: FC<ReduxDynamicModuleProps> = ({
    module: _module,
    builder,
    hash,
    children,
}) => {
    if (!_module && !builder) {
        throw new Error("Module configuration not provided");
    }

    const store = useStore();
    const module = useRef<ModuleData>(
        _module ?? (builder as ModuleBuilderFunction)({ hash }),
    ).current;

    useEffect(() => {
        store.addModules([module]);
        return () => {
            store.removeModules([module]);
        };
    }, [store, module, builder]);

    return (
        <ReduxDynamicModuleContext.Provider value={module}>
            {children}
        </ReduxDynamicModuleContext.Provider>
    );
};

export function withModule(
    configs: Pick<ReduxDynamicModuleProps, "builder" | "module">,
): <Props extends Record<string, any>>(
    Component: ComponentType<Props>,
) => FC<Props & Pick<ModuleConfigs, "hash">> {
    return <Props extends Record<string, any>>(
        Component: ComponentType<Props>,
    ) => {
        const Wrapped: FC<Props & Pick<ModuleConfigs, "hash">> = ({
            hash,
            ...props
        }) => (
            <ReduxDynamicModule {...configs} hash={hash}>
                <Component {...(props as Props)} />
            </ReduxDynamicModule>
        );
        Wrapped.displayName = `withModule(${Component.displayName})`;
        return Wrapped;
    };
}

export function useModule<
    Module extends ModuleData = ModuleData,
>(): ReduxDynamicModuleContext<Module> {
    return useContext(
        ReduxDynamicModuleContext,
    ) as ReduxDynamicModuleContext<Module>;
}
