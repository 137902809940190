import type { FC } from "react";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { routes } from "./routes";

const createRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = createRouter(routes);

const AppRouter: FC = () => <RouterProvider router={router} />;

export default AppRouter;

export { routes } from "./routes";
export { routeNames } from "./interface";
