import type { FC, ReactNode } from "react";

import { useMemo } from "react";

import { InputOverwriteContext } from "../utils";

import { classes } from "@utils/styles/tailwind/v3";

interface FormControlProps {
    id?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isReadOnly?: boolean;
    isIndeterminate?: boolean;
    className?: string;
    children?: ReactNode;
}

export const FormControl: FC<FormControlProps> = ({
    id,
    isRequired,
    isDisabled,
    isInvalid,
    isReadOnly,
    isIndeterminate,
    className = "",
    children,
}) => {
    const inputId = useMemo(
        () => id || `input-${Math.round(Math.random() * 10000)}`,
        [id],
    );
    return (
        <InputOverwriteContext.Provider
            value={{
                id: inputId,
                isDisabled,
                isIndeterminate,
                isInvalid,
                isReadOnly,
                isRequired,
            }}>
            <div
                role="group"
                className={classes`flex flex-col w-full ${className}`}>
                {children}
            </div>
        </InputOverwriteContext.Provider>
    );
};

export default FormControl;

export { default as FormErrorMessage } from "./FormErrorMessage";
export { default as FormHelperText } from "./FormHelperText";
export { default as FormLabel } from "./FormLabel";
