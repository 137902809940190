import type { TailwindPattern } from "../interface";

const roundedPattern: TailwindPattern = {
    base: /rounded/,
    variants: [
        { base: /rounded-s-/, arbitrary: "size" },
        { base: /rounded-e-/, arbitrary: "size" },
        { base: /rounded-ss-/, arbitrary: "size" },
        { base: /rounded-ee-/, arbitrary: "size" },
        { base: /rounded-se-/, arbitrary: "size" },
        { base: /rounded-es-/, arbitrary: "size" },
        { base: /rounded-t-/, arbitrary: "size" },
        { base: /rounded-b-/, arbitrary: "size" },
        { base: /rounded-l-/, arbitrary: "size" },
        { base: /rounded-r-/, arbitrary: "size" },
        { base: /rounded-tl-/, arbitrary: "size" },
        { base: /rounded-tr-/, arbitrary: "size" },
        { base: /rounded-bl-/, arbitrary: "size" },
        { base: /rounded-br-/, arbitrary: "size" },
        { base: /rounded/, arbitrary: "size" },
    ],
    arbitrary: "any",
};

export default roundedPattern;

export const roundedSample = [
    ["rounded-s-none", "rounded-s-full", "rounded-s-8", "rounded-s-[16px]"],
    ["rounded-e-none", "rounded-e-full", "rounded-e-8", "rounded-e-[16px]"],
    ["rounded-ss-none", "rounded-ss-full", "rounded-ss-8", "rounded-ss-[16px]"],
    ["rounded-ee-none", "rounded-ee-full", "rounded-ee-8", "rounded-ee-[16px]"],
    ["rounded-se-none", "rounded-se-full", "rounded-se-8", "rounded-se-[16px]"],
    ["rounded-es-none", "rounded-es-full", "rounded-es-8", "rounded-es-[16px]"],
    ["rounded-t-none", "rounded-t-full", "rounded-t-8", "rounded-t-[16px]"],
    ["rounded-b-none", "rounded-b-full", "rounded-b-8", "rounded-b-[16px]"],
    ["rounded-l-none", "rounded-l-full", "rounded-l-8", "rounded-l-[16px]"],
    ["rounded-r-none", "rounded-r-full", "rounded-r-8", "rounded-r-[16px]"],
    ["rounded-tl-none", "rounded-tl-full", "rounded-tl-8", "rounded-tl-[16px]"],
    ["rounded-tr-none", "rounded-tr-full", "rounded-tr-8", "rounded-tr-[16px]"],
    ["rounded-bl-none", "rounded-bl-full", "rounded-bl-8", "rounded-bl-[16px]"],
    ["rounded-br-none", "rounded-br-full", "rounded-br-8", "rounded-br-[16px]"],
    ["rounded-none", "rounded-full", "rounded-8", "rounded-[16px]"],
];
