import type { FC, ReactNode } from "react";
import { createContext, useContext } from "react";

export type InputSizes = "sm" | "md" | "lg" | "unset";
export type InputVariants = "primary" | "unstyled";

export interface InputOverwriteContext {
    isInvalid?: boolean;
    isDisabled?: boolean;
    isReadOnly?: boolean;
    isIndeterminate?: boolean;
    isRequired?: boolean;
    id?: string;
    name?: string;
    size?: InputSizes;
    className?: string;
    variant?: InputVariants;
    checkedIf?: string | number | undefined | (string | number | undefined)[];
}

export interface InputProps {
    className?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
    value?: string | number | undefined | readonly string[];
}

export const InputOverwriteContext = createContext<InputOverwriteContext>({});

export function useInputOverwrite<Props extends InputProps>(
    props: Props,
    superContext?: InputOverwriteContext,
): Props & InputOverwriteContext {
    const context = useContext(InputOverwriteContext);

    let defaultChecked = props.defaultChecked;
    if (context.checkedIf && typeof props.value !== "object") {
        if (Array.isArray(context.checkedIf)) {
            defaultChecked = context.checkedIf.includes(props.value);
        } else {
            defaultChecked = context.checkedIf === props.value;
        }
    }

    const className = [
        context.className ?? "",
        superContext?.className ?? "",
        props.className ?? "",
    ].filter((el) => !!el);

    const current: InputOverwriteContext = {
        isIndeterminate:
            context.isIndeterminate ?? superContext?.isIndeterminate,
        className: className.length ? className.join(" ") : undefined,
        id: context.id || superContext?.id,
        isDisabled:
            context.isDisabled ?? superContext?.isDisabled ?? props.disabled,
        isInvalid: context.isInvalid ?? superContext?.isInvalid,
        isReadOnly:
            context.isReadOnly ?? superContext?.isReadOnly ?? props.readOnly,
        isRequired:
            context.isRequired ?? superContext?.isRequired ?? props.required,
        name: context.name || superContext?.name,
        size: context.size || superContext?.size,
        variant: context.variant || superContext?.variant,
    };

    let key: keyof InputOverwriteContext;
    for (key in current) {
        if (typeof current[key] === "undefined") {
            delete current[key];
        }
    }

    return {
        ...props,
        ...(typeof defaultChecked !== "undefined" && { defaultChecked }),
        ...current,
    };
}

interface InputOverwriteContextProviderProps {
    value: InputOverwriteContext;
    children?: ReactNode;
}

export const InputOverwriteContextProvider: FC<
    InputOverwriteContextProviderProps
> = ({ value, children }) => {
    const superValue = useInputOverwrite({}, value);

    return (
        <InputOverwriteContext.Provider value={superValue}>
            {children}
        </InputOverwriteContext.Provider>
    );
};
