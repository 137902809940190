import type { TailwindPattern } from "../interface";

const displacementPattern: TailwindPattern = {
    base: /place|justify|items|self|content-(?!none|\[)/,
    variants: [
        /place-content/,
        /place-items/,
        /place-self/,
        /justify-items/,
        /justify-self/,
        /justify/,
        /items/,
        /self/,
        /content/,
    ],
};

export default displacementPattern;

export const displacementSample = [
    [
        "place-content-center",
        "place-content-start",
        "place-content-end",
        "place-content-between",
        "place-content-around",
        "place-content-evenly",
        "place-content-baseline",
        "place-content-stretch",
    ],
    [
        "place-items-start",
        "place-items-end",
        "place-items-center",
        "place-items-baseline",
        "place-items-stretch",
    ],
    [
        "place-self-auto",
        "place-self-start",
        "place-self-end",
        "place-self-center",
        "place-self-stretch",
    ],
    [
        "justify-items-start",
        "justify-items-end",
        "justify-items-center",
        "justify-items-stretch",
    ],
    [
        "justify-self-auto",
        "justify-self-start",
        "justify-self-end",
        "justify-self-center",
        "justify-self-stretch",
    ],
    [
        "justify-normal",
        "justify-start",
        "justify-end",
        "justify-center",
        "justify-between",
        "justify-around",
        "justify-evenly",
        "justify-stretch",
    ],
    [
        "items-start",
        "items-end",
        "items-center",
        "items-baseline",
        "items-stretch",
    ],
    [
        "self-auto",
        "self-start",
        "self-end",
        "self-center",
        "self-stretch",
        "self-baseline",
    ],
    [
        "content-normal",
        "content-center",
        "content-start",
        "content-end",
        "content-between",
        "content-around",
        "content-evenly",
        "content-baseline",
        "content-stretch",
    ],
];
