import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "CreditCardsBold",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M15.22 6.55078H5.78C5.5 6.55078 5.24 6.56078 5 6.57078C2.63 6.71078 2 7.58078 2 10.2808V10.8608C2 11.4108 2.45 11.8608 3 11.8608H18C18.55 11.8608 19 11.4108 19 10.8608V10.2808C19 7.30078 18.24 6.55078 15.22 6.55078Z"
                fill="currentColor"
            />
            <path
                d="M3 13.3594C2.45 13.3594 2 13.8094 2 14.3594V17.2694C2 20.2494 2.76 20.9994 5.78 20.9994H15.22C18.19 20.9994 18.97 20.2794 19 17.4294V14.3594C19 13.8094 18.55 13.3594 18 13.3594H3ZM6.96 18.5594H5.25C4.84 18.5594 4.5 18.2194 4.5 17.8094C4.5 17.3994 4.84 17.0594 5.25 17.0594H6.97C7.38 17.0594 7.72 17.3994 7.72 17.8094C7.72 18.2194 7.38 18.5594 6.96 18.5594ZM12.55 18.5594H9.11C8.7 18.5594 8.36 18.2194 8.36 17.8094C8.36 17.3994 8.7 17.0594 9.11 17.0594H12.55C12.96 17.0594 13.3 17.3994 13.3 17.8094C13.3 18.2194 12.97 18.5594 12.55 18.5594Z"
                fill="currentColor"
            />
            <path
                d="M22.0016 13.3316V8.09156C22.0016 4.96156 20.2116 3.60156 17.5116 3.60156H8.58157C7.82157 3.60156 7.14157 3.71156 6.54157 3.94156C6.07157 4.11156 5.65157 4.36156 5.31157 4.69156C5.13157 4.86156 5.27157 5.14156 5.53157 5.14156H16.4016C18.6516 5.14156 20.4716 6.96156 20.4716 9.21156V16.3816C20.4716 16.6316 20.7416 16.7716 20.9216 16.5916C21.6116 15.8616 22.0016 14.7916 22.0016 13.3316Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
