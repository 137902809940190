import { MockedIcon, MountMockedIcon } from "../../BaseIcon";
import type { IconInfo } from "../../interface";

export const iconInfo: IconInfo = {
    name: "BoxAddOutline",
    fill: "currentColor",
    stroke: "none",
    viewbox: "0 0 24 24",
    path: (
        <>
            <path
                d="M19.0002 22.75C17.6002 22.75 16.2902 22.14 15.3902 21.07C15.3802 21.05 15.3202 21 15.2702 20.92C15.1602 20.81 15.0302 20.61 14.9202 20.42C14.4802 19.71 14.2402 18.87 14.2402 17.99C14.2402 16.53 14.8902 15.18 16.0202 14.28C16.8602 13.61 17.9202 13.24 18.9902 13.24C20.1502 13.24 21.2202 13.64 22.0802 14.39C22.2002 14.47 22.3402 14.62 22.4702 14.76C23.2802 15.65 23.7302 16.79 23.7302 17.98C23.7302 18.85 23.4902 19.7 23.0402 20.43C22.8002 20.85 22.4702 21.23 22.0902 21.55C21.2802 22.33 20.1702 22.75 19.0002 22.75ZM19.0002 14.75C18.2602 14.75 17.5602 15 16.9702 15.47C16.2002 16.08 15.7502 17.01 15.7502 18C15.7502 18.59 15.9102 19.17 16.2202 19.67C16.3002 19.81 16.3802 19.92 16.4702 20.03C16.4902 20.05 16.5502 20.11 16.6002 20.19C17.1502 20.84 18.0502 21.26 19.0002 21.26C19.7902 21.26 20.5502 20.97 21.1302 20.45C21.3902 20.23 21.6102 19.97 21.7702 19.69C22.0902 19.18 22.2502 18.6 22.2502 18.01C22.2502 17.2 21.9402 16.41 21.3802 15.8C21.3102 15.72 21.2302 15.64 21.1502 15.58C20.5202 15.02 19.7902 14.75 19.0002 14.75Z"
                fill="currentColor"
            />
            <path
                d="M20.49 18.73H17.5C17.09 18.73 16.75 18.39 16.75 17.98C16.75 17.57 17.09 17.23 17.5 17.23H20.49C20.9 17.23 21.24 17.57 21.24 17.98C21.24 18.39 20.91 18.73 20.49 18.73Z"
                fill="currentColor"
            />
            <path
                d="M19 20.26C18.59 20.26 18.25 19.92 18.25 19.51V16.52C18.25 16.11 18.59 15.77 19 15.77C19.41 15.77 19.75 16.11 19.75 16.52V19.51C19.75 19.93 19.41 20.26 19 20.26Z"
                fill="currentColor"
            />
            <path
                d="M11.9993 13.2999C11.8693 13.2999 11.7393 13.2699 11.6193 13.1999L2.78934 8.0899C2.42934 7.8799 2.30935 7.41987 2.51935 7.05987C2.72935 6.69987 3.18934 6.57985 3.53934 6.78985L11.9894 11.6799L20.3893 6.81988C20.7493 6.60988 21.2094 6.7399 21.4094 7.0899C21.6194 7.4499 21.4893 7.90987 21.1393 8.11987L12.3693 13.1999C12.2593 13.2599 12.1293 13.2999 11.9993 13.2999Z"
                fill="currentColor"
            />
            <path
                d="M12 22.3598C11.59 22.3598 11.25 22.0198 11.25 21.6098V12.5398C11.25 12.1298 11.59 11.7898 12 11.7898C12.41 11.7898 12.75 12.1298 12.75 12.5398V21.6098C12.75 22.0198 12.41 22.3598 12 22.3598Z"
                fill="currentColor"
            />
            <path
                d="M11.9989 22.7499C11.1189 22.7499 10.2389 22.5599 9.5589 22.1699L4.2189 19.2099C2.7689 18.4099 1.62891 16.4799 1.62891 14.8199V9.1599C1.62891 7.4999 2.7689 5.57994 4.2189 4.76994L9.5589 1.80992C10.9189 1.03992 13.0589 1.03992 14.4289 1.80992L19.7689 4.76994C21.2189 5.56994 22.3589 7.4999 22.3589 9.1599V14.8199C22.3589 14.9199 22.3589 14.9999 22.3389 15.0999C22.2889 15.3599 22.0989 15.5799 21.8489 15.6599C21.5989 15.7499 21.3189 15.6899 21.1089 15.5199C19.9589 14.5199 18.1789 14.4799 16.9689 15.4499C16.1989 16.0599 15.7489 16.9899 15.7489 17.9799C15.7489 18.5699 15.9089 19.1499 16.2189 19.6499C16.2989 19.7899 16.3789 19.8999 16.4689 20.0099C16.6189 20.1799 16.6789 20.4099 16.6389 20.6299C16.5989 20.8499 16.4589 21.0399 16.2589 21.1499L14.4289 22.1599C13.7489 22.5599 12.8789 22.7499 11.9989 22.7499ZM11.9989 2.74992C11.3789 2.74992 10.7489 2.87993 10.2989 3.12993L4.95891 6.08995C3.98891 6.61995 3.1489 8.0599 3.1489 9.1599V14.8199C3.1489 15.9199 3.99891 17.3599 4.95891 17.8899L10.2989 20.8499C11.2089 21.3599 12.7989 21.3599 13.7089 20.8499L14.8289 20.2299C14.4589 19.5599 14.2589 18.7799 14.2589 17.9799C14.2589 16.5199 14.9089 15.1699 16.0389 14.2699C17.3989 13.1799 19.3489 12.9499 20.8689 13.5999V9.13994C20.8689 8.03994 20.0189 6.59993 19.0589 6.06993L13.7189 3.10991C13.2489 2.87991 12.6189 2.74992 11.9989 2.74992Z"
                fill="currentColor"
            />
        </>
    ),
};

const Icon: MockedIcon = MountMockedIcon(iconInfo);

export default Icon;
