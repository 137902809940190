import { thunk } from "redux-thunk";
import { enableMapSet } from "immer";

import ordersCreate from "./modules/hub/offers/create";
import { moduleRelation } from "./modules";
// import { moduleRelation } from "./examples/modules";
import type { RootState } from "./modules";

import {
    applyDynamicMiddleware,
    composeEnhancers,
    createDynamicStore,
    devToolsMiddleware,
} from "./utils";
import type { ModuleFetcher } from "./utils/interface";
import type { ModuleData } from "./utils/modules/interface";

import common from "@old/appRedux/reducers/Common";
import settings from "@old/appRedux/reducers/Settings";

const initModules: ModuleData[] = [
    {
        fixed: true,
        id: "common",
        reducer: common,
        actions: {},
        selectors: {},
        selectSlice: () => null,
    },
    {
        fixed: true,
        id: "settings",
        reducer: settings,
        actions: {},
        selectors: {},
        selectSlice: () => null,
    },
    ordersCreate,
];

const moduleFetcher = ((id) =>
    import(
        `./modules${moduleRelation[id as keyof typeof moduleRelation]}/index.ts`
    )) satisfies ModuleFetcher;

// const moduleFetcher = ((id) =>
//     import(
//         `./examples/modules${
//             moduleRelation[id as keyof typeof moduleRelation]
//         }/index.ts`
//     )) satisfies ModuleFetcher;

export function configureStore() {
    enableMapSet();
    const store = createDynamicStore<RootState>(
        initModules,
        moduleFetcher,
        composeEnhancers(applyDynamicMiddleware(thunk), devToolsMiddleware()),
    );
    store.initPersistor();
    return store;
}

export * from "./components";
export * from "./hooks";
