import { REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import reducers from "./reducers";
import selectorsSetup from "./selectors";

import { moduleId } from "./interface";
import type { State } from "./interface";

import { createModule, hydrate } from "@redux/utils";
import { DEFAULT_STATUS } from "@redux/utils/helpers";
import type { ModuleConfigs } from "@redux/utils/interface";

const initialState: State = {
    consultant: null,
    data: {},
    flow: {
        step: null,
        type: null,
    },
    message: null,
    payloadInfo: {
        finished: false,
        updatedAt: new Date().toISOString(),
    },
    paymentMethods: {
        data: [],
        status: DEFAULT_STATUS.PENDING,
    },
    status: DEFAULT_STATUS.PENDING,
    hydrated: false,
};

export const configs: ModuleConfigs<State, typeof moduleId> = {
    id: moduleId,
    fixed: false,
    persist: {
        storage,
        whitelist: ["payloadInfo"],
    },
};

const module = createModule(
    {
        initialState,
        reducers,
        extraReducers: (builder) => {
            builder.addCase(REHYDRATE, hydrate(moduleId));
        },
        selectors: (creator) => ({
            getModuleStatus: creator(
                (state) => [!!state, !!state?.hydrated],
                (live, hydrated) => ({ live, hydrated }),
            ),
        }),
        extraSelectors: selectorsSetup,
    },
    configs,
);

export default module;
export const { actions, selectors } = module;
