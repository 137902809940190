import type { InputSizes, InputVariants } from "../utils";

export const inputVariants: Record<InputVariants, string> = {
    primary:
        "bg-white border border-grey-100 rounded-4 placeholder:text-grey-200 focus:border-doladoBlue-300",
    unstyled: "",
};

export const inputSizes: Record<InputSizes, string> = {
    lg: "min-h-48 px-24",
    md: "min-h-40 px-16",
    sm: "min-h-32 px-8",
    unset: "",
};

export const inputGroupPaddings: Record<
    InputSizes,
    { left: string; right: string }
> = {
    lg: { left: "pl-48", right: "pr-48" },
    md: { left: "pl-40", right: "pr-40" },
    sm: { left: "pl-32", right: "pr-32" },
    unset: { left: "", right: "" },
};

export const inputCommonStyle =
    "w-full text-sm focus:outline focus:outline-0 placeholder:text-sm disabled:bg-grey-50 disabled:border-grey-100 disabled:outline-0 disabled:text-grey-400 disabled:placeholder:text-grey-300 read-only:bg-grey-50 read-only:border-grey-100 read-only:outline-0 read-only:pointer-events-none read-only:touch-none read-only:placeholder:text-grey-300";

export const inputInvalidStyle =
    "border-red disabled:border-red read-only:border-red focus:border-red";
