import LocationIcon from "./linear";

export default LocationIcon;
export {
    default as LocationIcon,
    iconInfo as LocationIconInfo,
} from "./linear";

export {
    default as LocationBoldIcon,
    iconInfo as LocationBoldIconInfo,
} from "./bold";
export {
    default as LocationBrokenIcon,
    iconInfo as LocationBrokenIconInfo,
} from "./broken";
export {
    default as LocationBulkIcon,
    iconInfo as LocationBulkIconInfo,
} from "./bulk";
export {
    default as LocationLinearIcon,
    iconInfo as LocationLinearIconInfo,
} from "./linear";
export {
    default as LocationOutlineIcon,
    iconInfo as LocationOutlineIconInfo,
} from "./outline";
export {
    default as LocationTwotoneIcon,
    iconInfo as LocationTwotoneIconInfo,
} from "./twotone";
