export default function FormatNumberToUSA(numero) {
    if (
        numero === "" ||
        numero === 0 ||
        numero === undefined ||
        numero === null
    ) {
        numero = 0;
    } else if (typeof numero === "string") {
        numero = numero.replaceAll(".", "");
        numero = numero.replace(",", ".");
        numero = parseFloat(numero);
    }

    return numero;
}
