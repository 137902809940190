import type {
    ActionReducerMapBuilder,
    ReducerCreators,
} from "@reduxjs/toolkit";

import { formatModuleName, flatReducers, remapActions } from "./helpers";

import type {
    ModuleBuilderFunction,
    ModuleConfigs,
    ModuleData,
} from "./interface";

import type {
    ReducerBuilderActionCaller,
    ReducerParsedRelation,
    ReducerRelation,
} from "../reducers/interface";
import { createSlice } from "../slice";
import { createSelectors } from "../selectors";
import type {
    SelectorParsedRelation,
    SelectorRelation,
} from "../selectors/interface";

interface CreateModuleOptions<
    State,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<
        Record<string, any>,
        SelectorRelation<State | undefined>
    >,
> {
    initialState: State | (() => State);
    reducers: Reducers;
    extraReducers?: (builder: ActionReducerMapBuilder<State>) => void;
    selectors: Selectors;
    extraSelectors?: ExtraSelectors;
}

export function createModule<
    State,
    ID extends string,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<Record<string, any>, NonNullable<unknown>>,
>(
    {
        reducers,
        selectors,
        extraSelectors,
        ...options
    }: CreateModuleOptions<State, Reducers, Selectors, ExtraSelectors>,
    configs: ModuleConfigs<State, ID>,
): ModuleData<
    State,
    ID,
    ReducerParsedRelation<Reducers>,
    Selectors,
    ExtraSelectors
> {
    let actions: Record<string, any> = {};

    const actionCaller = ((actionTag) => {
        return actions[actionTag] || (() => ({ type: "", payload: null }));
    }) as ReducerBuilderActionCaller<State, ReducerParsedRelation<Reducers>>;

    let temp: any = reducers;
    if (typeof reducers === "function") {
        temp = (creator: ReducerCreators<State>) => {
            const plain = reducers(creator, actionCaller);
            return flatReducers(plain);
        };
    } else {
        temp = flatReducers(temp);
    }

    const name = formatModuleName(configs);
    const slice = createSlice({ ...options, name, reducers: temp });

    actions = slice.actions;

    const selectSlice = (root: Record<string, any>) =>
        root[name] as State | undefined;

    const mainSelectors = createSelectors(selectSlice, selectors);

    let extra = {} as ExtraSelectors extends (...args: any[]) => infer RL
        ? RL
        : NonNullable<unknown>;
    if (extraSelectors) {
        extra = extraSelectors(selectSlice) as ExtraSelectors extends (
            ...args: any[]
        ) => infer RL
            ? RL
            : NonNullable<unknown>;
    }

    return {
        actions: remapActions(slice.actions),
        fixed: configs.fixed,
        hash: configs.hash,
        id: configs.id,
        persist: configs.persist,
        reducer: slice.reducer,
        selectSlice,
        selectors: { ...mainSelectors, ...extra },
    } as ModuleData<
        State,
        ID,
        ReducerParsedRelation<Reducers>,
        Selectors,
        ExtraSelectors
    >;
}

export function createModuleCreator<
    State,
    ID extends string,
    Reducers extends ReducerRelation<State, any>,
    Selectors extends SelectorRelation<State | undefined>,
    ExtraSelectors extends (
        rootSelector: (root: Record<string, any>) => State | undefined,
    ) => SelectorParsedRelation<
        Record<string, any>,
        SelectorRelation<State | undefined>
    >,
>(
    options: CreateModuleOptions<State, Reducers, Selectors, ExtraSelectors>,
    defaultConfigs: ModuleConfigs<State, ID>,
): ModuleBuilderFunction<
    State,
    ID,
    ReducerParsedRelation<Reducers>,
    Selectors,
    ExtraSelectors
> {
    return (configs) =>
        createModule(options, { ...defaultConfigs, ...configs }) as ModuleData<
            State,
            ID,
            ReducerParsedRelation<Reducers>,
            Selectors,
            ExtraSelectors
        >;
}
