import type { TailwindPattern } from "../interface";

const sizingPattern: TailwindPattern = {
    base: /((((max|min)-)?(w|h))|size)-/,
    variants: [
        { base: /size/, arbitrary: "size" },
        { base: /max-w/, arbitrary: "size" },
        { base: /max-h/, arbitrary: "size" },
        { base: /min-w/, arbitrary: "size" },
        { base: /min-h/, arbitrary: "size" },
        { base: /w/, arbitrary: "size" },
        { base: /h/, arbitrary: "size" },
    ],
    arbitrary: "any",
};

export default sizingPattern;

export const sizingSample = [
    [
        "size-auto",
        "size-full",
        "size-fit",
        "size-max",
        "size-min",
        "size-px",
        "size-64",
        "size-1/2",
        "size-[50%]",
        "size-[calc(100%-32px)]",
    ],
    [
        "max-w-auto",
        "max-w-full",
        "max-w-fit",
        "max-w-max",
        "max-w-min",
        "max-w-px",
        "max-w-64",
        "max-w-1/2",
        "max-w-[50%]",
        "max-w-[calc(100%-32px)]",
    ],
    [
        "max-h-auto",
        "max-h-full",
        "max-h-fit",
        "max-h-max",
        "max-h-min",
        "max-h-px",
        "max-h-64",
        "max-h-1/2",
        "max-h-[50%]",
        "max-h-[calc(100%-32px)]",
    ],
    [
        "min-w-auto",
        "min-w-full",
        "min-w-fit",
        "min-w-max",
        "min-w-min",
        "min-w-px",
        "min-w-64",
        "min-w-1/2",
        "min-w-[50%]",
        "min-w-[calc(100%-32px)]",
    ],
    [
        "min-h-auto",
        "min-h-full",
        "min-h-fit",
        "min-h-max",
        "min-h-min",
        "min-h-px",
        "min-h-64",
        "min-h-1/2",
        "min-h-[50%]",
        "min-h-[calc(100%-32px)]",
    ],
    [
        "w-auto",
        "w-full",
        "w-fit",
        "w-max",
        "w-min",
        "w-px",
        "w-64",
        "w-1/2",
        "w-[50%]",
        "w-[calc(100%-32px)]",
    ],
    [
        "h-auto",
        "h-full",
        "h-fit",
        "h-max",
        "h-min",
        "h-px",
        "h-64",
        "h-1/2",
        "h-[50%]",
        "h-[calc(100%-32px)]",
    ],
];
