import type { FC, ReactNode } from "react";

import { useDrawerContext } from "./helpers";
import { drawerCloseButtonIconStyle } from "./interface";

import ButtonIcon from "../ButtonIcon";
import { ReactComponent as CloseIcon } from "../Icons/mocks/close/linear.svg";

import { classes } from "@utils/styles/tailwind/v4";

export interface DrawerHeaderProps {
    buttonStyle?: string;
    children?: ReactNode;
    className?: string;
    hideButton?: boolean;
    iconStyle?: string;
}

const DrawerHeader: FC<DrawerHeaderProps> = ({
    hideButton,
    className,
    buttonStyle = "",
    iconStyle = "",
    children,
}) => {
    const { aria, variant, onClose } = useDrawerContext();
    return (
        <header id={aria.label} className={classes`relative p-16 ${className}`}>
            {!hideButton && (
                <ButtonIcon
                    variant="ghost"
                    size="md"
                    icon={
                        <CloseIcon
                            role="img"
                            className={`${drawerCloseButtonIconStyle[variant]} ${iconStyle}`}
                        />
                    }
                    label="close-drawer"
                    title="Fechar"
                    className={`absolute top-8 right-8 hover:bg-transparent ${buttonStyle}`}
                    onClick={onClose}
                />
            )}
            {children}
        </header>
    );
};

export default DrawerHeader;
