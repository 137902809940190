import {
    applyPatternChecker,
    filterPartInfo,
    filterPatternMatches,
    parsePartsInfo,
} from "./helpers";

import { patternCheckers } from "./patterns";

export function classes(fixed: TemplateStringsArray, ...variable: string[]) {
    return mergeClasses(
        ...fixed
            .flatMap((str, idx) =>
                idx !== 0 ? [variable[idx - 1], str] : [str],
            )
            .map((el) => el.trim())
            .filter((el) => !!el),
    );
}

export function mergeClasses(...relations: string[]): string {
    const classes = relations.flatMap((rlt) =>
        rlt.split(" ").filter((el) => !!el),
    );
    classes.reverse();

    const checked: string[] = [];
    const deleted: string[] = [];

    for (const pattern of patternCheckers) {
        const matches = applyPatternChecker(pattern, classes);
        filterPatternMatches(matches, checked, deleted);
    }

    const partsRelation = classes
        .filter((cls) => !checked.includes(cls) && !deleted.includes(cls))
        .map(parsePartsInfo);

    for (const parts of partsRelation) {
        if (checked.includes(parts[0]) || deleted.includes(parts[0])) continue;
        checked.push(parts[0]);
        const temp = filterPartInfo(partsRelation, parts).map(([cls]) => cls);
        deleted.push(...temp);
    }

    return checked.join(" ");
}
