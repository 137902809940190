import { parseInstanceDefaultValues } from "../helpers";
import { warrantyTimeTypeOptions } from "../utils";

import { moduleId } from "../interface";
import type { CreateOfferPayload, State, UserErrors } from "../interface";

import type { RootState } from "../../../..";

import { createReducers } from "@redux/utils";
import { DEFAULT_STATUS } from "@redux/utils/helpers";

import type { CompanyIntegration } from "@old/services/companies/integrations/interface";
import postOffer from "@old/services/hub/offers/post";
import syncOffer from "@old/services/hub/offers/sync";
import type { PostOfferPayload } from "@old/services/hub/offers/post";
import getHubResourcesInfo from "@old/services/hub/resources/getInfo";
import type { Sku } from "@old/services/skus/interface";

interface CreateOfferParams {
    autoSync?: boolean;
    companyIntegrationId: string | number;
    typeCompanyIntegrationId: string | number;
    payload: CreateOfferPayload;
}

type CreateOfferActionPayload = Pick<State, "offerInfo" | "userErrors">;

export default createReducers({} as State)((creator) => ({
    resetState: creator.reducer((state) => {
        state.userErrors = [];
        state.offerInfo = null;
        state.status = DEFAULT_STATUS.IDLE;
    }),
    createOffer: creator.asyncThunk(
        async (
            {
                autoSync,
                companyIntegrationId,
                typeCompanyIntegrationId,
                payload: userInput,
            }: CreateOfferParams,
            { signal, getState, rejectWithValue },
        ) => {
            const root = getState() as RootState;
            const state = root[moduleId] as State;

            // REDUX INFORMATION
            const sku = state.sku.data as Sku;
            const integration = state?.integrations.list.find(
                (integration) =>
                    integration.IDCompanyIntegration == companyIntegrationId,
            ) as CompanyIntegration;
            const typeData =
                state.integrations.typeData[typeCompanyIntegrationId];
            const instanceData =
                state.integrations.instanceData[
                    integration.IDCompanyIntegration
                ] || {};

            const actionPayload: CreateOfferActionPayload = {
                offerInfo: null,
                userErrors: [],
            };

            // MAIN DATA VALIDATIONS
            const titleMaxLength = typeCompanyIntegrationId === 40 ? 255 : 60;
            if (userInput.HubProductName.length > titleMaxLength) {
                actionPayload.userErrors.push({
                    type: "payload",
                    fieldName: "HubProductName",
                    messages: [
                        `Você ultrapassou o limite de ${titleMaxLength} caracteres`,
                    ],
                });
            }

            const priceSell = parseFloat(sku.PriceSell || "0");
            if (isNaN(userInput.PricingSet) || !userInput.PricingSet) {
                actionPayload.userErrors.push({
                    type: "payload",
                    fieldName: "PricingSet",
                    messages: ["Valor de venda não pode estar em branco"],
                });
            } else if (userInput.PricingSet < priceSell) {
                actionPayload.userErrors.push({
                    type: "payload",
                    fieldName: "PricingSet",
                    messages: [
                        "O Preço de venda não pode ser menor que o preço de custo",
                    ],
                });
            } else if (Math.abs(userInput.PricingSet - priceSell) < 1e-4) {
                actionPayload.userErrors.push({
                    type: "payload",
                    fieldName: "PricingSet",
                    messages: [
                        "O Preço de venda não pode ser igual ao preço de custo",
                    ],
                });
            }

            if (instanceData.offerType && !userInput.ListingTypeId) {
                actionPayload.userErrors.push({
                    type: "payload",
                    fieldName: "ListingTypeId",
                    messages: [
                        "Selecione o tipo do anúncio no canal de vendas",
                    ],
                });
            }

            if (actionPayload.userErrors.length) {
                return rejectWithValue(actionPayload);
            }

            // SPECIFICATIONS VALIDATIONS
            const { definitions, records } = typeData.specifications;
            const HubProductSpecification: PostOfferPayload["HubProductSpecification"] =
                [];
            if (userInput.HubProductSpecification) {
                // USER INPUT PROVIDED
                for (const def of definitions) {
                    const input = userInput.HubProductSpecification.find(
                        ({ id }) =>
                            id == def.IDStockKeepingUnitSpecificationsFieldId,
                    );
                    const option = def.FieldValue.find(
                        ({ Value }) => Value == input?.value,
                    );

                    const value = input?.value;
                    const error: Extract<UserErrors, { type: "payload" }> = {
                        type: "payload",
                        fieldName: `${def.IDStockKeepingUnitSpecificationsFieldId}`,
                        messages: [],
                    };

                    if (def.IsRequired && !value) {
                        error.messages.push("Campo não pode estar em branco");
                    }

                    switch (def.Type) {
                        case "text_area":
                        case "string":
                            if (
                                def.ValueMaxLength &&
                                value &&
                                value.length > def.ValueMaxLength
                            ) {
                                error.messages.push(
                                    `Campo deve ter no máximo ${def.ValueMaxLength} caracteres`,
                                );
                            }
                            break;
                        case "number":
                            if (
                                (def.IsRequired && !value) ||
                                (value &&
                                    value !== "NA" &&
                                    isNaN(parseFloat(value)))
                            ) {
                                error.messages.push(
                                    "Valor do campo não é numérico",
                                );
                            }
                            break;
                        case "boolean":
                        case "list":
                            if (
                                !option &&
                                (def.IsRequired || (value && value !== "NA"))
                            ) {
                                error.messages.push(
                                    "Selecione uma opção válida",
                                );
                            }
                            break;
                    }

                    if (error.messages.length) {
                        actionPayload.userErrors.push(error);
                        continue;
                    }

                    HubProductSpecification.push({
                        FieldName: def.FieldName,
                        IDStockKeepingUnitSpecificationsFieldId:
                            def.IDStockKeepingUnitSpecificationsFieldId,
                        IsVariant: def.IsVariant,
                        FieldValue: input
                            ? [
                                  {
                                      Value: input.value,
                                      ...(option && {
                                          IDStockKeepingUnitSpecificationsFieldValues:
                                              option.IDStockKeepingUnitSpecificationsFieldValues,
                                      }),
                                  },
                              ]
                            : [],
                    });
                }

                if (actionPayload.userErrors.length) {
                    return rejectWithValue(actionPayload);
                }
            } else {
                // NO USER INPUT PROVIDED - FALLBACK TO SAVED DATA
                for (const def of definitions) {
                    const record = records.find(
                        ({ externalId }) =>
                            externalId ==
                            def.IDStockKeepingUnitSpecificationsFieldId,
                    );
                    const option = def.FieldValue.find(
                        ({ Value }) => Value == record?.value,
                    );
                    HubProductSpecification.push({
                        FieldName: def.FieldName,
                        IDStockKeepingUnitSpecificationsFieldId:
                            def.IDStockKeepingUnitSpecificationsFieldId,
                        IsVariant: def.IsVariant,
                        FieldValue: record
                            ? [
                                  {
                                      Value: record.value,
                                      ...(option && {
                                          IDStockKeepingUnitSpecificationsFieldValues:
                                              option.IDStockKeepingUnitSpecificationsFieldValues,
                                      }),
                                  },
                              ]
                            : [],
                    });
                }
            }

            const Condition = parseInstanceDefaultValues(
                instanceData,
                userInput.Condition,
                "productCondition",
            );

            const ListingTypeId = parseInstanceDefaultValues(
                instanceData,
                userInput.ListingTypeId,
                "offerType",
            );
            const ListingTypeDescription = `${
                instanceData.offerType?.find(
                    ({ value }) => value == ListingTypeId,
                )?.label || ""
            }`;

            const OfficialStore = parseInstanceDefaultValues(
                instanceData,
                userInput.OfficialStore,
                "officialStore",
            );

            const ShippingFree = parseInstanceDefaultValues(
                instanceData,
                userInput.ShippingFree,
                "shippingFree",
            );
            const ShippingLocalPickUp = parseInstanceDefaultValues(
                instanceData,
                userInput.ShippingLocalPickUp,
                "shippingLocalPickUp",
            );
            const ShippingMode = parseInstanceDefaultValues(
                instanceData,
                userInput.ShippingMode,
                "shippingMode",
            );

            const WarrantyType = parseInstanceDefaultValues(
                instanceData,
                userInput.WarrantyType,
                "warrantyType",
            );

            const payload = {
                AdvertisementStatus: 1,
                AnswerTitle: "",
                CategoryId: typeData.category?.IDCategoryFrom || "",
                CategoryPath: typeData.category?.CategoryFromTree || "",
                Comments: "",
                Condition: `${Condition || ""}`,
                HubEcommerceDescription: userInput.HubEcommerceDescription,
                HubProductName: userInput.HubProductName,
                HubProductSalesPolicy: [],
                HubProductSpecification,
                IDCompanyIntegration: `${integration.IDCompanyIntegration}`,
                IDHubProduct: null,
                IDHubProductQuestionAnswerTemplate: null,
                IDProduct: sku.IDSku,
                IDProductHub: userInput.IDProductHub || "",
                LeadTimeDays: userInput.LeadTimeDays || null,
                ListingTypeDescription,
                ListingTypeId: `${ListingTypeId || ""}`,
                OfficialStore: `${OfficialStore || ""}`,
                PathName: integration.PathName || "",
                PricingSet: userInput.PricingSet,
                ShippingFree: `${ShippingFree || ""}`,
                ShippingLocalPickUp: `${ShippingLocalPickUp || ""}`,
                ShippingMode: `${ShippingMode || ""}`,
                ShowWithoutStock: 0,
                Visible: 0,
                WarrantyTime: userInput.WarrantyTime || "7",
                WarrantyTimeType:
                    userInput.WarrantyTimeType ||
                    warrantyTimeTypeOptions[0].value,
                WarrantyType: `${WarrantyType || ""}`,
            } satisfies PostOfferPayload;

            const createdOffer = await postOffer(payload, {
                accountName: integration.AccountName,
                signal,
            });

            if (signal.aborted) {
                actionPayload.userErrors.push({ type: "canceled" });
                return rejectWithValue(actionPayload);
            }

            if (createdOffer.__success) {
                actionPayload.offerInfo = {
                    accountName: createdOffer.data.AccountName,
                    id: createdOffer.data.IDHubProduct,
                };
                if (autoSync) {
                    const params = await getHubResourcesInfo(
                        typeCompanyIntegrationId,
                        { ResourceType: "sku" },
                        { accountName: integration.AccountName, signal },
                    );

                    if (signal.aborted) {
                        actionPayload.userErrors.push({ type: "canceled" });
                        return rejectWithValue(actionPayload);
                    }

                    const updateProductParam = params.find(
                        (item) => item.Resource === "UpdateProduct",
                    );

                    const updateSkuParam = params.find(
                        (item) => item.Resource === "UpdateSku",
                    );

                    const funcs = [];
                    if (!updateProductParam) {
                        actionPayload.userErrors.push({
                            type: "sync",
                            status: 500,
                            message:
                                "Recurso para sincronizar produto não encontrado",
                        });
                    } else {
                        funcs.push(() =>
                            syncOffer(typeCompanyIntegrationId, {
                                IDHubProduct: createdOffer.data.IDHubProduct,
                                IDTypeSalesChannelResource:
                                    updateProductParam.IDTypeSalesChannelResource,
                            }),
                        );
                    }

                    if (typeCompanyIntegrationId == 7) {
                        if (!updateSkuParam) {
                            actionPayload.userErrors.push({
                                type: "sync",
                                status: 500,
                                message:
                                    "Recurso para sincronizar sku não encontrado",
                            });
                        } else {
                            funcs.push(() =>
                                syncOffer(typeCompanyIntegrationId, {
                                    IDHubProduct:
                                        createdOffer.data.IDHubProduct,
                                    IDTypeSalesChannelResource:
                                        updateSkuParam.IDTypeSalesChannelResource,
                                }),
                            );
                        }
                    }

                    if (actionPayload.userErrors.length) {
                        return rejectWithValue(actionPayload);
                    }

                    const responses = await Promise.all(
                        funcs.map((func) => func()),
                    );

                    for (const res of responses) {
                        if (!res.__success) {
                            actionPayload.userErrors.push({
                                type: "sync",
                                status: res.statusCode,
                                message: res.message,
                            });
                        }
                    }

                    if (actionPayload.userErrors.length) {
                        return rejectWithValue(actionPayload);
                    }
                }
                return actionPayload;
            } else {
                actionPayload.userErrors.push({
                    type: "create",
                    status: createdOffer.statusCode,
                    message: createdOffer.message,
                });
                return rejectWithValue(actionPayload);
            }
        },
        {
            options: {
                condition: (
                    { companyIntegrationId, typeCompanyIntegrationId },
                    { getState },
                ) => {
                    const root = getState() as RootState;
                    const state = root[moduleId];
                    const skuExists =
                        state?.sku.status &&
                        state.sku.status !== DEFAULT_STATUS.PENDING;
                    const integrationExists = state?.integrations.list.find(
                        (integration) =>
                            integration.IDCompanyIntegration ==
                            companyIntegrationId,
                    );
                    const typeDataExists =
                        state?.integrations.typeData?.[
                            typeCompanyIntegrationId
                        ];
                    const instanceDataExists =
                        state?.integrations.instanceData?.[
                            companyIntegrationId
                        ];
                    return !!(
                        skuExists &&
                        integrationExists &&
                        typeDataExists &&
                        instanceDataExists
                    );
                },
            },
            pending: (state) => {
                state.status = DEFAULT_STATUS.PROCESSING;
                state.userErrors = [];
            },
            rejected: (state, action) => {
                const payload = action.payload as
                    | CreateOfferActionPayload
                    | undefined;
                if (payload) {
                    state.offerInfo = payload.offerInfo;
                    state.userErrors = payload.userErrors;
                }
                state.status = DEFAULT_STATUS.FAILURE;
            },
            fulfilled: (state, action) => {
                state.offerInfo = action.payload.offerInfo;
                state.status = DEFAULT_STATUS.SUCCESS;
            },
        },
    ),
}));
